import { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as InfoIconBase } from "@c1/gravity-icons/dist/svg/ui-lined-information-1-24.svg";
import combineStrings from "../../../utils/combineStrings";
import mediaQuery from "../../../utils/mediaQuery";

export const BlueInfoIcon = styled(InfoIconBase)`
  fill: var(--grv-color-icon-interaction-blue-50);
  padding: 0;
  height: 20px;
  aspect-ratio: 1;
  vertical-align: text-top;
`;

// There are a series of accessibility concerns with tooltips. None of these are made easier by the fact that
// gravity has made some questionable choices with how it is implementing these classes.
// As of September 2023, the WAI docs on tooltips are *also* a bit lacking: https://www.w3.org/WAI/ARIA/apg/patterns/tooltip/
// MDN has slightly better documentation, but not really by much: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tooltip_role
// And for completeness, here's how gravity recommends implementing this: https://github-pages.cloud.capitalone.com/Gravity/gravity/components/tooltips/#technical-usage
// The issues start arising when you try to use click and keydown handlers on this while it is inside the label for an input,
// the input ends up receiving those events and retriggering the logic.
// This is further complicated by touch screens/mobile, since they will not have the ability to hover.
// Putting all of this together means that in order to give a best attempt at having a good experience for all usages of this, while
// still keeping it inside an input's label and not breaking mobile devices:
// - The tooltip trigger is the parent element of the tooltip element
// - The tooltip element has role="tooltip"
// - The tooltip element will receive .grv-tooltip and any other control classes
// - The tooltip trigger will receive .grv-tooltip__trigger, as well as optionally .grv-tooltip__info-icon
// - The tooltip trigger is aria-describedby the tooltip element itself
// - Gravity makes the tooltip appear when the trigger is hovered using :hover selectors
// - If the tooltip is forced open by clicking/dragging/touching, and escape is pressed, it will be closed.
// It's entirely possible this will have usage issues in the future and may need to be changed
// Tooltip takes the following props:
// - id = Used for the id of the actual tooltip element, the wrapper/trigger element will get `id--wrapper`.
// - className = Additional classes for the *wrapper* element, not the tooltip itself.
// - children = Used as the body of the tooltip.
// - triggerContent = Used as the body of the wrapper/trigger element.
// - size = Adds the `grv-tooltip--size` class for the given size.
// - anchor = Set the anchor position of the tooltip, defaulting to bottom-right
// - light = Use the light background for the tooltip
// - icon = Set this to use the grv-tooltip__info-icon class, which supplies a built-in icon
const Tooltip = ({
  id,
  className,
  children,
  triggerContent,
  size,
  anchor = "bottom-right",
  light = false,
  icon = false,
}) => {
  const [forceEnabled, setForceEnabled] = useState(false);

  useEffect(() => {
    const onEscape = ({ key }) => key === "Escape" && setForceEnabled(false);
    document.addEventListener("keydown", onEscape);
    return () => {
      document.removeEventListener("keydown", onEscape);
    };
  });

  return (
    <span
      role="button"
      tabIndex="0"
      id={`${id}--wrapper`}
      className={combineStrings("grv-tooltip__trigger", {
        "grv-tooltip__info-icon": icon,
        [className]: className,
      })}
      aria-describedby={id}
      aria-label="Information tooltip"
      onMouseDown={() => setForceEnabled(true)}
      onMouseUp={() => setForceEnabled(false)}
      onTouchStart={() => setForceEnabled(c => !c)}
    >
      {triggerContent}
      <span
        role="tooltip"
        id={id}
        className={combineStrings("grv-tooltip", `grv-tooltip--${anchor}`, {
          [`grv-tooltip--${size}`]: size,
          "grv-tooltip--light": light,
          "grv-tooltip--active": forceEnabled,
        })}
      >
        {children}
      </span>
    </span>
  );
};

// Gravity applies their bottom-border with a complicated selector:
// span.grv-tooltip__trigger:not(.grv-tooltip__info-icon):not(.grv-tooltip__info-icon--light)
// In order to go beyond that specificity (without using !important), we need to use & 4 times,
// giving a selector with one more class involved than the Gravity selector.
// See styled-components docs for more details:
// https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
// This component will center itself on smaller screens but needs to be contained within some
// element spanning the width of the centering area, with position relative. This can most easily
// be done by giving the containing Col or Row a position: relative.
// Alternatively, this can be omitted to instead stretch the tooltip across the entire screen
// However, there will be no inline margins on the tooltip then, and you may need to add those
// to get the correct layout.
export const BlueInfoTooltip = styled(Tooltip).attrs(() => ({
  triggerContent: <BlueInfoIcon />,
}))`
  &&&& {
    z-index: var(--grv-z-index-sticky);
    border-bottom: none;

    .grv-tooltip {
      /* Enable sane word-wrapping */
      word-wrap: normal;
      white-space: normal;

      /* 
      On larger screens, we have more breathing room. Just clamp the width to a reasonable
      value so the word wrap has the correct behavior.
    */
      width: clamp(200px, 40vw, 500px);

      /* 
      Move the top-left of the tooltip into the slight middle of the icon. 
      This is to make sure the tool tip does not cover the "i" in the icon.
    */
      margin-top: 0;
      top: 60%;
      left: 60%;
    }

    @media not (${mediaQuery.large}) {
      .grv-tooltip {
        /* 
        Reduce the clamp for medium screen width
        */
        width: clamp(180px, 30vw, 400px);
      }
    }

    @media not (${mediaQuery.medium}) {
      .grv-tooltip {
        /* 
          On small screens, the tooltip will get positioned relative to the screen or
          relative to some other containing element (like a Col). Therefore, we add a
          bit of top spacing, but otherwise want to stretch for the entire width of the
          relative element, and let word wrap push the bottom of the tooltip down as far 
          as is needed.
        */
        inset: var(--grv-size-spacing-large-1) 0 auto;
        width: unset;
      }

      /* 
        Remove the relative positioning from the tooltip trigger. 
        This allows the tooltip itself to be centered relative to another element on mobile.
      */
      position: static;
    }
  }
`;

export default Tooltip;
