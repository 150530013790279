import { forwardRef } from "react";
import combineStrings from "../../../utils/combineStrings";

const Checkbox = forwardRef(
  (
    {
      id,
      label,
      helper,
      error,
      errorReplacesHelper = true,
      compact = false,
      bold = false,
      labelProps = {},
      helperProps = {},
      errorProps = {},
      className = "",
      labelClassName = "",
      inputClassName = "",
      value,
      ...rest
    },
    ref
  ) => {
    const labelId = `${id}--label`;
    const helperId = `${id}--helper`;
    const errorId = `${id}--error`;
    const showHelper = helper && !(errorReplacesHelper && error);

    return (
      <div
        className={combineStrings("grv-form-field", className, {
          "grv-form-field--compact": compact,
        })}
      >
        <div className="grv-checkbox__wrapper">
          <input
            ref={ref}
            type="checkbox"
            checked={value}
            id={id}
            className={combineStrings("grv-checkbox", inputClassName, {
              "grv-checkbox--error": error,
              "grv-checkbox--compact": compact,
            })}
            aria-labelledby={label ? labelId : ""}
            aria-describedby={combineStrings({
              [helperId]: showHelper,
              [errorId]: error,
            })}
            aria-invalid={error ? "true" : "false"}
            {...rest}
          />
          {label && (
            <label
              htmlFor={id}
              id={labelId}
              className={combineStrings("grv-label grv-label--checkbox", labelClassName, {
                "grv-label--compact": compact,
                "grv-weight--semibold": bold,
              })}
              {...labelProps}
            >
              {label}
            </label>
          )}
        </div>
        {showHelper && (
          <span id={helperId} className="grv-form-field__helper" {...helperProps}>
            {helper}
          </span>
        )}
        {error && (
          <span
            id={errorId}
            role="alert"
            className="grv-form-field__helper grv-form-field__helper--error"
            {...errorProps}
          >
            {error}
          </span>
        )}
      </div>
    );
  }
);

export default Checkbox;
