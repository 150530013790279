export const NO_ACTIVITIES = "NONE";
export const CHECK_CASHING_AND_GIFT_CARDS = "CHECK_CASHING_AND_GIFT_CARDS";
export const CHECK_CASHING_AND_GIFT_CARDS_THIRD_PARTY = "CHECK_CASHING_AND_GIFT_CARDS_THIRD_PARTY";
export const MONEY_SERVICES = "MONEY_SERVICES";
export const MONEY_SERVICES_EXCHANGE = "MONEY_SERVICES_EXCHANGE";
export const DIGITAL_CURRENCY = "DIGITAL_CURRENCY";
export const DIGITAL_CURRENCY_EXCHANGE = "DIGITAL_CURRENCY_EXCHANGE";
export const FINANCIAL_SERVICES = "FINANCIAL_SERVICES";
export const FINANCIAL_SERVICES_GENERAL = "FINANCIAL_SERVICES_GENERAL";
export const FINANCIAL_SERVICES_OTHER = "FINANCIAL_SERVICES_OTHER";
export const THIRD_PARTY_TRANSACTIONS_PAYMENTS = "THIRD_PARTY_TRANSACTIONS_PAYMENTS";
export const THIRD_PARTY_ACH_TRANSACTIONS = "THIRD_PARTY_ACH_TRANSACTIONS";
export const THIRD_PARTY_TRANSACTIONS = "THIRD_PARTY_TRANSACTIONS";
export const DEPOSIT_BROKER = "DEPOSIT_BROKER";
export const DEPOSIT_BROKER_CLIENTS = "DEPOSIT_BROKER_CLIENTS";
export const DEPOSIT_BROKER_DEPOSITS_PLACEMENT = "DEPOSIT_BROKER_DEPOSITS_PLACEMENT";
export const FINANCIAL_TRANSACTIONS_PROCESSING = "FINANCIAL_TRANSACTIONS_PROCESSING";
export const NON_STANDARD_FINANCIAL_SERVICES = "NON_STANDARD_FINANCIAL_SERVICES";
export const NON_STANDARD_FINANCIAL_SERVICES_DEPOSITS_PLACEMENT =
  "NON_STANDARD_FINANCIAL_SERVICES_DEPOSITS_PLACEMENT";
export const NON_STANDARD_FINANCIAL_SERVICES_CLIENTS = "NON_STANDARD_FINANCIAL_SERVICES_CLIENTS";
export const NON_STANDARD_FINANCIAL_SERVICES_OTHER = "NON_STANDARD_FINANCIAL_SERVICES_OTHER";
export const INTEREST_RATE_EXCEED = "INTEREST_RATE_EXCEED";
export const ATM_OPERATIONS = "ATM_OPERATIONS";
export const GAMBLING_SERVICES = "GAMBLING_SERVICES";
export const GAMBLING_ACTIVITIES = "GAMBLING_ACTIVITIES";
export const GAMBLING_MAJORITY_REVENUE = "GAMBLING_MAJORITY_REVENUE";
export const CURRENCY_TRANSPORTATION = "CURRENCY_TRANSPORTATION";
export const MARIJUANA = "MARIJUANA";
export const TIER_ONE_OR_TWO_MARIJUANA = "TIER_ONE_OR_TWO_MARIJUANA";
export const TIER_THREE_MARIJUANA = "TIER_THREE_MARIJUANA";
export const MARIJUANA_REVENUE_MAJORITY = "MARIJUANA_REVENUE_MAJORITY";
export const JEWELRY = "JEWELRY";
export const ANNUAL_JEWELRY_PURCHASE = "ANNUAL_JEWELRY_PURCHASE";
export const ANNUAL_JEWELRY_PURCHASE_AND_SALES = "ANNUAL_JEWELRY_PURCHASE_AND_SALES";
export const ANNUAL_JEWELRY_SALES = "ANNUAL_JEWELRY_SALES";
export const JEWELRY_NO_AML_PROGRAM = "JEWELRY_NO_AML_PROGRAM";
export const JEWELRY_FOREIGN_PURCHASE = "JEWELRY_FOREIGN_PURCHASE";
export const JEWELRY_DIRECT_TRANSPORT = "JEWELRY_DIRECT_TRANSPORT";
export const MISCELLANEOUS_ACTIVITIES = "MISCELLANEOUS_ACTIVITIES";
export const RETAIL_PHARMACY = "RETAIL_PHARMACY";
export const TELEMARKETING = "TELEMARKETING";
export const FOREIGN_BUSINESS = "FOREIGN_BUSINESS";
export const MINING = "MINING";
export const GOODS_MOVEMENT = "GOODS_MOVEMENT";
export const VEHICLE_PURCHASE = "VEHICLE_PURCHASE";
export const ADULT_ENTERTAINMENT = "ADULT_ENTERTAINMENT";

export const ZERO_TO_FIFTYK = "ZERO_TO_FIFTYK";
export const FIFTYK_TO_HUNDREDK = "FIFTYK_TO_HUNDREDK";
export const OVER_HUNDREDK = "OVER_HUNDREDK";

// This is used to map the chariot content to the matching questions and subquestions in the HRBR pages
export const FIRST_PAGE_BUNDLE_TO_MAIN_QUESTION_MAPPING = {
  checkCashingAndGiftCardsQuestion: CHECK_CASHING_AND_GIFT_CARDS,
  moneyServicesQuestion: MONEY_SERVICES,
  digitalCurrencyQuestion: DIGITAL_CURRENCY,
  financialServicesQuestion: FINANCIAL_SERVICES,
  thirdPartyTransactionsQuestion: FINANCIAL_TRANSACTIONS_PROCESSING,
  depositBrokerQuestions: DEPOSIT_BROKER,
};
export const FIRST_PAGE_MAIN_FIELDS_AND_SUBFIELDS = {
  CHECK_CASHING_AND_GIFT_CARDS: [CHECK_CASHING_AND_GIFT_CARDS_THIRD_PARTY],
  MONEY_SERVICES: [MONEY_SERVICES_EXCHANGE],
  DIGITAL_CURRENCY: [DIGITAL_CURRENCY_EXCHANGE],
  FINANCIAL_SERVICES: [
    FINANCIAL_SERVICES_OTHER,
    NON_STANDARD_FINANCIAL_SERVICES,
    INTEREST_RATE_EXCEED,
    NON_STANDARD_FINANCIAL_SERVICES_OTHER,
  ],
  FINANCIAL_TRANSACTIONS_PROCESSING: [THIRD_PARTY_TRANSACTIONS_PAYMENTS, THIRD_PARTY_ACH_TRANSACTIONS],
  DEPOSIT_BROKER: [DEPOSIT_BROKER_CLIENTS, DEPOSIT_BROKER_DEPOSITS_PLACEMENT],
};

export const SECOND_PAGE_MAIN_FIELDS_AND_SUBFIELDS = {
  ATM_OPERATIONS: [],
  GAMBLING_SERVICES: [GAMBLING_ACTIVITIES, GAMBLING_MAJORITY_REVENUE],
  CURRENCY_TRANSPORTATION: [],
  MARIJUANA: [TIER_ONE_OR_TWO_MARIJUANA, TIER_THREE_MARIJUANA, MARIJUANA_REVENUE_MAJORITY],
  JEWELRY: [
    ANNUAL_JEWELRY_PURCHASE,
    ANNUAL_JEWELRY_SALES,
    JEWELRY_NO_AML_PROGRAM,
    JEWELRY_FOREIGN_PURCHASE,
    JEWELRY_DIRECT_TRANSPORT,
  ],
  MISCELLANEOUS_ACTIVITIES: [],
};

// If the parent(key) is changed, the child (value) needs to be reset
export const CONDITIONAL_PARENTS = {
  ANNUAL_JEWELRY_PURCHASE: JEWELRY_NO_AML_PROGRAM,
  ANNUAL_JEWELRY_SALES: JEWELRY_NO_AML_PROGRAM,
  JEWELRY_FOREIGN_PURCHASE: JEWELRY_DIRECT_TRANSPORT,
  GAMBLING_ACTIVITIES: GAMBLING_MAJORITY_REVENUE,
  TIER_THREE_MARIJUANA: MARIJUANA_REVENUE_MAJORITY,
  NON_STANDARD_FINANCIAL_SERVICES: INTEREST_RATE_EXCEED,
};

// technically these are all subfields, but because of how they're split to the third page they're being treated as main fields here
export const THIRD_PAGE_MAIN_FIELDS_AND_SUBFIELDS = {
  RETAIL_PHARMACY: [],
  TELEMARKETING: [],
  FOREIGN_BUSINESS: [],
  MINING: [],
  GOODS_MOVEMENT: [],
  VEHICLE_PURCHASE: [],
  ADULT_ENTERTAINMENT: [],
};
// This is used to map the chariot content to the matching questions and subquestions in the HRBR pages
export const SECOND_PAGE_BUNDLE_TO_MAIN_QUESTION_MAPPING = {
  atmOperationsQuestion: ATM_OPERATIONS,
  gamblingQuestion: GAMBLING_SERVICES,
  currencyTransportationQuestion: CURRENCY_TRANSPORTATION,
  marijuanaQuestion: MARIJUANA,
  jewelryQuestion: JEWELRY,
  miscellaneousActivitiesQuestion: MISCELLANEOUS_ACTIVITIES,
};

export const THIRD_PAGE_BUNDLE_TO_MAIN_QUESTION_MAPPING = {
  miscellaneousActivitiesQuestion: MISCELLANEOUS_ACTIVITIES,
};

export const STANDARD_CONDITIONAL_RENDERS = {
  JEWELRY_DIRECT_TRANSPORT: JEWELRY_FOREIGN_PURCHASE,
  GAMBLING_MAJORITY_REVENUE: GAMBLING_ACTIVITIES,
  MARIJUANA_REVENUE_MAJORITY: TIER_THREE_MARIJUANA,
  INTEREST_RATE_EXCEED: NON_STANDARD_FINANCIAL_SERVICES,
};

export const jewelryRevenueRangeDisplay = {
  [ZERO_TO_FIFTYK]: "$0 - $50,000",
  [FIFTYK_TO_HUNDREDK]: "$50,001 - $100,000",
  [OVER_HUNDREDK]: "Over $100,000",
};

export const ALL_STANDARD_LOGIC_HRBR_ENUMS = {
  businessHasCheckCashingAndGiftCards: CHECK_CASHING_AND_GIFT_CARDS,
  businessHasCheckCashingAndGiftCardsThirdParty: CHECK_CASHING_AND_GIFT_CARDS_THIRD_PARTY,
  businessHasMoneyServices: MONEY_SERVICES,
  businessHasMoneyServicesExchange: MONEY_SERVICES_EXCHANGE,
  businessHasDigitalCurrency: DIGITAL_CURRENCY,
  businessHasDigitalCurrencyExchange: DIGITAL_CURRENCY_EXCHANGE,
  businessHasFinancialServices: FINANCIAL_SERVICES,
  businessHasFinancialServicesOther: FINANCIAL_SERVICES_OTHER,
  businessHasNonStandardFinancialServices: NON_STANDARD_FINANCIAL_SERVICES,
  businessHasInterestRateExceed: INTEREST_RATE_EXCEED,
  businessHasNonStandardFinancialServicesOther: NON_STANDARD_FINANCIAL_SERVICES_OTHER,
  businessHasFinancialTransactionsProcessing: FINANCIAL_TRANSACTIONS_PROCESSING,
  businessHasThirdPartyTransactionsPayments: THIRD_PARTY_TRANSACTIONS_PAYMENTS,
  businessHasThirdPartyAchTransactions: THIRD_PARTY_ACH_TRANSACTIONS,
  businessHasDepositBroker: DEPOSIT_BROKER,
  businessHasDepositBrokerClients: DEPOSIT_BROKER_CLIENTS,
  businessHasDepositBrokerDepositsPlacement: DEPOSIT_BROKER_DEPOSITS_PLACEMENT,
  businessHasAtmOperations: ATM_OPERATIONS,
  businessHasGamblingServices: GAMBLING_SERVICES,
  businessHasGamblingActivities: GAMBLING_ACTIVITIES,
  businessHasGamblingMajorityRevenue: GAMBLING_MAJORITY_REVENUE,
  businessHasCurrencyTransportation: CURRENCY_TRANSPORTATION,
  businessHasMarijuana: MARIJUANA,
  businessHasTierOneOrTwoMarijuana: TIER_ONE_OR_TWO_MARIJUANA,
  businessHasTierThreeMarijuana: TIER_THREE_MARIJUANA,
  businessHasMarijuanaRevenueMajority: MARIJUANA_REVENUE_MAJORITY,
  businessHasJewelry: JEWELRY,
  businessHasJewelryForeignPurchase: JEWELRY_FOREIGN_PURCHASE,
  businessHasJewelryDirectTransport: JEWELRY_DIRECT_TRANSPORT,
  businessHasMiscellaneousActivities: MISCELLANEOUS_ACTIVITIES,
  businessHasRetailPharmacy: RETAIL_PHARMACY,
  businessHasTelemarketing: TELEMARKETING,
  businessHasForeignBusiness: FOREIGN_BUSINESS,
  businessHasMining: MINING,
  businessHasGoodsMovement: GOODS_MOVEMENT,
  businessHasVehiclePurchase: VEHICLE_PURCHASE,
  businessHasAdultEntertainment: ADULT_ENTERTAINMENT,
};
// ANNUAL_JEWELRY_* is a number range.
// For JEWELRY_NO_AML_PROGRAM we want to send this value if the user selects NO
export const ALL_SPECIAL_LOGIC_HRBR_ENUMS = {
  businessHasAnnualJewelryPurchase: ANNUAL_JEWELRY_PURCHASE,
  businessHasAnnualJewelrySales: ANNUAL_JEWELRY_SALES,
  businessHasJewelryNoAmlProgram: JEWELRY_NO_AML_PROGRAM,
};

export const SPECIAL_DISPLAY_QUESTIONS = {
  businessHasAnnualJewelryPurchase:
    "Annual purchases of precious metals, stones, jewelry and/or finished goods",
  businessHasAnnualJewelrySales: "Annual sales of precious metals, stones, jewelry and/or finished goods",
};

// For displaying questions, separate each bundle's questions into mainQuestions and subQuestions
export const lookupBusinessServiceQuestions = ({ pageToMap, content, mapNewMainQuestions = false }) => {
  const mainQuestions = {};
  const subQuestions = {};
  let newMainQuestions;
  if (content) {
    Object.entries(pageToMap).forEach(bundleAndQuestion => {
      const bundleName = bundleAndQuestion[0];
      const mainQuestionKey = bundleAndQuestion[1];
      const mainQuestionLabel = content[bundleName][mainQuestionKey];

      mainQuestions[mainQuestionKey] = mainQuestionLabel;
      subQuestions[mainQuestionKey] = Object.entries(content[bundleName])
        .filter(([key]) => key !== mainQuestionKey)
        .map(([subQuestionCategory, subQuestion]) => ({ [subQuestionCategory]: subQuestion }));
    });
    // Miscellaneous activities needs to be mapped from sub questions to main questions
    if (mapNewMainQuestions) {
      newMainQuestions = subQuestions[MISCELLANEOUS_ACTIVITIES].reduce(
        (questionText, businessActivity) =>
          Object.assign(questionText, {
            [Object.keys(businessActivity)[0]]: [Object.values(businessActivity)[0]],
          }),
        {}
      );
    }
  }
  return [mainQuestions, subQuestions, newMainQuestions];
};

export const convertKeyIntoCamelCase = key =>
  key
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join("");

export const serviceIncludedInPage = (questionKey, pageStructureMapping) =>
  Object.keys(pageStructureMapping)
    .flatMap(key => [key, ...pageStructureMapping[key]])
    .includes(questionKey);
