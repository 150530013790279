import styled from "styled-components";
import fdicImage from "../../../assets/FDIC.png";
import equalHousingImage from "../../../assets/EqualHousingOpportunity.png";

const FDICImg = styled.img.attrs({
  alt: "Member of FDIC Image",
  src: fdicImage,
})`
  height: 26px;
  width: 43px;
  mix-blend-mode: darken;
`;

const EqualHousingOpportunityImg = styled.img.attrs({
  alt: "Equal Housing Opportunity Image",
  src: equalHousingImage,
})`
  margin-left: 32px;
  height: 32px;
  width: 30px;
  mix-blend-mode: darken;
`;

const LegalIconsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
`;

const FooterIcons = () => (
  <LegalIconsWrapper>
    <FDICImg />
    <EqualHousingOpportunityImg />
  </LegalIconsWrapper>
);

export default FooterIcons;
