import styled from "styled-components";

const ExternalLink = styled.a.attrs({
  "aria-label": "", // setting the empty label is the correct accessibility behavior for links with text content
  role: "link",
  tabIndex: "0",
  target: "_blank",
  rel: "noopener noreferrer",
})`
  background: transparent;
  text-decoration: underline;
  /* May change to 40 post-gravity update, for now match progress button */
  color: var(--grv-color-icon-interaction-blue-50);
  cursor: pointer;
`;

export default ExternalLink;
