import * as yup from "yup";

const getNameSchema = ({
  required,
  minLengthInvalid,
  maxLengthInvalid,
  nameInvalid,
  combinedLengthInvalid,
  otherNameKey,
}) =>
  yup
    .string()
    .required(required)
    .min(2, minLengthInvalid)
    .max(25, maxLengthInvalid)
    .matches(/^['\sa-zA-Z.-]+$/, {
      message: nameInvalid,
      excludeEmptyString: true,
    })
    .test(
      "combinedNameLength",
      combinedLengthInvalid,
      (self, { parent }) => (self?.length ?? 0) + (parent?.[otherNameKey]?.length ?? 0) <= 39
    );

export default getNameSchema;
