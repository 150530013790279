import { forwardRef, useMemo } from "react";
import { countryTypes } from "../../../constants/countryTypes";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import Select from "../Select";
import { useDropdownId } from "../../../utils/hooks/usePageScopedId";

// Simple wrapper around Select that provides all countries in countryTypes.js as options
// with a disabled placeholder option.
// Forwards all props to the underlying Select (including ref), with the exception of:
// - placeholder - The text to set as the label of the placeholder option.
//   Its value will always be PLACEHOLDER_NONE. If not provided or is null, no placeholder
//   option is inserted
// - disablePlaceholder - Allows for specifying if the placeholder should be disabled
// - excludeUS - Exclude the US from the select options, which can be useful when this
//   field is only displayed for citizenshipStatus !== US_CITIZEN. Defaults to false
const CountrySelect = forwardRef(
  ({ placeholder, disablePlaceholder = true, excludeUS = false, name, ...rest }, ref) => {
    const countryOptions = useMemo(
      () => (excludeUS ? countryTypes.filter(({ value }) => value !== "US") : countryTypes),
      [excludeUS]
    );

    return (
      <Select id={useDropdownId(name)} ref={ref} name={name} {...rest}>
        {placeholder && (
          <option className="grv-select__placeholder" value={PLACEHOLDER_NONE} disabled={disablePlaceholder}>
            {placeholder}
          </option>
        )}
        {countryOptions.map(({ label, value }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </Select>
    );
  }
);

export default CountrySelect;
