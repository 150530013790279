import { useCallback, useState } from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import shallow from "zustand/shallow";
import { correlationId } from "../../../api/sbaoAppEndpoints";
import useStore from "../../../store/store";
import combineStrings from "../../../utils/combineStrings";
import Button from "../../shared/Button";
import Link from "../../shared/Link";
import TextInput from "../../shared/TextInput";
import { getConfigValue } from "../../../utils/configSelector";

// MISSING TEST COVERAGE: We do not bother to test setting REACT_APP_DISABLE_ERRORS = true, since this would only ever be referenced for local runs
// istanbul ignore next
const errorsDisabled =
  (window?.UI_ENV === "local" && process?.env?.REACT_APP_DISABLE_ERRORS === "true") || false;
const isValidZip = zip => /^[-0-9]{0,5}$/.test(zip);

const getLocationConfigs = state => {
  const regionalization = getConfigValue(state, "sbbRegionalizationServiceUrl");
  const autolocation = getConfigValue(state, "enterpriseAutolocationUrl");
  return [regionalization, autolocation];
};

const ZipCodeInput = styled(TextInput).attrs({
  inputMode: "numeric",
  label: "ZIP Code",
  id: "geolocation-zip-code-input",
})`
  margin-top: 20px;
  margin-bottom: 8px;
  width: 160px;
`;

const AutolocateLink = styled(Link).attrs({
  id: "geolocation-autolocation-link",
})`
  font-size: var(--grv-size-font-small-1);
`;

const SubmitButton = styled(Button).attrs({
  gravityType: "action",
})`
  width: 94px;
`;

const GeolocationModal = () => {
  const navigate = useNavigate();
  const [regionalization, autolocation] = useStore(state => getLocationConfigs(state), shallow);
  // should the modal be shown
  const [show, setShow] = useState(true);
  // the zip code of the controlled input field
  const [zip, setZip] = useState("");
  // loading state
  const [isLoading, setIsLoading] = useState(false);
  // the current error message to display
  const [errorMessage, setErrorMessage] = useState("");

  // update the zipcode and clear the current error message
  const onChange = useCallback(({ target }) => {
    setErrorMessage("");
    setZip(target.value || "");
  }, []);

  // call autolocation API to auto-populate zipcode
  const getCurrentLocation = useCallback(async () => {
    try {
      const res = await fetch(autolocation);
      if (!res.ok && !errorsDisabled) {
        throw new Error("Failed autolocation");
      }
      const text = await res.text();
      const zipIndex = text?.indexOf("zip");
      const zipcode = text?.substring(zipIndex + 6, zipIndex + 11);
      setZip(zipcode);
    } catch (err) {
      setErrorMessage("We're unable to detect your location. Enter your ZIP code");
    }
  }, [autolocation]);

  // validate zipcode and call regionalization API
  const submitZip = useCallback(async () => {
    if (zip.length === 0) {
      setErrorMessage("Enter your ZIP code");
      return;
    }
    if (!isValidZip(zip)) {
      setErrorMessage("Enter a valid 5-digit ZIP code");
      return;
    }

    setErrorMessage("");

    // pulled out to a function for single retry
    const makeRegionalizationRequest = async () => {
      const res = await fetch(regionalization + zip, {
        method: "GET",
        headers: {
          Accept: "application/json;v=1",
          "Client-Correlation-Id": correlationId,
        },
      });

      if (!res.ok && !errorsDisabled) {
        throw new Error("Failed regionalization request");
      }

      if ((await res.json()).region === "InFoot") {
        // close modal, allowing user to proceed to application
        setShow(false);
      } else {
        // redirect to out of footprint treatment
        navigate("/out-of-footprint");
      }
    };

    setIsLoading(true);
    try {
      await makeRegionalizationRequest();
    } catch (err1) {
      // attempt single retry
      try {
        await makeRegionalizationRequest();
      } catch (err2) {
        // fall back to the normal error page
        navigate("/restart-application");
      }
    }
    setIsLoading(false);
  }, [zip, navigate, regionalization]);

  if (!regionalization || !autolocation) {
    // do not launch the modal until the configs are available
    return <></>;
  }

  return (
    <ReactModal
      ariaHideApp
      preventScroll
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
      aria={{
        labelledby: "location-dialog-header",
        describedby: "location-dialog-desc",
      }}
      bodyOpenClassName={null}
      className="grv-dialog grv-dialog--normal grv-dialog--active"
      contentLabel="Where are you located?"
      htmlOpenClassName={null}
      id="location-portal"
      isOpen={show}
      overlayClassName="grv-dialog__overlay grv-dialog__overlay--dark"
      portalClassName="grv-portal"
      role="dialog"
      shouldCloseOnEsc={false}
    >
      <div className="grv-dialog__container">
        <h1 className="grv-dialog__heading" id="location-dialog-header">
          Where are you located?
        </h1>
        <div className="grv-dialog__content" id="location-dialog-content">
          <span id="location-dialog-desc">
            Before proceeding to our online application, we’ll need to make sure our business banking products
            are available in your area.
          </span>
          <ZipCodeInput
            error={errorMessage}
            value={zip}
            onChange={onChange}
            maxLength="5"
            onKeyPress={({ key }) => {
              if (key === "Enter") {
                submitZip();
              }
            }}
          />
          <AutolocateLink onClick={getCurrentLocation}>Use current location</AutolocateLink>
        </div>
        <div className="grv-dialog__buttons">
          <SubmitButton
            className={combineStrings({
              "grv-button--loading": isLoading,
              "grv-button--loading-active": isLoading,
            })}
            onClick={submitZip}
          >
            Submit
          </SubmitButton>
        </div>
      </div>
    </ReactModal>
  );
};

export default GeolocationModal;
