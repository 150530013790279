import styled from "styled-components";
import { ReactComponent as RightArrow } from "@c1/gravity-icons/dist/svg/ui-lined-arrow-right-1-24.svg";
import ExternalLink from "../../shared/ExternalLink";
import mediaQuery from "../../../utils/mediaQuery";
import { useCheckboxId } from "../../../utils/hooks/usePageScopedId";

const BlueCheckbox = styled.input`
  margin: 0 0 0 auto;
  &&&:checked {
    background-color: var(--grv-color-interaction-blue-50);
    border-color: var(--grv-color-interaction-blue-50);
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: var(--grv-size-spacing-medium-2);
  padding-inline: var(--grv-size-spacing-medium-1);
  border-radius: var(--grv-size-border-radius-4);
  ${({ $cardIsSelected }) =>
    $cardIsSelected ? "outline: 3px solid var(--grv-color-interaction-blue-50)" : ""};
  border-top: ${({ $darkBorder }) =>
    $darkBorder
      ? "8px solid var(--grv-color-interaction-blue-70)"
      : "8px solid var(--grv-color-background-core-blue-60)"};
  box-shadow: ${({ $cardIsSelected }) =>
    $cardIsSelected ? "0px 4px 16px var(--grv-color-shadow-default)" : "var(--grv-elevation-level-1)"};
`;

const ProductNameWrapper = styled.label`
  display: flex;
  flex-direction: row;
`;

// Make font smaller and semi bold on iPad only - this is a request from design to get the card content to fit on certain screen widths
const ProductName = styled.span`
  @media (${mediaQuery.mediumLarge}) and (max-width: 850px) {
    font-size: var(--grv-size-font-medium-1);
    font-weight: var(--grv-font-weight-semibold);
  }
`;

const ProductTaglinePill = styled.div`
  border-radius: 20px;
  background-color: ${({ $darkBackground }) =>
    $darkBackground
      ? "var(--grv-color-background-accent-light-blue-10)"
      : "var(--grv-color-background-informational)"};
  text-align: center;
  line-height: 26px;
  margin-block: var(--grv-size-spacing-medium-1);
`;

const DetailsLink = styled(ExternalLink)`
  text-decoration: none;
  display: inline-flex;
  align-items: center;
`;

const RightArrowIcon = styled(RightArrow)`
  fill: var(--grv-color-icon-interaction-blue-50);
  height: 12px;
  width: 12px;
  margin-left: 10px;

  & path {
    stroke-width: 2.5px;
    stroke: var(--grv-color-icon-interaction-blue-50);
  }
`;

// This ensures the focus border fits the link and the links
// are even with each other on different screen sizes
const LinkWrapper = styled.div`
  width: fit-content;
  margin-top: auto;
`;

const ProductCard = ({
  productType,
  handleCardClick,
  isSelected,
  contentReader,
  productName,
  productTagline,
  primaryContentHeader,
  primaryContent,
  secondaryContentHeader,
  secondaryContent,
  checkingProduct = false,
}) => (
  <Card $cardIsSelected={isSelected} $darkBorder={checkingProduct}>
    <ProductNameWrapper>
      <ProductName className="grv-text--medium-2 grv-weight--light">{productName}&#174;</ProductName>
      <BlueCheckbox
        type="checkbox"
        checked={isSelected}
        id={useCheckboxId(`productType_${productType}`)}
        className="grv-checkbox"
        onChange={handleCardClick}
        onKeyPress={event => event.key === "Enter" && handleCardClick()}
      />
    </ProductNameWrapper>
    <ProductTaglinePill
      className="grv-color--interaction-blue-70 grv-text--small-1 grv-weight--semibold"
      $darkBackground={checkingProduct}
    >
      {productTagline}
    </ProductTaglinePill>
    <span className="grv-text--small-2 grv-weight--semibold grv-margin__bottom--small-2">
      {primaryContentHeader}
    </span>
    <span className="grv-margin__bottom--medium-2">{primaryContent}</span>
    <span className="grv-text--small-2 grv-weight--semibold grv-margin__bottom--small-2">
      {secondaryContentHeader}
    </span>
    <span className="grv-margin__bottom--medium-1">{secondaryContent}</span>
    <LinkWrapper>
      <DetailsLink
        className="grv-text--small-2 grv-weight--semibold"
        href={contentReader("disclosure").disclosureUrl}
      >
        Additional Details
        <RightArrowIcon />
      </DetailsLink>
    </LinkWrapper>
  </Card>
);

export default ProductCard;
