import * as yup from "yup";

export const invalidPhoneNumberPatterns = [
  // cannot start with 0 or 1
  /^[0-1]{1}[0-9]{9}$/,
  // cannot have a 0 or 1 in the 4th position
  /^[0-9]{3}[0-1]{1}[0-9]{6}$/,
  // banned area codes
  /^(211|311|411|456|500|511|555|611|700|711|811|800|880|881|882|888|900|911|976|999)[0-9]{7}$/,
  // banned central office codes
  /^[0-9]{3}(211|311|411|511|555|611|711|811|911|958|959|950|976)[0-9]{4}$/,
];

export const getPhoneNumberSchema = ({ required, invalid }) =>
  yup
    .string()
    .required(required)
    .min(10, invalid)
    .max(10, invalid)
    .test("validatePhone", invalid, value => !invalidPhoneNumberPatterns.some(pat => pat.test(value)));
