import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";
import * as yup from "yup";
import hasBeneficialOwners from "../../../utils/hasBeneficialOwners";
import Checkbox from "../Checkbox";
import { Col, Grid, Row } from "../Layout";
import TextInput from "../TextInput";
import { BooleanRadioListField } from "../RadioList";
import { useCheckboxId, useInputId } from "../../../utils/hooks/usePageScopedId";

const isBOAttestationRequired = (isApplicantBusinessOwner, ownershipPercentage, hasOtherOwners) =>
  hasOtherOwners !== null &&
  !hasBeneficialOwners(isApplicantBusinessOwner, ownershipPercentage, hasOtherOwners);

export const ownershipPercentageSchema = yup.number().when("isApplicantBusinessOwner", {
  is: true,
  then: schema =>
    schema
      .typeError("Enter your ownership percentage")
      .required("Enter your ownership percentage")
      .min(1, "Ownership percentage can not be less than 1%")
      .max(100, "Ownership percentage can not be more than 100%"),
  otherwise: () => yup.mixed(), // allow anything, prevents empty string issue
});

export const hasNoBeneficialOwnersAttestationSchema = yup
  .bool()
  .when(["isApplicantBusinessOwner", "ownershipPercentage", "hasOtherOwners"], {
    is: isBOAttestationRequired,
    then: schema =>
      schema
        .typeError("Certify that the statement is complete and correct")
        .test("isAttested", "Certify that the statement is complete and correct", v => v),
    otherwise: () => yup.mixed(), // allow anything, prevents null issue
  });

const OwnershipPercentage = ({ control }) => {
  const id = useInputId("ownershipPercentage");

  return (
    <Controller
      control={control}
      name="ownershipPercentage"
      render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
        <NumberFormat
          customInput={TextInput}
          id={id}
          label="What percentage do you own?"
          type="tel"
          decimalSeparator={false}
          isNumericString={false}
          suffix="%"
          allowNegative={false}
          error={error?.message}
          onValueChange={v => onChange(v.value)}
          {...rest}
        />
      )}
    />
  );
};

const NoBOAttestation = ({ compact, noBOAttestation, register, errors }) => (
  <>
    <Row className="grv-margin__top--medium-1 grv-margin__bottom--medium-2">
      <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
        <span className="grv-text grv-text--semibold">
          I certify, to the best of my knowledge, that the following statement is complete and correct:
        </span>
      </Col>
    </Row>
    <Row className={compact ? "grv-margin__bottom--medium-2" : "grv-margin__bottom--large-3"}>
      <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
        <Checkbox
          id={useCheckboxId("hasNoBeneficialOwnersAttestation")}
          label={noBOAttestation}
          error={errors?.hasNoBeneficialOwnersAttestation?.message}
          {...register("hasNoBeneficialOwnersAttestation")}
        />
      </Col>
    </Row>
  </>
);

// Container for the business ownership fields, excluding title
// Assumes it will be contained in a FormProvider and can useFormContext to obtain hook form methods
// Assumes the following fields in the form:
// - isApplicantBusinessOwner: Boolean, true if the user clicks Yes
// - ownershipPercentage: Integer, only filled out if isApplicantBusinessOwner is true
// - hasOtherOwners: Boolean, true if the user clicks Yes
// - hasNoBeneficialOwnersAttestation: Boolean, true if user certifies, only shown in the required case
// Takes two toggles as props, hideIsApplicantBusinessOwnerToggle and hideHasOtherOwnersToggle, for disabling those sections
// Takes noBOAttestation as a prop, so that containing pages can return LoadingSpinnerPage if the content is not ready
// Takes compactNoBOAttestation as another prop, to control the spacing after the no BO attestation
const BusinessOwnershipFields = ({
  hideIsApplicantBusinessOwnerToggle = false,
  hideHasOtherOwnersToggle = false,
  noBOAttestation,
  compactNoBOAttestation = false,
}) => {
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const isApplicantBusinessOwner = watch("isApplicantBusinessOwner");
  const ownershipPercentage = watch("ownershipPercentage");
  const hasOtherOwners = watch("hasOtherOwners");
  const showNoBOAttestation = isBOAttestationRequired(
    isApplicantBusinessOwner,
    ownershipPercentage,
    hasOtherOwners
  );

  useEffect(() => {
    if (!showNoBOAttestation) {
      // when the no BO attestation is hidden, uncheck it
      setValue("hasNoBeneficialOwnersAttestation", false);
    }
  }, [showNoBOAttestation, setValue]);

  return (
    <Grid>
      {!hideIsApplicantBusinessOwnerToggle && (
        <Row>
          <Col lg={3} md={3} offset={{ lg: 2 }} sm={4}>
            <BooleanRadioListField
              control={control}
              name="isApplicantBusinessOwner"
              legendText="Are you an owner?"
            />
          </Col>
        </Row>
      )}
      {isApplicantBusinessOwner && (
        <Row>
          <Col lg={3} md={3} offset={{ lg: 2 }} sm={4}>
            <OwnershipPercentage />
          </Col>
        </Row>
      )}
      {!hideHasOtherOwnersToggle && (
        <Row>
          <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
            <BooleanRadioListField
              control={control}
              name="hasOtherOwners"
              idBase="hasOtherBeneficialOwners"
              legendText="Do you have other owners that own 25% or more of the business?"
            />
          </Col>
        </Row>
      )}
      {showNoBOAttestation && (
        <NoBOAttestation compact={compactNoBOAttestation} {...{ register, errors, noBOAttestation }} />
      )}
    </Grid>
  );
};

export default BusinessOwnershipFields;
