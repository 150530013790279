import useStore from "../../store/store";

const useProductIdMapping = () =>
  useStore(state =>
    Object.fromEntries(
      state.content
        .find(bundle => !!bundle.products)
        ?.products?.map(({ productType, productId }) => [productType, productId]) ?? []
    )
  );

export default useProductIdMapping;
