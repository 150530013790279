import { useFormContext } from "react-hook-form";
import useStore from "../../../store/store";
import { BlueInfoTooltip } from "../Tooltip";
import LoadingSpinnerPage from "../LoadingSpinnerPage";
import { BooleanRadioListField } from "../RadioList";

const getNonProfitEligibilityToolTip = state =>
  state.content
    .map(
      bundle =>
        bundle?.uiDisclosures?.find(disc => disc?.disclosureId === "nonProfitEligibilityToolTip") ?? null
    )
    .find(bundle => bundle !== null)?.bodyText;

// Pulls non profit radio button value from the store and returns
// an object that can be spread into the hook form's default values
// to set up the form correctly.
export const useNonProfitRadioDefaultValues = () => {
  const storedIsNonProfit = useStore(state => state.isNonProfit);

  return {
    isNonProfit: storedIsNonProfit || false,
  };
};

// Since this component relies on form context, it must be wrapped inside
// of a FormProvider in order to function properly.
const NonProfitRadio = () => {
  const { control } = useFormContext();
  const nonProfitEligibilityToolTip = useStore(state => getNonProfitEligibilityToolTip(state));

  if (!nonProfitEligibilityToolTip) {
    return <LoadingSpinnerPage />;
  }

  return (
    <BooleanRadioListField
      control={control}
      name="isNonProfit"
      legendText={
        <span>
          Are you a non-profit organization?{" "}
          <BlueInfoTooltip id="nonprofit-tooltip">{nonProfitEligibilityToolTip}</BlueInfoTooltip>
        </span>
      }
    />
  );
};

export default NonProfitRadio;
