import usePageTitle from "../../../utils/hooks/usePageTitle";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import Page from "../../shared/Page";
import { Grid } from "../../shared/Layout";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import Heading from "../../shared/Heading";
import useStore from "../../../store/store";
import NavigationButtons from "../../shared/NavigationButtons";
import useRouting from "../../../utils/hooks/useRouting";
import ApplicationReviewBody from "./ApplicationReviewBody";
import PrefilledBusinessApplicationReviewBody from "./PrefilledBusinessApplicationReviewBody";

const ApplicationReview = () => {
  usePageTitle("Review");
  usePreventNavigation();
  const headingRef = useFocusHeading();
  const isNewBusiness = useStore(state => state.isNewBusiness);
  const [backRoute, nextRoute] = useRouting();

  return (
    <Page hidePromoBanner>
      <Grid ref={headingRef}>
        <Heading
          step="APPLICATION REVIEW"
          mainHeading="Make sure everything looks right"
          subHeading="Before you submit, double check your application for accuracy."
        />
        {isNewBusiness ? <ApplicationReviewBody /> : <PrefilledBusinessApplicationReviewBody />}
      </Grid>
      <NavigationButtons
        preventSubmit // no need for form logic on this page - let the subforms handle that
        nextRoute={nextRoute}
        backRoute={backRoute}
      />
    </Page>
  );
};

export default ApplicationReview;
