import { useState, useCallback } from "react";
import styled from "styled-components";
import shallow from "zustand/shallow";
import { citizenshipTypeDisplay, US_CITIZEN } from "../../../constants/citizenshipTypes";
import useStore from "../../../store/store";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import Heading from "../../shared/Heading";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import LineItem, { StaticLineItem } from "../../shared/LineItem";
import NavigationButtons from "../../shared/NavigationButtons";
import Page from "../../shared/Page";
import Accordion from "./Accordion";
import { getCountryName } from "../../../constants/countryTypes";
import { formatSSN, combineParts, formatPhoneNumber } from "../../../utils/reviewFormatters";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import { ReviewDialog } from "../../../utils/reviewContext";
import BusinessOwnersIndividualDetails from "../BusinessOwnersIndividualDetails";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import { addCriticalEventToApplication } from "../../../api/sbaoAppEndpoints";
import getUiDisclosureContent from "../../../utils/getUiDisclosureContent";
import { businessControllerTypeDisplay } from "../../../constants/businessControllerTypes";
import useRouting from "../../../utils/hooks/useRouting";

const OwnershipPercentageSpan = styled.span.attrs({
  className: "grv-text grv-weight--light",
})`
  margin-left: var(--grv-size-spacing-medium-1);
`;

// pushes accordion down from heading
// applies color + sizing to titles
// adds a bottom border to the last item, which matches the top border
const OwnerReviewAccordion = styled(Accordion)`
  margin-top: var(--grv-size-spacing-large-3);

  .grv-accordion__title {
    font-size: var(--grv-size-font-medium-2);
    :hover {
      color: var(--grv-color-background-core-blue-40);
    }
  }

  .grv-accordion__item:last-child {
    border-bottom: var(--grv-size-border-width-1) solid var(--grv-color-digital-gray-15);
  }
`;

const AttestationRow = styled(CenteredRow)`
  margin-top: var(--grv-size-spacing-large-3);
  margin-bottom: var(--grv-size-spacing-large-3);
`;

// margin-bottom is just enough to get a 24px gap, since the accordion item has padding-bottom: 16px
const EditButton = styled.button.attrs({
  type: "button",
  className: "grv-text grv-weight--normal grv-text--small-2",
})`
  border: none;
  display: inline-block;
  user-select: none;
  color: var(--grv-color-interaction-blue-50);
  background: transparent;
  padding: 0;
  margin-bottom: var(--grv-size-spacing-small-2);
  cursor: pointer;
`;

// Takes in a series of selectors into the store as props, which are used to render review data
// This is done so the applicant and additional owners can use the same formatting, while also doing minimal rerenders
const OwnerItem = ({
  ownerId, // used in the ids of all LineItems
  firstNameSelector, // select the first name of the owner from the store
  lastNameSelector, // select the last name of the owner from the store
  ownershipPercentageSelector, // select the ownership percentage, as a number
  dateOfBirthSelector, // select the DOB for display
  ssnSelector, // select the *formatted* SSN, unmasked, this component handles masking
  citizenshipStatusSelector, // select the citizenship status, unformatted, this component handles formatting
  primaryCitizenshipCodeSelector, // select the country *code*, not the country name
  secondaryCitizenshipCodeSelector, // select the country *code* (or PLACEHOLDER_NONE), not the country name
  addressSelector, // select the *formatted* address
  phoneNumberSelector, // select the *formatted* phone number
  onEdit, // callback for when edit link is clicked, if not provided editing is disabled
  displayOwnershipPercentage = true, // boolean flag to display ownership percentage (should not display if applicant is BC)
  jobTitle, // display job title only for the applicant
}) => {
  const [firstName, lastName, ownershipPercentage, citizenshipStatus, secondaryCitizenship] = useStore(
    state => [
      firstNameSelector(state),
      lastNameSelector(state),
      ownershipPercentageSelector(state),
      citizenshipStatusSelector(state),
      secondaryCitizenshipCodeSelector(state),
    ],
    shallow
  );
  const fullName = `${firstName} ${lastName}`;
  const isUSCitizen = citizenshipStatus === US_CITIZEN;

  return (
    <Accordion.Item
      startActive
      title={
        <div>
          {fullName}{" "}
          <OwnershipPercentageSpan>
            {displayOwnershipPercentage && `${ownershipPercentage}%`}
          </OwnershipPercentageSpan>
        </div>
      }
      titleProps={{
        "aria-label": `Review ownership details for ${fullName}, ${ownershipPercentage}% ownership, click to collapse or expand`,
      }}
    >
      <StaticLineItem id={`item_${ownerId}_name`} title="First & Last Name" value={fullName} />
      {jobTitle && (
        <StaticLineItem
          id={`item_${ownerId}_jobTitle`}
          title="Job Title"
          value={businessControllerTypeDisplay[jobTitle]}
        />
      )}
      <LineItem id={`item_${ownerId}_dateOfBirth`} title="Date of Birth" selector={dateOfBirthSelector} />
      <LineItem
        id={`item_${ownerId}_SSN`}
        title="Social Security Number"
        selector={ssnSelector}
        mask="•••-••-"
        maskButtonAriaLabel="toggle business owner SSN view"
      />
      <StaticLineItem
        id={`item_${ownerId}_citizenship`}
        title="Citizenship Status"
        value={citizenshipTypeDisplay[citizenshipStatus]}
      />
      {!isUSCitizen && (
        <LineItem
          id={`item_${ownerId}_primaryCountryOfCitizenship`}
          title="Country of Primary Citizenship"
          selector={state => getCountryName(primaryCitizenshipCodeSelector(state))}
        />
      )}
      {!isUSCitizen && secondaryCitizenship !== PLACEHOLDER_NONE && (
        <StaticLineItem
          id={`item_${ownerId}_secondaryCountryOfCitizenship`}
          title="Country of Secondary Citizenship"
          value={getCountryName(secondaryCitizenship)}
        />
      )}
      <LineItem
        id={`item_${ownerId}_streetAddress`}
        title="Residential Street Address"
        selector={addressSelector}
      />
      <LineItem id={`item_${ownerId}_phone`} title="Phone Number" selector={phoneNumberSelector} />
      {onEdit && (
        <EditButton
          id={`businessOwnershipReview_${ownerId}_editButton`}
          aria-label={`edit ${firstName}'s personal information`}
          onClick={onEdit}
        >
          Edit {firstName}&apos;s Personal Information
        </EditButton>
      )}
    </Accordion.Item>
  );
};

const BusinessControllerAndOwnersReview = () => {
  usePageTitle("Business Owners Review");
  usePreventNavigation();
  const headingRef = useFocusHeading();
  const [
    numOwners,
    companyName,
    isApplicantBusinessOwner,
    applicationReferenceId,
    boAttestation,
    businessControllerType,
    isApplicantPrefilled,
  ] = useStore(
    state => [
      state.businessOwners.length,
      state.businessLegalName,
      state.isApplicantBusinessOwner,
      state.applicationReferenceId,
      getUiDisclosureContent(state, "BOAttestation")?.bodyText,
      state.businessControllerType,
      state.isApplicantPrefilled,
    ],
    shallow
  );
  const [editingOwnerIndex, setEditingOwnerIndex] = useState(-1);
  const closeDialog = useCallback(() => setEditingOwnerIndex(-1), []);

  const [backRoute, nextRoute] = useRouting();

  const onNext = useCallback(async () => {
    await addCriticalEventToApplication({
      applicationReferenceId,
      event: "businessOwnershipCorrectAttestation",
    });
  }, [applicationReferenceId]);

  if (!boAttestation) {
    return <LoadingSpinnerPage />;
  }

  return (
    <Page>
      <Grid ref={headingRef}>
        {isApplicantBusinessOwner || numOwners > 0 ? (
          <Heading
            step="SECTION 3 OF 4"
            mainHeading="Confirm your business controller and beneficial owner(s)"
            subHeading={`Beneficial Owners are individuals who directly or indirectly have 25% or more ownership of ${companyName}.`}
          />
        ) : (
          <Heading
            step="SECTION 3 OF 4"
            mainHeading="Confirm your business controller details"
            subHeading="As the individual with significant responsibility for the business associated with this application, review your information below."
          />
        )}
        <CenteredRow>
          <Col lg={8} md={8} sm={4}>
            <OwnerReviewAccordion>
              <OwnerItem
                ownerId="applicant"
                firstNameSelector={({ applicantFirstName }) => applicantFirstName}
                lastNameSelector={({ applicantLastName }) => applicantLastName}
                ownershipPercentageSelector={({ applicantOwnershipPercentage }) =>
                  applicantOwnershipPercentage
                }
                jobTitle={businessControllerType}
                dateOfBirthSelector={
                  isApplicantPrefilled
                    ? ({ applicantDateOfBirthPartial }) =>
                        `${applicantDateOfBirthPartial?.replace("-", "/")}/••••`
                    : ({ applicantBirthDate }) => applicantBirthDate
                }
                ssnSelector={({ applicantTaxId }) => formatSSN(applicantTaxId)}
                citizenshipStatusSelector={({ applicantCitizenshipStatus }) => applicantCitizenshipStatus}
                primaryCitizenshipCodeSelector={({ applicantCountryPrimaryCitizenship }) =>
                  applicantCountryPrimaryCitizenship
                }
                secondaryCitizenshipCodeSelector={({ applicantCountrySecondaryCitizenship }) =>
                  applicantCountrySecondaryCitizenship
                }
                addressSelector={state =>
                  combineParts([
                    state.applicantAddress1,
                    state.applicantAddress2,
                    state.applicantAddressCity,
                    state.applicantAddressState,
                    state.applicantAddressPostalCode,
                  ])
                }
                phoneNumberSelector={
                  isApplicantPrefilled
                    ? ({ applicantMobilePhonePartial }) => `(•••)•••-${applicantMobilePhonePartial}`
                    : ({ applicantMobilePhone }) => formatPhoneNumber(applicantMobilePhone)
                }
                displayOwnershipPercentage={isApplicantBusinessOwner}
              />
              {Array.from(new Array(numOwners).keys()).map(ownerIndex => (
                <OwnerItem
                  key={ownerIndex}
                  ownerId={`owner-${ownerIndex}`}
                  firstNameSelector={({ businessOwners }) => businessOwners[ownerIndex].firstName}
                  lastNameSelector={({ businessOwners }) => businessOwners[ownerIndex].lastName}
                  ownershipPercentageSelector={({ businessOwners }) =>
                    businessOwners[ownerIndex].ownershipPercentage
                  }
                  dateOfBirthSelector={({ businessOwners }) => businessOwners[ownerIndex].dateOfBirth}
                  ssnSelector={({ businessOwners }) => formatSSN(businessOwners[ownerIndex].taxId)}
                  citizenshipStatusSelector={({ businessOwners }) =>
                    businessOwners[ownerIndex].citizenshipStatus
                  }
                  primaryCitizenshipCodeSelector={({ businessOwners }) =>
                    businessOwners[ownerIndex].primaryCitizenship
                  }
                  secondaryCitizenshipCodeSelector={({ businessOwners }) =>
                    businessOwners[ownerIndex].secondaryCitizenship
                  }
                  addressSelector={({ businessOwners }) =>
                    combineParts([
                      businessOwners[ownerIndex].address.addressLine1,
                      businessOwners[ownerIndex].address.addressLine2,
                      businessOwners[ownerIndex].address.city,
                      businessOwners[ownerIndex].address.state,
                      businessOwners[ownerIndex].address.zip,
                    ])
                  }
                  phoneNumberSelector={({ businessOwners }) =>
                    formatPhoneNumber(businessOwners[ownerIndex].phone)
                  }
                  onEdit={() => setEditingOwnerIndex(ownerIndex)}
                />
              ))}
            </OwnerReviewAccordion>
          </Col>
        </CenteredRow>
        <AttestationRow>
          <Col lg={8} md={8} sm={4}>
            <span
              className="grv-text grv-text--small-1"
              id="businessControllerAndOwnersReview_boAttestation_Text"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: boAttestation }}
            />
          </Col>
        </AttestationRow>
      </Grid>
      <ReviewDialog isOpen={editingOwnerIndex >= 0} onClose={closeDialog}>
        {editingOwnerIndex >= 0 && <BusinessOwnersIndividualDetails index={editingOwnerIndex} />}
      </ReviewDialog>
      <NavigationButtons
        preventSubmit // no need for form logic on this page - let the subforms handle that
        backRoute={backRoute}
        nextRoute={nextRoute}
        onNext={onNext}
      />
    </Page>
  );
};

export default BusinessControllerAndOwnersReview;
