import { forwardRef } from "react";
import * as yup from "yup";
import {
  businessControllerTypeDisplay,
  EXECUTIVE_OFFICER,
  MANAGING_MEMBER,
  MANAGING_PARTNER,
  OWNER,
  SENIOR_MANAGER,
} from "../../../constants/businessControllerTypes";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import Select from "../Select";
import { useDropdownId } from "../../../utils/hooks/usePageScopedId";

export const businessControllerTitleSchema = yup
  .string()
  .required("Select a title")
  .notOneOf([PLACEHOLDER_NONE], "Select a title");

// Simple wrapper around Select that is populated with the options in businessControllerTypes.js
// Forwards all props, including ref, to the underlying Select component
// Defaults id prop to "businessControllerTitle" and label prop to "What is your title?"
const BusinessControllerTitleSelect = forwardRef(({ name, ...rest }, ref) => (
  <Select id={useDropdownId(name)} name={name} label="What is your title?" ref={ref} {...rest}>
    <option className="grv-select__placeholder" value={PLACEHOLDER_NONE} disabled>
      Select your title
    </option>
    {[EXECUTIVE_OFFICER, MANAGING_MEMBER, OWNER, MANAGING_PARTNER, SENIOR_MANAGER].map(value => (
      <option key={value} value={value}>
        {businessControllerTypeDisplay[value]}
      </option>
    ))}
  </Select>
));

export default BusinessControllerTitleSelect;
