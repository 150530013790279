export const UNDER_TWENTYK = "UNDER_TWENTYK";
export const TWENTYK_TO_FIFTYK = "TWENTYK_TO_FIFTYK";
export const FIFTYK_TO_HUNDREDK = "FIFTYK_TO_HUNDREDK";
export const HUNDREDK_TO_TWOHUNDREDFIFTYK = "HUNDREDK_TO_TWOHUNDREDFIFTYK";
export const TWOHUNDREDFIFTYK_TO_FIVEHUNDREDK = "TWOHUNDREDFIFTYK_TO_FIVEHUNDREDK";
// Note - enum values below this point are not actually correct for the future downstream API.
// If this is ever updated to use the new enums directly, these UI-specific values should all
// be converted to OVER_FIVEHUNDREDK during submit.
export const FIVEHUNDREDK_TO_ONEMILLION = "FIVEHUNDREDK_TO_ONEMILLION";
export const ONEMILLION_TO_TWOMILLION = "ONEMILLION_TO_TWOMILLION";
export const OVER_TWOMILLION = "OVER_TWOMILLION";

export const annualBusinessRevenueRangeDisplay = {
  [UNDER_TWENTYK]: "$20,000 and under",
  [TWENTYK_TO_FIFTYK]: "$20,001 - $50,000",
  [FIFTYK_TO_HUNDREDK]: "$50,001 - $100,000",
  [HUNDREDK_TO_TWOHUNDREDFIFTYK]: "$100,001 - $250,000",
  [TWOHUNDREDFIFTYK_TO_FIVEHUNDREDK]: "$250,001 - $500,000",
  [FIVEHUNDREDK_TO_ONEMILLION]: "$500,001 - $1,000,000",
  [ONEMILLION_TO_TWOMILLION]: "$1,000,001 - $2,000,000",
  [OVER_TWOMILLION]: "Over $2,000,000",
};

// Annual revenue is returned from prefill as a different enum, and we need to look up our enum.
// Additionally, we want to set null for REVENUE_OVER_500000, to force the applicant to re-select.
// This allows the applicant to select the 1M and 2M options
export const lookupAnnualBusinessRevenueRangeForPrefill = range => {
  switch (range) {
    case "REVENUE_UNDER_20000":
      return UNDER_TWENTYK;
    case "REVENUE_20001_TO_50000":
      return TWENTYK_TO_FIFTYK;
    case "REVENUE_50001_TO_100000":
      return FIFTYK_TO_HUNDREDK;
    case "REVENUE_100001_TO_250000":
      return HUNDREDK_TO_TWOHUNDREDFIFTYK;
    case "REVENUE_250001_TO_500000":
      return TWOHUNDREDFIFTYK_TO_FIVEHUNDREDK;
    case "REVENUE_OVER_500000": // intentionally fall-through, force applicant to re-select
    default:
      return null;
  }
};

// mapping of the enum to values in the ranges, for use with the old API
export const annualBusinessRevenueValues = {
  [UNDER_TWENTYK]: 10_000,
  [TWENTYK_TO_FIFTYK]: 35_000,
  [FIFTYK_TO_HUNDREDK]: 75_000,
  [HUNDREDK_TO_TWOHUNDREDFIFTYK]: 175_000,
  [TWOHUNDREDFIFTYK_TO_FIVEHUNDREDK]: 375_000,
  [FIVEHUNDREDK_TO_ONEMILLION]: 500_001,
  [ONEMILLION_TO_TWOMILLION]: 1_000_001,
  [OVER_TWOMILLION]: 2_000_001,
};
