import create from "zustand";
import { devtools } from "zustand/middleware";
import { v4 as uuidv4 } from "uuid";
import {
  addCriticalEventToApplication,
  addNonCriticalEventToApplication,
  createApplication,
  updateApplication,
  submitApplication,
  verifyCustomerEligibility,
  getPrefillInformation,
  getBusinessPrefillInformation,
} from "../api/sbaoAppEndpoints";
import { EIN, SSN } from "../constants/businessTaxIdTypes";
import { BUSINESS_VALIDATION, PROMO_AND_PRODUCT_VALIDATION } from "../constants/promoValidationTypes";
import {
  CORPORATION,
  LIMITED_LIABILITY_COMPANY,
  PARTNERSHIP,
  SOLE_PROPRIETORSHIP,
  convertLegalEntityEnumToCompanyType,
} from "../constants/legalEntityTypes";
import { PRIVATELY_OWNED } from "../constants/ownershipTypes";
import { US_CITIZEN } from "../constants/citizenshipTypes";
import extractContentVersions from "../utils/extractContentVersions";
import { PLACEHOLDER_NONE } from "../constants/selectionValues";
import { OTHER } from "../constants/charitableOrganizationTypes";
import {
  EXISTING_CUSTOMER_PREFILL,
  EXISTING_CUSTOMER_BLOCK,
  EXISTING_BUSINESS_PREFILL,
  BACKEND_SYSTEM_ERROR,
  BACKEND_DATA_ERROR,
  MISSING_KYC_FIELDS_EMAIL_ADDRESS,
  MISSING_KYC_FIELDS_PHONE_NUMBER,
  MISSING_KYC_FIELDS_HOME_PHONE_NUMBER,
  MISSING_KYC_FIELDS_MOBILE_PHONE_NUMBER,
  INVALID_KYC_FIELDS_EMAIL_ADDRESS,
  GENERAL_ERROR,
  INVALID_TAX_ID_TYPE_ITIN,
} from "../constants/prefillValues";
import { formatDateOfBirthForPatch, formatDateOfBirthForStore } from "../utils/formatDateOfBirth";
import { INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC } from "../constants/businessTaxClassifications";
import { OWNER } from "../constants/businessControllerTypes";
import {
  annualBusinessRevenueValues,
  lookupAnnualBusinessRevenueRangeForPrefill,
} from "../constants/annualBusinessRevenueRanges";
import { isFeatureEnabled } from "../utils/configSelector";
import {
  ALL_SPECIAL_LOGIC_HRBR_ENUMS,
  ALL_STANDARD_LOGIC_HRBR_ENUMS,
  ANNUAL_JEWELRY_PURCHASE,
  ANNUAL_JEWELRY_PURCHASE_AND_SALES,
  ANNUAL_JEWELRY_SALES,
  FIFTYK_TO_HUNDREDK,
  NO_ACTIVITIES,
  OVER_HUNDREDK,
} from "../constants/riskyBusinessQuestionsValues";
import { NEW_CUSTOMER } from "../constants/eligibilityCodes";
import { SBB_WEB } from "../constants/applicationChannelTypes";
import getApplicationChannelFromState from "./getApplicationChannelFromState";
import { setCustomAttributeForNewRelicData } from "../utils/newRelic";
import { trackAdobeTargetEvent } from "../utils/adobeTargetHelpers";

// exported for unit testing
export const formatApplicationProductsForSubmit = (products, purpose) => {
  // customer enabled changing the order of the application where it asks account purpose before account type
  // if the purpose is still empty (null, empty string, etc.,), force it to not be in the request
  const accountPurpose = purpose || undefined;
  return products?.map(({ productType, promotionCodeDetails }) => ({
    productType,
    promotionCodeDetails,
    accountPurpose: accountPurpose?.[productType],
    costCenter: "62999",
  }));
};

// Return explicit null for case of removing optional field
const formatDoingBusinessAsNameForSubmit = doingBusinessName =>
  doingBusinessName.length === 0 ? null : doingBusinessName;

// Update account purposes to filter out purposes that aren't selected products, needed if the user goes back and changes account type NSBOAO-24207
const formatAccountPurposesForStore = (products, accountPurpose) =>
  Object.fromEntries(products.map(product => [product.productType, accountPurpose[product.productType]]));

// Reformats business addresses for submit
// Also handles logic of inserting country codes and duplicating address if no separate mailing
const formatBusinessAddressesForSubmit = ({ address, hasDifferentMailingAddress, mailingAddress }) => {
  const businessAddress = {
    addressLine1: address.addressLine1,
    addressLine2: address.addressLine2,
    city: address.city,
    stateCode: address.state,
    postalCode: address.zip,
    countryCode: "US",
  };
  const businessMailingAddress = hasDifferentMailingAddress
    ? {
        addressLine1: mailingAddress.addressLine1,
        addressLine2: mailingAddress.addressLine2,
        city: mailingAddress.city,
        stateCode: mailingAddress.state,
        postalCode: mailingAddress.zip,
        countryCode: "US",
      }
    : businessAddress;

  return [
    { address: businessAddress, addressType: "BUSINESS" },
    { address: businessMailingAddress, addressType: "MAILING" },
  ];
};

// Ownership data that is true for all sole props
const SOLE_PROP_OWNERSHIP_DATA = {
  businessControllerType: OWNER,
  individualTypes: ["AUTHORIZED_SIGNER"],
  ownershipPercentage: 100,
};

// Handles the logic for determining if applicant is BO
const formatOwnershipDataForSubmit = (isApplicantBusinessOwner, ownershipPercentage) => {
  const individualTypes = ["AUTHORIZED_SIGNER", "BUSINESS_CONTROLLER"];
  const realPercentage = isApplicantBusinessOwner ? ownershipPercentage : 0;
  if (realPercentage >= 25) {
    individualTypes.push("BENEFICIAL_OWNER");
  }

  return {
    ownershipPercentage: realPercentage,
    individualTypes,
  };
};

// Adds the correct BOBC events, making at most two add event requests
// Returns a list of promises for the requests
const addBOBCAttestationEvents = (applicationReferenceId, hasNoBeneficialOwnersAttestation) => {
  const requests = [
    addCriticalEventToApplication({
      applicationReferenceId,
      event: "businessControllerAttestation",
    }),
  ];
  if (hasNoBeneficialOwnersAttestation) {
    requests.push(
      addCriticalEventToApplication({
        applicationReferenceId,
        event: "noBeneficialOwnersAttestation",
      })
    );
  }
  return requests;
};

// Used during submit to track the validation state of the BO addresses
const invalidBOAddressEventNames = [
  "invalidBOOneAddress",
  "invalidBOTwoAddress",
  "invalidBOThreeAddress",
  "invalidBOFourAddress",
];

// exported for unit testing
export const formatBusinessOwnerForSubmit = ({
  // note - if any of these are undefined/haven't been filled out yet, they will not get included in payload
  individualId,
  firstName,
  lastName,
  ownershipPercentage,
  dateOfBirth,
  taxId,
  citizenshipStatus,
  primaryCitizenship,
  secondaryCitizenship,
  address,
  phone,
}) => ({
  individualId,
  individualTypes: ["BENEFICIAL_OWNER"],
  isExistingIndividual: null,
  firstName,
  lastName,
  taxIdType: "SSN",
  taxId,
  dateOfBirth: dateOfBirth && formatDateOfBirthForPatch(new Date(dateOfBirth)),
  citizenshipStatus,
  primaryCitizenshipCountry:
    primaryCitizenship && (citizenshipStatus !== US_CITIZEN ? primaryCitizenship : "US"),
  secondaryCitizenshipCountry:
    secondaryCitizenship &&
    (citizenshipStatus !== US_CITIZEN && secondaryCitizenship !== PLACEHOLDER_NONE
      ? secondaryCitizenship
      : null),
  ownershipPercentage,
  addresses: address && [
    {
      address: {
        addressLine1: address?.addressLine1,
        addressLine2: address?.addressLine2,
        city: address?.city,
        stateCode: address?.state,
        postalCode: address?.zip,
        countryCode: "US",
      },
      addressType: "RESIDENTIAL",
    },
  ],
  phoneNumbers: phone && [
    {
      phoneNumber: phone,
      phoneType: "HOME",
    },
  ],
});

const addUnsupportedBusinessTypeEvents = (applicationReferenceId, isUnsupportedBusinessType, isTrust) => {
  if (isUnsupportedBusinessType) {
    addNonCriticalEventToApplication({ applicationReferenceId, event: "isUnsupportedBusinessType" });
  }
  if (isTrust) {
    addNonCriticalEventToApplication({ applicationReferenceId, event: "isTrust" });
  }
};

const getProductTypeWithValidPromo = appProducts =>
  appProducts.find(
    product =>
      product.promotionCodeDetails?.promotionCode &&
      product.promotionCodeDetails?.passedValidations.includes(PROMO_AND_PRODUCT_VALIDATION)
  )?.productType;

const store = (set, get) => ({
  applicationReferenceId: "",
  isApplicantPrefilled: false,
  promotionCode: "", // only used for prefilling the field itself, actual promotion code is stored in the products
  displayPromotionCode: false, // action below can be used to toggle this, usually done after validations
  applicationProductsSelected: [],
  promotionMessageDetails: null,
  accountPurpose: null,
  hasApplicantSelectedCustomerType: false, // controlled entirely by the customer type check page, should not be used outside of that, candidate for store splitting in the future
  applicantFirstName: "",
  applicantLastName: "",
  applicantBirthDate: "",
  applicantTaxId: "",
  applicantCitizenshipStatus: "",
  applicantCountryPrimaryCitizenship: "",
  applicantCountrySecondaryCitizenship: "",
  applicantEmailAddress: "",
  isApplicantAddressValid: false, // used to add extra events during submit
  applicantAddress1: "",
  applicantAddress2: "",
  applicantAddressCity: "",
  applicantAddressState: "",
  applicantAddressPostalCode: "",
  applicantMobilePhone: "",
  applicantHomePhone: "",
  applicantIsMobilePhoneSameAsHome: null,
  applicantOwnershipPercentage: null,
  missingPhoneNumberType: null,
  businessOwners: [],
  areStateDocsFiled: null,
  hasMultipleBusinessOwners: null,
  companyType: "",
  businessLegalEntityType: "",
  businessTaxClassification: "",
  businessTaxIdType: "",
  businessTaxId: "",
  businessLegalName: "",
  businessDoingBusinessAsName: "",
  businessIndustryDescription: "",
  businessIndustryCode: "",

  // HRBR ACTIVITIES
  hrbrActivities: {
    businessHasCheckCashingAndGiftCards: null,
    businessHasCheckCashingAndGiftCardsThirdParty: null,
    businessHasMoneyServices: null,
    businessHasMoneyServicesExchange: null,
    businessHasDigitalCurrency: null,
    businessHasDigitalCurrencyExchange: null,
    businessHasFinancialServices: null,
    businessHasFinancialServicesOther: null,
    businessHasNonStandardFinancialServices: null,
    businessHasInterestRateExceed: null,
    businessHasNonStandardFinancialServicesOther: null,
    businessHasFinancialTransactionsProcessing: null,
    businessHasThirdPartyTransactionsPayments: null,
    businessHasThirdPartyAchTransactions: null,
    businessHasDepositBroker: null,
    businessHasDepositBrokerClients: null,
    businessHasDepositBrokerDepositsPlacement: null,
    businessHasAtmOperations: null,
    businessHasGamblingServices: null,
    businessHasGamblingActivities: null,
    businessHasGamblingMajorityRevenue: null,
    businessHasCurrencyTransportation: null,
    businessHasMarijuana: null,
    businessHasTierOneOrTwoMarijuana: null,
    businessHasTierThreeMarijuana: null,
    businessHasMarijuanaRevenueMajority: null,
    businessHasJewelry: null,
    businessHasAnnualJewelryPurchase: PLACEHOLDER_NONE,
    businessHasAnnualJewelrySales: PLACEHOLDER_NONE,
    businessHasJewelryNoAmlProgram: null,
    businessHasJewelryForeignPurchase: null,
    businessHasJewelryDirectTransport: null,
    businessHasMiscellaneousActivities: null,
    businessHasRetailPharmacy: null,
    businessHasTelemarketing: null,
    businessHasForeignBusiness: null,
    businessHasMining: null,
    businessHasGoodsMovement: null,
    businessHasVehiclePurchase: null,
    businessHasAdultEntertainment: null,
  },

  businessAnnualRevenueRange: null, // Prefill API returns a range instead of an exact ammount
  isBusinessHeadquarteredOutsideUS: false,
  businessHeadquarterCountry: "",
  businessCountryOfRegistrationOrIncorporation: "",
  businessCountryOfPrimaryOperations: "",
  isCharitableOrganization: null,
  businessPurposeOfOrganizationCategory: "",
  businessPurposeOfOrganizationOtherDescription: "",
  doesBusinessExpectRepeatedInternationalActivity: false,
  isBusinessAddressValid: false, // used to add extra events during submit
  businessAddress1: "",
  businessAddress2: "",
  businessAddressCity: "",
  businessAddressState: "",
  businessAddressPostalCode: "",
  doesBusinessUseDifferentMailingAddress: false,
  isBusinessMailingAddressValid: false, // used to add extra events during submit
  businessMailingAddress1: "",
  businessMailingAddress2: "",
  businessMailingAddressCity: "",
  businessMailingAddressState: "",
  businessMailingAddressPostalCode: "",
  businessPrimaryPhone: "",
  businessPrimaryEmail: "",
  businessWebAddress: "",
  isBusinessAddressTheHomeAddress: null,
  businessOwnershipType: "",
  businessControllerType: null,
  isApplicantBusinessController: null,
  isApplicantBusinessOwner: null,
  businessControllerAttestationTimestamp: "",
  hasOtherBeneficialOwners: null,
  hasNoBeneficialOwnersAttestation: null,
  noBeneficialOwnersAttestationTimestamp: "",
  isSingleMemberLLC: null,
  isNonProfit: false,
  applicationProhibitedActivitiesSelected: [],
  isSubjectToBackupWithholding: false,
  isTaxExempt: false,
  taxExemptPayeeCode: null,
  termsAndConditionsReadTimestamp: "",
  taxReportingTimestamp: "",
  sessionExpiredTime: "",
  sessionEndedTime: "",
  timeoutPopped: "",
  timeoutWindowPoppedCount: null,
  emailAddressCollectedTimestamp: "",
  taxReportingStatementsSelected: [],
  content: [],
  industryContent: {
    industryList: [],
    industryCodeLookup: null,
  },
  queryParams: {},
  cookieParams: {},
  targetTestParams: {},
  isUsingAdobeTarget: false,
  hasProcessedAdobeTarget: false,
  allContentLoaded: false,
  contentLoadingError: false,
  submissionResult: {},
  hasProcessedQueryParams: false,
  hasProcessedLoginCookie: false,
  isApplicantAuthenticated: false,
  applicationCreated: false,
  reachedTerminalPage: false,
  isUnsupportedBusinessType: null,
  isTrust: null,
  // partial values returned by prefill API are stored separately for simplicity
  applicantDateOfBirthPartial: "",
  isNewBusiness: false,
  existingCustomerEligibleBusinesses: [],
  selectedExistingBusiness: {},
  applicantHomePhonePartial: "",
  applicantMobilePhonePartial: "",
  userDeviceData: {},

  // actions
  addContent: newContent => set({ content: [...get().content, newContent] }),
  setAllContentLoaded: () => set({ allContentLoaded: true }),
  setContentLoadingError: () => set({ contentLoadingError: true }),
  setBusinessIndustries: industryList => {
    // when the newContentServiceEnabled feature flag is cleaned up, this ternary can be removed
    const flattened = Array.isArray(industryList)
      ? industryList
      : industryList.businessIndustryCategoryList.flatMap(industry => industry.secondaryIndustries);

    set({
      industryContent: {
        industryList: flattened,
        industryCodeLookup: new Map(
          flattened.slice().map(({ industryName, codeValue }) => [industryName, codeValue])
        ),
      },
    });
  },
  setHasProcessedQueryParams: () => set({ hasProcessedQueryParams: true }),
  addQueryParam: (param, value) => set({ queryParams: { ...get().queryParams, [param]: value } }),
  setHasProcessedLoginCookie: () => set({ hasProcessedLoginCookie: true }),
  setIsApplicantAuthenticated: () => set({ isApplicantAuthenticated: true }),
  addCookieParam: (param, value) => set({ cookieParams: { ...get().cookieParams, [param]: value } }),
  setIsUsingAdobeTarget: () => set({ isUsingAdobeTarget: true }),
  setHasProcessedAdobeTarget: () => set({ hasProcessedAdobeTarget: true }),
  addTargetTestParam: (param, value) =>
    set({ targetTestParams: { ...get().targetTestParams, [param]: value } }),
  getTargetTestValue: key => {
    const targetTests = get().targetTestParams;
    return targetTests[key] ?? "";
  },
  setUserDeviceData: userDeviceData => {
    set({ userDeviceData });
  },
  setOngoingApplication: applicationReferenceId => set({ applicationReferenceId, applicationCreated: true }),
  setReachedTerminalPage: () => set({ reachedTerminalPage: true }),
  checkCustomerEligibility: async payload => {
    if (
      isFeatureEnabled(get(), "delayedExistingCustomerCheckEnabled") &&
      getApplicationChannelFromState(get()) === SBB_WEB
    ) {
      // For SBB_WEB channel applicants, customer eligibility is delayed until after submit.
      // So instead we simply assume all applicants reaching this point are new customers.
      // This applies to all business tax ID checks, as well as the applicant SSN check on personal info.
      return { eligibilityTreatment: NEW_CUSTOMER, isEligibleCustomer: true };
    }

    const appRefId = get().applicationReferenceId;
    const eligibilityResponse = await verifyCustomerEligibility(appRefId, payload);
    const { isEligibleCustomer } = eligibilityResponse;

    const updateAppPayload = {};
    if (payload.applicantTaxId) {
      updateAppPayload.applicant = { isExistingIndividual: !isEligibleCustomer };
    }
    if (payload.businessTaxId) {
      updateAppPayload.business = { isExistingBusiness: !isEligibleCustomer };
    }

    await updateApplication(get().isApplicantAuthenticated, appRefId, updateAppPayload);

    return eligibilityResponse;
  },
  handleApplicantPrefill: async () => {
    const missingPersonalEmailRedirectEnabled = isFeatureEnabled(
      get(),
      "missingPersonalEmailRedirectEnabled"
    );
    const appRefId = get().applicationReferenceId;
    const prefillContent = await getPrefillInformation(appRefId);
    const missingPhoneNumberReasonCodes = [
      MISSING_KYC_FIELDS_HOME_PHONE_NUMBER,
      MISSING_KYC_FIELDS_MOBILE_PHONE_NUMBER,
    ];

    switch (prefillContent.eligibilityTreatment) {
      case EXISTING_CUSTOMER_PREFILL: {
        setCustomAttributeForNewRelicData("isExistingIndividual", true);
        const {
          firstName,
          lastName,
          maskedDateOfBirth,
          taxId,
          citizenshipStatus,
          emailAddress,
          primaryCitizenshipCountry,
          secondaryCitizenshipCountry,
          addresses,
          phoneNumbers,
          applicationProducts,
        } = prefillContent.applicant;
        set({
          isApplicantAuthenticated: true,
          isApplicantPrefilled: true,
          applicantFirstName: firstName,
          applicantLastName: lastName,
          applicantDateOfBirthPartial: maskedDateOfBirth,
          applicantTaxId: taxId,
          applicantCitizenshipStatus: citizenshipStatus,
          applicantEmailAddress: emailAddress,
          applicantCountryPrimaryCitizenship: primaryCitizenshipCountry,
          applicantCountrySecondaryCitizenship: secondaryCitizenshipCountry,
          applicantAddress1: addresses?.[0]?.address?.addressLine1,
          applicantAddress2: addresses?.[0]?.address?.addressLine2,
          applicantAddressCity: addresses?.[0]?.address?.city,
          applicantAddressState: addresses?.[0]?.address?.stateCode,
          applicantAddressPostalCode: addresses?.[0]?.address?.postalCode,
          applicantHomePhonePartial: phoneNumbers?.find(
            phoneNumber => phoneNumber.phoneType.toUpperCase() === "HOME"
          )?.lastFourPhoneNumber,
          applicantMobilePhonePartial: phoneNumbers?.find(
            phoneNumber => phoneNumber.phoneType.toUpperCase() === "MOBILE"
          )?.lastFourPhoneNumber,
          existingCustomerEligibleBusinesses: prefillContent.eligibleBusinesses,
          applicationProductsSelected: applicationProducts,
          displayPromotionCode: applicationProducts?.some(
            product =>
              product.promotionCodeDetails?.promotionCode &&
              product.promotionCodeDetails?.passedValidations.includes(PROMO_AND_PRODUCT_VALIDATION)
          ),
          promotionCode:
            applicationProducts?.find(product => product.promotionCodeDetails)?.promotionCodeDetails
              .promotionCode || "",
        });

        return "/applicant-information-prefilled";
      }
      // TODO we want to update the code to reduce the cognitive complexity, the newest route increased it past the threshold of 15
      case EXISTING_CUSTOMER_BLOCK:
        if (
          missingPersonalEmailRedirectEnabled &&
          prefillContent.reasonCodes?.length === 1 &&
          prefillContent.reasonCodes.includes(MISSING_KYC_FIELDS_EMAIL_ADDRESS)
        ) {
          return "/missing-personal-email";
        }

        if (
          missingPhoneNumberReasonCodes.every(reasonCode => prefillContent.reasonCodes?.includes(reasonCode))
        ) {
          set({
            missingPhoneNumberType: MISSING_KYC_FIELDS_PHONE_NUMBER,
          });
          return "/missing-home-and-mobile-phone-number";
        }
        if (prefillContent.reasonCodes?.includes(MISSING_KYC_FIELDS_HOME_PHONE_NUMBER)) {
          set({
            missingPhoneNumberType: MISSING_KYC_FIELDS_HOME_PHONE_NUMBER,
          });
          return "/missing-home-phone-number";
        }
        if (prefillContent.reasonCodes?.includes(MISSING_KYC_FIELDS_MOBILE_PHONE_NUMBER)) {
          set({
            missingPhoneNumberType: MISSING_KYC_FIELDS_MOBILE_PHONE_NUMBER,
          });
          return "/missing-mobile-phone-number";
        }
        if (prefillContent.reasonCodes?.includes(INVALID_KYC_FIELDS_EMAIL_ADDRESS)) {
          return "/invalid-email-address";
        }
        if (prefillContent.reasonCodes?.includes(INVALID_TAX_ID_TYPE_ITIN)) {
          return "/unsupported-tax-id";
        }

        // RA 4xx error -- route to non-restartable error
        if (prefillContent.reasonCodes?.includes(BACKEND_DATA_ERROR)) {
          return "/something-went-wrong";
        }
        // RA 5xx error -- route to restartable error
        if (prefillContent.reasonCodes?.includes(BACKEND_SYSTEM_ERROR)) {
          return "/restart-application";
        }
        return "/cannot-proceed";
      default:
        // default case: customer is a new customer
        setCustomAttributeForNewRelicData("isExistingIndividual", false);
        set({
          isApplicantAuthenticated: true,
        });
        return "/personal-info";
    }
  },
  updateBusinessOwners: async businessOwners => {
    await updateApplication(get().isApplicantAuthenticated, get().applicationReferenceId, {
      businessOwners: businessOwners.map(formatBusinessOwnerForSubmit),
    });
    set({ businessOwners });
  },
  setApplicationProductsSelected: productTypes => {
    const applicationProductsSelected = productTypes.map(productType => ({
      productType,
    }));
    set({ applicationProductsSelected });
  },
  setPromotionCode: promotionCode => set({ promotionCode }),
  setDisplayPromotionCode: displayPromotionCode => set({ displayPromotionCode }),
  setPromotionMessageDetails: (productType, promotionMessageContent) =>
    set({ promotionMessageDetails: { productType, promotionMessageContent } }),
  setCustomerTypeSelected: () => set({ hasApplicantSelectedCustomerType: true }),
  getPersonalTaxIdsToValidate: (index = -1) => {
    const { applicantTaxId, businessOwners } = get();
    const filteredBusinessOwners = index >= 0 ? businessOwners.slice(0, index) : businessOwners;
    return [
      ...(applicantTaxId ? [applicantTaxId] : []),
      ...filteredBusinessOwners.map(owner => owner.taxId).filter(Boolean),
    ];
  },
  pageSubmit: {
    createApplication: async () => {
      const contentVersions = extractContentVersions(get().content);

      // AB TEST: If we are using Adobe Target, we need to track one of the secondary metrics for reporting + add the AB test to content versions array
      if (get().isUsingAdobeTarget) {
        // include active Adobe Target test in the contentVersions array for auditing purposes
        const adobeTargetTests = Object.entries(get().targetTestParams).map(([key, value]) => ({
          contentName: `adobeTargetTest_${key}`,
          version: value,
        }));
        adobeTargetTests.forEach(test => contentVersions.push(test));

        // track the continuedPastLandingPage event for Adobe Target
        trackAdobeTargetEvent({ successMetric: "continuedPastLanding" });
      }
      // remove query params that aren't needed by createApplication
      const { productsSelected, ...branchParams } = get().queryParams;
      const { applicationReferenceId } = await createApplication({ contentVersions }, branchParams);
      set({ applicationReferenceId, applicationCreated: true });
      setCustomAttributeForNewRelicData("applicationReferenceId", applicationReferenceId);
    },
    submitGettingStarted: () => {
      addNonCriticalEventToApplication({
        applicationReferenceId: get().applicationReferenceId,
        event: "customerClickedApplyNow",
      });
    },
    submitSoleProp: async ({
      isUnsupportedBusinessType,
      isTrust,
      taxId,
      taxIdType,
      areStateDocsFiled,
      hasMultipleBusinessOwners,
    }) => {
      if (isUnsupportedBusinessType || isTrust) {
        addUnsupportedBusinessTypeEvents(get().applicationReferenceId, isUnsupportedBusinessType, isTrust);
        return { isEligibleCustomer: false };
      }
      const legalBusinessName = `${get().applicantFirstName} ${get().applicantLastName}`;

      await updateApplication(get().isApplicantAuthenticated, get().applicationReferenceId, {
        // don't need to patch applicant tax id fields -- already patched on personal info page or by prefill endpoint
        applicant: SOLE_PROP_OWNERSHIP_DATA,
        business: {
          taxIdType,
          taxId,
          legalEntityType: SOLE_PROPRIETORSHIP,
          ownershipType: PRIVATELY_OWNED,
          legalBusinessName,
          // patch these fields since sole props do not have tax classification and cannot be non-profit
          taxClassification: null,
          isNonProfit: false,
        },
        businessOwners: [],
      });

      // do this before setting state to avoid messing up the loading flag in forms
      // don't check applicantTaxId for existing customer flow since it would have already
      // been checked in personal info page or by prefill endpoint
      const { isEligibleCustomer } = await get().checkCustomerEligibility({
        businessTaxId: taxId,
        businessTaxIdType: taxIdType,
      });

      set({
        isNewBusiness: true,
        businessControllerType: "OWNER",
        applicantOwnershipPercentage: 100,
        businessTaxIdType: taxIdType,
        businessTaxId: taxId,
        companyType: SOLE_PROPRIETORSHIP,
        businessLegalEntityType: SOLE_PROPRIETORSHIP,
        businessOwnershipType: PRIVATELY_OWNED,
        businessOwners: [],
        businessLegalName: legalBusinessName,
        isSingleMemberLLC: false,
        isUnsupportedBusinessType,
        isTrust,
        // reset these fields since sole props do not have tax classification and cannot be non-profit
        businessTaxClassification: "",
        isNonProfit: false,
        // save screening question answers in store for determining which events to send on submit
        areStateDocsFiled,
        hasMultipleBusinessOwners,
      });
      return { isEligibleCustomer };
    },
    submitLLC: async ({
      isUnsupportedBusinessType,
      isTrust,
      taxClassification,
      legalBusinessName,
      ein,
      ownershipType,
      isNonProfit,
      areStateDocsFiled,
      hasMultipleBusinessOwners,
    }) => {
      if (isUnsupportedBusinessType || isTrust) {
        addUnsupportedBusinessTypeEvents(get().applicationReferenceId, isUnsupportedBusinessType, isTrust);
        return { isEligibleCustomer: false };
      }
      const isSingleMemberLLC = taxClassification === INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC;
      await updateApplication(get().isApplicantAuthenticated, get().applicationReferenceId, {
        business: {
          legalBusinessName,
          taxClassification,
          taxIdType: EIN,
          taxId: ein,
          legalEntityType: LIMITED_LIABILITY_COMPANY,
          ownershipType,
          isNonProfit,
        },
        applicant: {
          ownershipPercentage: isSingleMemberLLC ? 100 : undefined,
        },
      });

      // do this before setting state to avoid messing up the loading flag in forms
      const { isEligibleCustomer } = await get().checkCustomerEligibility({
        businessTaxId: ein,
        businessTaxIdType: EIN,
      });

      set({
        isNewBusiness: true,
        businessLegalName: legalBusinessName,
        businessTaxClassification: taxClassification,
        businessTaxIdType: EIN,
        businessTaxId: ein,
        companyType: LIMITED_LIABILITY_COMPANY,
        businessLegalEntityType: LIMITED_LIABILITY_COMPANY,
        businessOwnershipType: ownershipType,
        isApplicantBusinessOwner: isSingleMemberLLC || null,
        applicantOwnershipPercentage: isSingleMemberLLC ? 100 : null,
        hasOtherBeneficialOwners: !isSingleMemberLLC && null,
        hasNoBeneficialOwnersAttestation: !isSingleMemberLLC && null,
        isSingleMemberLLC,
        isNonProfit,
        isUnsupportedBusinessType,
        isTrust,
        // reset below field in store in case sole prop case previously set it
        businessControllerType: null,
        // save screening question answers in store for determining which events to send on submit
        areStateDocsFiled,
        hasMultipleBusinessOwners,
      });

      return {
        isEligibleCustomer,
        isEligibleBusiness: ownershipType === PRIVATELY_OWNED,
      };
    },
    submitPartnership: async ({
      isUnsupportedBusinessType,
      isTrust,
      taxClassification,
      legalBusinessName,
      ein,
      ownershipType,
      partnershipStructure,
      isNonProfit,
      areStateDocsFiled,
      hasMultipleBusinessOwners,
    }) => {
      if (isUnsupportedBusinessType || isTrust) {
        addUnsupportedBusinessTypeEvents(get().applicationReferenceId, isUnsupportedBusinessType, isTrust);
        return { isEligibleCustomer: false };
      }
      await updateApplication(get().isApplicantAuthenticated, get().applicationReferenceId, {
        business: {
          legalBusinessName,
          taxClassification,
          taxIdType: EIN,
          taxId: ein,
          legalEntityType: partnershipStructure,
          ownershipType,
          isNonProfit,
        },
      });

      // do this before setting state to avoid messing up the loading flag in forms
      const { isEligibleCustomer } = await get().checkCustomerEligibility({
        businessTaxId: ein,
        businessTaxIdType: EIN,
      });

      set({
        isNewBusiness: true,
        businessLegalName: legalBusinessName,
        businessTaxClassification: taxClassification,
        businessTaxIdType: EIN,
        businessTaxId: ein,
        companyType: PARTNERSHIP,
        businessLegalEntityType: partnershipStructure,
        businessOwnershipType: ownershipType,
        isSingleMemberLLC: false,
        isNonProfit,
        isUnsupportedBusinessType,
        isTrust,
        ...(isNonProfit && { isApplicantBusinessOwner: null }),
        ...(isNonProfit && { hasOtherBeneficialOwners: null }),
        ...(isNonProfit && { hasNoBeneficialOwnersAttestation: null }),
        // reset below fields in store in case sole prop case previously set them
        businessControllerType: null,
        applicantOwnershipPercentage: null,
        // save screening question answers in store for determining which events to send on submit
        areStateDocsFiled,
        hasMultipleBusinessOwners,
      });

      return {
        isEligibleCustomer,
        isEligibleBusiness: ownershipType === PRIVATELY_OWNED,
      };
    },
    submitCorporation: async ({
      isUnsupportedBusinessType,
      isTrust,
      taxClassification,
      legalBusinessName,
      ein,
      ownershipType,
      isNonProfit,
      areStateDocsFiled,
      hasMultipleBusinessOwners,
    }) => {
      if (isUnsupportedBusinessType || isTrust) {
        addUnsupportedBusinessTypeEvents(get().applicationReferenceId, isUnsupportedBusinessType, isTrust);
        return { isEligibleCustomer: false };
      }
      await updateApplication(get().isApplicantAuthenticated, get().applicationReferenceId, {
        business: {
          legalBusinessName,
          taxClassification,
          taxIdType: EIN,
          taxId: ein,
          legalEntityType: taxClassification,
          ownershipType,
          isNonProfit,
        },
      });

      // do this before setting state to avoid messing up the loading flag in forms
      const { isEligibleCustomer } = await get().checkCustomerEligibility({
        businessTaxId: ein,
        businessTaxIdType: EIN,
      });
      set({
        isNewBusiness: true,
        businessLegalName: legalBusinessName,
        businessTaxClassification: taxClassification,
        businessTaxIdType: EIN,
        businessTaxId: ein,
        companyType: CORPORATION,
        businessLegalEntityType: taxClassification,
        businessOwnershipType: ownershipType,
        isSingleMemberLLC: false,
        isNonProfit,
        isUnsupportedBusinessType,
        isTrust,
        ...(isNonProfit && { isApplicantBusinessOwner: null }),
        ...(isNonProfit && { hasOtherBeneficialOwners: null }),
        ...(isNonProfit && { hasNoBeneficialOwnersAttestation: null }),
        // reset below fields in store in case sole prop case previously set them
        businessControllerType: null,
        applicantOwnershipPercentage: null,
        // save screening question answers in store for determining which events to send on submit
        areStateDocsFiled,
        hasMultipleBusinessOwners,
      });

      return {
        isEligibleCustomer,
        isEligibleBusiness: ownershipType === PRIVATELY_OWNED,
      };
    },
    submitProductSelection: async (
      productTypes,
      promotionCode = "",
      productTypeWithValidPromotion = null
    ) => {
      const promotionCodeDetails = { promotionCode, passedValidations: [PROMO_AND_PRODUCT_VALIDATION] };
      const applicationProductsSelected = productTypes.map(productType =>
        productTypeWithValidPromotion && productType === productTypeWithValidPromotion
          ? { productType, promotionCodeDetails }
          : { productType }
      );

      await updateApplication(get().isApplicantAuthenticated, get().applicationReferenceId, {
        applicationProducts: formatApplicationProductsForSubmit(
          applicationProductsSelected,
          get().accountPurpose
        ),
      });
      set({ applicationProductsSelected, promotionCode });
    },
    submitPersonalInfo: async ({
      applicantFirstName,
      applicantLastName,
      applicantBirthDate,
      applicantTaxId,
    }) => {
      const firstName = applicantFirstName.trim();
      const lastName = applicantLastName.trim();

      await updateApplication(get().isApplicantAuthenticated, get().applicationReferenceId, {
        applicant: {
          firstName,
          lastName,
          dateOfBirth: formatDateOfBirthForPatch(applicantBirthDate),
          taxIdType: SSN,
          taxId: applicantTaxId,
        },
      });

      // Do this before setting state to avoid messing up the loading flag in forms
      const eligibilityResponse = await get().checkCustomerEligibility({
        applicantTaxId,
        dateOfBirth: formatDateOfBirthForPatch(applicantBirthDate),
      });

      set({
        applicantFirstName: firstName,
        applicantLastName: lastName,
        applicantBirthDate: formatDateOfBirthForStore(applicantBirthDate),
        applicantTaxId,
      });

      return eligibilityResponse;
    },
    submitExistingBusinessSelection: async ({
      selectedExistingBusiness,
      isNewBusiness,
      isNonProfit,
      isPromotionValid = false,
    }) => {
      const appProducts = get().applicationProductsSelected;
      const productTypeToUpdatePromotion = getProductTypeWithValidPromo(appProducts);
      if (productTypeToUpdatePromotion) {
        // if promo is valid, both validations can be set to passed
        // if promo is invalid for an existing business, the product validation can be maintained
        // if promo is invalid for a new business, the product validation must have failed
        // eslint-disable-next-line no-nested-ternary
        const passedValidations = isPromotionValid
          ? [PROMO_AND_PRODUCT_VALIDATION, BUSINESS_VALIDATION]
          : isNewBusiness
          ? []
          : [PROMO_AND_PRODUCT_VALIDATION];

        const applicationProductsSelected = appProducts.map(product =>
          product.productType === productTypeToUpdatePromotion
            ? { ...product, promotionCodeDetails: { ...product.promotionCodeDetails, passedValidations } }
            : product
        );

        await updateApplication(get().isApplicantAuthenticated, get().applicationReferenceId, {
          applicationProducts: formatApplicationProductsForSubmit(
            applicationProductsSelected,
            get().accountPurpose
          ),
        });

        set({ applicationProductsSelected });
      }

      if (isNewBusiness) {
        set({
          // from page
          selectedExistingBusiness: {},
          isNewBusiness,
          isNonProfit: false,
          // reset any fields that might have been pre-filled previously
          businessLegalName: "",
          businessLegalEntityType: "",
          companyType: "",
          businessTaxId: "",
          businessTaxIdType: "",
          businessAnnualRevenueRange: null,
          businessWebAddress: "",
          businessPrimaryEmail: "",
          businessAddress1: "",
          businessAddress2: "",
          businessAddressCity: "",
          businessAddressState: "",
          businessAddressPostalCode: "",
          businessMailingAddress1: "",
          businessMailingAddress2: "",
          businessMailingAddressCity: "",
          businessMailingAddressState: "",
          businessMailingAddressPostalCode: "",
          businessPrimaryPhone: "",
          businessIndustryCode: "",
          businessIndustryDescription: "",
          isCharitableOrganization: null,
          businessPurposeOfOrganizationCategory: "",
          businessPurposeOfOrganizationOtherDescription: "",
          doesBusinessExpectRepeatedInternationalActivity: false,
          isBusinessHeadquarteredOutsideUS: false,
          businessHeadquarterCountry: "",
          businessCountryOfRegistrationOrIncorporation: "",
          businessCountryOfPrimaryOperations: "",
          businessOwnershipType: "",
          applicationProhibitedActivitiesSelected: [],
        });
        return { prefillFailure: false };
      }
      setCustomAttributeForNewRelicData("isExistingBusiness", !isNewBusiness);

      const appRefId = get().applicationReferenceId;
      const { businessSorId, businessSorIdType } = selectedExistingBusiness;
      const businessPrefillContent = await getBusinessPrefillInformation(
        appRefId,
        businessSorId,
        businessSorIdType
      );

      if (businessPrefillContent.eligibilityTreatment !== EXISTING_BUSINESS_PREFILL) {
        set({ selectedExistingBusiness, isNewBusiness, isNonProfit });
        if (
          businessPrefillContent.reasonCodes.length === 1 &&
          businessPrefillContent.reasonCodes.includes(INVALID_KYC_FIELDS_EMAIL_ADDRESS)
        ) {
          // Only set as invalid email when this is the only field, otherwise go to /cannot-proceed since if it's email + other reasons they'll still end up in /cannot-proceed even after fixing email.
          return { prefillFailure: true, prefillFailureReason: INVALID_KYC_FIELDS_EMAIL_ADDRESS };
        }
        // can't be prefilled - trigger terminal page in ExistingBusinessSelection
        return { prefillFailure: true, prefillFailureReason: GENERAL_ERROR };
      }

      const {
        legalBusinessName,
        legalEntityType,
        taxId,
        taxIdType,
        annualRevenueRange,
        webAddress,
        emailAddress,
        isCharitableOrganization,
        charitableOrganizationInformation,
        addresses,
        phoneNumbers,
        naicsCode,
        countryOfRegistration,
        countryOfHeadquarters,
        countryOfPrimaryOperations,
        ownershipType,
        businessActivities,
      } = businessPrefillContent.business;
      const businessAddress = addresses?.find(({ addressType }) => addressType === "BUSINESS")?.address;
      const businessMailingAddress = addresses?.find(({ addressType }) => addressType === "MAILING")?.address;
      set({
        // from page
        selectedExistingBusiness,
        isNewBusiness,
        isNonProfit,
        // from pre-fill
        businessLegalName: legalBusinessName,
        businessLegalEntityType: legalEntityType,
        companyType: convertLegalEntityEnumToCompanyType(legalEntityType),
        businessTaxId: taxId,
        businessTaxIdType: taxIdType,
        businessAnnualRevenueRange: lookupAnnualBusinessRevenueRangeForPrefill(annualRevenueRange),
        businessWebAddress: webAddress,
        businessPrimaryEmail: emailAddress,
        businessAddress1: businessAddress?.addressLine1 ?? "",
        businessAddress2: businessAddress?.addressLine2 ?? "",
        businessAddressCity: businessAddress?.city ?? "",
        businessAddressState: businessAddress?.stateCode ?? "",
        businessAddressPostalCode: businessAddress?.postalCode ?? "",
        businessMailingAddress1: businessMailingAddress?.addressLine1 ?? "",
        businessMailingAddress2: businessMailingAddress?.addressLine2 ?? "",
        businessMailingAddressCity: businessMailingAddress?.city ?? "",
        businessMailingAddressState: businessMailingAddress?.stateCode ?? "",
        businessMailingAddressPostalCode: businessMailingAddress?.postalCode ?? "",
        businessPrimaryPhone: phoneNumbers.find(number => number?.phoneType === "WORK")?.phoneNumber,
        businessIndustryCode: naicsCode,
        businessIndustryDescription:
          get().industryContent.industryList.find(industry => industry.codeValue === naicsCode)
            ?.descriptionText ?? "",
        isCharitableOrganization,
        businessPurposeOfOrganizationCategory: charitableOrganizationInformation?.purposeOfOrganization ?? "",
        businessPurposeOfOrganizationOtherDescription:
          charitableOrganizationInformation?.purposeOfOrganizationOther ?? "",
        doesBusinessExpectRepeatedInternationalActivity:
          charitableOrganizationInformation?.hasRepeatedInternationalActivity ?? false,
        isBusinessHeadquarteredOutsideUS:
          countryOfHeadquarters !== "US" ||
          countryOfRegistration !== "US" ||
          countryOfPrimaryOperations !== "US",
        businessHeadquarterCountry: countryOfHeadquarters,
        businessCountryOfRegistrationOrIncorporation: countryOfRegistration,
        businessCountryOfPrimaryOperations: countryOfPrimaryOperations,
        businessOwnershipType: ownershipType,
        applicationProhibitedActivitiesSelected: businessActivities,
      });

      return { prefillFailure: false };
    },
    submitPersonalContactInfo: async ({
      isAddressValid,
      address: { addressLine1, addressLine2, city, state, zip },
      mobilePhone,
      homePhone,
      isMobilePhoneSameAsHome,
      email,
      citizenshipStatus,
      primaryCitizenship,
      secondaryCitizenship,
    }) => {
      // AB TEST: If we are using Adobe Target, we need to track the secondary metrics for reporting
      if (get().isUsingAdobeTarget) {
        // track the completedPersonal event for Adobe Target
        trackAdobeTargetEvent({ successMetric: "completedPersonal" });
      }

      const isUSCitizen = citizenshipStatus === US_CITIZEN;
      const citizenApplicantData = {
        citizenshipStatus,
        primaryCitizenshipCountry: isUSCitizen ? "US" : primaryCitizenship,
        secondaryCitizenshipCountry:
          isUSCitizen || secondaryCitizenship === PLACEHOLDER_NONE ? null : secondaryCitizenship,
      };
      // do not need to await in Promise.all since it is non-critical
      addNonCriticalEventToApplication({
        applicationReferenceId: get().applicationReferenceId,
        event: "emailAddressCollected",
      });
      await Promise.all([
        updateApplication(get().isApplicantAuthenticated, get().applicationReferenceId, {
          applicant: {
            emailAddress: email,
            addresses: [
              {
                address: {
                  addressLine1,
                  addressLine2,
                  city,
                  stateCode: state,
                  postalCode: zip,
                  countryCode: "US",
                },
                addressType: "RESIDENTIAL",
              },
            ],
            phoneNumbers: [
              {
                phoneNumber: mobilePhone,
                phoneType: "MOBILE",
              },
              {
                phoneNumber: isMobilePhoneSameAsHome ? mobilePhone : homePhone,
                phoneType: "HOME",
              },
            ],
            ...citizenApplicantData,
          },
        }),
        addCriticalEventToApplication({
          applicationReferenceId: get().applicationReferenceId,
          event: "smsVerifyIdentityAttestation",
        }),
      ]);
      set({
        isApplicantAddressValid: isAddressValid,
        applicantAddress1: addressLine1,
        applicantAddress2: addressLine2,
        applicantAddressCity: city,
        applicantAddressState: state,
        applicantAddressPostalCode: zip,
        applicantMobilePhone: mobilePhone,
        // We want the home phone input to be empty when a user returns to the page if they select the "Yes" radio button
        applicantHomePhone: isMobilePhoneSameAsHome ? "" : homePhone,
        applicantIsMobilePhoneSameAsHome: isMobilePhoneSameAsHome,
        applicantEmailAddress: email,
        applicantCitizenshipStatus: citizenshipStatus,
        applicantCountryPrimaryCitizenship: primaryCitizenship,
        applicantCountrySecondaryCitizenship: secondaryCitizenship,
      });
    },
    submitBusinessDetails: async ({
      doingBusinessName,
      businessIndustry,
      naicsCode,
      annualRevenueRange,
      accountPurpose,
      isBusinessHeadquarteredOutsideUS,
      outsideUSInfo: { countryOfHeadquarters, countryOfRegistration, countryOfPrimaryOperations } = {},
      isCharitableOrganization,
      charitableOrganization: {
        purposeOfOrganization,
        purposeOfOrganizationOther,
        hasRepeatedInternationalActivity,
      } = {},
    }) => {
      const appProducts = get().applicationProductsSelected;
      const productTypeToUpdatePromotion = getProductTypeWithValidPromo(appProducts);

      const products = appProducts.map(product =>
        product.productType === productTypeToUpdatePromotion
          ? {
              ...product,
              promotionCodeDetails: {
                ...product.promotionCodeDetails,
                passedValidations: [PROMO_AND_PRODUCT_VALIDATION, BUSINESS_VALIDATION],
              },
            }
          : product
      );

      await updateApplication(get().isApplicantAuthenticated, get().applicationReferenceId, {
        applicationProducts:
          products.length > 0 ? formatApplicationProductsForSubmit(products, accountPurpose) : undefined,
        business: {
          doingBusinessAs: formatDoingBusinessAsNameForSubmit(doingBusinessName),
          annualRevenue: annualBusinessRevenueValues[annualRevenueRange],
          naicsCode,
          countryOfHeadquarters: isBusinessHeadquarteredOutsideUS ? countryOfHeadquarters : "US",
          countryOfRegistration: isBusinessHeadquarteredOutsideUS ? countryOfRegistration : "US",
          countryOfPrimaryOperations: isBusinessHeadquarteredOutsideUS ? countryOfPrimaryOperations : "US",
          isCharitableOrganization,
          charitableOrganizationInformation: isCharitableOrganization
            ? {
                hasRepeatedInternationalActivity,
                purposeOfOrganization,
                purposeOfOrganizationOther: purposeOfOrganization === OTHER ? purposeOfOrganizationOther : "",
              }
            : null,
        },
      });

      set({
        applicationProductsSelected: products,
        businessDoingBusinessAsName: doingBusinessName,
        businessIndustryDescription: businessIndustry,
        businessAnnualRevenueRange: annualRevenueRange,
        accountPurpose: formatAccountPurposesForStore(get().applicationProductsSelected, accountPurpose),
        isBusinessHeadquarteredOutsideUS,
        businessHeadquarterCountry: isBusinessHeadquarteredOutsideUS
          ? countryOfHeadquarters
          : PLACEHOLDER_NONE,
        businessCountryOfRegistrationOrIncorporation: isBusinessHeadquarteredOutsideUS
          ? countryOfRegistration
          : PLACEHOLDER_NONE,
        businessCountryOfPrimaryOperations: isBusinessHeadquarteredOutsideUS
          ? countryOfPrimaryOperations
          : PLACEHOLDER_NONE,
        isCharitableOrganization,
        businessPurposeOfOrganizationCategory: isCharitableOrganization
          ? purposeOfOrganization
          : PLACEHOLDER_NONE,
        businessPurposeOfOrganizationOtherDescription:
          isCharitableOrganization && purposeOfOrganization === OTHER ? purposeOfOrganizationOther : "",
        doesBusinessExpectRepeatedInternationalActivity: hasRepeatedInternationalActivity,
      });
    },
    submitRiskyProhibitedBusinesses: async currentApplicationProhibitedActivitiesSelected => {
      const updatedValues = { ...get().hrbrActivities };
      Object.keys(currentApplicationProhibitedActivitiesSelected).forEach(key => {
        updatedValues[key] = currentApplicationProhibitedActivitiesSelected[key];
      });
      // Special case to reset miscellaneous activity sub questions because it's split across two pages.
      if (currentApplicationProhibitedActivitiesSelected?.businessHasMiscellaneousActivities === false) {
        updatedValues.businessHasRetailPharmacy = null;
        updatedValues.businessHasTelemarketing = null;
        updatedValues.businessHasForeignBusiness = null;
        updatedValues.businessHasMining = null;
        updatedValues.businessHasGoodsMovement = null;
        updatedValues.businessHasVehiclePurchase = null;
        updatedValues.businessHasAdultEntertainment = null;
      }
      set({ hrbrActivities: updatedValues });
      let prohibitedActivitiesEnums = [];
      // going through all (non special rule) enums and adding activities
      Object.keys(ALL_STANDARD_LOGIC_HRBR_ENUMS).forEach(key => {
        if (get().hrbrActivities[key]) {
          prohibitedActivitiesEnums.push(ALL_STANDARD_LOGIC_HRBR_ENUMS[key]);
        }
      });
      // Special rules
      // Pass ANNUAL_JEWELRY_SALES and ANNUAL_JEWELRY_PURCHASE_AND_SALES for each 50,001 and over (50,001-100,000 & 100k+), don't pass the dollar amount
      Object.keys(ALL_SPECIAL_LOGIC_HRBR_ENUMS).forEach(key => {
        switch (key) {
          case "businessHasAnnualJewelryPurchase":
          case "businessHasAnnualJewelrySales":
            if (updatedValues[key] === FIFTYK_TO_HUNDREDK || updatedValues[key] === OVER_HUNDREDK) {
              prohibitedActivitiesEnums.push(ALL_SPECIAL_LOGIC_HRBR_ENUMS[key]);
            }
            // If both ANNUAL_JEWELRY_SALES and ANNUAL_JEWELRY_PURCHASE_AND_SALES >= 50,001, also include ANNUAL_JEWELRY_PURCHASE_AND_SALES
            if (
              prohibitedActivitiesEnums.includes(ANNUAL_JEWELRY_PURCHASE) &&
              prohibitedActivitiesEnums.includes(ANNUAL_JEWELRY_SALES)
            ) {
              prohibitedActivitiesEnums.push(ANNUAL_JEWELRY_PURCHASE_AND_SALES);
            }
            break;
          // JEWELRY_NO_AML_PROGRAM adding for false instead of true
          case "businessHasJewelryNoAmlProgram":
            if (updatedValues[key] === false) {
              prohibitedActivitiesEnums.push(ALL_SPECIAL_LOGIC_HRBR_ENUMS[key]);
            }
            break;
          default:
        }
      });

      if (prohibitedActivitiesEnums.length === 0) {
        prohibitedActivitiesEnums.push(NO_ACTIVITIES);
      } else if (prohibitedActivitiesEnums.includes(NO_ACTIVITIES)) {
        // NONE can be set from previous pages, so it has to be removed if other activities are added
        prohibitedActivitiesEnums = prohibitedActivitiesEnums.filter(
          activities => activities !== NO_ACTIVITIES
        );
      }

      // do not need to await on this event since it is non-critical
      addNonCriticalEventToApplication({
        applicationReferenceId: get().applicationReferenceId,
        event: "riskyProhibitedActivities",
      });

      await updateApplication(get().isApplicantAuthenticated, get().applicationReferenceId, {
        business: {
          businessActivities: prohibitedActivitiesEnums,
        },
      });
    },
    submitBusinessContactInfo: async ({
      isPhysicalValid,
      isMailingValid,
      address,
      mailingAddress,
      hasDifferentMailingAddress,
      phone,
      businessEmail,
      webAddress,
      isBusinessAddressTheHomeAddress,
    }) => {
      // do not need to await on this event since it is non-critical
      addNonCriticalEventToApplication({
        applicationReferenceId: get().applicationReferenceId,
        event: "businessEmailAddressCollected",
      });

      await updateApplication(get().isApplicantAuthenticated, get().applicationReferenceId, {
        business: {
          addresses: formatBusinessAddressesForSubmit({
            address,
            hasDifferentMailingAddress,
            mailingAddress,
          }),
          phoneNumbers: [
            {
              phoneNumber: phone,
              phoneType: "WORK",
            },
          ],
          webAddress,
          emailAddress: businessEmail,
          isBusinessAddressTheHomeAddress,
        },
      });

      set({
        isBusinessAddressValid: isPhysicalValid,
        businessAddress1: address.addressLine1,
        businessAddress2: address.addressLine2,
        businessAddressCity: address.city,
        businessAddressState: address.state,
        businessAddressPostalCode: address.zip,
        isBusinessMailingAddressValid: isMailingValid,
        businessMailingAddress1: mailingAddress?.addressLine1,
        businessMailingAddress2: mailingAddress?.addressLine2,
        businessMailingAddressCity: mailingAddress?.city,
        businessMailingAddressState: mailingAddress?.state,
        businessMailingAddressPostalCode: mailingAddress?.zip,
        doesBusinessUseDifferentMailingAddress: hasDifferentMailingAddress,
        businessPrimaryPhone: phone,
        businessWebAddress: webAddress,
        businessPrimaryEmail: businessEmail,
        isBusinessAddressTheHomeAddress,
      });
    },
    submitBusinessOwnershipInfo: async ({
      isApplicantBusinessOwner,
      ownershipPercentage,
      businessControllerType,
      hasOtherOwners,
      hasNoBeneficialOwnersAttestation,
    }) => {
      const businessOwners = hasOtherOwners ? get().businessOwners : [];

      await Promise.all([
        updateApplication(get().isApplicantAuthenticated, get().applicationReferenceId, {
          applicant: {
            businessControllerType,
            ...formatOwnershipDataForSubmit(isApplicantBusinessOwner, ownershipPercentage),
          },
          businessOwners: businessOwners.map(formatBusinessOwnerForSubmit),
        }),
        ...addBOBCAttestationEvents(get().applicationReferenceId, hasNoBeneficialOwnersAttestation),
      ]);

      set({
        businessControllerType,
        isApplicantBusinessOwner,
        applicantOwnershipPercentage: ownershipPercentage,
        hasOtherBeneficialOwners: hasOtherOwners,
        hasNoBeneficialOwnersAttestation,
        businessOwners, // this will clear out BOs if !hasOtherOwners
      });
    },
    submitAdditionalExistingBusinessDetails: async ({
      companyTypeSelected,
      taxClassification,
      partnershipStructure,
      doingBusinessName,
      businessIndustry,
      naicsCode,
      annualRevenueRange,
      accountPurpose,
      isBusinessHeadquarteredOutsideUS,
      outsideUSInfo: { countryOfHeadquarters, countryOfRegistration, countryOfPrimaryOperations } = {},
      isCharitableOrganization,
      charitableOrganization: {
        purposeOfOrganization,
        purposeOfOrganizationOther,
        hasRepeatedInternationalActivity,
      } = {},
      isUnsupportedBusinessType,
      isTrust,
      requireCompanyType,
      areStateDocsFiled,
      hasMultipleBusinessOwners,
    }) => {
      // Special case where existing non profit selected no to both screening questions. In this case,
      // there are no eligible company types (since the only eligible company type when both screening question
      // answers are no is a sole proprietorship, which is not an option for non profits), so we send events to
      // indicate their screening question answers and return without patching anything
      if (requireCompanyType && areStateDocsFiled === false && hasMultipleBusinessOwners === false) {
        addNonCriticalEventToApplication({
          applicationReferenceId: get().applicationReferenceId,
          event: "noStateDocsFiled",
        });
        addNonCriticalEventToApplication({
          applicationReferenceId: get().applicationReferenceId,
          event: "noMultipleBusinessOwners",
        });
        return;
      }

      // companyTypeSelected will be falsy (technically an empty string) if the radios
      // were never displayed (i.e., if this is a for-profit business).
      // In that case, we don't want patch anything for the legal entity type, nor do we
      // want to overwrite what's in the store for any of those fields.
      // However, for existing for-profit LLCs there is a special case, tax classification
      // is collected and so needs to be stored and sent downstream.
      if (isUnsupportedBusinessType || isTrust) {
        addUnsupportedBusinessTypeEvents(get().applicationReferenceId, isUnsupportedBusinessType, isTrust);
      }

      const legalEntitySubmitFields = {};
      const legalEntityStoreFields = {};
      if (companyTypeSelected) {
        legalEntitySubmitFields.taxClassification = taxClassification;
        legalEntityStoreFields.companyType = companyTypeSelected;
        legalEntityStoreFields.businessTaxClassification = taxClassification;

        if (companyTypeSelected === LIMITED_LIABILITY_COMPANY) {
          legalEntitySubmitFields.legalEntityType = LIMITED_LIABILITY_COMPANY;
          legalEntityStoreFields.businessLegalEntityType = LIMITED_LIABILITY_COMPANY;
          legalEntityStoreFields.isSingleMemberLLC = false; // cannot be SMLLC if non-profit
        } else if (companyTypeSelected === PARTNERSHIP) {
          legalEntitySubmitFields.legalEntityType = partnershipStructure;
          legalEntityStoreFields.businessLegalEntityType = partnershipStructure;
        } else if (companyTypeSelected === CORPORATION) {
          legalEntitySubmitFields.legalEntityType = taxClassification;
          legalEntityStoreFields.businessLegalEntityType = taxClassification;
        }
      } else if (get().companyType === LIMITED_LIABILITY_COMPANY) {
        legalEntitySubmitFields.taxClassification = taxClassification;
        legalEntityStoreFields.businessTaxClassification = taxClassification;
        legalEntityStoreFields.isSingleMemberLLC =
          taxClassification === INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC;
      }

      const products = get().applicationProductsSelected;

      await updateApplication(get().isApplicantAuthenticated, get().applicationReferenceId, {
        applicationProducts:
          products.length > 0 ? formatApplicationProductsForSubmit(products, accountPurpose) : undefined,
        // for sole props we insert the same as what would be present for a normal sole prop business.
        // note - this is different than companyTypeSelected, that will be falsy for sole props,
        // since they cannot be non profits
        // other entity types do not need this as they go through the BOBC flow
        applicant: get().companyType === SOLE_PROPRIETORSHIP ? SOLE_PROP_OWNERSHIP_DATA : undefined,
        business: {
          doingBusinessAs: formatDoingBusinessAsNameForSubmit(doingBusinessName),
          annualRevenue: annualBusinessRevenueValues[annualRevenueRange],
          ...legalEntitySubmitFields,
          naicsCode,
          countryOfHeadquarters: isBusinessHeadquarteredOutsideUS ? countryOfHeadquarters : "US",
          countryOfRegistration: isBusinessHeadquarteredOutsideUS ? countryOfRegistration : "US",
          countryOfPrimaryOperations: isBusinessHeadquarteredOutsideUS ? countryOfPrimaryOperations : "US",
          isCharitableOrganization,
          charitableOrganizationInformation: isCharitableOrganization
            ? {
                hasRepeatedInternationalActivity,
                purposeOfOrganization,
                purposeOfOrganizationOther: purposeOfOrganization === OTHER ? purposeOfOrganizationOther : "",
              }
            : null,
        },
      });

      set({
        businessDoingBusinessAsName: doingBusinessName,
        ...legalEntityStoreFields,
        accountPurpose: formatAccountPurposesForStore(products, accountPurpose),
        businessIndustryDescription: businessIndustry,
        businessAnnualRevenueRange: annualRevenueRange,
        isBusinessHeadquarteredOutsideUS,
        businessHeadquarterCountry: isBusinessHeadquarteredOutsideUS
          ? countryOfHeadquarters
          : PLACEHOLDER_NONE,
        businessCountryOfRegistrationOrIncorporation: isBusinessHeadquarteredOutsideUS
          ? countryOfRegistration
          : PLACEHOLDER_NONE,
        businessCountryOfPrimaryOperations: isBusinessHeadquarteredOutsideUS
          ? countryOfPrimaryOperations
          : PLACEHOLDER_NONE,
        isCharitableOrganization,
        businessPurposeOfOrganizationCategory: isCharitableOrganization
          ? purposeOfOrganization
          : PLACEHOLDER_NONE,
        businessPurposeOfOrganizationOtherDescription:
          isCharitableOrganization && purposeOfOrganization === OTHER ? purposeOfOrganizationOther : "",
        doesBusinessExpectRepeatedInternationalActivity:
          isCharitableOrganization && hasRepeatedInternationalActivity,
        isUnsupportedBusinessType,
        isTrust,
        areStateDocsFiled: requireCompanyType ? areStateDocsFiled : null,
        hasMultipleBusinessOwners: requireCompanyType ? hasMultipleBusinessOwners : null,
      });
    },
    submitBusinessOwnersTopLevel: async businessOwners => {
      await get().updateBusinessOwners(
        businessOwners.map(({ individualId, ...rest }) => ({
          individualId: individualId || uuidv4(),
          ...rest,
        }))
      );
    },
    submitBusinessOwnersIndividualDetails: async ({ dateOfBirth, ...rest }, index) => {
      const owners = get().businessOwners;
      owners[index] = {
        ...owners[index],
        // handle DOB specifically to convert Date obj into string
        dateOfBirth: formatDateOfBirthForStore(dateOfBirth),
        ...rest,
      };
      await get().updateBusinessOwners(owners);
    },
    // submitBusinessOwnersReview: async (data, history) => {},
    // submitApplicationReview: async (data, history) => {},
    submitTermsAndConditionsAndApp: async ({
      isSubjectToBackupWithholding,
      isTaxExempt,
      taxExemptPayeeCode,
    }) => {
      // AB TEST: If we are using Adobe Target, we need to track the primary metric for reporting
      if (get().isUsingAdobeTarget) {
        // track the applicationSubmitted event for Adobe Target
        trackAdobeTargetEvent({ successMetric: "applicationSubmitted" });
      }

      const businessLegalEntityType = await get().businessLegalEntityType;

      await updateApplication(get().isApplicantAuthenticated, get().applicationReferenceId, {
        isSubjectToBackupWithholding,
        isTaxExempt: businessLegalEntityType !== SOLE_PROPRIETORSHIP ? isTaxExempt : null,
        taxExemptPayeeCode: isTaxExempt ? taxExemptPayeeCode : null,
        userDeviceData: get().userDeviceData,
      });

      const {
        applicationReferenceId,
        isApplicantAddressValid,
        isBusinessAddressValid,
        isBusinessMailingAddressValid,
        businessOwners,
        areStateDocsFiled,
        hasMultipleBusinessOwners,
      } = get();

      // do not need to await on these events since they are non-critical
      if (areStateDocsFiled !== null) {
        addNonCriticalEventToApplication({
          applicationReferenceId,
          event: areStateDocsFiled ? "stateDocsFiled" : "noStateDocsFiled",
        });
      }
      if (hasMultipleBusinessOwners !== null) {
        addNonCriticalEventToApplication({
          applicationReferenceId,
          event: hasMultipleBusinessOwners ? "hasMultipleBusinessOwners" : "noMultipleBusinessOwners",
        });
      }
      addNonCriticalEventToApplication({ applicationReferenceId, event: "applicationSubmitted" });
      if (!isApplicantAddressValid) {
        addNonCriticalEventToApplication({ applicationReferenceId, event: "invalidPersonalAddress" });
      }
      if (!isBusinessAddressValid) {
        addNonCriticalEventToApplication({ applicationReferenceId, event: "invalidBusinessAddress" });
      }
      if (!isBusinessMailingAddressValid) {
        addNonCriticalEventToApplication({ applicationReferenceId, event: "invalidMailingAddress" });
      }
      businessOwners
        .map(({ isAddressValid }, i) => [isAddressValid, invalidBOAddressEventNames[i]])
        .filter(([isAddressValid]) => !isAddressValid)
        .forEach(([, eventName]) => {
          addNonCriticalEventToApplication({ applicationReferenceId, event: eventName });
        });

      await Promise.all([
        // The following 3 events are required for compliance
        // retry 3 times and error out the application if they fail
        addCriticalEventToApplication({
          applicationReferenceId,
          event: "termsAndConditionsAttestation",
        }),
        addCriticalEventToApplication({
          applicationReferenceId,
          event: "backupWithholding",
        }),
        addCriticalEventToApplication({
          applicationReferenceId,
          event: "w9CertificationsAttestation",
        }),
      ]);

      const submissionResult = await submitApplication(
        get().isApplicantAuthenticated,
        get().applicationReferenceId
      );
      set({
        isSubjectToBackupWithholding,
        isTaxExempt,
        taxExemptPayeeCode,
        submissionResult,
      });
      return submissionResult;
    },
  },
});

// Enable devtools in everywhere except for prod based on UI_ENV
// Defaults to prod as a fallback
const useStore = (window.UI_ENV ?? "prod").includes("prod") ? create(store) : create(devtools(store));

export default useStore;
