const disclosureMap = {
  "sbao.comp.disclosure.rgda": "rulesGoverningDisclosure",
  "sbao.comp.disclosure.facta": "factaDisclosure",
  "sbao.comp.disclosure.eddn": "electronicDisclosuresAgreement",
  "sbao.disclosure.creditfreezenoticeqf": "creditFreezeNotice",
};

const extractContentVersions = content => {
  const contentVersions = [];

  content.forEach(bundle => {
    // Compliance Disclosures
    if (bundle.complianceDisclosures) {
      bundle.complianceDisclosures
        .map(({ disclosureId, disclosureVersion }) => ({
          disclosureId,
          version: disclosureVersion,
          contentName: disclosureMap[disclosureId],
        }))
        .filter(({ contentName }) => contentName)
        .forEach(version => contentVersions.push(version));
      return;
    }

    // Risky Activities
    if (bundle.riskyProhibitedActivitiesQuestions) {
      contentVersions.push({
        contentName: "riskyProhibitedActivitiesQuestions",
        contentKey: "sbao.ui.business_activities.riskyProhibitedActivitiesQuestions",
        version: bundle.riskyProhibitedActivitiesQuestions.riskyProhibitedActivitiesQuestionsMessagesVersion,
      });
      return;
    }

    // Industries
    if (bundle.businessIndustriesVersion) {
      contentVersions.push({
        contentName: "businessIndustriesList",
        version: bundle.businessIndustriesVersion,
      });
      return;
    }

    // UI Disclosures
    if (bundle.uiDisclosures) {
      bundle.uiDisclosures.forEach(({ disclosureId, disclosureVersion }) =>
        contentVersions.push({
          contentName: disclosureId,
          version: disclosureVersion,
          disclosureId,
        })
      );
      return;
    }

    // Existing Customer Content
    if (bundle.existingCustomerFlow) {
      contentVersions.push({
        contentName: "existingCustomerFlow",
        contentKey: "sbao.ui.existingCustomerFlow",
        version: bundle.existingCustomerFlow.existingCustomerFlowContentVersion,
      });
    }

    // Customer Messages
    if (bundle.preSubmitTreatments) {
      // pre-submit
      contentVersions.push({
        contentName: "preSubmitErrors",
        contentKey: "sbao.ui.presubmitTreatments.presubmitErrors",
        version: bundle.preSubmitTreatments.preSubmitErrorsContentVersion,
      });
      contentVersions.push({
        contentName: "branchPreSubmitErrors",
        contentKey: "sbao.ui.presubmitTreatments.branch.presubmitErrors",
        version: bundle.preSubmitTreatments.branchPreSubmitErrorsContentVersion,
      });
      contentVersions.push({
        contentName: "preSubmitWarnings",
        contentKey: "sbao.ui.presubmitTreatments.presubmitWarnings",
        version: bundle.preSubmitTreatments.preSubmitWarningsContentVersion,
      });
      contentVersions.push({
        contentName: "preSubmitFriction",
        contentKey: "sbao.ui.presubmitTreatments.presubmitFriction",
        version: bundle.preSubmitTreatments.preSubmitFrictionContentVersion,
      });
      contentVersions.push({
        contentName: "branchPreSubmitFriction",
        contentKey: "sbao.ui.presubmitTreatments.branch.presubmitFriction",
        version: bundle.preSubmitTreatments.branchPreSubmitFrictionContentVersion,
      });

      // post-submit
      contentVersions.push({
        contentName: "postSubmitErrors",
        contentKey: "sbao.ui.postsubmitTreatments.postsubmitErrors",
        version: bundle.postSubmitTreatments.postSubmitErrorsContentVersion,
      });
      contentVersions.push({
        contentName: "branchPostSubmitErrors",
        contentKey: "sbao.ui.postsubmitTreatments.branch.postSubmitErrors",
        version: bundle.postSubmitTreatments.branchPostSubmitErrorsContentVersion,
      });
      contentVersions.push({
        contentName: "branchPostSubmitDeclined",
        contentKey: "sbao.ui.postsubmitTreatments.branch.declined",
        version: bundle.postSubmitTreatments.branchPostSubmitDeclinedContentVersion,
      });
      contentVersions.push({
        contentName: "platformApproved",
        contentKey: "sbao.ui.postsubmitTreatments.platformTreatments.approved",
        version: bundle.postSubmitTreatments.platformTreatments.approvedContentVersion,
      });
      contentVersions.push({
        contentName: "platformDeclined",
        contentKey: "sbao.ui.postsubmitTreatments.platformTreatments.declined",
        version: bundle.postSubmitTreatments.platformTreatments.declinedContentVersion,
      });
      contentVersions.push({
        contentName: "platformReview",
        contentKey: "sbao.ui.postsubmitTreatments.platformTreatments.inProcess",
        version: bundle.postSubmitTreatments.platformTreatments.inProcessContentVersion,
      });
      contentVersions.push({
        contentName: "platformErrors",
        contentKey: "sbao.ui.postsubmitTreatments.platformTreatments.error",
        version: bundle.postSubmitTreatments.platformTreatments.errorContentVersion,
      });

      // landing page
      contentVersions.push({
        contentName: "landingPage",
        contentKey: "sbao.ui.landingPageContent",
        version: bundle.landingPageContent.landingPageMessagesVersion,
      });
      contentVersions.push({
        contentName: "eligibilityDisclosure",
        contentKey: "sbao.ui.disclosure.eligibility",
        version: bundle.landingPageContent.eligibilityDisclosureVersion,
      });

      // legal entities
      contentVersions.push({
        contentName: "legalEntityDescription",
        contentKey: "sbao.ui.faqs.entityDescriptions",
        version: bundle.legalEntityDescriptionContent.legalEntityDescriptionVersion,
      });
      contentVersions.push({
        contentName: "llcEligibilityStatement",
        contentKey: "sbao.ui.disclosure.llcEligibilityStatement",
        version: bundle.legalEntityDescriptionContent.llcEligibilityStatementVersion,
      });
      contentVersions.push({
        contentName: "taxClassificationDescription",
        contentKey: "sbao.ui.faqs.taxClassificationDescription",
        version: bundle.legalEntityDescriptionContent.taxClassificationDescriptionVersion,
      });
    }
    // Multiple Account Opening Content
    if (bundle.multipleAccountOpening) {
      contentVersions.push({
        contentName: "multipleAccountOpening",
        contentKey: "sbao.ui.multipleAccountOpening",
        version: bundle.multipleAccountOpening.multipleAccountOpeningContentVersion,
      });
    }

    // otherwise, bundle is config, can be skipped
  });

  return contentVersions;
};

export default extractContentVersions;
