import { useMemo, forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import shallow from "zustand/shallow";
import * as yup from "yup";
import {
  accountPurposeDisplay,
  COLLATERAL,
  ESCROW,
  MERCHANT_SERVICES,
  OPERATING,
  PAYROLL,
  PETTY_CASH,
  SAVINGS,
} from "../../../constants/accountPurposeTypes";
import { convertProductTypeEnum } from "../../../constants/productTypes";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import useStore from "../../../store/store";
import { Col, Row } from "../Layout";
import Select from "../Select";
import { useDropdownId } from "../../../utils/hooks/usePageScopedId";

export const accountPurposeSchema = yup.object().shape({
  BUSINESS_ADVANTAGE_SAVINGS: yup.string().notOneOf([PLACEHOLDER_NONE], "Select bank account use"),
  BUSINESS_BASIC_CHECKING: yup.string().notOneOf([PLACEHOLDER_NONE], "Select bank account use"),
  BUSINESS_UNLIMITED_CHECKING: yup.string().notOneOf([PLACEHOLDER_NONE], "Select bank account use"),
});

export const useDefaultAccountPurpose = () => {
  // Stored selected products can differ from stored account purpose if the user goes back and changes the account type.
  // This is so the non-matching accounts are not added and the default is the placeholder if the account purpose hasn't been selected yet.

  const [storedSelectedProducts, storedAccountPurpose] = useStore(
    state => [
      state.applicationProductsSelected?.map(product => product.productType) ?? [],
      state.accountPurpose,
    ],
    shallow
  );

  return useMemo(
    () =>
      Object.fromEntries(
        storedSelectedProducts.map(selectedProductType => [
          selectedProductType,
          storedAccountPurpose?.[selectedProductType] ?? PLACEHOLDER_NONE,
        ])
      ),
    [storedSelectedProducts, storedAccountPurpose]
  );
};

// Component for the actual selection, mostly extracted to allow for calling field ID hook
const AccountPurposeSelect = forwardRef(({ productType, ...rest }, ref) => (
  <Select
    id={useDropdownId("accountPurpose", productType)}
    label={`How do you plan to use this ${convertProductTypeEnum(productType)}® bank account?`}
    {...rest}
    ref={ref}
  >
    <option className="grv-select__placeholder" value={PLACEHOLDER_NONE} disabled>
      Select bank account use
    </option>
    {[OPERATING, PETTY_CASH, PAYROLL, ESCROW, MERCHANT_SERVICES, COLLATERAL, SAVINGS].map(value => (
      <option key={value} value={value}>
        {accountPurposeDisplay[value]}
      </option>
    ))}
  </Select>
));

// Adds a dropdown field for each of the currently selected products. These fields are registered
// in the containing form under the accountPurpose field, each getting the name of its assocaited product,
// e.g. accountPurpose.BUSINESS_ADVANTAGE_SAVINGS
// Takes no props, generates its own field IDs.
// Must be inside a FormContext.
const AccountPurposeFields = () => {
  const applicationProductsSelected = useStore(state => state.applicationProductsSelected);
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Row>
      {applicationProductsSelected.map(({ productType }, index) => (
        <Col key={productType} lg={4} md={3} sm={4} offset={index === 0 ? { lg: 2 } : undefined}>
          <AccountPurposeSelect
            productType={productType}
            error={errors?.accountPurpose?.[productType]?.message}
            {...register(`accountPurpose.${productType}`)}
          />
        </Col>
      ))}
    </Row>
  );
};

export default AccountPurposeFields;
