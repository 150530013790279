import { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import shallow from "zustand/shallow";
import NavigationButtons from "../../shared/NavigationButtons";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import { CenteredRow, Grid, Col, Row } from "../../shared/Layout";
import Button from "../../shared/Button";
import Page from "../../shared/Page";
import Heading from "../../shared/Heading";
import useStore from "../../../store/store";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import ProductCard from "./ProductCard";
import {
  BAS_PRODUCT_PRIMARY_CONTENT,
  BAS_PRODUCT_PRIMARY_CONTENT_HEADER,
  BAS_PRODUCT_SECONDARY_CONTENT_HEADER,
  BAS_PRODUCT_SECONDARY_CONTENT_LANDING_PAGE_KEY,
  BAS_PRODUCT_TAGLINE,
  BBC_PRODUCT_PRIMARY_CONTENT,
  BBC_PRODUCT_PRIMARY_CONTENT_HEADER,
  BBC_PRODUCT_SECONDARY_CONTENT,
  BBC_PRODUCT_SECONDARY_CONTENT_HEADER,
  BBC_PRODUCT_TAGLINE,
  BUC_PRODUCT_PRIMARY_CONTENT,
  BUC_PRODUCT_PRIMARY_CONTENT_HEADER,
  BUC_PRODUCT_SECONDARY_CONTENT,
  BUC_PRODUCT_SECONDARY_CONTENT_HEADER,
  BUC_PRODUCT_TAGLINE,
  BUSINESS_ADVANTAGE_SAVINGS,
  BUSINESS_BASIC_CHECKING,
  BUSINESS_UNLIMITED_CHECKING,
  convertProductTypeEnum,
  productTypeEnumLookup,
} from "../../../constants/productTypes";
import getContentReader from "../../../utils/getContentReader";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import { useNavigateUnlessReview } from "../../../utils/reviewContext";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import useRouting from "../../../utils/hooks/useRouting";
import mediaQuery from "../../../utils/mediaQuery";
import { useButtonId, useFieldId } from "../../../utils/hooks/usePageScopedId";
import TextInput from "../../shared/TextInput/TextInput";
import useApplicationChannel from "../../../utils/hooks/useApplicationChannel";
import { SBB_WEB } from "../../../constants/applicationChannelTypes";
import { isFeatureEnabled } from "../../../utils/configSelector";
import PromoCodeIssueModal from "../../shared/PromoCodeIssueModal";
import GettingStartedInfoModal from "./GettingStartedInfoModal";
import useLandingPageContent from "../../../utils/hooks/useLandingPageContent";
import {
  PROMO_CODE_MAX_LENGTH,
  sanitizePromoCode,
  processPromoValidation,
} from "../../../utils/promoCodeValidation";
import useProductIdMapping from "../../../utils/hooks/useProductIdMapping";

const selectProductContent = (state, productType) =>
  state.content.flatMap(bundle => bundle?.products ?? []).find(bundle => bundle.productType === productType);

// Switch to single column layout at the specific screen width where the cards become too narrow to fit all of their content
const ProductSelectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: var(--grv-size-spacing-medium-1);

  @media (${mediaQuery.mediumLarge}) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: var(--grv-size-spacing-medium-1);
    grid-row-gap: var(--grv-size-spacing-small-2);
    margin-top: var(--grv-size-spacing-large-3);
  }
  ${({ $extraBottomMargin }) => ($extraBottomMargin ? "margin-bottom: 10px" : "")}
`;

const ProductSelecionSectionHeader = styled.h2`
  align-items: center;
  display: flex;
  align-self: center;
  justify-content: center;
  height: var(--grv-size-spacing-large-2);
  border: 5px 0 0 0;
  border-radius: var(--grv-size-border-radius-4);
`;

const CheckingAccountsHeader = styled(ProductSelecionSectionHeader)`
  grid-area: 1 / 1 / 2 / 2;
  margin-bottom: var(--grv-size-spacing-none);

  @media (${mediaQuery.mediumLarge}) {
    grid-area: 1 / 1 / 2 / 3;
    margin: var(--grv-size-spacing-none);
  }
`;

const SavingsAccountsHeader = styled(ProductSelecionSectionHeader)`
  grid-area: 4 / 1 / 5 / 2;
  margin: var(--grv-size-spacing-none);

  @media (${mediaQuery.mediumLarge}) {
    grid-area: 1 / 3 / 2 / 4;
  }
`;

const ProductCardsCol = styled(Col)`
  flex-direction: column;
  display: flex;
  justify-content: center;
`;

const ApplyButton = styled(Button).attrs({
  className: "grv-margin__top--medium-2",
  id: "productSelection_applyOnline_Button",
  gravityType: "progress",
})`
  min-width: 100%;
`;

const PromoCodeField = ({ error, value, onChange, onKeyDown }) => (
  <TextInput
    id={useFieldId("promoCode", "Input")}
    className="grv-margin--none"
    label="Promo code (Optional)"
    maxLength={PROMO_CODE_MAX_LENGTH}
    {...{ error, value, onChange, onKeyDown }}
  />
);

const ProductSelection = () => {
  usePageTitle("Product Selection");
  usePreventNavigation();
  const headingRef = useFocusHeading();
  const promoModalTriggeringElementRef = useRef();
  const [
    storedSelectedProducts,
    preSelectedProduct,
    BASContent,
    BBCContent,
    BUCContent,
    submitProductSelection,
    createApplication,
    submitGettingStarted,
    hasApplicationBeenCreated,
    targetTestValue,
    storedPromotionCode,
    setDisplayPromotionCode,
    setPromotionMessageDetails,
    offerFulfillmentEnabled,
  ] = useStore(
    state => [
      state.applicationProductsSelected?.map(product => product.productType) ?? [],
      productTypeEnumLookup[state.queryParams?.productsSelected?.split(",", 1)[0]] ?? null,
      selectProductContent(state, BUSINESS_ADVANTAGE_SAVINGS),
      selectProductContent(state, BUSINESS_BASIC_CHECKING),
      selectProductContent(state, BUSINESS_UNLIMITED_CHECKING),
      state.pageSubmit.submitProductSelection,
      state.pageSubmit.createApplication,
      state.pageSubmit.submitGettingStarted,
      state.applicationCreated,
      state.getTargetTestValue("landingTreatment") || "gettingStarted",
      state.promotionCode,
      state.setDisplayPromotionCode,
      state.setPromotionMessageDetails,
      isFeatureEnabled(state, "offerFulfillmentEnabled"),
    ],
    shallow
  );
  const applicationChannel = useApplicationChannel();
  const promoCodeEnabled = offerFulfillmentEnabled && applicationChannel === SBB_WEB;

  // AB TEST GROUP: product-selection landing treatment
  const isLandingPage = targetTestValue !== "gettingStarted";
  const isLandingPageWithModal =
    isLandingPage &&
    (targetTestValue === "productSelectionModalWithoutTiming" ||
      targetTestValue === "productSelectionModalWithTiming");
  const showTimeToComplete = isLandingPageWithModal && targetTestValue === "productSelectionModalWithTiming";
  const [showGettingStartedInfoModal, setShowGettingStartedInfoModal] = useState(!hasApplicationBeenCreated);

  // Use the stored selected product, otherwise fall back to pre selected product
  const [selectedProducts, setSelectedProducts] = useState(
    storedSelectedProducts.length === 0 && preSelectedProduct ? [preSelectedProduct] : storedSelectedProducts
  );
  const [isNoSelectionError, setIsNoSelectionError] = useState(false);
  const [isMultipleCheckingErrorVisible, setIsMultipleCheckingErrorVisible] = useState(false);

  // Show the pre select message only if there was pre selected product or the pre selected product matches the selected product if they came back
  const showPreSelectMessage =
    preSelectedProduct &&
    (storedSelectedProducts.length === 0 || storedSelectedProducts.includes(preSelectedProduct));

  const [promotionCode, setPromotionCode] = useState(storedPromotionCode);
  const [promotionCodeFieldError, setPromotionCodeFieldError] = useState(null);
  const [promotionCodeUnavailable, setPromotionCodeUnavailable] = useState(false);
  const [promotionCodeLookupError, setPromotionCodeLookupError] = useState(false);
  const onPromoCodeChange = useCallback(e => {
    setPromotionCode(sanitizePromoCode(e.target.value));
    setPromotionCodeFieldError(null);
  }, []);

  const productIdMapping = useProductIdMapping();

  const navigate = useNavigateUnlessReview();
  const [loading, setLoading] = useState(false);
  const [backRoute, nextRoute] = useRouting();
  const submitHandler = useCallback(
    async (skipPromoCheck = false) => {
      setLoading(true);
      if (selectedProducts.length === 0) {
        setIsNoSelectionError(true);
        setLoading(false);
        return;
      }
      // If multiple checking accounts are selected, display an error
      if (
        selectedProducts.length > 1 &&
        selectedProducts.includes(BUSINESS_UNLIMITED_CHECKING) &&
        selectedProducts.includes(BUSINESS_BASIC_CHECKING)
      ) {
        setIsMultipleCheckingErrorVisible(true);
        setLoading(false);
        return;
      }

      try {
        if (isLandingPage) {
          if (!hasApplicationBeenCreated) {
            await createApplication();
          }
          submitGettingStarted();
        }
        if (!skipPromoCheck && promoCodeEnabled && promotionCode) {
          let validateProducts;
          try {
            validateProducts = await Promise.all(
              selectedProducts.map(productType =>
                processPromoValidation(productIdMapping[productType], productType, promotionCode)
              )
            );
          } catch (error) {
            setPromotionCodeLookupError(true);
            setDisplayPromotionCode(false);
            return;
          }
          const passedPromoProduct = validateProducts.find(product => product.isPromotionValid);
          if (!passedPromoProduct) {
            const promoErrorDetails = validateProducts.map(({ errorCode }) => errorCode).filter(Boolean);
            // no matter what error we got - stop showing the promotion code banner
            setDisplayPromotionCode(false);
            // search for the high priority expiration error code first
            if (promoErrorDetails.includes("228007")) {
              setPromotionCodeFieldError("This promo code has expired.");
              return;
            }
            // otherwise just use the first error code - if we somehow don't have any, this will treat it as an API error
            switch (promoErrorDetails[0]) {
              case "228000":
              case "228001":
                setPromotionCodeFieldError("This promo code is invalid. Check the code and try again.");
                return;
              case "229000":
                setPromotionCodeUnavailable(true);
                return;
              default:
                setPromotionCodeLookupError(true);
                return;
            }
          }

          // promotion is valid for a product - can proceed to submit, and start displaying the banner
          setDisplayPromotionCode(true);
          setPromotionMessageDetails(
            passedPromoProduct.productType,
            passedPromoProduct.fundingPromotionMessage
          );
          await submitProductSelection(selectedProducts, promotionCode, passedPromoProduct.productType);
        } else {
          await submitProductSelection(selectedProducts);
        }
      } finally {
        setLoading(false);
      }
      navigate(nextRoute);
    },
    [
      submitProductSelection,
      setDisplayPromotionCode,
      setPromotionMessageDetails,
      selectedProducts,
      productIdMapping,
      promoCodeEnabled,
      promotionCode,
      navigate,
      nextRoute,
      createApplication,
      hasApplicationBeenCreated,
      isLandingPage,
      submitGettingStarted,
    ]
  );
  const submitWithErrorHandling = useSubmitWithErrorHandling(submitHandler);

  const handleCardClick = useCallback(
    product => {
      // If the product that was clicked is already selected, remove it from the list; otherwise, add it to the list
      const newProductArray = selectedProducts.includes(product)
        ? selectedProducts.filter(arrayItem => arrayItem !== product)
        : [...selectedProducts, product];
      setSelectedProducts(newProductArray);

      setIsNoSelectionError(false);
      // Clear the multiple checking accounts selected error if it is resolved we need to check
      // newProductArray here since selectedProducts won't be updated until function concludes
      const isMultipleCheckingError =
        newProductArray.length > 1 &&
        newProductArray.includes(BUSINESS_UNLIMITED_CHECKING) &&
        newProductArray.includes(BUSINESS_BASIC_CHECKING);
      if (!isMultipleCheckingError) {
        setIsMultipleCheckingErrorVisible(false);
      }
    },
    [selectedProducts]
  );

  const applyOnlineButtonId = useButtonId("applyOnline");

  const landingPageContent = useLandingPageContent();

  if (!BASContent || !BBCContent || !BUCContent || !landingPageContent) {
    return <LoadingSpinnerPage />;
  }

  const readBASContent = getContentReader(BASContent);
  const readBBCContent = getContentReader(BBCContent);
  const readBUCContent = getContentReader(BUCContent);
  const readLandingPageContent = getContentReader(landingPageContent);

  return (
    <Page showCIPDisclaimer={isLandingPage}>
      <Grid ref={headingRef}>
        <Heading
          step={isLandingPage ? "PRODUCT SELECTION" : "PRODUCT INTEREST"}
          stepIndicatorCustomCol={10}
          mainHeadingCustomCol={10}
          subHeadingCustomCol={10}
          mainHeading={
            showPreSelectMessage
              ? "Would you like to add an additional product?"
              : "Which account(s) would you like to open?"
          }
          subHeading={
            // eslint-disable-next-line no-nested-ternary
            showPreSelectMessage ? (
              <>
                You pre-selected a{preSelectedProduct !== BUSINESS_BASIC_CHECKING ? "n" : ""}{" "}
                <span className="grv-text grv-weight--semibold">
                  {convertProductTypeEnum(preSelectedProduct)}®
                </span>{" "}
                {isLandingPage
                  ? "account. You can change your selection or add an additional product before you apply."
                  : "account. You can change your selection or add an additional product."}
              </>
            ) : isLandingPage ? (
              "We currently offer 3 types of Small Business Bank accounts. Select the account(s) you'd like to open before you apply."
            ) : (
              "We currently offer 3 types of Small Business Bank accounts. Make your selection below."
            )
          }
        />
        <CenteredRow>
          <ProductCardsCol lg={10} md={8} sm={4}>
            <ProductSelectionGrid $extraBottomMargin={isMultipleCheckingErrorVisible}>
              <CheckingAccountsHeader className="grv-color--white grv-text--medium-1 grv-weight--semibold grv-background--interaction-blue-70">
                Business Checking
              </CheckingAccountsHeader>
              <SavingsAccountsHeader className="grv-color--white grv-text--medium-1 grv-weight--semibold grv-background--core-blue-60">
                Business Savings
              </SavingsAccountsHeader>
              <ProductCard
                productType={BUSINESS_BASIC_CHECKING}
                handleCardClick={() => handleCardClick(BUSINESS_BASIC_CHECKING)}
                isSelected={selectedProducts.includes(BUSINESS_BASIC_CHECKING)}
                contentReader={readBBCContent}
                productName={convertProductTypeEnum(BUSINESS_BASIC_CHECKING)}
                productTagline={BBC_PRODUCT_TAGLINE}
                primaryContentHeader={BBC_PRODUCT_PRIMARY_CONTENT_HEADER}
                primaryContent={BBC_PRODUCT_PRIMARY_CONTENT}
                secondaryContentHeader={BBC_PRODUCT_SECONDARY_CONTENT_HEADER}
                secondaryContent={BBC_PRODUCT_SECONDARY_CONTENT}
                checkingProduct
              />
              <ProductCard
                productType={BUSINESS_UNLIMITED_CHECKING}
                handleCardClick={() => handleCardClick(BUSINESS_UNLIMITED_CHECKING)}
                isSelected={selectedProducts.includes(BUSINESS_UNLIMITED_CHECKING)}
                contentReader={readBUCContent}
                productName={convertProductTypeEnum(BUSINESS_UNLIMITED_CHECKING)}
                productTagline={BUC_PRODUCT_TAGLINE}
                primaryContentHeader={BUC_PRODUCT_PRIMARY_CONTENT_HEADER}
                primaryContent={BUC_PRODUCT_PRIMARY_CONTENT}
                secondaryContentHeader={BUC_PRODUCT_SECONDARY_CONTENT_HEADER}
                secondaryContent={BUC_PRODUCT_SECONDARY_CONTENT}
                checkingProduct
              />
              <ProductCard
                productType={BUSINESS_ADVANTAGE_SAVINGS}
                handleCardClick={() => handleCardClick(BUSINESS_ADVANTAGE_SAVINGS)}
                isSelected={selectedProducts.includes(BUSINESS_ADVANTAGE_SAVINGS)}
                contentReader={readBASContent}
                productName={convertProductTypeEnum(BUSINESS_ADVANTAGE_SAVINGS)}
                productTagline={BAS_PRODUCT_TAGLINE}
                primaryContentHeader={BAS_PRODUCT_PRIMARY_CONTENT_HEADER}
                primaryContent={BAS_PRODUCT_PRIMARY_CONTENT}
                secondaryContentHeader={BAS_PRODUCT_SECONDARY_CONTENT_HEADER}
                secondaryContent={readLandingPageContent(BAS_PRODUCT_SECONDARY_CONTENT_LANDING_PAGE_KEY)}
              />
            </ProductSelectionGrid>
            {isMultipleCheckingErrorVisible && (
              <span aria-atomic="true" role="alert" className="grv-text grv-color--interaction-red-50">
                You can only select 1 checking and 1 savings account
              </span>
            )}
          </ProductCardsCol>
        </CenteredRow>
        {isNoSelectionError && (
          <CenteredRow className="grv-margin__top--small">
            <Col lg={10} md={8} sm={4}>
              <span aria-atomic="true" role="alert" className="grv-text grv-color--interaction-red-50">
                You must select an account to continue
              </span>
            </Col>
          </CenteredRow>
        )}
        {promoCodeEnabled && (
          <Row className="grv-margin__top--large-3">
            {/* Large/Medium-Large the field is small, when the product cards become a column the field takes the whole width */}
            <Col lg={3} ml={2} md={8} sm={4} offset={{ lg: 1 }}>
              <PromoCodeField
                error={promotionCodeFieldError}
                value={promotionCode}
                onChange={onPromoCodeChange}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    // if the modal opens, we want to return focus to this element when it closes
                    promoModalTriggeringElementRef.current = e.target;
                    // this isn't a form, but we want to submit on enter
                    submitWithErrorHandling();
                  }
                }}
              />
            </Col>
          </Row>
        )}
        {/* AB TEST GROUP: product-selection landing treatment */}
        {isLandingPage && (
          <CenteredRow>
            <Col lg={4} md={4} sm={4}>
              <ApplyButton
                id={applyOnlineButtonId}
                loading={loading}
                onClick={e => {
                  // if the promo modal opens, we want to return focus to this element when it closes
                  promoModalTriggeringElementRef.current = e?.target;
                  // then just submit as normal
                  submitWithErrorHandling();
                }}
              >
                Apply Online
              </ApplyButton>
            </Col>
          </CenteredRow>
        )}
      </Grid>
      {/* AB TEST CONTROL: getting-started landing treatment */}
      {!isLandingPage && (
        <NavigationButtons
          className="grv-margin__top--large"
          customCols={{ lg: 10 }}
          backRoute={backRoute}
          onNext={e => {
            // if the promo modal opens, we want to return focus to this element when it closes
            promoModalTriggeringElementRef.current = e?.target;
            // then just submit as normal
            submitWithErrorHandling();
          }}
          preventSubmit={isNoSelectionError || isMultipleCheckingErrorVisible}
        />
      )}
      {promoCodeEnabled && (
        // Modal will be enabled if the code is unavailable or if there was an error looking up the code
        // The only difference between the two states is some messaging
        <PromoCodeIssueModal
          isOpen={promotionCodeUnavailable || promotionCodeLookupError}
          onClose={() => {
            setPromotionCodeUnavailable(false);
            setPromotionCodeLookupError(false);
            promoModalTriggeringElementRef.current?.focus();
          }}
          onContinue={() => submitWithErrorHandling(true)}
          idBase={promotionCodeUnavailable ? "promoCodeUnavailable" : "promoCodeLookupError"}
          headingText={promotionCodeUnavailable ? "Promo unavailable" : "Something went wrong"}
        >
          {promotionCodeUnavailable ? (
            <span>
              The promo code {promotionCode} is unavailable for this account type. Click “Back” to modify your
              account type(s) or click “Continue” to continue your application without a promo code.
            </span>
          ) : (
            <span>
              Looks like there was a technical issue applying your promo code. Click “Back” to try again or
              click “Continue” to continue your application without a promo code.
            </span>
          )}
        </PromoCodeIssueModal>
      )}
      {isLandingPageWithModal && (
        <GettingStartedInfoModal
          isOpen={showGettingStartedInfoModal}
          onContinue={() => setShowGettingStartedInfoModal(false)}
          idBase="gettingStartedInfo"
          showTimeToComplete={showTimeToComplete}
        />
      )}
    </Page>
  );
};

export default ProductSelection;
