import { useLinkId } from "../../../utils/hooks/usePageScopedId";
import ExplanationModal, { ModalSubheading, ModalText } from "../../shared/ExplanationModal";
import ExternalLink from "../../shared/ExternalLink";

const UsPersonModal = ({ active, onOk }) => (
  <ExplanationModal
    {...{ active, onOk }}
    idBase="usPerson"
    headingText="U.S. Person"
    className="grv-dialog--normal"
    shouldCloseOnEsc={false}
  >
    <ModalSubheading className="grv-margin__top--small-2">
      Are you a U.S. person for tax purposes?
    </ModalSubheading>
    <ModalText>
      This includes U.S. citizens or a U.S. resident, U.S. corporations, or an estate or trust formed in the
      U.S.
    </ModalText>
    <ModalSubheading className="grv-margin__top--small-2">
      What is a U.S. resident for tax purposes?
    </ModalSubheading>
    <ModalText>
      If you are a permanent resident of the U.S. (green card holder), you are a U.S. resident for tax
      purposes. If you are a non-U.S. citizen without permanent resident status, please visit the IRS
      website&nbsp;
      <ExternalLink
        id={useLinkId("taxResidencyStatus")}
        href="https://www.irs.gov/individuals/international-taxpayers/determining-alien-tax-status"
      >
        to determine your tax residency status
      </ExternalLink>
      .
    </ModalText>
  </ExplanationModal>
);

export default UsPersonModal;
