// exported for tests
import * as yup from "yup";

const webAddressSchema = yup
  .string()
  .notRequired()
  .matches(/^((?!(\d[\s.-]*){9}).)*$/, {
    excludeEmptyString: true,
    message:
      "Avoid entering sensitive information such as Business Tax ID Numbers or Social Security Numbers",
  })
  .max(30, "Your Business Web Address cannot be longer than 30 letters.");

export default webAddressSchema;
