export const INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC =
  "INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC";
export const PARTNERSHIP = "PARTNERSHIP";
export const C_CORPORATION = "C_CORPORATION";
export const S_CORPORATION = "S_CORPORATION";
export const UNINCORPORATED = "UNINCORPORATED";
export const INCORPORATED = "INCORPORATED";

export const businessTaxClassificationDisplay = {
  [INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC]: "Individual/Sole Proprietor or Single Member LLC",
  [PARTNERSHIP]: "Partnership",
  [C_CORPORATION]: "C-Corporation",
  [S_CORPORATION]: "S-Corporation",
  [UNINCORPORATED]: "Other-Unincorporated",
  [INCORPORATED]: "Other-Incorporated",
};
