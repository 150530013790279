import { useCallback } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { differenceInYears, isFuture, isExists } from "date-fns";
import shallow from "zustand/shallow";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import Page from "../../shared/Page";
import Heading from "../../shared/Heading";
import NavigationButtons from "../../shared/NavigationButtons";
import useStore from "../../../store/store";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import { blockedTINs, ssnRejectPatterns } from "../../../constants/taxIdNumbers";
import {
  EXISTING_CUSTOMER_SIGN_IN,
  EXISTING_CUSTOMER_SSO_ENROLL,
  NEW_CUSTOMER,
} from "../../../constants/eligibilityCodes";
import PersonalInfoSection from "./PersonalInfoSection";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import { useIsReviewPage, useNavigateUnlessReview } from "../../../utils/reviewContext";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import useRouting from "../../../utils/hooks/useRouting";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import getContentReader from "../../../utils/getContentReader";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";
import getNameSchema from "../../../utils/nameValidation";
import usePrefillHandler from "../../../utils/hooks/usePrefillHandler";
import ExternalLink from "../../shared/ExternalLink";
import { isFeatureEnabled } from "../../../utils/configSelector";
import useApplicationChannel from "../../../utils/hooks/useApplicationChannel";
import { SBB_WEB } from "../../../constants/applicationChannelTypes";
import { useLinkId } from "../../../utils/hooks/usePageScopedId";

const firstNameSchema = getNameSchema({
  required: "Enter your first name",
  minLengthInvalid: "Your first name cannot be less than 2 letters",
  maxLengthInvalid: "Your first name cannot be longer than 25 letters",
  nameInvalid: "Enter your valid first name",
  combinedLengthInvalid: "Make sure your first and last name are less than 39 characters combined",
  otherNameKey: "applicantLastName",
});

const lastNameSchema = getNameSchema({
  required: "Enter your last name",
  minLengthInvalid: "Your last name cannot be less than 2 letters",
  maxLengthInvalid: "Your last name cannot be longer than 25 letters",
  nameInvalid: "Enter your valid last name",
  combinedLengthInvalid: "Make sure your first and last name are less than 39 characters combined",
  otherNameKey: "applicantFirstName",
});

// exported for testing
export const nameSchema = {
  applicantFirstName: firstNameSchema,
  applicantLastName: lastNameSchema,
};

// exported for testing
export const dateOfBirthSchema = yup
  .date()
  .transform((val, original) => {
    const parts = original.split("/");
    const yearPart = parts[parts.length - 1].trim();
    const dateExists = isExists(Number(yearPart), Number(parts[0] - 1), Number(parts[1]));
    return yearPart.length === 4 && dateExists ? val : null;
  })
  .typeError("Enter a valid date of birth")
  .required("Enter a valid date of birth")
  .test(
    "validateDateOfBirth",
    "Enter a valid date of birth",
    value => !(isFuture(value) || (value && value.getFullYear() < 1900))
  )
  .test(
    "validateDateOfBirth",
    "You must be 18 or over to apply for a business banking account",
    value => Math.abs(differenceInYears(new Date(), value)) >= 18
  );

// exported for testing
export const taxIdSchema = yup
  .string()
  .required("Enter your Social Security Number")
  .min(9, "Enter a valid 9-digit Social Security Number")
  .max(9, "Enter a valid 9-digit Social Security Number")
  .test(
    "validateSocialSecurity",
    "Enter a valid 9-digit Social Security Number",
    value => !(blockedTINs.includes(value) || ssnRejectPatterns.some(pat => pat.test(value)))
  );

const personalInfoSchema = yup.object().shape({
  ...nameSchema,
  applicantBirthDate: dateOfBirthSchema,
  applicantTaxId: taxIdSchema,
});

const PersonalInfo = () => {
  usePageTitle("Personal Information");
  const headingRef = useFocusHeading();
  const { ssoUrl, handlePrefill } = usePrefillHandler();
  const branchSSOEnrollContent = usePreSubmitTreatment("branchPreSubmitFriction", "ssoEnrollment");
  const [
    storedApplicantFirstName,
    storedApplicantLastName,
    storedApplicantBirthDate,
    storedApplicantTaxId,
    isApplicantPrefilled,
    isApplicantAuthenticated,
    delayedExistingCustomerCheckEnabled,
    submitPersonalInfo,
  ] = useStore(
    state => [
      state.applicantFirstName,
      state.applicantLastName,
      state.applicantBirthDate,
      state.applicantTaxId,
      state.isApplicantPrefilled,
      state.isApplicantAuthenticated,
      isFeatureEnabled(state, "delayedExistingCustomerCheckEnabled"),
      state.pageSubmit.submitPersonalInfo,
    ],
    shallow
  );
  const applicationChannel = useApplicationChannel();

  const isNotPrefillEligible = !isApplicantPrefilled && isApplicantAuthenticated;
  usePreventNavigation(isNotPrefillEligible);
  const isReviewPage = useIsReviewPage();

  const formMethods = useForm({
    resolver: yupResolver(personalInfoSchema),
    shouldFocusError: false,
    defaultValues: {
      applicantFirstName: storedApplicantFirstName || "",
      applicantLastName: storedApplicantLastName || "",
      applicantBirthDate: storedApplicantBirthDate || "",
      applicantTaxId: storedApplicantTaxId || "",
    },
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;
  const navigate = useNavigateUnlessReview();
  const [backRoute, nextRoute] = useRouting();
  const submitHandler = useCallback(
    async ({ applicantFirstName, applicantLastName, applicantBirthDate, applicantTaxId }) => {
      // show explicit block page for ITINs
      if (applicantTaxId.startsWith("9")) {
        navigate("/unsupported-tax-id");
        return;
      }

      const { eligibilityTreatment } = await submitPersonalInfo({
        applicantFirstName,
        applicantLastName,
        applicantBirthDate,
        applicantTaxId,
      });

      if (eligibilityTreatment === EXISTING_CUSTOMER_SIGN_IN) {
        navigate("/sign-in");
      } else if (eligibilityTreatment === EXISTING_CUSTOMER_SSO_ENROLL) {
        navigate("/enroll");
      } else if (eligibilityTreatment === NEW_CUSTOMER) {
        navigate(nextRoute);
      } else {
        navigate("/cannot-proceed");
      }
    },
    [submitPersonalInfo, navigate, nextRoute]
  );
  const submitWithErrorHandling = useSubmitWithErrorHandling(submitHandler);

  const signInLinkId = useLinkId("signIn");
  const enrollLinkId = useLinkId("enrollment");

  if (!ssoUrl || !handlePrefill || !branchSSOEnrollContent) {
    return <LoadingSpinnerPage />;
  }

  const enrollUrl = getContentReader(branchSSOEnrollContent)("buttonLinkTarget");

  return (
    <Page>
      <Grid ref={headingRef}>
        <Heading
          step="SECTION 1 OF 4"
          mainHeading={isNotPrefillEligible ? "Let's take it from the top" : "Tell us about yourself"}
          subHeading={
            isNotPrefillEligible || (delayedExistingCustomerCheckEnabled && applicationChannel === SBB_WEB)
              ? "Provide your personal information to get started."
              : "We'll use your personal information to verify your identity."
          }
        />

        {!isReviewPage &&
          !isNotPrefillEligible &&
          !(delayedExistingCustomerCheckEnabled && applicationChannel === SBB_WEB) && (
            <CenteredRow className="grv-margin__top--medium-2">
              <Col lg={8} md={8} sm={4}>
                <div className="grv-text--medium-2 grv-weight--semibold">
                  Have a Capital One Business account? We may be able to fill part of your application after
                  you{" "}
                  <ExternalLink
                    id={signInLinkId}
                    href={ssoUrl}
                    target="_self"
                    className="grv-weight--semibold"
                    onClick={async event => {
                      navigate("/loading");
                      event.preventDefault(); // stop redirect so we can check prefill endpoint first
                      await handlePrefill();
                    }}
                  >
                    sign in.
                  </ExternalLink>
                </div>
                <div className="grv-text--medium-1 grv-margin__top--small-1">
                  Capital One Business customers must sign in to their online account before moving forward.
                  If you have not{" "}
                  <ExternalLink
                    id={enrollLinkId}
                    href={enrollUrl}
                    target="_self"
                    className="grv-weight--semibold"
                  >
                    set up online access,
                  </ExternalLink>{" "}
                  please do so and then exit the application and begin again.
                </div>
              </Col>
            </CenteredRow>
          )}

        {isNotPrefillEligible && (
          <CenteredRow className="grv-margin__top--large-3">
            <Col lg={8} md={8} sm={4}>
              <div>
                <p
                  className="grv-alert--warning grv-alert--active grv-alert__message"
                  id="unsuccessful-prefill-alert"
                >
                  We weren&apos;t able to fill in part of your application for you, but you can still get
                  started with the application
                </p>
              </div>
            </Col>
          </CenteredRow>
        )}

        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
            <Grid>
              <PersonalInfoSection />
            </Grid>
            <NavigationButtons
              backRoute={backRoute}
              hideBack={delayedExistingCustomerCheckEnabled && isNotPrefillEligible && !isReviewPage}
              nextLoading={isSubmitting}
            />
          </form>
        </FormProvider>
      </Grid>
    </Page>
  );
};

export default PersonalInfo;
