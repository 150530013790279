const entitySuffixes = [
  " PC",
  " P.C.",
  " INC",
  " TRUST",
  " LLM",
  " L.L.M.",
  " Corp",
  " PLLC",
  " P.L.L.C.",
  " LLC",
  " L.L.C.",
  " INC",
  " CO",
  " PARTNERSHIP",
  " LTD",
  " L.T.D.",
];

export default entitySuffixes;
