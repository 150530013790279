export const OPERATING = "OPERATING";
export const PETTY_CASH = "PETTY_CASH";
export const PAYROLL = "PAYROLL";
export const ESCROW = "ESCROW";
export const MERCHANT_SERVICES = "MERCHANT_SERVICES";
export const COLLATERAL = "COLLATERAL";
export const SAVINGS = "SAVINGS";

export const accountPurposeDisplay = {
  [OPERATING]: "Operating",
  [PETTY_CASH]: "Petty Cash",
  [PAYROLL]: "Payroll",
  [ESCROW]: "Escrow",
  [MERCHANT_SERVICES]: "Merchant services",
  [COLLATERAL]: "Collateral",
  [SAVINGS]: "Savings",
};
