import { useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CenteredRow, Col, Grid, Row } from "../../shared/Layout";
import NavigationButtons from "../../shared/NavigationButtons";
import useStore from "../../../store/store";
import { SSN } from "../../../constants/businessTaxIdTypes";
import SectionHeader from "../../shared/SectionHeader";
import useScrollToComponent from "../../../utils/hooks/useScrollToComponent";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import DisabledField from "../../shared/DisabledField";
import useRouting from "../../../utils/hooks/useRouting";
import UnsupportedBusinessTypesSection, {
  unsupportedBusinessTypesSchema,
  useUnsupportedBusinessTypesDefault,
} from "../../shared/UnsupportedBusinessTypesSection";

const solePropSchema = yup.object().shape(unsupportedBusinessTypesSchema);

const SolePropTaxInfo = ({ areStateDocsFiled, hasMultipleBusinessOwners }) => {
  const [submitPage, applicantTaxId] = useStore(
    state => [state.pageSubmit.submitSoleProp, state.applicantTaxId],
    shallow
  );

  const unsupportedBusinessTypesDefault = useUnsupportedBusinessTypesDefault();
  const formMethods = useForm({
    resolver: yupResolver(solePropSchema),
    shouldFocusError: false,
    defaultValues: unsupportedBusinessTypesDefault,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;
  const navigate = useNavigate();
  const [backRoute, nextRoute] = useRouting();
  const submitHandler = useCallback(
    async ({ isUnsupportedBusinessType, isTrust }) => {
      // We can pass applicantTaxId for taxIdType=SSN since the ssn field is disabled for that case
      const { isEligibleCustomer } = await submitPage({
        isUnsupportedBusinessType,
        isTrust,
        taxId: applicantTaxId,
        taxIdType: SSN,
        areStateDocsFiled,
        hasMultipleBusinessOwners,
      });
      navigate(isEligibleCustomer ? nextRoute : "/cannot-proceed");
    },
    [nextRoute, applicantTaxId, submitPage, navigate, areStateDocsFiled, hasMultipleBusinessOwners]
  );
  const submitWithErrorHandling = useSubmitWithErrorHandling(submitHandler);
  const headerRef = useScrollToComponent();

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
        <Grid>
          <CenteredRow>
            <Col lg={8} md={8} sm={4}>
              {/* TODO: Change to sentence case when merging into HRBR feature branch */}
              <SectionHeader compact ref={headerRef} title="Business Type" />
            </Col>
          </CenteredRow>
          <Row>
            <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
              <UnsupportedBusinessTypesSection />
            </Col>
          </Row>
          <CenteredRow>
            <Col lg={8} md={8} sm={4}>
              <SectionHeader compact title="Tax Information" />
            </Col>
          </CenteredRow>
          <Row>
            <Col lg={4} md={4} offset={{ lg: 2 }} sm={4}>
              <DisabledField
                id="ssn-input"
                helper="We require your SSN to open a Sole Proprietorship account, even if you have an EIN. Any applicable tax reporting (e.g., IRS Form 1099) will reflect your SSN."
                label="Social Security Number (SSN)"
                value={`•••-••-${applicantTaxId.slice(5)}`}
              />
            </Col>
          </Row>
        </Grid>
        <NavigationButtons backRoute={backRoute} nextLoading={isSubmitting} />
      </form>
    </FormProvider>
  );
};

export default SolePropTaxInfo;
