import shallow from "zustand/shallow";
import useStore from "../../../store/store";
import PrefillLineItem, { StaticPrefillLineItem } from "../../shared/PrefillBox";
import {
  convertLegalEntityEnumToDisplay,
  LIMITED_LIABILITY_COMPANY,
  SOLE_PROPRIETORSHIP,
} from "../../../constants/legalEntityTypes";
import { SSN } from "../../../constants/businessTaxIdTypes";
import { formatSSN, formatEIN } from "../../../utils/reviewFormatters";
import { ownershipTypesDisplay } from "../../../constants/ownershipTypes";
import {
  businessTaxClassificationDisplay,
  C_CORPORATION,
  S_CORPORATION,
} from "../../../constants/businessTaxClassifications";
import { partnershipTypesDisplay } from "../../../constants/partnershipTypes";

// note: also used in reviewSections but slightly different treatment for LLC
const legalEntityDisplayNames = {
  [SOLE_PROPRIETORSHIP]: convertLegalEntityEnumToDisplay(SOLE_PROPRIETORSHIP),
  [LIMITED_LIABILITY_COMPANY]: convertLegalEntityEnumToDisplay(LIMITED_LIABILITY_COMPANY),
  ...partnershipTypesDisplay,
  [C_CORPORATION]: businessTaxClassificationDisplay[C_CORPORATION],
  [S_CORPORATION]: businessTaxClassificationDisplay[S_CORPORATION],
};

export const BusinessLegalName = () => {
  const [businessLegalName, isSoleProp] = useStore(
    state => [state.businessLegalName, state.businessLegalEntityType === SOLE_PROPRIETORSHIP],
    shallow
  );

  // Hide legal business name for sole prop since it is just the applicant's full name
  return businessLegalName && !isSoleProp ? (
    <StaticPrefillLineItem
      idBase="businessPrefilledLegalName"
      labelText="Legal Business Name"
      content={businessLegalName}
    />
  ) : (
    <></>
  );
};

export const BusinessLegalEntityType = () => {
  const [isNonProfit, businessLegalEntityType] = useStore(
    state => [state.isNonProfit, state.businessLegalEntityType],
    shallow
  );

  // Company type cannot be shown for nonprofits
  return !isNonProfit && businessLegalEntityType ? (
    <StaticPrefillLineItem
      idBase="businessPrefilledLegalEntityType"
      labelText="Company Type"
      content={legalEntityDisplayNames[businessLegalEntityType]}
    />
  ) : (
    <></>
  );
};

export const BusinessIsNonProfit = () => {
  const isSoleProp = useStore(state => state.businessLegalEntityType === SOLE_PROPRIETORSHIP);

  // Sole props cannot have a nonprofit organization status so field is not shown for that case
  return !isSoleProp ? (
    <PrefillLineItem
      idBase="businessPrefilledIsNonProfit"
      labelText="Non-Profit Organization"
      selector={({ isNonProfit }) => (isNonProfit ? "Yes" : "No")}
    />
  ) : (
    <></>
  );
};

export const BusinessTaxId = () => {
  const [businessTaxIdType, businessTaxId] = useStore(
    state => [state.businessTaxIdType, state.businessTaxId],
    shallow
  );

  return businessTaxId ? (
    <StaticPrefillLineItem
      idBase="businessPrefilledTaxId"
      labelText={
        businessTaxIdType === SSN ? "Social Security Number (SSN)" : "Employer Identification Number (EIN)"
      }
      content={businessTaxIdType === SSN ? formatSSN(businessTaxId) : formatEIN(businessTaxId)}
      mask={businessTaxIdType === SSN ? "•••-••-" : "••-•••"}
      maskButtonAriaLabel="toggle business tax ID view"
    />
  ) : (
    <></>
  );
};

export const BusinessOwnershipType = () => {
  const [businessOwnershipType, isSoleProp] = useStore(
    state => [state.businessOwnershipType, state.businessLegalEntityType === SOLE_PROPRIETORSHIP],
    shallow
  );

  // Sole props cannot have a majority ownership type so field is not shown for that case
  return !isSoleProp && businessOwnershipType ? (
    <StaticPrefillLineItem
      idBase="businessPrefilledOwnershipType"
      labelText="Majority Ownership Type"
      content={ownershipTypesDisplay[businessOwnershipType]}
    />
  ) : (
    <></>
  );
};

// Not utilized in current version of MVP2 but keeping in case this changes
// export const BusinessAnnualRevenueRange = () => {
//   const businessAnnualRevenueRange = useStore(state => state.businessAnnualRevenueRange);

//   return businessAnnualRevenueRange ? (
//     <StaticPrefillLineItem
//       idBase="businessPrefilledAnnualRevenue"
//       labelText="Annual Business Revenue"
//       content={annualBusinessRevenueRangeDisplay[businessAnnualRevenueRange]}
//     />
//   ) : (
//     <></>
//   );
// };

// Not utilized in current version of MVP2 but keeping in case this changes
// export const BusinessAddress = () => (
//   <PrefillLineItem
//     idBase="businessPrefilledAddress"
//     labelText="Physical Business Street Address"
//     selector={state =>
//       combineParts([
//         state.businessAddress1,
//         state.businessAddress2,
//         state.businessAddressCity,
//         state.businessAddressState,
//         state.businessAddressPostalCode,
//       ])
//     }
//   />
// );

// Not utilized in current version of MVP2 but keeping in case this changes
// export const BusinessPhoneNumber = () => {
//   const businessPrimaryPhone = useStore(state => state.businessPrimaryPhone);

//   return businessPrimaryPhone ? (
//     <StaticPrefillLineItem
//       idBase="businessPrefilledPhoneNumber"
//       labelText="Business Phone Number"
//       content={formatPhoneNumber(businessPrimaryPhone)}
//     />
//   ) : (
//     <></>
//   );
// };

// Not utilized in current version of MVP2 but keeping in case this changes
// export const BusinessWebAddress = () => {
//   const businessWebAddress = useStore(state => state.businessWebAddress);

//   return businessWebAddress ? (
//     <StaticPrefillLineItem
//       idBase="businessPrefilledWebAddress"
//       labelText="Business Web Address (Optional)"
//       content={businessWebAddress}
//     />
//   ) : (
//     <></>
//   );
// };
