import React, { forwardRef } from "react";
import styled from "styled-components";
import { SOLE_PROPRIETORSHIP } from "../../../constants/legalEntityTypes";
import useStore from "../../../store/store";
import mediaQuery from "../../../utils/mediaQuery";
import { CenteredRow, Col } from "../Layout";

// TODO might be better to use gravity's smaller header/text classes
// instead of manually changing the font sizes, but we would need to
// conditionally apply them based on media query

export const MainHeading = styled.h1.attrs(({ id }) => ({
  className: "grv-text grv-text__heading--xlarge",
  tabIndex: "-1",
  id: id || "page-main-heading",
}))`
  text-align: ${({ $alignCenter }) => ($alignCenter ? `center` : `left`)};
  margin-bottom: var(--grv-size-spacing-small-2);
  @media not (${mediaQuery.large}) {
    font-size: var(--grv-size-font-32);
  }
  @media print {
    font-size: var(--grv-size-font-32);
  }
`;

export const MainHeadingExtraSpacing = styled(MainHeading)`
  margin-bottom: var(--grv-size-spacing-medium-2);
  text-align: ${({ $alignCenter }) => ($alignCenter ? `center` : `left`)};
`;

export const SubHeading = styled.div.attrs(({ id }) => ({
  className: "grv-text grv-text--medium-large",
  id: id || "page-sub-heading",
}))`
  text-align: left;
  margin: 0;
  @media not (${mediaQuery.large}) {
    font-size: var(--grv-size-font-20);
  }
  @media print {
    display: none;
  }
  text-align: ${({ $alignCenter }) => ($alignCenter ? `center` : `left`)};
`;

export const StepRow = styled(CenteredRow)`
  text-align: left;
  color: var(--grv-color-digital-gray-60);
  margin-bottom: var(--grv-size-spacing-small-2);
`;

export const StepIndicator = forwardRef(({ children, ...rest }, ref) => (
  <div id="step-indicator" className="grv-text__heading grv-text__heading--normal" ref={ref} {...rest}>
    {children}
  </div>
));

export const DynamicStepIndicator = forwardRef(({ stepNumber, ...rest }, ref) => {
  const isSoleProp = useStore(state => state.businessLegalEntityType === SOLE_PROPRIETORSHIP);
  const totalNumber = isSoleProp ? 7 : 9;
  return (
    <StepIndicator {...rest} ref={ref}>
      STEP {stepNumber} OF {totalNumber}
    </StepIndicator>
  );
});

const Heading = ({
  mainHeading = null,
  subHeading = null,
  step = null,
  dynamicStepNumber = null,
  mainHeadingProps = {},
  subHeadingProps = {},
  stepIndicatorProps = {},
  extraSpacingAfterMain = false,
  subHeadingCustomCol = null,
  mainHeadingCustomCol = null,
  stepIndicatorCustomCol = null,
  alignCenter = false,
}) => (
  <>
    {step && (
      <StepRow>
        <Col lg={stepIndicatorCustomCol || 8}>
          <StepIndicator {...stepIndicatorProps}>{step}</StepIndicator>
        </Col>
      </StepRow>
    )}
    {dynamicStepNumber && (
      <StepRow>
        <Col lg={stepIndicatorCustomCol || 8}>
          <DynamicStepIndicator stepNumber={dynamicStepNumber} {...stepIndicatorProps} />
        </Col>
      </StepRow>
    )}
    {extraSpacingAfterMain || mainHeadingCustomCol ? (
      <CenteredRow>
        <Col lg={mainHeadingCustomCol || 8}>
          <MainHeadingExtraSpacing {...mainHeadingProps} $alignCenter={alignCenter}>
            {mainHeading}
          </MainHeadingExtraSpacing>
        </Col>
      </CenteredRow>
    ) : (
      <CenteredRow>
        <Col lg={8}>
          <MainHeading {...mainHeadingProps} $alignCenter={alignCenter}>
            {mainHeading}
          </MainHeading>
        </Col>
      </CenteredRow>
    )}
    <CenteredRow>
      <Col lg={subHeadingCustomCol || 8}>
        <SubHeading {...subHeadingProps} $alignCenter={alignCenter}>
          {subHeading}
        </SubHeading>
      </Col>
    </CenteredRow>
  </>
);

export default Heading;
