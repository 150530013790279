export const LIBRARIES_HISTORICAL_STUDIES_LANDMARK_PRESERVATION =
  "LIBRARIES_HISTORICAL_STUDIES_LANDMARK_PRESERVATION";
export const EARLY_CHILDHOOD_PROGRAMS_AND_SERVICES = "EARLY_CHILDHOOD_PROGRAMS_AND_SERVICES";
export const ENVIRONMENTAL_PROTECTION_AND_CONSERVATION = "ENVIRONMENTAL_PROTECTION_AND_CONSERVATION";
export const DISEASES_DISORDERS_AND_DISCIPLINES = "DISEASES_DISORDERS_AND_DISCIPLINES";
export const ADVOCACY_AND_EDUCATION = "ADVOCACY_AND_EDUCATION";
export const CIVIC_ORGANIZATIONS = "CIVIC_ORGANIZATIONS";
export const CHILDREN_AND_FAMILY_SERVICES = "CHILDREN_AND_FAMILY_SERVICES";
export const DEVELOPMENT_AND_RELIEF_SERVICES = "DEVELOPMENT_AND_RELIEF_SERVICES";
export const NON_MEDICAL_SCIENCE_AND_TECHNOLOGY_RESEARCH = "NON_MEDICAL_SCIENCE_AND_TECHNOLOGY_RESEARCH";
export const RELIGIOUS_ACTIVITIES = "RELIGIOUS_ACTIVITIES";
export const OTHER = "OTHER";

export const organizationTypeDisplayNames = {
  [LIBRARIES_HISTORICAL_STUDIES_LANDMARK_PRESERVATION]:
    "Libraries, Historical Societies, Landmark Preservation",
  [EARLY_CHILDHOOD_PROGRAMS_AND_SERVICES]: "Early Childhood Programs and Services",
  [ENVIRONMENTAL_PROTECTION_AND_CONSERVATION]: "Environmental Protection and Conservation",
  [DISEASES_DISORDERS_AND_DISCIPLINES]: "Diseases, Disorders and Disciplines",
  [ADVOCACY_AND_EDUCATION]: "Advocacy and Education",
  [CIVIC_ORGANIZATIONS]: "Civic Organizations",
  [CHILDREN_AND_FAMILY_SERVICES]: "Children's and Family Services",
  [DEVELOPMENT_AND_RELIEF_SERVICES]: "Development and Relief Services",
  [NON_MEDICAL_SCIENCE_AND_TECHNOLOGY_RESEARCH]: "Non-Medical Science and Technology Research",
  [RELIGIOUS_ACTIVITIES]: "Religious Activities",
  [OTHER]: "Other",
};
