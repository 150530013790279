import React, { useEffect } from "react";
import { ContentContainer } from "../Page";
import useStore from "../../../store/store";
import { isFeatureEnabled } from "../../../utils/configSelector";

const CEMPSurvey = () => {
  const customerExperienceSurveyEnabled = useStore(state =>
    isFeatureEnabled(state, "customerExperienceSurveyEnabled")
  );

  const envLoc = window.UI_ENV;
  const containerId =
    envLoc === "prod" ? "cemp-digital-sbb-account-opening" : "cemp-digital-sbb-account-opening-test";
  useEffect(() => {
    if (
      customerExperienceSurveyEnabled &&
      !!window.activateCempDigital &&
      typeof window.activateCempDigital === "function"
    ) {
      window.cempDataLayer = {
        ...window.cempDataLayer,
        responseURL: window.cempDataLayer?.responseURL ?? window.location.href,
      };

      window.activateCempDigital();
    }
  }, [customerExperienceSurveyEnabled]);
  return customerExperienceSurveyEnabled ? (
    <ContentContainer className="grv-margin__top--large-1">
      <div id={containerId} />
    </ContentContainer>
  ) : null;
};

export default CEMPSurvey;
