import { useState, useCallback } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as ShowLined } from "@c1/gravity-icons/dist/svg/ui-lined-show-1-24.svg";
import { ReactComponent as HideLined } from "@c1/gravity-icons/dist/svg/ui-lined-show-hide-1-24.svg";
import { ReactComponent as ShowFilled } from "@c1/gravity-icons/dist/svg/ui-filled-show-1-24.svg";
import { ReactComponent as HideFilled } from "@c1/gravity-icons/dist/svg/ui-filled-show-hide-1-24.svg";

const iconStyle = css`
  fill: var(--grv-color-icon-interaction-blue-50);
  padding: 0;
  height: ${({ $size }) => $size};
  aspect-ratio: 1;
  vertical-align: middle;
`;

export const ShowIconLined = styled(ShowLined)`
  ${iconStyle}
`;

export const HideIconLined = styled(HideLined)`
  ${iconStyle}
`;

export const ShowIconFilled = styled(ShowFilled)`
  ${iconStyle}
`;

export const HideIconFilled = styled(HideFilled)`
  ${iconStyle}
`;

const MaskButton = styled.button`
  border: none;
  background: transparent;
  user-select: none;
  cursor: pointer;
`;

const MaskedValue = ({
  id,
  value,
  mask = "",
  maskButtonAriaLabel = "show",
  showIcon,
  hideIcon,
  fieldClassName,
  buttonClassName,
}) => {
  const [masked, setMasked] = useState(true);
  const toggleMasking = useCallback(() => setMasked(m => !m), []);

  return (
    <>
      <span id={`${id}-field`} className={fieldClassName}>
        {masked ? `${mask}${value.slice(mask.length)}` : value}
      </span>
      <MaskButton
        id={`${id}-button`}
        aria-label={maskButtonAriaLabel}
        onClick={toggleMasking}
        aria-pressed={!masked}
        className={buttonClassName}
      >
        {masked ? showIcon : hideIcon}
      </MaskButton>
    </>
  );
};

export default MaskedValue;
