// react-hook-form's default logic for scrolling to the first field with an error has some shortfalls
// - first: it does not (consistently) work with fields inside a Controller, even when refs are passed appropriately
// - second: it frequently trips up on conditionally mounted fields, often selecting the first to *mount* instead of the first *visually*
// Neither of these are easy problems for the library to solve in a generic way

// This function provides a way to get the desired behavior without relying on hook-form at all.
// This function should be given as the second argument to handleSubmit, which will then be called with the current form errors.

const selectFirstFieldWithError = errors => {
  // First - query the document to find everything that looks like a field
  // Unfortunately, hook-form calls this *before* any error elements are present, and so aria-invalid (or similar) cannot be used
  // Therefore, we have to query for all elements within a form
  // Then, filter these elements to only those with errors in hook-form
  // Specifically, split the name of the element on .'s, and use those to key into the given errors object
  // For example, address field names are of the form "address.addressLine1", "address.city", etc., and
  // the errors object will then look like { address: { addressLine1: {...}, city: {...} }}.
  // So, the components of the field name can be used to key into that object
  // If the result of drilling into the errors object is non-null, there is an error for that field

  const elements = Array.from(document.forms) // all forms
    .flatMap(form => Array.from(form.elements)) // ... all elements within all forms ...
    .filter(({ name }) => name?.split(".")?.reduce((acc, key) => acc?.[key], errors)); // ... where that field has an error

  // If there were no elements with errors, give up. Theoretically, this should never happen
  if (elements.length === 0) {
    return;
  }

  // Then, find the "first" error field
  const first = elements.reduce((best, curr) => {
    const bestPos = best.getBoundingClientRect();
    const currPos = curr.getBoundingClientRect();

    // If these are at the same Y level, pick the one on the left
    if (bestPos.top === currPos.top) {
      return bestPos.left < currPos.left ? best : curr;
    }

    // Otherwise, pick the one that is higher up the page
    return bestPos.top < currPos.top ? best : curr;
  });

  // And finally, scroll that error field to the center of the screen (to avoid the header) and focus it
  // (scrollIntoView is done conditionally to avoid breaking unit tests where it is not supported)
  first.scrollIntoView?.({ block: "center" });
  first.focus({ preventScroll: true });
};

export default selectFirstFieldWithError;
