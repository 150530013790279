import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { sendPageViewEvent, sendFormComplete, sendFormFail } from "../sendAnalyticsEvent";
import useApplicationChannel from "./useApplicationChannel";
import { sendPageActionToNewRelic } from "../newRelic";

const usePageEvents = () => {
  const location = useLocation();
  const channel = useApplicationChannel();

  // Send page_view event to OneTag & NR every time a new, valid page is visited
  useEffect(() => {
    if (channel) {
      if (location.pathname !== "/loading") {
        sendPageViewEvent(channel);
      }
      sendPageActionToNewRelic("pageView", { location: location?.pathname });

      if (location.pathname.startsWith("/decision") || location.pathname.startsWith("/gov-id")) {
        sendFormComplete(channel);
      }

      // send sendFormFail for error pages
      if (
        location.pathname === "/branch-application-not-created" ||
        location.pathname === "/application-unavailable" ||
        location.pathname === "/cannot-proceed" ||
        location.pathname === "/existing-data-failure" ||
        location.pathname === "/something-went-wrong" ||
        location.pathname === "/restart-application"
      ) {
        sendFormFail(channel);
      }
    }
  }, [location, channel]);
};

export default usePageEvents;
