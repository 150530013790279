export const BUSINESS_ADVANTAGE_SAVINGS = "BUSINESS_ADVANTAGE_SAVINGS";
export const BUSINESS_BASIC_CHECKING = "BUSINESS_BASIC_CHECKING";
export const BUSINESS_UNLIMITED_CHECKING = "BUSINESS_UNLIMITED_CHECKING";
export const BBC_PRODUCT_TAGLINE = "For everyday checking needs";
export const BBC_PRODUCT_PRIMARY_CONTENT_HEADER = "MONTHLY SERVICE FEE";
export const BBC_PRODUCT_PRIMARY_CONTENT = "$15 monthly service fee, waived with $2,000 minimum balance";
export const BBC_PRODUCT_SECONDARY_CONTENT_HEADER = "WIRES";
export const BBC_PRODUCT_SECONDARY_CONTENT =
  "For domestic wires, $15 per incoming wire and $25 per outgoing wire";
export const BUC_PRODUCT_TAGLINE = "For higher transaction needs";
export const BUC_PRODUCT_PRIMARY_CONTENT_HEADER = "MONTHLY SERVICE FEE";
export const BUC_PRODUCT_PRIMARY_CONTENT = "$35 monthly service fee, waived with $25,000 minimum balance";
export const BUC_PRODUCT_SECONDARY_CONTENT_HEADER = "WIRES";
export const BUC_PRODUCT_SECONDARY_CONTENT =
  "Incoming wires are free, first 5 outgoing wires are free each month";
export const BAS_PRODUCT_TAGLINE = "For growing your money";
export const BAS_PRODUCT_PRIMARY_CONTENT_HEADER = "MONTHLY SERVICE FEE";
export const BAS_PRODUCT_PRIMARY_CONTENT = "$3 monthly service fee, waived with $300 minimum balance ";
export const BAS_PRODUCT_SECONDARY_CONTENT_HEADER = "INTEREST RATE";
export const BAS_PRODUCT_SECONDARY_CONTENT_LANDING_PAGE_KEY = "savingsProductSecondaryContent"; // BAS (savings) product secondary pulled from chariot

export const convertProductTypeEnum = enumValue => {
  switch (enumValue) {
    case BUSINESS_ADVANTAGE_SAVINGS:
      return "Advantage Savings";
    case BUSINESS_BASIC_CHECKING:
      return "Basic Checking";
    case BUSINESS_UNLIMITED_CHECKING:
      return "Enhanced Checking";
    default:
      return enumValue;
  }
};

export const KNOWN_PRODUCT_TYPES = [
  BUSINESS_ADVANTAGE_SAVINGS,
  BUSINESS_BASIC_CHECKING,
  BUSINESS_UNLIMITED_CHECKING,
];

export const productKeyMapping = {
  [BUSINESS_UNLIMITED_CHECKING]: {
    primary: "primaryEnhancedCheckingDetail",
    secondary: "secondaryEnhancedCheckingDetail",
    disclosure: "disclosureEnhancedChecking",
  },
  [BUSINESS_ADVANTAGE_SAVINGS]: {
    primary: "primaryAdvantageSavingsDetail",
    secondary: "secondaryAdvantageSavingsDetail",
    disclosure: "disclosureAdvantageSavings",
  },
  [BUSINESS_BASIC_CHECKING]: {
    primary: "primaryBasicCheckingDetail",
    secondary: "secondaryBasicCheckingDetail",
    disclosure: "disclosureBasicChecking",
  },
};

export const productTypeEnumLookup = {
  basic: BUSINESS_BASIC_CHECKING,
  enhanced: BUSINESS_UNLIMITED_CHECKING,
  // Keeping for old backwards compatibility - to remove when all systems pass new query parameter
  unlimited: BUSINESS_UNLIMITED_CHECKING,
  savings: BUSINESS_ADVANTAGE_SAVINGS,
};

export const validQueryParamProductTypes = Object.keys(productTypeEnumLookup);

export const convertProductTypeEnumToQueryParam = productTypeEnum =>
  validQueryParamProductTypes.find(
    key => productTypeEnumLookup[key] === productTypeEnum && key !== "unlimited" // unlimited account type is deprecated
  );
