export const APPROVED = "APPROVED"; // for Mult Acct Opening
export const APPROVED_AND_BOOKED = "APPROVED_AND_BOOKED";
export const APPROVED_AND_ACCEPTED = "APPROVED_AND_ACCEPTED";
export const APPROVED_AND_BOOKING_ERROR = "APPROVED_AND_BOOKING_ERROR";
export const DECLINED = "DECLINED";
export const REVIEW = "REVIEW";
export const ERROR_WILL_RETRY_DECISIONING = "ERROR_WILL_RETRY_DECISIONING";
export const ERROR_DECISIONING_SYSTEM = "ERROR_DECISIONING_SYSTEM";
export const ERROR_DECISIONING_DATA = "ERROR_DECISIONING_DATA";
export const ERROR_BACKEND_SYSTEM = "ERROR_BACKEND_SYSTEM";
export const GOVID_SMS = "GOVID_SMS";
export const GOVID_EMAIL = "GOVID_EMAIL";
export const SUBMITTED_WITH_ERRORS = "SUBMITTED_WITH_ERRORS";

// possible account statuses
export const ACCOUNT_BOOKED = "ACCOUNT_BOOKED";
export const PENDING = "PENDING";
export const ERROR = "ERROR";
export const FAILURE = "FAILURE";
export const CONFLICT = "CONFLICT";

// possible approval page modes
export const APPROVED_MODE = "APPROVED_MODE";
export const NEXT_DAY_MODE = "NEXT_DAY_MODE";
export const SUCCESS_AND_FAILURE_MODE = "SUCCESS_AND_FAILURE_MODE";
export const ISSUE_OPENING_MODE = "ISSUE_OPENING_MODE";
export const CONFLICT_MODE = "CONFLICT_MODE";
