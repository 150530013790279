import { useCallback } from "react";
import styled from "styled-components";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import shallow from "zustand/shallow";
import * as yup from "yup";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import Heading from "../../shared/Heading";
import { CenteredRow, Col, Grid, Row } from "../../shared/Layout";
import NavigationButtons from "../../shared/NavigationButtons";
import Page from "../../shared/Page";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import { useNavigateUnlessReview } from "../../../utils/reviewContext";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import { useBackRoute, useNextRouteProvider } from "../../../utils/hooks/useRouting";
import TextInput from "../../shared/TextInput";
import AccountPurposeFields, {
  accountPurposeSchema,
  useDefaultAccountPurpose,
} from "../../shared/AccountPurposeFields";
import useStore from "../../../store/store";
import SectionHeader, { Divider } from "../../shared/SectionHeader";
import {
  LIMITED_LIABILITY_COMPANY,
  OTHER,
  PARTNERSHIP,
  SOLE_PROPRIETORSHIP,
} from "../../../constants/legalEntityTypes";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import OtherBusinessFindBranchInfo from "../LegalEntity/OtherBusinessFindBranchInfo";
import doingBusinessNameSchema from "../../../utils/doingBusinessNameSchema";
import PartnershipStructureSelect from "../../shared/PartnershipStructureSelect";
import ExistingNonProfitCompanyTypeSection from "./ExistingNonProfitCompanyTypeSection";
import TaxClassificationSelect, {
  SingleMemberLLCTaxClassification,
  taxClassificationSchema,
  useDefaultTaxClassification,
} from "../../shared/TaxClassificationSelect";
import { INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC } from "../../../constants/businessTaxClassifications";
import BusinessIndustryCombobox, {
  businessIndustrySchema,
  useIsIndustryListLoaded,
} from "../../shared/BusinessIndustryCombobox";
import BusinessOutsideUSSection, {
  conditionalBusinessOutsideUSSchema,
  useDefaultOutsideUSInfo,
} from "../../shared/BusinessOutsideUSSection";
import CharitableOrganizationSection, {
  conditionalCharitableOrganizationSchema,
  useDefaultCharitableOrganizationInfo,
} from "../../shared/CharitableOrganizationSection";
import AnnualRevenueRangeSelect, { annualRevenueRangeSchema } from "../../shared/AnnualRevenueSelect";
import ExistingLLCTypeSelection from "./ExistingLLCTypeSelection";
import UnsupportedBusinessTypesSection, {
  useUnsupportedBusinessTypesDefault,
} from "../../shared/UnsupportedBusinessTypesSection";
import { useInputId } from "../../../utils/hooks/usePageScopedId";

// cannot be done via grv-margin__top--none due to precedence
const PrefillDivider = styled(Divider)`
  margin-block-start: 0;
`;

const getBCAttestation = content =>
  content
    .map(bundle => bundle?.uiDisclosures?.find(disc => disc?.disclosureId === "BCAttestation") ?? null)
    .find(bundle => bundle !== null)?.bodyText;

const getNoBOAttestation = content =>
  content
    .map(bundle => bundle?.uiDisclosures?.find(disc => disc?.disclosureId === "noBOAttestation") ?? null)
    .find(bundle => bundle !== null)?.bodyText;

// This schema has a lot of "when" statements because for the case of non-profits where both screening question answers are no,
// we do not show any other fields and want to allow the applicant to continue forward, where they will then be navigated to a terminal page.
// Keep in mind also that requireCompanyType is true for non-profits and false for all other cases.
const informationSchema = yup.object().shape({
  requireCompanyType: yup.bool(),
  areStateDocsFiled: yup
    .boolean()
    .nullable()
    .when("requireCompanyType", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
      otherwise: schema => schema,
    }),
  hasMultipleBusinessOwners: yup
    .boolean()
    .nullable()
    .when("requireCompanyType", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
      otherwise: schema => schema,
    }),
  companyTypeSelected: yup
    .string()
    .nullable()
    .when(["requireCompanyType", "areStateDocsFiled", "hasMultipleBusinessOwners"], {
      is: (requireCompanyType, areStateDocsFiled, hasMultipleBusinessOwners) =>
        requireCompanyType && (areStateDocsFiled || hasMultipleBusinessOwners),
      then: () => yup.string().typeError("Select a company type").required("Select a company type"),
      otherwise: schema => schema,
    }),
  doingBusinessName: yup
    .string()
    .when(["requireCompanyType", "areStateDocsFiled", "hasMultipleBusinessOwners"], {
      is: (requireCompanyType, areStateDocsFiled, hasMultipleBusinessOwners) =>
        !requireCompanyType || areStateDocsFiled || hasMultipleBusinessOwners,
      then: () => doingBusinessNameSchema,
      otherwise: schema => schema,
    }),
  taxClassification: yup
    .string()
    .when(
      [
        "requireTaxClassification",
        "isSingleMemberLLC",
        "requireCompanyType",
        "areStateDocsFiled",
        "hasMultipleBusinessOwners",
      ],
      {
        is: (
          requireTaxClassification,
          isSingleMemberLLC,
          requireCompanyType,
          areStateDocsFiled,
          hasMultipleBusinessOwners
        ) =>
          (!requireCompanyType || areStateDocsFiled || hasMultipleBusinessOwners) &&
          requireTaxClassification &&
          !isSingleMemberLLC, // SMLLC resets the tax class during submit, ignore the field
        then: () => taxClassificationSchema,
        otherwise: () => yup.mixed(), // allow anything, prevents null issue
      }
    ),
  partnershipStructure: yup
    .string()
    .when(["companyTypeSelected", "requireCompanyType", "areStateDocsFiled", "hasMultipleBusinessOwners"], {
      is: (companyTypeSelected, requireCompanyType, areStateDocsFiled, hasMultipleBusinessOwners) =>
        (!requireCompanyType || areStateDocsFiled || hasMultipleBusinessOwners) &&
        companyTypeSelected === PARTNERSHIP,
      then: schema =>
        schema
          .required("Select your partnership structure")
          .notOneOf([PLACEHOLDER_NONE], "Select your partnership structure"),
      otherwise: () => yup.mixed(), // allow anything, prevents null issue
    }),
  accountPurpose: yup
    .object()
    .when(["requireCompanyType", "areStateDocsFiled", "hasMultipleBusinessOwners"], {
      is: (requireCompanyType, areStateDocsFiled, hasMultipleBusinessOwners) =>
        !requireCompanyType || areStateDocsFiled || hasMultipleBusinessOwners,
      then: () => accountPurposeSchema,
      otherwise: schema => schema,
    }),
  isSingleMemberLLC: yup.mixed().when("requireLLCTypeToggle", {
    is: true,
    then: schema => schema.required("Select your LLC type").oneOf([true, false], "Select your LLC type"),
    otherwise: schema => schema,
  }),
  businessIndustry: yup
    .string()
    .when(["requireCompanyType", "areStateDocsFiled", "hasMultipleBusinessOwners"], {
      is: (requireCompanyType, areStateDocsFiled, hasMultipleBusinessOwners) =>
        !requireCompanyType || areStateDocsFiled || hasMultipleBusinessOwners,
      then: () => businessIndustrySchema,
      otherwise: schema => schema,
    }),
  annualRevenueRange: yup
    .string()
    .when(["requireCompanyType", "areStateDocsFiled", "hasMultipleBusinessOwners"], {
      is: (requireCompanyType, areStateDocsFiled, hasMultipleBusinessOwners) =>
        !requireCompanyType || areStateDocsFiled || hasMultipleBusinessOwners,
      then: () => annualRevenueRangeSchema,
      otherwise: schema => schema,
    }),
  isUnsupportedBusinessType: yup
    .boolean()
    .nullable()
    .when(["requireCompanyType", "areStateDocsFiled", "hasMultipleBusinessOwners"], {
      is: (requireCompanyType, areStateDocsFiled, hasMultipleBusinessOwners) =>
        !requireCompanyType || areStateDocsFiled || hasMultipleBusinessOwners,
      then: schema => schema.required("Select a response"),
      otherwise: schema => schema,
    }),
  isTrust: yup
    .boolean()
    .nullable()
    .when(["requireCompanyType", "areStateDocsFiled", "hasMultipleBusinessOwners"], {
      is: (requireCompanyType, areStateDocsFiled, hasMultipleBusinessOwners) =>
        !requireCompanyType || areStateDocsFiled || hasMultipleBusinessOwners,
      then: schema => schema.required("Select a response"),
      otherwise: schema => schema,
    }),
  // these default to false so we do not need conditional whens for case where both screening question answers are no
  ...conditionalBusinessOutsideUSSchema,
  ...conditionalCharitableOrganizationSchema,
});

const DoingBusinessAsName = ({ register, errors }) => (
  <TextInput
    id={useInputId("doingBusinessName")}
    label="Doing Business As (DBA) Name (If Applicable)"
    helper="Enter your public-facing name. This will appear on any checks you order."
    error={errors?.doingBusinessName?.message}
    maxLength={40}
    {...register("doingBusinessName")}
  />
);

const AdditionalExistingBusinessInformation = () => {
  usePageTitle("Additional Existing Business Information");
  usePreventNavigation();
  const headingRef = useFocusHeading();

  const [
    storedCompanyType,
    storedDoingBusinessAsName,
    storedTaxClassification,
    storedPartnershipStructure,
    storedControllerType,
    storedIsApplicantBusinessOwner,
    storedOwnershipPercentage,
    storedHasOtherOwners,
    storedHasNoBeneficialOwnersAttestation,
    storedAreStateDocsFiled,
    storedHasMultipleBusinessOwners,
    isNonProfit,
    storedIndustry,
    storedAnnualRevenueRange,
    industryCodeLookup,
    bcAttestation,
    noBOAttestation,
    submitPage,
  ] = useStore(
    state => [
      state.companyType,
      state.businessDoingBusinessAsName,
      state.businessTaxClassification,
      state.companyType === PARTNERSHIP ? state.businessLegalEntityType : null,
      state.businessControllerType,
      state.isApplicantBusinessOwner,
      state.applicantOwnershipPercentage,
      state.hasOtherBeneficialOwners,
      state.hasNoBeneficialOwnersAttestation,
      state.areStateDocsFiled,
      state.hasMultipleBusinessOwners,
      state.isNonProfit,
      state.businessIndustryDescription,
      state.businessAnnualRevenueRange,
      state.industryContent.industryCodeLookup,
      getBCAttestation(state.content),
      getNoBOAttestation(state.content),
      state.pageSubmit.submitAdditionalExistingBusinessDetails,
    ],
    shallow
  );

  const isSoleProp = storedCompanyType === SOLE_PROPRIETORSHIP;

  // Company type radios only show up for non-profits
  const showCompanyTypeRadios = isNonProfit ?? false;

  const accountPurposeDefault = useDefaultAccountPurpose();
  const charitableOrgDefault = useDefaultCharitableOrganizationInfo();
  const outsideUSDefault = useDefaultOutsideUSInfo();
  const unsupportedBusinessTypesDefault = useUnsupportedBusinessTypesDefault();

  // Tax classification necessary for non profits but also for any LLCs
  const showTaxClassification = showCompanyTypeRadios || storedCompanyType === LIMITED_LIABILITY_COMPANY;

  // For for-profits, storedCompanyType will actually have the correct company type, and so this hook will
  // properly return the expected tax classification (either stored or PLACEHOLDER_NONE if not set yet).
  // For non-profits, storedCompanyType will be invalid the first time this page is reached, and so the hook
  // will end up (correctly) returning PLACEHOLDER_NONE. If the user navigates back here and swaps their company
  // type this will not actually reset tax classification (since the form doesn't unload) but it actually doesn't
  // matter since taxClassification is fully cleared by any change to ExistingNonProfitCompanyTypeSection.
  // For-profit SMLLCs are also not a problem for the same reason, ExistingLLCTypeSelection clears taxClassification
  // on any change.
  const defaultTaxClassification = useDefaultTaxClassification({
    isNonProfit,
    companyType: storedCompanyType,
  });

  // for for-profit LLCs, confirm single vs multi member before handling tax classification
  const requireLLCTypeToggle = !isNonProfit && storedCompanyType === LIMITED_LIABILITY_COMPANY;

  const formMethods = useForm({
    resolver: yupResolver(informationSchema),
    context: { industryCodeLookup },
    shouldFocusError: false,
    defaultValues: {
      requireCompanyType: showCompanyTypeRadios,
      companyTypeSelected: showCompanyTypeRadios ? storedCompanyType : "",
      doingBusinessName: storedDoingBusinessAsName || "",
      isSingleMemberLLC: storedTaxClassification // if the storedTaxClassification is falsy, unset the radios
        ? storedTaxClassification === INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC
        : null, // explicit null so radios start unset
      requireLLCTypeToggle,
      taxClassification: defaultTaxClassification,
      requireTaxClassification: showTaxClassification,
      partnershipStructure: storedPartnershipStructure || PLACEHOLDER_NONE,
      accountPurpose: accountPurposeDefault,
      requireControllerTitle: !isSoleProp, // entire BC section not present for sole props
      businessControllerType: storedControllerType ?? PLACEHOLDER_NONE,
      requireOwnershipFields: !isNonProfit && !isSoleProp, // BC fields will not be present for non-profits and sole props
      isApplicantBusinessOwner: storedIsApplicantBusinessOwner, // explicitly allowing null
      ownershipPercentage: storedOwnershipPercentage ?? "",
      hasOtherOwners: storedHasOtherOwners, // explicitly allowing null
      hasNoBeneficialOwnersAttestation: storedHasNoBeneficialOwnersAttestation ?? false,
      businessIndustry: storedIndustry || "",
      annualRevenueRange: storedAnnualRevenueRange || PLACEHOLDER_NONE,
      areStateDocsFiled: showCompanyTypeRadios ? storedAreStateDocsFiled : null,
      hasMultipleBusinessOwners: showCompanyTypeRadios ? storedHasMultipleBusinessOwners : null,
      ...unsupportedBusinessTypesDefault,
      ...charitableOrgDefault,
      ...outsideUSDefault,
    },
  });
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = formMethods;

  const navigate = useNavigateUnlessReview();
  const backRoute = useBackRoute();
  const nextRouteProvider = useNextRouteProvider();

  const submitWithErrorHandling = useSubmitWithErrorHandling(
    useCallback(
      async ({
        isSingleMemberLLC,
        taxClassification,
        isApplicantBusinessOwner,
        ownershipPercentage,
        hasOtherOwners,
        hasNoBeneficialOwnersAttestation,
        businessIndustry,
        isUnsupportedBusinessType,
        isTrust,
        areStateDocsFiled,
        hasMultipleBusinessOwners,
        requireCompanyType,
        ...rest
      }) => {
        await submitPage({
          taxClassification: isSingleMemberLLC
            ? INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC
            : taxClassification,
          isApplicantBusinessOwner: isSingleMemberLLC ? true : isApplicantBusinessOwner,
          ownershipPercentage: isSingleMemberLLC ? 100 : ownershipPercentage,
          hasOtherOwners: isSingleMemberLLC ? false : hasOtherOwners,
          hasNoBeneficialOwnersAttestation,
          businessIndustry,
          naicsCode: industryCodeLookup.get(businessIndustry),
          isUnsupportedBusinessType,
          isTrust,
          areStateDocsFiled,
          hasMultipleBusinessOwners,
          requireCompanyType,
          ...rest,
        });
        if (
          isUnsupportedBusinessType ||
          isTrust ||
          (requireCompanyType && areStateDocsFiled === false && hasMultipleBusinessOwners === false)
        ) {
          navigate("/cannot-proceed");
        } else {
          navigate(
            nextRouteProvider({
              isSingleMemberLLC,
              businessHasOtherBeneficialOwners: isSingleMemberLLC ? false : hasOtherOwners,
            })
          );
        }
      },
      [industryCodeLookup, navigate, nextRouteProvider, submitPage]
    )
  );

  // If "Something Else" is selected, or no radio is selected, hide most sections
  const companyTypeSelected = watch("companyTypeSelected");
  const areStateDocsFiled = watch("areStateDocsFiled");
  const hasMultipleBusinessOwners = watch("hasMultipleBusinessOwners");
  // if company type radios are supposed to be shown, but the user has not answered the screening questions, the rest of the form fields should not be shown
  const invalidCompanyTypeSelected =
    showCompanyTypeRadios &&
    (companyTypeSelected === OTHER ||
      !companyTypeSelected ||
      areStateDocsFiled === null ||
      hasMultipleBusinessOwners === null);
  const showDetailsSection = !invalidCompanyTypeSelected;
  const showNavigationButtons = companyTypeSelected !== OTHER;

  // Partnership structure only necessary for partnerships
  const showPartnershipStructure = showCompanyTypeRadios && companyTypeSelected === PARTNERSHIP;

  // Show the "Cannot Open" section only if company type is "Something Else"
  const showCannotOpenSection = companyTypeSelected === OTHER;

  const isSMLLC = watch("isSingleMemberLLC");

  const industryListLoaded = useIsIndustryListLoaded();

  if (!noBOAttestation || !bcAttestation || !industryListLoaded) {
    return <LoadingSpinnerPage />;
  }

  return (
    <Page>
      <Grid ref={headingRef}>
        <Heading
          step={storedCompanyType === SOLE_PROPRIETORSHIP ? "SECTION 3 OF 4" : "SECTION 2 OF 4"}
          mainHeading="Tell us about your business"
          subHeading="We've filled in some of your additional business details. Review and fill any missing information."
        />
        {!showCompanyTypeRadios && (
          // Don't show the Section Header for Non Profits, since it is handled within ExistingNonProfitCompanyTypeSection for that case
          <CenteredRow>
            <Col lg={8} md={8} sm={4}>
              <SectionHeader title="Additional business details" />
            </Col>
          </CenteredRow>
        )}
      </Grid>

      <form onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
        {showCompanyTypeRadios && (
          <FormProvider {...formMethods}>
            <ExistingNonProfitCompanyTypeSection />
          </FormProvider>
        )}

        {showDetailsSection && (
          <>
            <Grid>
              {requireLLCTypeToggle && (
                // for for-profit LLCs, confirm single vs multi member before handling tax classification
                <FormProvider {...formMethods}>
                  <ExistingLLCTypeSelection />
                </FormProvider>
              )}
              {showTaxClassification && (!requireLLCTypeToggle || isSMLLC !== null) && (
                // show this for all non-profits and LLCs, BUT when the LLC toggle is shown, hide this until a toggle option is chosen
                <Row className={showCompanyTypeRadios ? "grv-margin__top--medium-2" : ""}>
                  <Col lg={5} md={5} offset={{ lg: 2 }} sm={4}>
                    {isSMLLC ? (
                      <SingleMemberLLCTaxClassification />
                    ) : (
                      <TaxClassificationSelect
                        {...register("taxClassification")}
                        error={errors?.taxClassification?.message}
                        // companyTypeSelected is null for everything but non-profits, but this field also comes up for for-profit LLCs
                        companyType={companyTypeSelected || LIMITED_LIABILITY_COMPANY}
                        isNonProfit={isNonProfit}
                      />
                    )}
                  </Col>
                </Row>
              )}
              <FormProvider {...formMethods}>
                <Row>
                  <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
                    <UnsupportedBusinessTypesSection />
                  </Col>
                </Row>
              </FormProvider>
              <Row>
                <Col lg={5} md={4} offset={{ lg: 2 }} sm={4}>
                  <DoingBusinessAsName {...{ register, errors }} />
                </Col>
              </Row>
              {showPartnershipStructure && (
                <Row>
                  <Col lg={5} md={5} offset={{ lg: 2 }} sm={4}>
                    <PartnershipStructureSelect
                      {...register("partnershipStructure")}
                      error={errors?.partnershipStructure?.message}
                    />
                  </Col>
                </Row>
              )}
              <FormProvider {...formMethods}>
                <AccountPurposeFields />
              </FormProvider>
              <CenteredRow>
                <Col lg={8} md={8} sm={4}>
                  <PrefillDivider />
                </Col>
              </CenteredRow>
              <CenteredRow>
                <Col lg={8} md={8} sm={4}>
                  <BusinessIndustryCombobox control={control} />
                </Col>
              </CenteredRow>
              <Row>
                <Col lg={4} md={3} offset={{ lg: 2 }} sm={4}>
                  <AnnualRevenueRangeSelect
                    label="Annual Business Revenue"
                    helper="This refers to the gross income in 1 year"
                    error={errors?.annualRevenueRange?.message}
                    {...register("annualRevenueRange")}
                  />
                </Col>
              </Row>
            </Grid>
            <FormProvider {...formMethods}>
              <BusinessOutsideUSSection />
              <CharitableOrganizationSection />
            </FormProvider>
          </>
        )}

        {showNavigationButtons && (
          <NavigationButtons
            // needs top margin when the rest of the form is hidden
            className={invalidCompanyTypeSelected ? "grv-margin__top--medium-2" : ""}
            backRoute={backRoute}
            nextLoading={isSubmitting}
          />
        )}
      </form>

      {showCannotOpenSection && (
        <div className="grv-margin__top--large-3">
          <OtherBusinessFindBranchInfo />
        </div>
      )}
    </Page>
  );
};

export default AdditionalExistingBusinessInformation;
