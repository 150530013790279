// Combines strings conditionally, delimited by spaces
// This can be useful for class names and aria fields
// Strings provided as arguments are included in the result string
// Objects provided as arguments have each value evaluated and, if it is truthy, the key is included in the result string
// For example:
//    combineStrings("foo", "bar") === "foo bar"
//    combineStrings("foo", { baz: false }, "bar") === "foo bar"
//    combineStrings("foo", { baz: true }, "bar") === "foo baz bar"
//    combineStrings("A", { "B": 100, "C": null }, "D") === "A B D"
// This is similar functionality to the classNames library

const combineStrings = (...args) =>
  args
    .map(arg =>
      typeof arg === "string"
        ? arg
        : Object.entries(arg)
            .filter(([, v]) => !!v)
            .map(([k]) => k)
            .join(" ")
    )
    .join(" ");

export default combineStrings;
