import { forwardRef } from "react";
import styled from "styled-components";
import combineStrings from "../../../utils/combineStrings";

const RadioList = forwardRef(
  (
    {
      id,
      helper,
      errorReplacesHelper = true,
      className = "",
      name,
      radios,
      error,
      onChange,
      value,
      disabled = false,
      textSize = "normal",
      legendText = null,
      errorProps = {},
      helperProps = {},
      ...rest
    },
    ref
  ) => {
    const fieldsetClasses = combineStrings("grv-fieldset grv-radiobutton__group", className);
    const legendClasses = combineStrings("grv-fieldset__legend", {
      "grv-fieldset__legend--small": textSize === "small",
    });
    const radioDivClasses = combineStrings("grv-form-field grv-radiobutton__wrapper", {
      "grv-form-field--compact": textSize === "small",
    });
    const radioInputClasses = combineStrings("grv-radiobutton", {
      "grv-radiobutton--compact": textSize === "small",
      "grv-radiobutton--error": error,
    });
    const radioLabelClasses = combineStrings("grv-label grv-label--radiobutton", {
      "grv-label--radiobutton grv-label--compact": textSize === "small",
      "grv-label--disabled": disabled,
    });
    const helperId = `${id}--helper`;
    const errorId = `${id}--error`;
    const showHelper = helper && !(errorReplacesHelper && error);
    const showLegend = legendText || error || showHelper;

    return (
      <fieldset id={id} className={fieldsetClasses} ref={ref} {...rest}>
        {showLegend && (
          <legend className={legendClasses}>
            {legendText}
            {showHelper && (
              <span id={helperId} className="grv-form-field__helper" {...helperProps}>
                {helper}
              </span>
            )}
            {error && (
              <span
                id={errorId}
                role="alert"
                className="grv-form-field__helper grv-form-field__helper--error"
                {...errorProps}
              >
                {error}
              </span>
            )}
          </legend>
        )}
        {radios?.map(radio => (
          <div key={radio.value} className={radioDivClasses}>
            <input
              className={radioInputClasses}
              type="radio"
              name={name}
              id={radio.id}
              value={radio.value}
              checked={value === radio.value}
              disabled={radio.disabled || disabled}
              onChange={onChange}
            />
            <label
              htmlFor={radio.id}
              className={combineStrings(radioLabelClasses, { "grv-label--disabled": radio.disabled })}
            >
              {radio.label}
            </label>
          </div>
        ))}
      </fieldset>
    );
  }
);

export const RadioListNormalLegend = styled(RadioList)`
  margin-bottom: var(--grv-size-spacing-medium-2);

  & legend {
    font-weight: var(--grv-font-weight-normal);
  }
`;

export const HorizontalRadioList = styled(RadioListNormalLegend)`
  display: flex;
  flex-wrap: wrap;
  gap: var(--grv-size-spacing-large-1);

  & .grv-radiobutton__wrapper {
    margin-bottom: 0;
  }
`;

export default RadioList;
