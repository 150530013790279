import styled from "styled-components";
import useStore from "../../../store/store";
import MaskedValue, { ShowIconLined, HideIconLined } from "../MaskedValue";

const LineItemContainer = styled.div.attrs({
  className: "grv-margin__bottom--medium-1 grv-text--small-2",
})`
  overflow-wrap: break-word;
`;

// Simple component that takes a title and a value, and displays them on two lines
// The id prop is used to set the prefix of the ids of these two lines
export const StaticLineItem = ({ id, title, value }) => (
  <LineItemContainer>
    <div id={`${id}-title`} className="grv-color--digital-gray-60 grv-weight--semibold">
      {title}
    </div>
    <div id={`${id}-value`}>{value}</div>
  </LineItemContainer>
);

// More complex wrapper around StaticLineItem that will automatically pull from the store
// Props:
//   - id, title: same as StaticLineItem
//   - selector: a function to be applied to the stored state to extract the value for the line item
//   - mask: should be a string if it is provided, and will be used to add a prefix mask to the
//     value resulting from selector. If this is provided, a button will be added to toggle the mask
//   - maskButtonAriaLabel: the aria-label passed to the mask toggle button, if mask is provieded
//     this should also be provided in order for this component to be accessible
const LineItem = ({ id, title, selector, mask = false, maskButtonAriaLabel = null }) => {
  const value = useStore(state => selector(state));

  return (
    <StaticLineItem
      id={id}
      title={title}
      value={
        mask ? (
          <MaskedValue
            id={`${id}-mask`}
            value={value}
            mask={mask}
            maskButtonAriaLabel={maskButtonAriaLabel}
            fieldClassName="grv-margin__right--small-2"
            showIcon={<ShowIconLined alt="Show" $size="16px" />}
            hideIcon={<HideIconLined alt="Hide" $size="16px" />}
          />
        ) : (
          value
        )
      }
    />
  );
};

export default LineItem;
