import { useEffect } from "react";

const usePreventNavigation = (preventNavigation = true) => {
  useEffect(() => {
    if (preventNavigation) {
      const unloadHandler = event => {
        event.preventDefault();
        // handle the legacy methods too
        event.returnValue = ""; // eslint-disable-line no-param-reassign
        return "";
      };
      window.addEventListener("beforeunload", unloadHandler);
      return () => {
        window.removeEventListener("beforeunload", unloadHandler);
      };
    }
    return () => {};
  }, [preventNavigation]);
};

export default usePreventNavigation;
