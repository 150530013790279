import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import "@c1/gravity-core/css/grv-core.min.css";
import "@c1/gravity-tokens/css/tokens.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TimeoutModal from "./components/shared/TimeoutModal";

const ModalOverlayStyles = createGlobalStyle`
  .grv-dialog__overlay {
    z-index: 101; /* ensure this covers the header */
  }

  .timeout-overlay {
    z-index: 102; /* ensure this covers other overlays */
  }
`;

// Hide all different console methods in production
if (window.UI_ENV === "prod") {
  const noop = () => {};
  ["log", "info", "error"].forEach(method => {
    window.console[method] = noop;
  });
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ModalOverlayStyles />
      <TimeoutModal />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
