const sharedParams = {
  lobIdentifier: "small-business-bank",
  formId: "sb_account_application",
  fieldTrackingFormName: "apply for a small business account",
};

export const { lobIdentifier, formId, fieldTrackingFormName } = sharedParams;

export const sendPageViewEvent = channel => {
  window.oneTag?.track("view", {
    event_name: "page_view",
    customer_type: channel,
    lob_identifier: lobIdentifier,
  });
};

export const sendFormStart = channel => {
  window.oneTag?.track("link", {
    event_name: "form_start",
    customer_type: channel,
    lob_identifier: lobIdentifier,
    form_id: formId,
    field_tracking_form_name: fieldTrackingFormName,
    field_tracking_name: "load",
    feature_state: "start",
  });
};

export const sendFormFieldInteraction = (interaction, target, type, channel) => {
  window.oneTag?.track("link", {
    event_name: "form_field_interaction",
    customer_type: channel,
    lob_identifier: lobIdentifier,
    form_id: formId,
    ui_interaction_action: interaction,
    ui_interaction_element_name: target,
    ui_interaction_element_type: type,
    field_tracking_form_name: fieldTrackingFormName,
    field_tracking_name: target,
    feature_state: "interim",
  });
};

export const sendButtonClick = (interaction, id, channel) => {
  window.oneTag?.track("link", {
    event_name: "button_click",
    customer_type: channel,
    lob_identifier: lobIdentifier,
    ui_interaction_action: interaction,
    ui_interaction_element_name: id,
    ui_interaction_element_type: "button",
    feature_state: "interim",
  });
};

export const sendLinkClick = (interaction, target, type, channel) => {
  window.oneTag?.track("link", {
    event_name: "link_click",
    customer_type: channel,
    lob_identifier: lobIdentifier,
    page_location: target,
    ui_interaction_action: interaction,
    ui_interaction_element_name: target,
    ui_interaction_element_type: type,
    feature_state: "interim",
  });
};

export const sendFormComplete = channel => {
  window.oneTag?.track("link", {
    event_name: "form_complete",
    customer_type: channel,
    lob_identifier: lobIdentifier,
    form_id: formId,
    field_tracking_form_name: fieldTrackingFormName,
    field_tracking_name: "complete",
    feature_state: "completion",
  });
};

export const sendFormFail = channel => {
  window.oneTag?.track("link", {
    event_name: "form_fail",
    customer_type: channel,
    lob_identifier: lobIdentifier,
    form_id: formId,
    field_tracking_form_name: fieldTrackingFormName,
    field_tracking_name: "form fail",
    application_error_message: "Error screen",
    application_error_programming_language: "Javascript",
    feature_state: "error",
  });
};
