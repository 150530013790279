/*
  Logic to determine if the business has beneficial owners
  This is relevant for:
    - showing the "no BOs" attestation
    - determining if the BO review page can be skipped
  
  Logic is according to the following table:
  x = applicant is a business owner
  y = applicant ownership >= 25% (applicant is "beneficial" owner)
  z = has other beneficial owners
  x | y | z | has BOs
  0 | 0 | 0 | 0
  0 | 0 | 1 | 1
  0 | 1 | 0 | 0
  0 | 1 | 1 | 1
  1 | 0 | 0 | 0
  1 | 0 | 1 | 1
  1 | 1 | 0 | 1
  1 | 1 | 1 | 1
  has BOs = (x && y && z) || (x && y && !z) || (x && !y && z) || (!x && y && z) || (!x && !y && z)
          = (x && y) || z
*/
const hasBeneficialOwners = (
  isApplicantBusinessOwner,
  applicantOwnershipPercentage,
  hasOtherBeneficialOwners
) => (isApplicantBusinessOwner && applicantOwnershipPercentage >= 25) || hasOtherBeneficialOwners;

export default hasBeneficialOwners;
