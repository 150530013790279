import cautionImage from "../../../assets/caution.svg";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import getContentReader from "../../../utils/getContentReader";
import TerminalPageTemplate from "../../shared/TerminalPageTemplate";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";

const MissingPersonalEmail = () => {
  usePageTitle("Missing Personal Email");
  const content = usePreSubmitTreatment("preSubmitFriction", "missingEmail");

  if (!content) {
    return <LoadingSpinnerPage />;
  }

  return (
    <TerminalPageTemplate
      enableButton
      buttonType={null}
      buttonClassName=""
      image={cautionImage}
      mediumIcon
      readContent={getContentReader(content)}
      extraSpacingAfterMainHeading={false}
    />
  );
};

export default MissingPersonalEmail;
