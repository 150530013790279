import { useEffect } from "react";
import getContentReader from "../../../utils/getContentReader";
import useStore from "../../../store/store";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import examineIcon from "../../../assets/ExamineIconAnimated.svg";
import ExistingCheckTransitionalTemplate from "./ExistingCheckTransitionalTemplate";
import usePrefillHandler from "../../../utils/hooks/usePrefillHandler";

const selectExistingCustomerCheckContent = state =>
  state.content
    .flatMap(bundle => bundle?.existingCustomerFlow?.existingCustomerFlowContent ?? [])
    .find(bundle => bundle.contentId === "existingCustomerCheck");

const ExistingCustomerCheck = () => {
  const existingCustomerCheckContent = useStore(state => selectExistingCustomerCheckContent(state));
  usePageTitle("Existing Customer Check");
  const content = existingCustomerCheckContent;
  const { handlePrefill } = usePrefillHandler({
    performSSOLoginRedirect: false, // do not bother sending the user back to sign-in, they should only be here after arriving directly from there
  });

  useEffect(() => {
    handlePrefill?.();
  }, [handlePrefill]);

  if (!content) {
    return <LoadingSpinnerPage />;
  }

  const readContent = getContentReader(content);

  return (
    <ExistingCheckTransitionalTemplate
      iconSrc={examineIcon}
      iconAlt=""
      headerText={readContent("headerText")}
      bodyText={readContent("bodyText")}
      helperText="If you have an existing Small Business Bank account with us, we may be able to fill in part of your application. If not, no worries! You can still get started with the application."
    />
  );
};
export default ExistingCustomerCheck;
