import { useCallback } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { Item, SearchCombobox } from "../Combobox";
import useStore from "../../../store/store";
import combineStrings from "../../../utils/combineStrings";
import ExternalLink from "../ExternalLink";
import { useFieldId, useLinkId } from "../../../utils/hooks/usePageScopedId";

// Validate that the industry list has been loaded into the store and is non-empty.
// While this is an edge case that should never happen, it is good practice to call
// this and return <LoadingSpinnerPage /> until this hook returns true.
export const useIsIndustryListLoaded = () => {
  const industryList = useStore(state => state.industryContent.industryList);
  return !!(industryList && industryList.length > 0);
};

// Schema for business industry, which relies on the industryCodeLookup
// being placed into the yup context. To do this, the industryContent.industryCodeLookup
// must be pulled from the store, and passed into the useForm context as follows:
//   const industryCodeLookup = useStore(state => state.industryContent.industryCodeLookup);
//   const formMethods = useForm({ context: { industryContext }, ... });
export const businessIndustrySchema = yup
  .string()
  .required("Select your business industry")
  .test(
    "valid",
    "Select a valid business industry",
    (
      industry,
      {
        options: {
          context: { industryCodeLookup },
        },
      }
    ) => industryCodeLookup.has(industry)
  );

// keys used for the searching logic of the SearchCombobox
// pulled out to a constant to prevent changes to the prop
const industryKeys = ["codeValue", "industryName"];

const IndustryItem = styled(Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// Render function for an individual entry in the combobox/dropdown
//
// MISSING TEST COVERAGE: industryName will always be present in renderItem
// istanbul ignore next
const renderIndustryItem = ({ codeValue, industryName }, search, idBase) => (
  <IndustryItem key={codeValue} id={`${idBase}--${codeValue}`} selectionValue={{ codeValue, industryName }}>
    <span aria-label={industryName || ""}>
      {[...industryName].map((c, i) => (
        <span
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={combineStrings({
            "grv-weight--semibold": search.toLowerCase().includes(c.toLowerCase()),
          })}
        >
          {c}
        </span>
      ))}
    </span>
    <span className="grv-color--digital-gray-60" aria-hidden="true">
      {codeValue}
    </span>
  </IndustryItem>
);

// Combobox for selecting the business industry, by either the name of the industry or the NAICS code.
// Pulls industries directly from industryContent in the store.
// Only uses the control prop, which should come from useForm. Does not forward refs, as that is handled
// separately by the underlying Controller.
const BusinessIndustryCombobox = ({ control, name = "businessIndustry" }) => {
  const industryList = useStore(state => state.industryContent.industryList);
  const idBase = useFieldId(name, "Combobox");
  const industryIdBase = useFieldId(name, "Item");
  const linkId = useLinkId("censusNAICSSearch");
  const renderItem = useCallback(
    (item, text) => renderIndustryItem(item, text, industryIdBase),
    [industryIdBase]
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...rest }, formState }) => (
        <SearchCombobox
          source={industryList}
          keys={industryKeys}
          maxResults={15}
          renderItem={renderItem}
          idBase={idBase}
          listboxLabel="Industries"
          onSelected={({ industryName }) => {
            onChange(industryName);
            return industryName;
          }}
          inputProps={{
            label: "Business Industry",
            helper: (
              <span>
                Choose the one that best describes what your business does. If you are unsure, use the{" "}
                <ExternalLink id={linkId} href="https://www.census.gov/naics/">
                  U.S. Census NAICS search
                </ExternalLink>
                .
              </span>
            ),
            error: formState?.errors?.businessIndustry?.message,
            onChange,
            ...rest,
          }}
        />
      )}
    />
  );
};

export default BusinessIndustryCombobox;
