import styled from "styled-components";
import shallow from "zustand/shallow";
import { Navigate } from "react-router-dom";
import useStore from "../../../../store/store";
import usePageTitle from "../../../../utils/hooks/usePageTitle";
import Page from "../../../shared/Page";
import Settings from "../../../../assets/settings.svg";
import mediaQuery from "../../../../utils/mediaQuery";
import getContentReader from "../../../../utils/getContentReader";
import LoadingSpinnerPage from "../../../shared/LoadingSpinnerPage";
import {
  NEXT_DAY_MODE,
  CONFLICT_MODE,
  ISSUE_OPENING_MODE,
  ACCOUNT_BOOKED,
  PENDING,
} from "../../../../constants/submitApplicationAndAccountStatuses";
import { productKeyMapping } from "../../../../constants/productTypes";
import { useDecisionPlatformTreatment } from "../../../../utils/hooks/usePostSubmitTreatment";
import CEMPSurvey from "../../../shared/CEMPSurvey";
import { isFeatureEnabled } from "../../../../utils/configSelector";
import DecisionTemplate from "../DecisionTemplate";
import HeaderGraphic from "./HeaderGraphic";
import TitleElement from "./TitleElement";
import FootnotesSection from "./FootnotesSection";
import useDecisionInfo from "./useDecisionInfo";
import NextStepsSection from "./NextStepsSection";

const CEMPSurveyWrapper = styled.div`
  @media (${mediaQuery.medium}) {
    /* needed because the background of this page is white */
    border-top: var(--grv-size-spacing-large-2) solid var(--grv-color-digital-gray-5);
    /* needed because the current CEMP implementation doesn't have proper spacing for medium viewport */
    padding-top: var(--grv-size-spacing-large-3);

    /* used to remove unneeded styling from the underlying CEMP implementation */
    div {
      margin-top: 0;
      box-shadow: none;
    }
  }

  /* needed because the current CEMP implementation doesn't have proper spacing for medium viewport */
  @media (${mediaQuery.large}) {
    padding-top: 0px;
  }
`;

const NextDayModeFootnotes = styled(FootnotesSection)`
  /* When matching the product table, need additional padding on small screens */
  padding-top: var(--grv-size-spacing-medium-2);
  @media (${mediaQuery.medium}) {
    /* Set to white text on dark background to match product table */
    color: var(--grv-color-white);
    background-color: var(--grv-color-background-core-blue-50);
    padding-top: var(--grv-size-spacing-small-2);
  }

  ol {
    @media (${mediaQuery.medium}) {
      /* Tweak the spacing on large screens when connected to product table */
      margin-bottom: var(--grv-size-spacing-medium-1);
    }
  }
`;

const ApprovalPage = ({
  readContent,
  numberOfAccounts,
  treatment,
  collectiveAccountStatuses,
  promotionalOfferInformation,
  children,
}) => {
  const customerExperienceSurveyEnabled = useStore(state =>
    isFeatureEnabled(state, "customerExperienceSurveyEnabled")
  );

  return (
    <Page lightMode customPageLayout>
      <HeaderGraphic />
      <TitleElement
        readContent={readContent}
        numberOfAccounts={numberOfAccounts}
        treatment={treatment}
        collectiveAccountStatuses={collectiveAccountStatuses}
        promotionalOfferInformation={promotionalOfferInformation}
      />
      {children}
      {customerExperienceSurveyEnabled && (
        <CEMPSurveyWrapper>
          <CEMPSurvey />
        </CEMPSurveyWrapper>
      )}
    </Page>
  );
};

const DecisionApproved = () => {
  const { numberOfAccounts, treatment, collectiveAccountStatuses } = useDecisionInfo();

  usePageTitle(treatment === ISSUE_OPENING_MODE ? "Something Went Wrong" : "You've Been Approved");

  const productInformationContent = useDecisionPlatformTreatment("approved", "approvedProductInformation");
  const issueOpeningSingleAccountContent = useDecisionPlatformTreatment(
    "approved",
    "approved_mao_one_booking_failed"
  );
  const issueOpeningMultipleAccountContent = useDecisionPlatformTreatment(
    "approved",
    "approved_mao_two_booking_failed"
  );

  const [displayPromotionCode, promotionalOfferInformation, promotionProductType] = useStore(
    state => [
      state.displayPromotionCode,
      state.promotionMessageDetails?.promotionMessageContent,
      state.promotionMessageDetails?.productType,
    ],
    shallow
  );
  // only actually display promo content if the account in question was booked
  const displayPromotionCodeInformation =
    displayPromotionCode && collectiveAccountStatuses[ACCOUNT_BOOKED]?.includes(promotionProductType);

  if (
    !productInformationContent ||
    !issueOpeningSingleAccountContent ||
    !issueOpeningMultipleAccountContent
  ) {
    return <LoadingSpinnerPage />;
  }

  const readContent = getContentReader(
    // this is cleaner with nested ternary
    // eslint-disable-next-line no-nested-ternary
    treatment === ISSUE_OPENING_MODE
      ? numberOfAccounts > 1
        ? issueOpeningMultipleAccountContent
        : issueOpeningSingleAccountContent
      : productInformationContent
  );

  switch (treatment) {
    case CONFLICT_MODE:
      // We shouldn't be on this page if any booking status was CONFLICT
      return <Navigate to="/restart-application" replace />;
    case ISSUE_OPENING_MODE:
      // if the treatment is not APPROVED_MODE, NEXT_DAY_MODE, or SUCCESS_AND_FAILURE_MODE, then we show a generic account issue page
      return (
        <DecisionTemplate
          iconSrc={Settings}
          headerText={readContent("headerText")}
          // utilize existing content bundles for the messaging
          bodyText={readContent(numberOfAccounts > 1 ? "bodyTextTwoFail" : "bodyText")}
        />
      );
    case NEXT_DAY_MODE:
      // All accounts pending - footnotes visually connect to the product description table and TitleElement
      // No promotion code information will be displayed - the account in question was not booked
      return (
        <ApprovalPage {...{ readContent, numberOfAccounts, treatment, collectiveAccountStatuses }}>
          <NextDayModeFootnotes
            mobileDepositsExplanation={
              numberOfAccounts === 1 &&
              readContent(productKeyMapping[collectiveAccountStatuses?.[PENDING]?.[0]].disclosure)
            }
          />
        </ApprovalPage>
      );
    default:
      // APPROVED_MODE, SUCCESS_AND_FAILURE_MODE
      // At least one account booked - show Next Steps with footnotes after that
      return (
        <ApprovalPage
          {...{
            readContent,
            numberOfAccounts,
            treatment,
            collectiveAccountStatuses,
          }}
          promotionalOfferInformation={displayPromotionCodeInformation && promotionalOfferInformation}
        >
          <NextStepsSection
            {...{ treatment, collectiveAccountStatuses }}
            onSetupClick={() => window.open(readContent("setupButtonLinkTarget"), "_blank")}
          />
          <FootnotesSection
            displayPromotionCodeInformation={displayPromotionCodeInformation}
            mobileDepositsExplanation={
              numberOfAccounts === 1 &&
              readContent(productKeyMapping[collectiveAccountStatuses?.[ACCOUNT_BOOKED]?.[0]].disclosure)
            }
          />
        </ApprovalPage>
      );
  }
};

export default DecisionApproved;
