import { useCallback, useEffect, useRef, useState } from "react";

// You may be asking: But why does this not just use setTimeout?

// There was a requirement that this timeout logic happen even when
// a browser tab is suspended and then returned to. That can happen
// on mobile when a user leaves their browser application and returns,
// or on desktop in certain browsers if the user moves to a new tab for
// a long period of time. When a browser tab is suspended, setTimeout
// and other related counters *generally* stop working. Those timers
// get paused and resume when the tab is unsuspended. However, this
// timeout logic needs to fire anyway, even if the tab is suspended.
// More specifically: If the tab was suspended, it needs to immediately
// "catch up" on that lost time when it is resumed, and determine if
// the user needs to be timed out

// So, it is necessary to instead track the time of the last user
// interaction, and compare that to the current time on some reasonable
// interval. Obviously, logic like that could very easily trigger a
// re-render of the entire application on each of those intervals,
// and so care is taken to avoid that. This hook should only be used in
// the TimeoutModal component itself, since that is mounted as a sibling
// to the App component, and so the impact of any rerender is minimized.

const interactionEvents = ["click", "keydown", "mousemove"];

const usePersistentTimeout = (timeToWarn, timeAfterWarn, enabled = true) => {
  // Accepts two times in ms. The first is the time to wait since last user interaction
  // before the timeout will enter the "warn" state. The second is the time to wait
  // once in the "warn" state before automatically transitioning to the "expired" state.
  // In the "warn" state, user interaction will *not* reset the timer on its own.
  // Optional third argument for an enabled flag, defaulting to true. If provided and false,
  // timeout logic will be halted (no checks against last interaction will be made).
  // This hook returns:
  //    { warn, expired, timeRemaining, reset }
  // Where warn and expired are boolean flags of the current timeout state,
  // timeRemaining is the time in ms before the timeout will fully expire, and reset
  // is a function which will move the timeout out of the "warn" state and effectively
  // reset the timer. Note timeRemaining is only updated in the "warn" state.

  // how long after the last interaction the session is forcibly expired
  const timeToExpire = timeToWarn + timeAfterWarn;

  // initialize timeout state and time remaining
  const [warn, setWarn] = useState(false);
  const [expired, setExpired] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(timeAfterWarn);

  // track the last time the page was interacted with and initialize it on mount
  const lastInteraction = useRef();
  const updateLastInteraction = useCallback(() => {
    lastInteraction.current = Date.now();
  }, []);
  useEffect(updateLastInteraction, [updateLastInteraction]);

  // add event listeners for all interaction events, but only while not in the warn state
  useEffect(() => {
    if (warn) {
      return () => {};
    }

    interactionEvents.forEach(e => document.addEventListener(e, updateLastInteraction));
    // on transition into warn state, clear these listeners and stop updating last interaction
    return () => {
      interactionEvents.forEach(e => document.removeEventListener(e, updateLastInteraction));
    };
  }, [warn, updateLastInteraction]);

  // on a reset, just force the last interaction to be now and warn to false
  const reset = useCallback(() => {
    updateLastInteraction();
    setWarn(false);
  }, [updateLastInteraction]);

  // set up the interval to check last interaction and update the state, if enabled
  useEffect(() => {
    if (!enabled) {
      // if disabled, the old interval will get cleaned up and we don't need to do anything
      return () => {};
    }

    // This is done every half-second to avoid visually skipping seconds in the modal text,
    // as well as to ensure that if the page is suspended, the check happens quickly after it is
    // unsuspended. This does potentially cause a re-render and is the reason this hook should
    // be used carefully
    const interval = setInterval(() => {
      const now = Date.now();
      setWarn(oldWarn => {
        const shouldWarn = oldWarn || lastInteraction.current + timeToWarn < now;
        if (shouldWarn) {
          // updating here prevents the time string from being updated every 500ms when
          // the modal is not up, and also prevents it from going to 00:00:00 when the user
          // clicks continue
          setTimeRemaining(Math.max(0, timeToExpire - now + lastInteraction.current));
        }
        return shouldWarn;
      });
      setExpired(now > lastInteraction.current + timeToExpire);
    }, 500);
    return () => clearInterval(interval);
  }, [timeToWarn, timeToExpire, enabled]);

  return { warn, expired, timeRemaining, reset };
};

export default usePersistentTimeout;
