import React from "react";
import styled from "styled-components";

const BannerBody = styled.div`
  background: var(--grv-color-background-core-blue-50);
  border-radius: var(--grv-size-border-radius-4);
  display: flex;
  align-items: center;
  padding-inline: var(--grv-size-spacing-small-2);

  svg {
    fill: var(--grv-color-accent-light-blue-60);
    padding: 0;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    vertical-align: middle;
    margin-inline: var(--grv-size-spacing-small-2);
  }
`;

const BannerText = styled.span.attrs({
  className: "grv-text grv-text--semibold",
})`
  color: #ffffff;
  padding-top: var(--grv-size-spacing-medium-1);
  padding-bottom: var(--grv-size-spacing-medium-1);
  padding-right: var(--grv-size-spacing-medium-1);
`;

const Banner = ({ bannerText, bannerIcon }) => (
  <BannerBody>
    {bannerIcon}
    <BannerText>{bannerText}</BannerText>
  </BannerBody>
);

export default Banner;
