import { useMemo } from "react";
import useStore from "../../../../store/store";
import {
  ACCOUNT_BOOKED,
  APPROVED_MODE,
  CONFLICT,
  CONFLICT_MODE,
  ERROR,
  FAILURE,
  ISSUE_OPENING_MODE,
  NEXT_DAY_MODE,
  PENDING,
  SUCCESS_AND_FAILURE_MODE,
} from "../../../../constants/submitApplicationAndAccountStatuses";
import { KNOWN_PRODUCT_TYPES } from "../../../../constants/productTypes";

const useDecisionInfo = () => {
  const accountInformation = useStore(
    state => state.submissionResult?.applicationBookingDetails?.accountInformation ?? []
  );

  const numberOfAccounts = accountInformation.length;
  const productTypes = accountInformation.map(({ productType }) => productType);

  const collectiveAccountStatuses = useMemo(
    () =>
      // reformat accountInformation to a collection of arrays, where each array is keyed by accountStatus
      // and contains all productTypes with the given accountStatus
      accountInformation.reduce((acc, { accountStatus, productType }) => {
        if (!acc[accountStatus]) {
          acc[accountStatus] = [];
        }
        acc[accountStatus].push(productType);
        return acc;
      }, {}),
    [accountInformation]
  );

  const bookedCount = collectiveAccountStatuses[ACCOUNT_BOOKED]?.length ?? 0;
  const pendingCount = collectiveAccountStatuses[PENDING]?.length ?? 0;
  const conflictCount = collectiveAccountStatuses[CONFLICT]?.length ?? 0;
  const errorCount =
    (collectiveAccountStatuses[ERROR]?.length ?? 0) + (collectiveAccountStatuses[FAILURE]?.length ?? 0);

  const treatment = useMemo(() => {
    // we should only be evaluating 1 or 2 accounts, there should be no unknown product types, and there should be no duplicates
    if (
      numberOfAccounts > 0 &&
      numberOfAccounts <= 2 &&
      productTypes.every(product => KNOWN_PRODUCT_TYPES.includes(product)) &&
      new Set(productTypes).size === productTypes.length
    ) {
      if (bookedCount === numberOfAccounts) {
        return APPROVED_MODE;
      }
      if (pendingCount === numberOfAccounts) {
        return NEXT_DAY_MODE;
      }
      if (conflictCount > 0) {
        return CONFLICT_MODE;
      }
      if (errorCount > 0 && bookedCount > 0) {
        return SUCCESS_AND_FAILURE_MODE;
      }
      // any other case - fall through to ISSUE_OPENING_MODE
    }
    return ISSUE_OPENING_MODE;
  }, [productTypes, bookedCount, pendingCount, conflictCount, errorCount, numberOfAccounts]);

  return { numberOfAccounts, treatment, collectiveAccountStatuses };
};

export default useDecisionInfo;
