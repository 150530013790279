export const splitChariotTextHtml = text => {
  const preHtmlText = text.split("<", 1);
  const html = text.substring(text.indexOf("<") - 1, text.lastIndexOf(">") + 1);
  const postHtmlText = text.substring(text.lastIndexOf(">") + 1);
  return [preHtmlText[0], html, postHtmlText];
};

export const splitChariotTextLink = text => {
  const [preLinkText, rest] = text.split("{{", 2);
  const [link, postLinkText] = rest.split("}}", 2);
  return [preLinkText, link, postLinkText];
};
export default splitChariotTextLink;
