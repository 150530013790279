import { useFormContext } from "react-hook-form";
import { Col, Row } from "../../shared/Layout";
import { BooleanRadioListField } from "../../shared/RadioList";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import { INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC } from "../../../constants/businessTaxClassifications";

const ExistingLLCTypeSelection = () => {
  const { control, setValue } = useFormContext();

  return (
    <Row>
      <Col lg={5} md={5} offset={{ lg: 2 }} sm={4}>
        <BooleanRadioListField
          control={control}
          name="isSingleMemberLLC"
          legendText="Are you a single member LLC?"
          onChange={value => {
            // resets the tax classification to clear old value when going to MMLLC
            // for SMLLC, set tax classification for sanity even though submit will already override this
            setValue(
              "taxClassification",
              value ? INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC : PLACEHOLDER_NONE
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default ExistingLLCTypeSelection;
