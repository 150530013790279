import { useEffect } from "react";
import { sendFormFieldInteraction, sendLinkClick, sendButtonClick } from "../sendAnalyticsEvent";
import useApplicationChannel from "./useApplicationChannel";

const eventTypeMapping = {
  "select-one": "dropdown",
  email: "text area",
  text: "text area",
  tel: "text input",
  checkbox: "check",
};

const allowedEventTypes = [
  "tooltip",
  "dropdown",
  "text area",
  "text input",
  "toggle",
  "check",
  "radio",
  "calendar",
];

const interactionEvent = (event, channel) => {
  if (event.target?.id && event.target?.type) {
    const mappedType = eventTypeMapping[event.target.type] || event.target.type;

    if (allowedEventTypes.includes(mappedType)) {
      sendFormFieldInteraction(event.type, event.target.id, mappedType, channel);
    }
  }
};

const clickInteractionEvent = (event, channel) => {
  if (event.target?.href && event.target?.tagName === "A") {
    sendLinkClick(event.type, event.target.href, event.target.tagName, channel);
  } else if (event.target?.tagName === "BUTTON") {
    sendButtonClick(event.type, event.target.id, channel);
  } else if (event.target.closest('[role="button"]')) {
    const cardTargetId = event.target.closest('[role="button"]').id;
    sendButtonClick(event.type, cardTargetId, channel);
  }
};

const useInteractionEvents = () => {
  const channel = useApplicationChannel();

  useEffect(() => {
    if (channel) {
      const interactionEventListener = event => interactionEvent(event, channel);
      const clickInteractionEventListener = event => clickInteractionEvent(event, channel);

      document.addEventListener("focus", interactionEventListener, true);
      document.addEventListener("click", clickInteractionEventListener, true);

      return () => {
        document.removeEventListener("focus", interactionEventListener, true);
        document.removeEventListener("click", clickInteractionEventListener, true);
      };
    }
    return () => {};
  }, [channel]);
};

export default useInteractionEvents;
