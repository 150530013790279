import { useCallback, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import shallow from "zustand/shallow";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import useStore from "../../../store/store";
import Heading from "../../shared/Heading";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import NavigationButtons from "../../shared/NavigationButtons";
import SectionHeader, { Divider } from "../../shared/SectionHeader";
import Page from "../../shared/Page";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import { useNavigateUnlessReview } from "../../../utils/reviewContext";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import {
  SECOND_PAGE_BUNDLE_TO_MAIN_QUESTION_MAPPING,
  ATM_OPERATIONS,
  GAMBLING_SERVICES,
  CURRENCY_TRANSPORTATION,
  MARIJUANA,
  JEWELRY,
  MISCELLANEOUS_ACTIVITIES,
  lookupBusinessServiceQuestions,
  NO_ACTIVITIES,
  FIFTYK_TO_HUNDREDK,
  OVER_HUNDREDK,
} from "../../../constants/riskyBusinessQuestionsValues";
import { useBackRoute, useNextRouteProvider } from "../../../utils/hooks/useRouting";
import RiskyBusinessQuestions from "./RiskyBusinessQuestions";
import { trackAdobeTargetEvent } from "../../../utils/adobeTargetHelpers";

// Second HRBR page

const riskyBusinessActivitiesQuestionsContent = state =>
  state.content
    .map(bundle => bundle?.riskyProhibitedActivitiesQuestions ?? null)
    .find(bundle => bundle !== null);

export const industryActivitiesHRBRSchema = yup.object({
  // ATM_OPERATIONS questions
  businessHasAtmOperations: yup.boolean().nullable().required("Select a response"),
  // GAMBLING_SERVICES questions
  businessHasGamblingServices: yup.boolean().nullable().required("Select a response"),
  businessHasGamblingActivities: yup
    .boolean()
    .nullable()
    .when("businessHasGamblingServices", {
      is: true,
      then: () => yup.boolean().nullable().nullable().required("Select a response"),
    }),
  businessHasGamblingMajorityRevenue: yup
    .boolean()
    .nullable()
    .when("businessHasGamblingActivities", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  // CURRENCY_TRANSPORTATION questions
  businessHasCurrencyTransportation: yup.boolean().nullable().required("Select a response"),
  // MARIJUANA questions
  businessHasMarijuana: yup.boolean().nullable().required("Select a response"),
  businessHasTierOneOrTwoMarijuana: yup
    .boolean()
    .nullable()
    .when("businessHasMarijuana", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  businessHasTierThreeMarijuana: yup
    .boolean()
    .nullable()
    .when("businessHasMarijuana", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  businessHasMarijuanaRevenueMajority: yup
    .boolean()
    .nullable()
    .when("businessHasTierThreeMarijuana", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  // JEWELRY questions
  businessHasJewelry: yup.boolean().nullable().required("Select a response"),
  businessHasAnnualJewelryPurchase: yup
    .string()
    .nullable()
    .when("businessHasJewelry", {
      is: true,
      then: () =>
        yup.string().nullable().required("Select a response").notOneOf([NO_ACTIVITIES], "Select a response"),
    }),
  businessHasAnnualJewelrySales: yup
    .string()
    .nullable()
    .when("businessHasJewelry", {
      is: true,
      then: () =>
        yup.string().nullable().required("Select a response").notOneOf([NO_ACTIVITIES], "Select a response"),
    }),
  businessHasJewelryNoAmlProgram: yup
    .boolean()
    .nullable()
    .when(["businessHasAnnualJewelrySales", "businessHasAnnualJewelryPurchase"], {
      is: (jewelrySales, jewelryPurchase) =>
        [FIFTYK_TO_HUNDREDK, OVER_HUNDREDK].includes(jewelrySales) &&
        [FIFTYK_TO_HUNDREDK, OVER_HUNDREDK].includes(jewelryPurchase),
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  businessHasJewelryForeignPurchase: yup
    .boolean()
    .nullable()
    .when("businessHasJewelry", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  businessHasJewelryDirectTransport: yup
    .boolean()
    .nullable()
    .when("businessHasJewelryForeignPurchase", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  // MISCELLANEOUS_ACTIVITIES questions
  businessHasMiscellaneousActivities: yup.boolean().nullable().required("Select a response"),
});

const RiskyBusinessIndustryActivities = () => {
  usePageTitle("Business Services");
  usePreventNavigation();
  const headingRef = useFocusHeading();

  const [
    riskyBusinessActivitiesQuestions,
    submitRiskyProhibitedBusinesses,
    storedBusinessHasAtmOperations,
    storedBusinessHasGamblingServices,
    storedBusinessHasGamblingActivities,
    storedBusinessHasGamblingMajorityRevenue,
    storedBusinessHasCurrencyTransportation,
    storedBusinessHasMarijuana,
    storedBusinessHasTierOneOrTwoMarijuana,
    storedBusinessHasTierThreeMarijuana,
    storedBusinessHasMarijuanaRevenueMajority,
    storedBusinessHasJewelry,
    storedBusinessHasAnnualJewelryPurchase,
    storedBusinessHasAnnualJewelrySales,
    storedBusinessHasJewelryNoAmlProgram,
    storedBusinessHasJewelryForeignPurchase,
    storedBusinessHasJewelryDirectTransport,
    storedBusinessHasMiscellaneousActivities,
    isUsingAdobeTarget,
  ] = useStore(
    state => [
      riskyBusinessActivitiesQuestionsContent(state),
      state.pageSubmit.submitRiskyProhibitedBusinesses,
      state.hrbrActivities.businessHasAtmOperations,
      state.hrbrActivities.businessHasGamblingServices,
      state.hrbrActivities.businessHasGamblingActivities,
      state.hrbrActivities.businessHasGamblingMajorityRevenue,
      state.hrbrActivities.businessHasCurrencyTransportation,
      state.hrbrActivities.businessHasMarijuana,
      state.hrbrActivities.businessHasTierOneOrTwoMarijuana,
      state.hrbrActivities.businessHasTierThreeMarijuana,
      state.hrbrActivities.businessHasMarijuanaRevenueMajority,
      state.hrbrActivities.businessHasJewelry,
      state.hrbrActivities.businessHasAnnualJewelryPurchase,
      state.hrbrActivities.businessHasAnnualJewelrySales,
      state.hrbrActivities.businessHasJewelryNoAmlProgram,
      state.hrbrActivities.businessHasJewelryForeignPurchase,
      state.hrbrActivities.businessHasJewelryDirectTransport,
      state.hrbrActivities.businessHasMiscellaneousActivities,
      state.isUsingAdobeTarget,
    ],
    shallow
  );

  const formMethods = useForm({
    resolver: yupResolver(industryActivitiesHRBRSchema),
    shouldFocusError: false,
    defaultValues: {
      businessHasAtmOperations: storedBusinessHasAtmOperations,
      businessHasGamblingServices: storedBusinessHasGamblingServices,
      businessHasGamblingActivities: storedBusinessHasGamblingActivities,
      businessHasGamblingMajorityRevenue: storedBusinessHasGamblingMajorityRevenue,
      businessHasCurrencyTransportation: storedBusinessHasCurrencyTransportation,
      businessHasMarijuana: storedBusinessHasMarijuana,
      businessHasTierOneOrTwoMarijuana: storedBusinessHasTierOneOrTwoMarijuana,
      businessHasTierThreeMarijuana: storedBusinessHasTierThreeMarijuana,
      businessHasMarijuanaRevenueMajority: storedBusinessHasMarijuanaRevenueMajority,
      businessHasJewelry: storedBusinessHasJewelry,
      businessHasAnnualJewelryPurchase: storedBusinessHasAnnualJewelryPurchase,
      businessHasAnnualJewelrySales: storedBusinessHasAnnualJewelrySales,
      businessHasJewelryNoAmlProgram: storedBusinessHasJewelryNoAmlProgram,
      businessHasJewelryForeignPurchase: storedBusinessHasJewelryForeignPurchase,
      businessHasJewelryDirectTransport: storedBusinessHasJewelryDirectTransport,
      businessHasMiscellaneousActivities: storedBusinessHasMiscellaneousActivities,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const navigate = useNavigateUnlessReview();
  const backRoute = useBackRoute();
  const nextRouteProvider = useNextRouteProvider();
  const submitHandler = useCallback(
    async ({
      businessHasAtmOperations,
      businessHasGamblingServices,
      businessHasGamblingActivities,
      businessHasGamblingMajorityRevenue,
      businessHasCurrencyTransportation,
      businessHasMarijuana,
      businessHasTierOneOrTwoMarijuana,
      businessHasTierThreeMarijuana,
      businessHasMarijuanaRevenueMajority,
      businessHasJewelry,
      businessHasAnnualJewelryPurchase,
      businessHasAnnualJewelrySales,
      businessHasJewelryNoAmlProgram,
      businessHasJewelryForeignPurchase,
      businessHasJewelryDirectTransport,
      businessHasMiscellaneousActivities,
    }) => {
      await submitRiskyProhibitedBusinesses({
        businessHasAtmOperations,
        businessHasGamblingServices,
        businessHasGamblingActivities,
        businessHasGamblingMajorityRevenue,
        businessHasCurrencyTransportation,
        businessHasMarijuana,
        businessHasTierOneOrTwoMarijuana,
        businessHasTierThreeMarijuana,
        businessHasMarijuanaRevenueMajority,
        businessHasJewelry,
        businessHasAnnualJewelryPurchase,
        businessHasAnnualJewelrySales,
        businessHasJewelryNoAmlProgram,
        businessHasJewelryForeignPurchase,
        businessHasJewelryDirectTransport,
        businessHasMiscellaneousActivities,
      });

      // AB TEST: If we are using Adobe Target, we want to track the secondary metrics for reporting
      if (isUsingAdobeTarget && !businessHasMiscellaneousActivities) {
        // track the completedBusiness event for Adobe Target
        trackAdobeTargetEvent({ successMetric: "completedBusiness" });
      }

      navigate(
        nextRouteProvider({
          businessHasMiscellaneousActivities,
        })
      );
    },
    [submitRiskyProhibitedBusinesses, navigate, nextRouteProvider, isUsingAdobeTarget]
  );
  const submitWithErrorHandling = useSubmitWithErrorHandling(submitHandler);

  const [mainQuestions, subQuestions] = useMemo(
    () =>
      lookupBusinessServiceQuestions({
        pageToMap: SECOND_PAGE_BUNDLE_TO_MAIN_QUESTION_MAPPING,
        content: riskyBusinessActivitiesQuestions,
      }),
    [riskyBusinessActivitiesQuestions]
  );

  if (!riskyBusinessActivitiesQuestions) {
    return <LoadingSpinnerPage />;
  }

  return (
    <Page>
      <Grid ref={headingRef}>
        <form onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
          <FormProvider {...formMethods}>
            <Heading
              step="SECTION 2 OF 4"
              mainHeading="Tell us about your business activities"
              subHeading="This is required to comply with regulatory requirements and may affect our ability to open or maintain your business accounts."
            />
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                <SectionHeader
                  id="riskyOrProhibitedBusinesses_section_header"
                  title="Industry-specific business activities"
                />
              </Col>
            </CenteredRow>
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                <RiskyBusinessQuestions
                  category={ATM_OPERATIONS}
                  label={mainQuestions[ATM_OPERATIONS]}
                  subQuestions={subQuestions[ATM_OPERATIONS]}
                />
                <Divider />
                <RiskyBusinessQuestions
                  category={GAMBLING_SERVICES}
                  label={mainQuestions[GAMBLING_SERVICES]}
                  subQuestions={subQuestions[GAMBLING_SERVICES]}
                />
                <Divider />
                <RiskyBusinessQuestions
                  category={CURRENCY_TRANSPORTATION}
                  label={mainQuestions[CURRENCY_TRANSPORTATION]}
                  subQuestions={subQuestions[CURRENCY_TRANSPORTATION]}
                />
                <Divider />
                <RiskyBusinessQuestions
                  category={MARIJUANA}
                  label={mainQuestions[MARIJUANA]}
                  subQuestions={subQuestions[MARIJUANA]}
                />
                <Divider />
                <RiskyBusinessQuestions
                  category={JEWELRY}
                  label={mainQuestions[JEWELRY]}
                  subQuestions={subQuestions[JEWELRY]}
                />
                <Divider />
                <RiskyBusinessQuestions
                  category={MISCELLANEOUS_ACTIVITIES}
                  label={mainQuestions[MISCELLANEOUS_ACTIVITIES]}
                />
              </Col>
            </CenteredRow>
            <NavigationButtons
              backRoute={backRoute}
              nextLoading={isSubmitting}
              pageNameOverrideForIds="riskyBusinessesIndustryActivities"
            />
          </FormProvider>
        </form>
      </Grid>
    </Page>
  );
};

export default RiskyBusinessIndustryActivities;
