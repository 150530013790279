import { forwardRef } from "react";
import combineStrings from "../../../utils/combineStrings";

const TextInput = forwardRef(
  (
    {
      id,
      label,
      helper,
      "aria-describedby": additionalDescriber,
      error,
      errorReplacesHelper = true,
      compact = false,
      labelProps = {},
      helperProps = {},
      errorProps = {},
      className = "",
      labelClassName = "",
      inputClassName = "",
      ...rest
    },
    ref
  ) => {
    const labelId = `${id}--label`;
    const helperId = `${id}--helper`;
    const errorId = `${id}--error`;
    const showHelper = helper && !(errorReplacesHelper && error);

    return (
      <div className={combineStrings("grv-form-field", className, { "grv-form-field--compact": compact })}>
        {label && (
          <label
            htmlFor={id}
            id={labelId}
            className={combineStrings("grv-label grv-label--textfield", labelClassName, {
              "grv-label--compact": compact,
            })}
            {...labelProps}
          >
            {label}
          </label>
        )}
        <input
          ref={ref}
          type="text"
          id={id}
          className={combineStrings("grv-textfield__input", inputClassName, {
            "grv-textfield__input--error": error,
          })}
          aria-labelledby={label ? labelId : ""}
          aria-describedby={combineStrings({
            [helperId]: showHelper,
            [errorId]: error,
            [additionalDescriber]: additionalDescriber,
          })}
          aria-invalid={error ? "true" : "false"}
          {...rest}
          onInput={event => {
            // sanitize input value
            const { target } = event;
            target.value = target.value.replace(/[\u2019]/g, "'"); // smart right single quote
            // Other sanitization are left here as reference for a likely future enhancement
            // .replace(/[\u2014]/g, "--") // emdash
            // .replace(/[\u2022]/g, "*") // bullet
            // .replace(/[\u2018\u2019]/g, "'") // smart single quotes
            // .replace(/[\u201C\u201D]/g, '"'); // smart double quotes

            // call the passed onInput afterwards
            rest.onInput?.(event);
          }}
        />
        {showHelper && (
          <span id={helperId} className="grv-form-field__helper" {...helperProps}>
            {helper}
          </span>
        )}
        {error && (
          <span
            id={errorId}
            role="alert"
            className="grv-form-field__helper grv-form-field__helper--error"
            {...errorProps}
          >
            {error}
          </span>
        )}
      </div>
    );
  }
);

export default TextInput;
