import * as yup from "yup";
import { useFormContext } from "react-hook-form";
import shallow from "zustand/shallow";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import { countryTypes } from "../../../constants/countryTypes";
import { Col, Grid, Row } from "../Layout";
import CountrySelect from "../CountrySelect";
import useStore from "../../../store/store";
import { BooleanRadioListField } from "../RadioList";

// Unconditional validation of the outsideUSInfo object, exported primarily for testing.
export const outsideUSSchema = yup.object().shape({
  countryOfHeadquarters: yup
    .string()
    .required("Select a valid country")
    .notOneOf([PLACEHOLDER_NONE], "Select a valid country")
    .test("countryOfBusinessHeadquarters", "Select a valid country", value =>
      countryTypes.some(type => type.value === value)
    ),
  countryOfRegistration: yup
    .string()
    .required("Select a valid country")
    .notOneOf([PLACEHOLDER_NONE], "Select a valid country")
    .test("countryOfRegOrIncorporation", "Select a valid country", value =>
      countryTypes.some(type => type.value === value)
    ),
  countryOfPrimaryOperations: yup
    .string()
    .required("Select a valid country")
    .notOneOf([PLACEHOLDER_NONE], "Select a valid country")
    .test("countryOfPrimaryOperations", "Select a valid country", value =>
      countryTypes.some(type => type.value === value)
    ),
});

// Schema containing isBusinessHeadquarteredOutsideUS as well as the outsideUSInfo,
// which will be conditionally validated against the above schema when
// isBusinessHeadquarteredOutsideUS is true. This should be spread into the form schema definition.
export const conditionalBusinessOutsideUSSchema = {
  isBusinessHeadquarteredOutsideUS: yup.bool(),
  outsideUSInfo: yup.object().when("isBusinessHeadquarteredOutsideUS", {
    is: true,
    then: schema => schema.concat(outsideUSSchema),
    otherwise: schema => schema,
  }),
};

// Generate default values for the foreign business fields by pulling content from the store.
// The resulting object can be spread into the defaultValues passed to useForm.
export const useDefaultOutsideUSInfo = () => {
  const [
    storedIsHeadquarteredOutsideUS,
    storedHeadquarterCountry,
    storedCountryOfRegistration,
    storedCountryOfPrimaryOperations,
  ] = useStore(
    state => [
      state.isBusinessHeadquarteredOutsideUS,
      state.businessHeadquarterCountry,
      state.businessCountryOfRegistrationOrIncorporation,
      state.businessCountryOfPrimaryOperations,
    ],
    shallow
  );

  return {
    isBusinessHeadquarteredOutsideUS: storedIsHeadquarteredOutsideUS || false,
    outsideUSInfo: {
      countryOfHeadquarters: storedHeadquarterCountry || PLACEHOLDER_NONE,
      countryOfRegistration: storedCountryOfRegistration || PLACEHOLDER_NONE,
      countryOfPrimaryOperations: storedCountryOfPrimaryOperations || PLACEHOLDER_NONE,
    },
  };
};

// Section containing fields for collection the details of a business operating, headquartered,
// or registered outside the US, which expand conditionally based on an initial radio button.
// All form data is stored under the following fields
// isBusinessHeadquarteredOutsideUS - true if the business is a foreign business, false otherwise
// outsideUSInfo.countryOfHeadquarters - country code of headquarters, US if isBusinessHeadquarteredOutsideUS is false
// outsideUSInfo.countryOfRegistration - country code of registration, US if isBusinessHeadquarteredOutsideUS is false
// outsideUSInfo.countryOfPrimaryOperations - country code of operations, US if isBusinessHeadquarteredOutsideUS is false
const BusinessOutsideUSSection = () => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const showCountrySelects = watch("isBusinessHeadquarteredOutsideUS");

  return (
    <Grid>
      <Row>
        <Col lg={8} md={5} offset={{ lg: 2 }} sm={4}>
          <BooleanRadioListField
            control={control}
            name="isBusinessHeadquarteredOutsideUS"
            legendText="Is your business headquartered, registered or primarily operating outside the U.S.?"
          />
        </Col>
      </Row>
      {showCountrySelects && (
        <>
          <Row>
            <Col lg={6} md={6} offset={{ lg: 2 }} sm={4}>
              <CountrySelect
                label="Country of Business Headquarters"
                error={errors?.outsideUSInfo?.countryOfHeadquarters?.message}
                {...register("outsideUSInfo.countryOfHeadquarters")}
                placeholder="Select a country"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} offset={{ lg: 2 }} sm={4}>
              <CountrySelect
                label="Country of Registration or Incorporation"
                error={errors?.outsideUSInfo?.countryOfRegistration?.message}
                {...register("outsideUSInfo.countryOfRegistration")}
                placeholder="Select a country"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} offset={{ lg: 2 }} sm={4}>
              <CountrySelect
                label="Country of Primary Operation"
                error={errors?.outsideUSInfo?.countryOfPrimaryOperations?.message}
                {...register("outsideUSInfo.countryOfPrimaryOperations")}
                placeholder="Select a country"
              />
            </Col>
          </Row>
        </>
      )}
    </Grid>
  );
};

export default BusinessOutsideUSSection;
