import styled from "styled-components";
import { ReactComponent as InfoLined } from "@c1/gravity-icons/dist/svg/ui-lined-information-1-24.svg";
import useStore from "../../../store/store";
import MaskedValue, { ShowIconLined, HideIconLined } from "../MaskedValue";

const FieldButton = styled.button`
  border: none;
  background: transparent;
  user-select: none;
  cursor: pointer;
`;

const InfoIcon = styled(InfoLined).attrs({
  alt: "Info",
})`
  fill: var(--grv-color-icon-interaction-blue-50);
  padding: 0;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-bottom: 2px;
`;

// Slight variation on MaskedValue
const InfoValue = ({ id, value, showAdditionalInfo, infoButtonAriaLabel = "open info modal" }) => (
  <>
    <span id={`${id}-field`}>{value}</span>
    <FieldButton
      id={`${id}-button`}
      aria-label={infoButtonAriaLabel}
      onClick={showAdditionalInfo}
      aria-pressed={showAdditionalInfo}
    >
      <InfoIcon />
    </FieldButton>
  </>
);

export const PrefillSummaryBoxContainer = styled.div`
  border: 1px solid var(--grv-color-digital-gray-60);
  border-radius: var(--grv-size-border-radius-4);
  padding: var(--grv-size-spacing-medium-1) var(--grv-size-spacing-medium-2);
`;

const PrefillItem = styled.div`
  display: flex;
  margin-top: var(--grv-size-spacing-medium-1);
  margin-bottom: var(--grv-size-spacing-medium-1);
  &:last-child {
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: 0;
  }
`;

const PrefillItemLabel = styled.span`
  flex: 1 1 50%;
  text-align: left;
  margin-right: var(--grv-size-spacing-large-1);
  font-size: var(--grv-size-font-small-2);
`;

const PrefillItemContent = styled.span`
  flex: 1 1 50%;
  text-align: right;
  font-size: var(--grv-size-font-small-2);
  word-wrap: break-word;
  min-width: 0;
`;

export const StaticPrefillLineItem = ({
  idBase,
  labelText,
  content,
  mask = false,
  maskButtonAriaLabel = null,
  showAdditionalInfo = false,
  infoButtonAriaLabel = null,
}) => (
  <PrefillItem>
    <PrefillItemLabel id={`${idBase}--label`}>
      {showAdditionalInfo ? (
        <InfoValue
          id={`${idBase}--info`}
          value={labelText}
          showAdditionalInfo={showAdditionalInfo}
          infoButtonAriaLabel={infoButtonAriaLabel || undefined}
        />
      ) : (
        labelText
      )}
    </PrefillItemLabel>
    <PrefillItemContent id={`${idBase}--content`}>
      {mask ? (
        <MaskedValue
          id={`${idBase}-mask`}
          value={content}
          mask={mask}
          maskButtonAriaLabel={maskButtonAriaLabel || undefined}
          buttonClassName="grv-padding__right--none"
          showIcon={<ShowIconLined alt="Show" $size="16px" />}
          hideIcon={<HideIconLined alt="Hide" $size="16px" />}
        />
      ) : (
        content
      )}
    </PrefillItemContent>
  </PrefillItem>
);

// More complex wrapper around PrefillLineItem that will automatically pull from the store
const PrefillLineItem = ({
  idBase,
  labelText,
  selector,
  mask = false,
  maskButtonAriaLabel = null,
  showAdditionalInfo = false,
  infoButtonAriaLabel = false,
}) => {
  const value = useStore(state => selector(state));

  return (
    <StaticPrefillLineItem
      idBase={idBase}
      labelText={labelText}
      mask={mask}
      maskButtonAriaLabel={maskButtonAriaLabel}
      content={value}
      showAdditionalInfo={showAdditionalInfo}
      infoButtonAriaLabel={infoButtonAriaLabel}
    />
  );
};

export default PrefillLineItem;
