import { useEffect, useRef } from "react";

const useScrollToComponent = () => {
  const component = useRef();

  useEffect(() => {
    if (component.current) {
      const element = component.current;
      const elementPosition = element.offsetTop;
      const offset = 88; // Banner height + 16px
      const newPosition = elementPosition - offset;
      window.scrollTo({ top: newPosition, behavior: "smooth" });
    }
  }, []);

  return component;
};

export default useScrollToComponent;
