// Track anything you want as an event to be sent off to new relic
// Docs: https://docs.newrelic.com/docs/data-apis/custom-data/custom-events/report-browser-monitoring-custom-events-attributes/
export const sendPageActionToNewRelic = (eventName, attributes = {}) => {
  window?.newrelic?.addPageAction?.(eventName, attributes);
};

// Use this function to add an attribute to all future events/errors for this application
// For example, correlationId, channel, etc.
export const setCustomAttributeForNewRelicData = (name, value) => {
  window?.newrelic?.setCustomAttribute?.(name, value);
};

export class NewRelicCustomError extends Error {
  constructor(params) {
    super(params);
    this.name = this.constructor.name;
  }
}

// Use this function to send a caught error to new relic,
// which can then be monitored on the browser error page:
// https://one.newrelic.com/nr1-core/errors-inbox/entity-inbox/MTExNjExM3xCUk9XU0VSfEFQUExJQ0FUSU9OfDE1ODg3MDMwMTQ?duration=1800000&filters=%28name%20LIKE%20%27sbao-web%27%20OR%20id%20%3D%20%27sbao-web%27%20OR%20domainId%20%3D%20%27sbao-web%27%29&state=71f1a5bb-55c1-d8da-8270-76fdb18d476f
// Reference docs: https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/noticeerror/
// More docs: https://docs.newrelic.com/docs/errors-inbox/browser-tab/
export const sendErrorToNewRelic = (customErrorMessage, error = null, attributes = {}) => {
  const errorMessage = error ? `${customErrorMessage}: ${error.name}: ${error.message}` : customErrorMessage;
  window?.newrelic?.noticeError?.(new NewRelicCustomError(errorMessage), attributes);
};
