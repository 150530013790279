import {
  SOLE_PROPRIETORSHIP,
  LIMITED_LIABILITY_COMPANY,
  PARTNERSHIP,
  CORPORATION,
  OTHER,
  convertLegalEntityEnumToDisplay,
} from "../../../constants/legalEntityTypes";
import { INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC } from "../../../constants/businessTaxClassifications";
import { buildIdFromComponents } from "../../../utils/hooks/usePageScopedId";

const createCompanyTypeRadios = (areStateDocsFiled, hasMultipleBusinessOwners, idBase, isKnownNonProfit) => {
  // Summary of filtered options:
  // (logic sourced from https://docs.google.com/spreadsheets/d/1kvHKG2rvxGkFU7Ur4Mr5tHhpbiM-WjytQ0zL2rgCLFc/edit?usp=sharing)
  // NP: Non Profit; P: For Profit
  // areStateDocsFiled  | hasMultipleBusinessOwners | eligibleCompanyTypes
  // ------------------ | ------------------------- | ---------------------
  // false              | false                     | NP: Display nothing (will navigate to /cannot-proceed on next)
  //                    |                           | P: Sole Proprietorship, Something Else
  // true               | false                     | NP: Corporation, Something Else
  //                    |                           | P: Single Member LLC, Corporation, Something Else
  // false              | true                      | NP/P: Partnership, Something Else
  // true               | true                      | NP/P: Multi Member LLC, Partnership, Corporation, Something Else
  const filteredCompanyTypes = [
    // NP cannot be Sole Proprietorship, so exclude this option
    ...(!isKnownNonProfit && areStateDocsFiled === false && hasMultipleBusinessOwners === false
      ? [
          {
            id: buildIdFromComponents(idBase, "SoleProprietorship"),
            label: convertLegalEntityEnumToDisplay(SOLE_PROPRIETORSHIP),
            value: SOLE_PROPRIETORSHIP,
          },
        ]
      : []),
    // NP cannot be Single Member LLC, so exclude this option
    ...(!isKnownNonProfit && areStateDocsFiled === true && hasMultipleBusinessOwners === false
      ? [
          {
            id: buildIdFromComponents(idBase, "SingleMemberLimitedLiabilityCompany"),
            label: "Single Member LLC",
            value: INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC,
          },
        ]
      : []),
    ...(areStateDocsFiled === true && hasMultipleBusinessOwners === true
      ? [
          {
            id: buildIdFromComponents(idBase, "LimitedLiabilityCompany"),
            label: "Multi Member LLC",
            value: LIMITED_LIABILITY_COMPANY,
          },
        ]
      : []),
    ...(areStateDocsFiled !== null && hasMultipleBusinessOwners === true
      ? [
          {
            id: buildIdFromComponents(idBase, "Partnership"),
            label: convertLegalEntityEnumToDisplay(PARTNERSHIP),
            value: PARTNERSHIP,
          },
        ]
      : []),
    ...(areStateDocsFiled === true && hasMultipleBusinessOwners !== null
      ? [
          {
            id: buildIdFromComponents(idBase, "Corporation"),
            label: convertLegalEntityEnumToDisplay(CORPORATION),
            value: CORPORATION,
          },
        ]
      : []),
    {
      id: buildIdFromComponents(idBase, "SomethingElse"),
      label: convertLegalEntityEnumToDisplay(OTHER),
      value: OTHER,
    },
  ];

  return filteredCompanyTypes;
};

export default createCompanyTypeRadios;
