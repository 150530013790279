import styled from "styled-components";
import shallow from "zustand/shallow";
import useStore from "../../../store/store";

const selectCIPDisclaimer = state =>
  state.content
    .map(bundle => bundle?.uiDisclosures?.find(disc => disc?.disclosureId === "cipDisclosure") ?? null)
    .find(bundle => bundle !== null) ?? {};

const CipTitle = styled.div.attrs({
  id: "gettingStarted_cipDisclaimerHeader_Text",
  className: "grv-text__heading grv-text__heading--tiny",
})`
  margin-top: 48px;
  margin-bottom: 10px;
`;

const CIPDisclaimer = () => {
  const { headerText, bodyText } = useStore(state => selectCIPDisclaimer(state), shallow);

  return (
    <>
      <CipTitle dangerouslySetInnerHTML={{ __html: headerText }} />
      <span
        className="grv-text grv-text--tiny"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: bodyText }}
        id="gettingStarted_cipDisclaimerBody_Text"
      />
    </>
  );
};

export default CIPDisclaimer;
