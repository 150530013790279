import { SBB_BRANCH, SBB_CAFE, SBB_WEB } from "../constants/applicationChannelTypes";

// Be careful calling this function! You almost certainly want the useApplicationChannel hook
// under utils instead - this function should be used when the store is being accessed "immediately",
// without a need for stateful updates to the component, whereas the hook will automatically
// respond to changes in the application state.
// To avoid a dependency cycle, this logic has to live here so the store actions can also make use of the
// application channel.
const getApplicationChannelFromState = ({
  hasProcessedQueryParams,
  hasProcessedLoginCookie,
  queryParams,
  cookieParams,
}) => {
  if (!hasProcessedQueryParams || !hasProcessedLoginCookie) {
    return null;
  }

  // cookie param for application channel takes precedence overy query param
  if ([SBB_WEB, SBB_BRANCH, SBB_CAFE].includes(cookieParams.applicationChannel)) {
    return cookieParams.applicationChannel;
  }

  if ([SBB_BRANCH, SBB_CAFE].includes(queryParams.applicationChannel)) {
    return queryParams.applicationChannel;
  }

  // default case
  return SBB_WEB;
};

export default getApplicationChannelFromState;
