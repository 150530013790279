import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";
import DisabledField from "../../shared/DisabledField";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import useStore from "../../../store/store";
import { CenteredRow, Col, Grid, Row } from "../../shared/Layout";
import NavigationButtons from "../../shared/NavigationButtons";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import useRouting from "../../../utils/hooks/useRouting";
import BusinessControllerTitleSelect, {
  businessControllerTitleSchema,
} from "../../shared/BusinessControllerTitleSelect";

const ControllerAttestationRow = styled(CenteredRow)`
  margin-top: var(--grv-size-spacing-medium-2);
  margin-bottom: var(--grv-size-spacing-large-3);
`;

const getBCAttestation = content =>
  content
    .map(bundle => bundle?.uiDisclosures?.find(disc => disc?.disclosureId === "BCAttestation") ?? null)
    .find(bundle => bundle !== null)?.bodyText;

const ownershipSchema = yup.object().shape({
  businessControllerType: businessControllerTitleSchema,
});

const SingleMemberLLCBusinessOwnershipForm = () => {
  const navigate = useNavigate();
  const [storedControllerType, submitPage, bcAttestation] = useStore(
    state => [
      state.businessControllerType,
      state.pageSubmit.submitBusinessOwnershipInfo,
      getBCAttestation(state.content),
    ],
    shallow
  );
  const {
    register,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(ownershipSchema),
    shouldFocusError: false,
    defaultValues: {
      businessControllerType: storedControllerType ?? PLACEHOLDER_NONE,
    },
  });

  const businessControllerType = watch("businessControllerType");
  const showOwnershipPercentage = businessControllerType !== "NONE";

  const [backRoute, nextRoute] = useRouting();
  const onSubmit = useCallback(
    async data => {
      await submitPage({
        isApplicantBusinessOwner: true,
        ownershipPercentage: 100,
        businessControllerType: data.businessControllerType,
        hasOtherOwners: false,
        hasNoBeneficialOwnersAttestation: false,
      });
      navigate(nextRoute);
    },
    [submitPage, navigate, nextRoute]
  );
  const submitWithErrorHandling = useSubmitWithErrorHandling(onSubmit);

  if (!bcAttestation) {
    return <LoadingSpinnerPage />;
  }

  return (
    <form onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
      <Grid>
        <Row>
          <Col lg={3} md={3} offset={{ lg: 2 }} sm={4}>
            <BusinessControllerTitleSelect
              error={errors?.businessControllerType?.message}
              {...register("businessControllerType")}
            />
          </Col>
        </Row>
        {showOwnershipPercentage && (
          <Row>
            <Col lg={3} md={3} offset={{ lg: 2 }} sm={4}>
              <DisabledField label="Percentage of ownership" value="100%" />
            </Col>
          </Row>
        )}
      </Grid>
      <Grid>
        <ControllerAttestationRow justify="center">
          <Col lg={8} md={8} sm={4}>
            <span
              className="grv-text grv-text--small"
              id="businessOwnershipInfoSection_bcAttestation_Text"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: bcAttestation }}
            />
          </Col>
        </ControllerAttestationRow>
      </Grid>
      <NavigationButtons backRoute={backRoute} nextLoading={isSubmitting} />
    </form>
  );
};

export default SingleMemberLLCBusinessOwnershipForm;
