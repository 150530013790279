import styled from "styled-components";
import ExplanationModal, { ModalSubheading, ModalText } from "../../shared/ExplanationModal";

const RowSubheading = styled.h2.attrs({
  className:
    "grv-row grv-row__margin--xs--small grv-row__margin--sm--small grv-row__margin--md--small grv-row__margin--ml--small grv-row__margin--lg--small grv-row__margin--xl--small grv-row__margin--ds--small grv-row--odd",
})`
  margin-top: 16px;
  margin-bottom: 32px;
`;

const BackupWithholdingModal = ({ active, onOk }) => (
  <ExplanationModal
    {...{ active, onOk }}
    idBase="backupWithholding"
    headingText="Backup Withholding"
    className="grv-dialog--large"
    buttonClassName="grv-button--progressive"
    shouldCloseOnEsc
    shouldCloseOnOverlayClick
  >
    <RowSubheading>What is backup withholding?</RowSubheading>
    <ModalText>
      Sometimes we have to withhold and send 24% (rate subject to change) of the interest we pay to you, to
      the IRS instead. This is called backup withholding.
    </ModalText>
    <ModalText>
      You may be subject to - or become subject to - backup withholding: If you do not provide us with a
      correct taxpayer identification number (TIN), your Social Security Number (SSN) or Employment
      Identification Number (SSN), and the IRS later notifies us that the TIN is incorrect.
    </ModalText>
    <ModalText>
      If you fail to report or you under report interest and dividends on your tax return and the IRS later
      notifies us to start withholding on interest or dividends. The IRS will first send you several notices.
    </ModalText>
    <ModalText>
      You certify that you&apos;re subject to backup withholding for under reporting of interest and dividends
      on the W-9 substitute form that we will present to you later by selecting “Yes”.
    </ModalText>
    <ModalSubheading className="grv-margin__top--small-2">Does it apply to me?</ModalSubheading>
    <ModalText>
      This applies if the IRS has sent you up to 4 letters/notices informing you that you are subject to
      backup withholding on interest and dividends, because you&apos;ve under reported interest or dividends
      on your tax return. If this applies, select “Yes” below. If not, select “No”.
    </ModalText>
    <ModalText>
      Please note that if you were previously notified by the IRS that you are subject to backup withholding,
      and the IRS later wrote to you that you weren&apos;t, you are no longer subject to backup withholding
      and can select “No”.
    </ModalText>
  </ExplanationModal>
);

export default BackupWithholdingModal;
