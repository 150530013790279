import { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Checkmark } from "@c1/gravity-icons/dist/svg/ui-lined-checkmark-1-24.svg";
import { sendPageActionToNewRelic } from "../../../../utils/newRelic";
import ConfettiBanner from "../../../shared/ConfettiBanner";
import mediaQuery from "../../../../utils/mediaQuery";

const DesktopHeaderGraphic = styled.div`
  display: none;
  @media (${mediaQuery.medium}) {
    display: flex;
  }
  flex-direction: column;
  align-items: center;
`;

const MobileHeaderGraphic = styled.div`
  display: flex;
  @media (${mediaQuery.medium}) {
    display: none;
  }
  flex-direction: column;
  align-items: center;

  width: 100vw;
  height: 150px;
  overflow: hidden;
  margin-bottom: var(--grv-size-spacing-medium-2);
`;

const BlueRectangleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--grv-color-background-core-blue-50);
  width: 100%;
`;

const CheckCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  aspect-ratio: 1;
  border-radius: 50%;
  z-index: var(--grv-z-index-default);

  position: absolute;
  margin-top: 0;
  border: 6px solid transparent;
  /* on mobile, fake a seamless gradient between the circle and the arc using this linear gradient with approximate, not gravity-specific colors */
  background: linear-gradient(white, white) padding-box, linear-gradient(#01689b 40%, #005782) border-box;
  @media (${mediaQuery.medium}) {
    position: relative;
    margin-top: -50px;
    /* solid border for non-mobile view */
    border: var(--grv-size-border-width-4) solid var(--grv-color-core-blue-50);
    background: var(--grv-color-background-white);
  }
`;

const CheckmarkIcon = styled(Checkmark)`
  width: 70%;
  stroke-width: 1.2;

  /* not gravity-specific color */
  stroke: #026697;
  @media (${mediaQuery.medium}) {
    stroke: var(--grv-color-core-blue-50);
  }
`;

const Arc = styled.div`
  /* shift circle upwards to create desired arc effect */
  transform: translateY(-88%);
  padding-bottom: 1000px;

  /* width of circle should exceed viewport width */
  width: 250vw;

  border-radius: 50%;
  border: 100px solid transparent;
  /* radial gradient with parameters that generally match provided design (not gravity-specific colors) */
  background: linear-gradient(white, white) padding-box,
    radial-gradient(closest-side, #028ed4 60%, #013d5b 110%) border-box;
`;

const HeaderGraphic = () => {
  const [animationLoopCount, setAnimationLoopCount] = useState(1);
  const [isAbleToLoop, setIsAbleToLoop] = useState(true);
  const increment = () => {
    // We use this loop count as a key for confetti banner, so changing the count causes it to rerender
    // and thus replay the animation
    setAnimationLoopCount(animationLoopCount + 1);
    sendPageActionToNewRelic("confetti-replay-button-clicked");
    setIsAbleToLoop(false);

    // A confetti piece animation has a max delay of 2.5 seconds and a max length of 4 seconds,
    // so maximum animation length of the confetti banner as a whole is 2.5 + 4 = 6.5 seconds.
    // Because of this, we use a timeout to prevent the animation from being replayed until
    // this duration has elapsed.
    // ignore for testing, since we are mocking ConfettiBanner anyways
    // istanbul ignore next
    setTimeout(() => {
      setIsAbleToLoop(true);
    }, 7000);
  };

  return (
    <>
      {/* displays only when at or above medium viewport min width */}
      <DesktopHeaderGraphic>
        <ConfettiBanner
          numPieces={300}
          key={animationLoopCount}
          id={`confettiBanner_${animationLoopCount}`}
        />
        <BlueRectangleWrapper>
          <CheckCircle id="confetti_Controller" onClick={() => isAbleToLoop && increment()}>
            <CheckmarkIcon alt="checkmark" />
          </CheckCircle>
        </BlueRectangleWrapper>
      </DesktopHeaderGraphic>
      {/* displays only when below medium viewport min width */}
      <MobileHeaderGraphic>
        <CheckCircle>
          <CheckmarkIcon alt="checkmark" />
        </CheckCircle>
        <Arc />
      </MobileHeaderGraphic>
    </>
  );
};

export default HeaderGraphic;
