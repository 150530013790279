// Combine a list of refs into a singular (functional) ref.
// This supports both useRef output as well as functional refs.
//
// For example, consider this component:
// const MyComponent = forwardRef((props, ref) => {
//    const ref1 = inst => console.log(inst);
//    const ref2 = useRef();
//    const combined = combineRefs(ref, ref1, ref2)
//    return <div ref={combined}>Example</div>
// }
// When this component mounts:
// - The externally forwarded ref will be properly connected to the div
// - ref1 will be called with the div element, logging it to console
// - ref2.current will be set to the div element
const combineRefs =
  (...refs) =>
  instance =>
    refs
      .filter(ref => !!ref)
      .forEach(ref => {
        if (typeof ref === "function") {
          ref(instance);
        } else {
          ref.current = instance; // eslint-disable-line no-param-reassign
        }
      });

export default combineRefs;
