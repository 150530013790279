const getPotomacIds = cookies => {
  const startIndex = cookies.indexOf("_sp_id.");
  if (startIndex < 0) {
    // malformed or missing => return null for both
    return { clickstreamDomainUserId: null, clickstreamDomainSessionId: null };
  }
  const otherCookies = cookies.slice(startIndex);
  const endIndex = otherCookies.indexOf(";");
  const fullCookie = endIndex < 0 ? otherCookies : otherCookies.slice(0, endIndex);
  // grab just the first and last part of the value
  // cookie format is _sp_id.HASH=user_id.timestamp.visits.timestamp.timestamp.session_id
  const [clickstreamDomainUserId, , , , , clickstreamDomainSessionId] = fullCookie
    .split(/=/, 2)?.[1]
    ?.split(".");
  return { clickstreamDomainUserId, clickstreamDomainSessionId };
};

export default getPotomacIds;
