import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";
import shallow from "zustand/shallow";
import SectionHeader from "../../shared/SectionHeader";
import TextInput from "../../shared/TextInput";
import { Row, Col, CenteredRow } from "../../shared/Layout";
import useStore from "../../../store/store";
import DisabledField from "../../shared/DisabledField";
import SecretTextInput from "../../shared/SecretTextInput";
import { useIsReviewPage } from "../../../utils/reviewContext";
import { useInputId } from "../../../utils/hooks/usePageScopedId";

const PersonalInfoSection = () => {
  const {
    register,
    control,
    trigger,
    formState: { isSubmitted, errors },
  } = useFormContext();

  const [storedApplicantTaxId, storedBirthDate] = useStore(
    state => [state.applicantTaxId, state.applicantBirthDate],
    shallow
  );
  const isReviewPage = useIsReviewPage();
  const enableSSNInput = !isReviewPage;
  const enableDOBInput = !isReviewPage;

  const dateOfBirthFieldId = useInputId("applicantBirthDate");
  const taxIdFieldId = useInputId("applicantTaxId");

  return (
    <CenteredRow>
      <Col lg={8} md={8} sm={4}>
        <SectionHeader title="Personal information" />
        <Row>
          <Col lg={6} md={3} sm={4}>
            <TextInput
              id={useInputId("applicantFirstName")}
              label="First Name"
              helper="This is your legal first name found on your government-issued ID"
              error={errors?.applicantFirstName?.message}
              {...register("applicantFirstName", {
                onChange: () => {
                  // manually re-trigger validation on last name due to shared name length validation
                  if (isSubmitted) {
                    trigger(`applicantLastName`);
                  }
                },
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={3} sm={4}>
            <TextInput
              id={useInputId("applicantLastName")}
              label="Last Name"
              helper="This is your legal last name found on your government-issued ID"
              error={errors?.applicantLastName?.message}
              {...register("applicantLastName", {
                onChange: () => {
                  // manually re-trigger validation on first name due to shared name length validation
                  if (isSubmitted) {
                    trigger(`applicantFirstName`);
                  }
                },
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={2} sm={3}>
            {enableDOBInput ? (
              <Controller
                render={({ field: { onChange, ref, ...rest } }) => (
                  <NumberFormat
                    customInput={TextInput}
                    id={dateOfBirthFieldId}
                    getInputRef={ref}
                    label="Date of Birth (MM/DD/YYYY)"
                    placeholder="__/__/____"
                    format="##/##/####"
                    mask="_"
                    error={errors?.applicantBirthDate?.message}
                    onValueChange={v => onChange(v.formattedValue)}
                    {...rest}
                  />
                )}
                name="applicantBirthDate"
                control={control}
              />
            ) : (
              <DisabledField label="Date of Birth (MM/DD/YYYY)" value={storedBirthDate} />
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={2} sm={3}>
            {enableSSNInput ? (
              <Controller
                render={({ field: { onChange, ref, ...rest } }) => (
                  <NumberFormat
                    customInput={SecretTextInput}
                    id={taxIdFieldId}
                    getInputRef={ref}
                    label="Social Security Number"
                    helper="Enter the number on your previous year's tax return or letter from the Internal Revenue Service"
                    autoComplete="off"
                    placeholder="___-__-____"
                    isNumericString
                    format="###-##-####"
                    mask="_"
                    secretMask="•••-••-"
                    hideIf={v => v.replaceAll("_", "").replaceAll("-", "").length >= 9}
                    error={errors?.applicantTaxId?.message}
                    onValueChange={v => onChange(v.value)}
                    {...rest}
                  />
                )}
                name="applicantTaxId"
                control={control}
              />
            ) : (
              <DisabledField
                helper="Enter the number on your previous year's tax return or letter from the Internal Revenue Service"
                label="Social Security Number"
                value={`•••-••-${storedApplicantTaxId.slice(5)}`}
              />
            )}
          </Col>
        </Row>
      </Col>
    </CenteredRow>
  );
};

export default PersonalInfoSection;
