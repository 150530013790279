import React from "react";
import styled from "styled-components";

// mimics a disabled input from Gravity
const DisabledFieldInput = styled.span.attrs({
  className: "grv-text grv-text--normal grv-background--digital-gray-5 grv-color--digital-gray-60",
})`
  border-radius: var(--grv-size-border-radius-4);
  display: flex;
  align-items: center;
  padding-inline: var(--grv-size-spacing-medium-1);
  min-height: 48px;
  border: 1px solid var(--grv-color-digital-gray-15);
  cursor: not-allowed;
`;

const DisabledField = ({ label, value, helper }) => (
  <div className="grv-margin__bottom--medium-2">
    {/* mimics the field label from Gravity, but with digital gray text */}
    <div className="grv-text grv-text--small grv-color--digital-gray-60 grv-margin__bottom--small-2">
      {label}
    </div>
    <DisabledFieldInput>{value}</DisabledFieldInput>
    {helper && (
      // mimics the helper text label from Gravity
      <div className="grv-text grv-text--tiny grv-color--digital-gray-60 grv-margin__top--small-2">
        {helper}
      </div>
    )}
  </div>
);

export default DisabledField;
