import { useEffect } from "react";
import settingsImage from "../../../assets/settings.svg";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import getContentReader from "../../../utils/getContentReader";
import { SBB_BRANCH, SBB_CAFE, SBB_WEB } from "../../../constants/applicationChannelTypes";
import useApplicationChannel from "../../../utils/hooks/useApplicationChannel";
import useStore from "../../../store/store";
import TerminalPageTemplate from "../../shared/TerminalPageTemplate";
import { addNonCriticalEventToApplication } from "../../../api/sbaoAppEndpoints";
import defaultRestartableErrorPageContent from "../../../constants/defaultRestartableErrorPageContent";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";

const RestartableError = () => {
  usePageTitle("Restart Application");
  const applicationChannel = useApplicationChannel();
  let bundleName;
  let contentId;
  let enableButton;
  switch (applicationChannel) {
    case SBB_BRANCH:
      bundleName = "branchPreSubmitFriction";
      contentId = "restartableBranchErrors";
      enableButton = false;
      break;
    case SBB_CAFE: // fall-through
    case SBB_WEB: // fall-through
    default:
      bundleName = "preSubmitFriction";
      contentId = "restartableWebErrors";
      enableButton = true;
  }
  const content = usePreSubmitTreatment(bundleName, contentId);
  const applicationReferenceId = useStore(state => state.applicationReferenceId);

  useEffect(() => {
    if (applicationReferenceId) {
      // no need to await - this can happen asynchronously
      addNonCriticalEventToApplication({ applicationReferenceId, event: "restartableErrorPageViewed" });
    }
  }, [applicationReferenceId]);

  return (
    <TerminalPageTemplate
      enableButton={enableButton}
      image={settingsImage}
      mediumIcon
      readContent={getContentReader(content || defaultRestartableErrorPageContent)}
    />
  );
};

export default RestartableError;
