import { useCallback } from "react";
import useStore from "../../store/store";

const usePreSubmitTreatment = (bundleName, contentId) => {
  const selector = useCallback(
    state =>
      state.content
        .flatMap(bundle => bundle?.preSubmitTreatments?.[bundleName] ?? [])
        .find(bundle => bundle.contentId === contentId),
    [bundleName, contentId]
  );
  return useStore(selector);
};

export default usePreSubmitTreatment;
