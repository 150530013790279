import { useCallback, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import shallow from "zustand/shallow";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import useStore from "../../../store/store";
import Heading from "../../shared/Heading";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import NavigationButtons from "../../shared/NavigationButtons";
import SectionHeader, { Divider } from "../../shared/SectionHeader";
import Page from "../../shared/Page";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import { useNavigateUnlessReview } from "../../../utils/reviewContext";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import {
  SECOND_PAGE_BUNDLE_TO_MAIN_QUESTION_MAPPING,
  lookupBusinessServiceQuestions,
  RETAIL_PHARMACY,
  TELEMARKETING,
  FOREIGN_BUSINESS,
  MINING,
  GOODS_MOVEMENT,
  VEHICLE_PURCHASE,
  ADULT_ENTERTAINMENT,
} from "../../../constants/riskyBusinessQuestionsValues";
import useRouting from "../../../utils/hooks/useRouting";
import RiskyBusinessQuestions from "./RiskyBusinessQuestions";
import { trackAdobeTargetEvent } from "../../../utils/adobeTargetHelpers";

// Third (conditional) HRBR page

const riskyBusinessActivitiesQuestionsContent = state =>
  state.content
    .map(bundle => bundle?.riskyProhibitedActivitiesQuestions ?? null)
    .find(bundle => bundle !== null);

export const industryAdditionalActivitiesHRBRSchema = yup.object({
  businessHasRetailPharmacy: yup.boolean().nullable().required("Select a response"),
  businessHasTelemarketing: yup.boolean().nullable().required("Select a response"),
  businessHasForeignBusiness: yup.boolean().nullable().required("Select a response"),
  businessHasMining: yup.boolean().nullable().required("Select a response"),
  businessHasGoodsMovement: yup.boolean().nullable().required("Select a response"),
  businessHasVehiclePurchase: yup.boolean().nullable().required("Select a response"),
  businessHasAdultEntertainment: yup.boolean().nullable().required("Select a response"),
});

const RiskyBusinessAdditionalIndustryActivities = () => {
  usePageTitle("Business Services");
  usePreventNavigation();
  const headingRef = useFocusHeading();

  const [
    riskyBusinessActivitiesQuestions,
    submitRiskyProhibitedBusinesses,
    storedBusinessHasRetailPharmacy,
    storedBusinessHasTelemarketing,
    storedBusinessHasForeignBusiness,
    storedBusinessHasMining,
    storedBusinessHasGoodsMovement,
    storedBusinessHasVehiclePurchase,
    storedBusinessHasAdultEntertainment,
    isUsingAdobeTarget,
  ] = useStore(
    state => [
      riskyBusinessActivitiesQuestionsContent(state),
      state.pageSubmit.submitRiskyProhibitedBusinesses,
      state.hrbrActivities.businessHasRetailPharmacy,
      state.hrbrActivities.businessHasTelemarketing,
      state.hrbrActivities.businessHasForeignBusiness,
      state.hrbrActivities.businessHasMining,
      state.hrbrActivities.businessHasGoodsMovement,
      state.hrbrActivities.businessHasVehiclePurchase,
      state.hrbrActivities.businessHasAdultEntertainment,
      state.isUsingAdobeTarget,
    ],
    shallow
  );

  const formMethods = useForm({
    resolver: yupResolver(industryAdditionalActivitiesHRBRSchema),
    shouldFocusError: false,
    defaultValues: {
      businessHasRetailPharmacy: storedBusinessHasRetailPharmacy,
      businessHasTelemarketing: storedBusinessHasTelemarketing,
      businessHasForeignBusiness: storedBusinessHasForeignBusiness,
      businessHasMining: storedBusinessHasMining,
      businessHasGoodsMovement: storedBusinessHasGoodsMovement,
      businessHasVehiclePurchase: storedBusinessHasVehiclePurchase,
      businessHasAdultEntertainment: storedBusinessHasAdultEntertainment,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const navigate = useNavigateUnlessReview();
  const [backRoute, nextRoute] = useRouting();

  const submitHandler = useCallback(
    async data => {
      await submitRiskyProhibitedBusinesses(data);

      // AB TEST: If we are using Adobe Target, we want to track the secondary metrics for reporting
      if (isUsingAdobeTarget) {
        // track the completedBusiness event for Adobe Target
        trackAdobeTargetEvent({ successMetric: "completedBusiness" });
      }

      navigate(nextRoute);
    },
    [submitRiskyProhibitedBusinesses, navigate, nextRoute, isUsingAdobeTarget]
  );

  const submitWithErrorHandling = useSubmitWithErrorHandling(submitHandler);

  // second page bundle mapping here because misc activities is split between page two and three.
  const [, , newMainQuestions] = useMemo(
    () =>
      lookupBusinessServiceQuestions({
        pageToMap: SECOND_PAGE_BUNDLE_TO_MAIN_QUESTION_MAPPING,
        content: riskyBusinessActivitiesQuestions,
        mapNewMainQuestions: true,
      }),
    [riskyBusinessActivitiesQuestions]
  );

  if (!riskyBusinessActivitiesQuestions) {
    return <LoadingSpinnerPage />;
  }

  return (
    <Page>
      <Grid ref={headingRef}>
        <form onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
          <FormProvider {...formMethods}>
            <Heading
              step="SECTION 2 OF 4"
              mainHeading="Tell us about your business activities"
              subHeading="This is required to comply with regulatory requirements and may affect our ability to open or maintain your business accounts."
            />
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                <SectionHeader
                  id="riskyBusinessesAdditionalIndustryActivities_section_header"
                  title="Additional industry-specific business activities"
                />
              </Col>
            </CenteredRow>
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                <RiskyBusinessQuestions
                  category={RETAIL_PHARMACY}
                  label={newMainQuestions[RETAIL_PHARMACY]}
                />
                <Divider />
                <RiskyBusinessQuestions category={TELEMARKETING} label={newMainQuestions[TELEMARKETING]} />
                <Divider />
                <RiskyBusinessQuestions
                  category={FOREIGN_BUSINESS}
                  label={newMainQuestions[FOREIGN_BUSINESS]}
                />
                <Divider />
                <RiskyBusinessQuestions category={MINING} label={newMainQuestions[MINING]} />
                <Divider />
                <RiskyBusinessQuestions category={GOODS_MOVEMENT} label={newMainQuestions[GOODS_MOVEMENT]} />
                <Divider />
                <RiskyBusinessQuestions
                  category={VEHICLE_PURCHASE}
                  label={newMainQuestions[VEHICLE_PURCHASE]}
                />
                <Divider />
                <RiskyBusinessQuestions
                  category={ADULT_ENTERTAINMENT}
                  label={newMainQuestions[ADULT_ENTERTAINMENT]}
                />
              </Col>
            </CenteredRow>
            <NavigationButtons
              backRoute={backRoute}
              nextLoading={isSubmitting}
              pageNameOverrideForIds="riskyBusinessesAdditionalIndustryActivities"
            />
          </FormProvider>
        </form>
      </Grid>
    </Page>
  );
};

export default RiskyBusinessAdditionalIndustryActivities;
