import { useEffect, useRef } from "react";
import shallow from "zustand/shallow";
import useStore from "../../store/store";
import { globalMboxName, validReceivableTargetTests, trackAdobeTargetEvent } from "../adobeTargetHelpers";

const useLoadABTests = () => {
  // addTargetTestParam: function to add a target test parameter to the store
  // setIsUsingAdobeTarget: function to set the store to indicate that we are using a valid decision from Adobe Target for AB
  // setHasProcessedAdobeTarget: function to set the store to indicate that we have processed Adobe Target for A/B (even if we did not get a valid decision from it)
  const [addTargetTestParam, setIsUsingAdobeTarget, setHasProcessedAdobeTarget] = useStore(
    state => [state.addTargetTestParam, state.setIsUsingAdobeTarget, state.setHasProcessedAdobeTarget],
    shallow
  );

  const isLoaded = useRef(false);

  useEffect(() => {
    if (isLoaded.current) {
      return;
    }

    const parseAdobeTargetResponse = response => {
      const finalABDecisions = {};

      // For each valid A/B test, we will check if the value received from Adobe Target is acceptable (and if we even received one in the first place).
      // If it is, we will use it. If not, we will use the default value set in the test's object in the validReceivableTargetTests array.
      validReceivableTargetTests.forEach(({ targetTestName, defaultValue, acceptableValues }) => {
        // Using hasOwnProperty here to allow for falsey values to be still correctly interpreted if they are received from Adobe Target API call
        const valueReceivedFromAdobeResponse = response
          .flatMap(item => item.content)
          .find(contentItem => Object.prototype.hasOwnProperty.call(contentItem, targetTestName));
        if (
          valueReceivedFromAdobeResponse &&
          acceptableValues.includes(valueReceivedFromAdobeResponse[targetTestName])
        ) {
          // NOTE: We should only have one active test at a time. We would need to modify this logic if this no longer holds, though, so that we can properly track metrics.
          setIsUsingAdobeTarget();
          // Manually send an event to Adobe Target to indicate that we are using it for this A/B test
          trackAdobeTargetEvent({ targetTestName: valueReceivedFromAdobeResponse[targetTestName] });
          finalABDecisions[targetTestName] = valueReceivedFromAdobeResponse[targetTestName];
        } else {
          finalABDecisions[targetTestName] = defaultValue;
        }
      });

      // Adds a valid value to the store for each validReceivableTargetTests item to be used as reference throughout application
      Object.entries(finalABDecisions).forEach(([key, value]) => addTargetTestParam(key, value));
      setHasProcessedAdobeTarget();

      // Return the final decisions for logging purposes
      return finalABDecisions;
    };

    const fetchABTests = async () => {
      isLoaded.current = true;

      const offerRequest = {
        mbox: globalMboxName,
        success: response => {
          const finalABDecisions = parseAdobeTargetResponse(response);
          console.log(finalABDecisions);
        },
        error: (status, error) => {
          // Log the error if we were not able to fetch the A/B test values from Adobe Target
          console.log("Adobe target call failed with error", status, error);
          // We will use the default A/B test values if we were not able to fetch them from Adobe Target
          const finalABDecisions = parseAdobeTargetResponse([]);
          console.log(finalABDecisions);
        },
      };

      window.adobe.target.getOffer(offerRequest);
    };

    // Make sure that Adobe Target is available before trying to fetch the A/B test values
    if (!window.adobe || !window.adobe.target) {
      isLoaded.current = true;

      // Log the error if Adobe Target is not available
      console.log("Adobe target was not available");
      // We will use the default A/B test values if we were not able to fetch them from Adobe Target
      const finalABDecisions = parseAdobeTargetResponse([]);
      console.log(finalABDecisions);
      return;
    }

    // Try to fetch the A/B test values from Adobe Target
    fetchABTests();
  }, [isLoaded, addTargetTestParam, setIsUsingAdobeTarget, setHasProcessedAdobeTarget]);
};

export default useLoadABTests;
