import { createContext, useCallback, useContext } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import mediaQuery from "./mediaQuery";
import { useButtonId } from "./hooks/usePageScopedId";

const ReviewContext = createContext({
  isReviewPage: false,
  onSaveReview: null,
  onCancelReview: null,
});

export const useIsReviewPage = () => useContext(ReviewContext).isReviewPage;
export const useReviewSaveCallback = () => useContext(ReviewContext).onSaveReview;
export const useReviewCancelCallback = () => useContext(ReviewContext).onCancelReview;
export const useNavigateUnlessReview = () => {
  const isReview = useIsReviewPage();
  const saveReview = useReviewSaveCallback();
  const wrappedSaveReview = useCallback(() => {
    saveReview?.();
  }, [saveReview]);
  const navigate = useNavigate();
  return isReview ? wrappedSaveReview : navigate;
};

export const ReviewWrapper = ({ onSaveReview, onCancelReview, children }) => (
  <ReviewContext.Provider value={{ isReviewPage: true, onSaveReview, onCancelReview }}>
    {children}
  </ReviewContext.Provider>
);

const Container = styled.div.attrs({
  className: "grv-dialog__container",
})`
  max-width: 1120px;
  flex: 0 1 1120px;
  height: 85vh;
  padding-left: 0px;
  padding-right: 0px;

  @media not (${mediaQuery.large}) {
    border-radius: 0;
    bottom: 0;
    box-shadow: none;
    left: 0;
    min-height: 100vh;
    height: 100%;
    max-width: 100vw;
    right: 0;
    top: 0;
  }
`;

const Content = styled.div.attrs({
  className: "grv-dialog__content",
})`
  overflow-y: scroll;
`;

export const ReviewDialog = ({ isOpen, onClose, children }) => (
  <ReactModal
    ariaHideApp
    preventScroll
    shouldFocusAfterRender
    shouldReturnFocusAfterClose
    aria={{
      // aligned to ids in Heading component
      labelledby: "page-main-heading",
      describedby: "page-sub-heading",
    }}
    bodyOpenClassName={null}
    className="grv-dialog grv-dialog--normal grv-dialog--active"
    htmlOpenClassName={null}
    id="review-dialog"
    isOpen={isOpen}
    overlayClassName="grv-dialog__overlay grv-dialog__overlay--dark"
    portalClassName="grv-portal"
    role="dialog"
    shouldCloseOnEsc
    onRequestClose={onClose}
  >
    <Container>
      <button
        aria-label="cancel editing"
        className="grv-dialog__close"
        type="button"
        onClick={onClose}
        id={useButtonId("editingModalClose")}
      />
      <Content>
        <ReviewWrapper onSaveReview={onClose} onCancelReview={onClose}>
          {children}
        </ReviewWrapper>
      </Content>
    </Container>
  </ReactModal>
);
