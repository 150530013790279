import usePageTitle from "../../../utils/hooks/usePageTitle";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import FatalErrorPageTemplate from "../../shared/FatalErrorPageTemplate";
import useApplicationChannel from "../../../utils/hooks/useApplicationChannel";
import { SBB_CAFE, SBB_WEB } from "../../../constants/applicationChannelTypes";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";

const RaDataFailure = () => {
  usePageTitle("Existing Data Failure");
  const applicationChannel = useApplicationChannel();
  const content = usePreSubmitTreatment("branchPreSubmitErrors", "raApi5xxError");

  if (!content || !applicationChannel) {
    return <LoadingSpinnerPage />;
  }

  return (
    <FatalErrorPageTemplate
      content={content}
      enableButtons={[SBB_WEB, SBB_CAFE].includes(applicationChannel)}
    />
  );
};

export default RaDataFailure;
