import { useEffect } from "react";

const usePageTitle = (headingTitle = "", baseTitle = "Apply for a Small Business Account") => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = headingTitle ? `${baseTitle} | ${headingTitle}` : `${baseTitle}`;
    return () => {
      document.title = prevTitle || baseTitle;
    };
  }, [headingTitle, baseTitle]);
};

export default usePageTitle;
