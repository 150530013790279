import useStore from "../../../store/store";
import PrefillLineItem, { StaticPrefillLineItem } from "../../shared/PrefillBox";
import { formatSSN, combineParts } from "../../../utils/reviewFormatters";
import { citizenshipTypeDisplay } from "../../../constants/citizenshipTypes";

export const ApplicantFirstName = () => {
  const applicantFirstName = useStore(state => state.applicantFirstName);

  return applicantFirstName ? (
    <StaticPrefillLineItem
      idBase="applicantPrefilledFirstName"
      labelText="First Name"
      content={applicantFirstName}
    />
  ) : (
    <></>
  );
};

export const ApplicantLastName = () => {
  const applicantLastName = useStore(state => state.applicantLastName);

  return applicantLastName ? (
    <StaticPrefillLineItem
      idBase="applicantPrefilledLastName"
      labelText="Last Name"
      content={applicantLastName}
    />
  ) : (
    <></>
  );
};

export const ApplicantDateOfBirthPartial = () => {
  const applicantDateOfBirthPartial = useStore(state => state.applicantDateOfBirthPartial);

  return applicantDateOfBirthPartial ? (
    <StaticPrefillLineItem
      idBase="applicantPrefilledPartialDateOfBirth"
      labelText="Date of Birth (MM/DD)"
      content={`${applicantDateOfBirthPartial?.replace("-", "/")}/••••`}
    />
  ) : (
    <></>
  );
};

export const ApplicantTaxId = () => {
  const applicantTaxId = useStore(state => state.applicantTaxId);

  return applicantTaxId ? (
    <StaticPrefillLineItem
      idBase="applicantPrefilledTaxId"
      labelText="Social Security Number (SSN)"
      content={formatSSN(applicantTaxId)}
      mask="•••-••-"
      maskButtonAriaLabel="toggle personal information SSN view"
    />
  ) : (
    <></>
  );
};

export const ApplicantCitizenshipStatus = () => {
  const applicantCitizenshipStatus = useStore(state => state.applicantCitizenshipStatus);

  return applicantCitizenshipStatus ? (
    <StaticPrefillLineItem
      idBase="applicantPrefilledCitizenshipStatus"
      labelText="Citizenship Status"
      content={citizenshipTypeDisplay[applicantCitizenshipStatus]}
    />
  ) : (
    <></>
  );
};

export const ApplicantAddress = () => (
  <PrefillLineItem
    idBase="applicantPrefilledAddress"
    labelText="Residential Street Address"
    selector={state =>
      combineParts([
        state.applicantAddress1,
        state.applicantAddress2,
        state.applicantAddressCity,
        state.applicantAddressState,
        state.applicantAddressPostalCode,
      ])
    }
  />
);

export const ApplicantHomePhonePartial = () => {
  const applicantHomePhonePartial = useStore(state => state.applicantHomePhonePartial);

  return applicantHomePhonePartial ? (
    <StaticPrefillLineItem
      idBase="applicantPrefilledPartialHomePhone"
      labelText="Last 4 Digits of Home Phone Number"
      content={`(•••)•••-${applicantHomePhonePartial}`}
    />
  ) : (
    <></>
  );
};

export const ApplicantMobilePhonePartial = () => {
  const applicantMobilePhonePartial = useStore(state => state.applicantMobilePhonePartial);

  return applicantMobilePhonePartial ? (
    <StaticPrefillLineItem
      idBase="applicantPrefilledPartialMobilePhone"
      labelText="Last 4 Digits of Mobile Phone Number"
      content={`(•••)•••-${applicantMobilePhonePartial}`}
    />
  ) : (
    <></>
  );
};

export const ApplicantEmailAddress = () => {
  const applicantEmailAddress = useStore(state => state.applicantEmailAddress);

  return applicantEmailAddress ? (
    <StaticPrefillLineItem
      idBase="applicantPrefilledEmailAddress"
      labelText="Personal Email Address"
      content={applicantEmailAddress}
    />
  ) : (
    <></>
  );
};
