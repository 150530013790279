export const EXECUTIVE_OFFICER = "EXECUTIVE_OFFICER";
export const MANAGING_MEMBER = "MANAGING_MEMBER";
export const OWNER = "OWNER";
export const MANAGING_PARTNER = "MANAGING_PARTNER";
export const SENIOR_MANAGER = "SENIOR_MANAGER";

export const businessControllerTypeDisplay = {
  [EXECUTIVE_OFFICER]: "Executive Officer",
  [MANAGING_MEMBER]: "Managing Member",
  [OWNER]: "Owner",
  [MANAGING_PARTNER]: "Managing Partner",
  [SENIOR_MANAGER]: "Senior Manager",
};
