import { forwardRef, useState } from "react";
import combineStrings from "../../../utils/combineStrings";

// Implements a "multi-open" Accordion using gravity classes
// The key logic actually lives in Accordion.Item, Accordion is just a simple wrapper
// that applies .grv-accordion to a div and otherwise forwards all props (including
// children).
// Note that this component can only be this simple bc we are allowing the "multi-open"
// functionality - if the more traditional "single-open" functionality is ever
// needed in this UI, this component will need to be rewritten.
// Forwards ref, spreads all props, properly combines className
const Accordion = forwardRef(({ className, ...rest }, ref) => (
  <div ref={ref} className={combineStrings("grv-accordion", { [className]: className })} {...rest} />
));

// Accordion.Items are essentially just a title button that toggles their "active"
// state, and a section container below it, which itself contains the item's content.
// The following props are available:
// - startActive: Boolean, if true the Accordion.Item will mount in the "active" state
// - title: will be mounted in the title button
// - titleProps: additional props that will be spread to the title button. onClick will
//   be combined with the Accordion.Item functionality.
// - sectionProps: additional props that will be spread to the section wrapper.
// - contentProps: additional props that will be spread to the content wrapper.
// - children: will be wrapped in a div with .grv-accordion__content, which is then
//   mounted in the section wrapper
// All other props provided are passed to the containing .grv-accordion__item div.
// Also forwards ref to the containing .grv-accordion__item div.
// Other refs can also be passed as part of titleProps, sectionProps, and contentProps.
// Both the top level className prop as well as classNames within titleProps,
// sectionProps and contentProps are properly combined with the gravity classes.
Accordion.Item = forwardRef(
  ({ startActive, title, titleProps, sectionProps, contentProps, children, className, ...rest }, ref) => {
    const [active, setActive] = useState(() => Boolean(startActive));

    return (
      <div ref={ref} className={combineStrings("grv-accordion__item", { [className]: className })} {...rest}>
        <button
          type="button"
          aria-expanded={String(active)}
          {...titleProps}
          onClick={event => {
            setActive(a => !a);
            titleProps?.onClick?.(event);
          }}
          className={combineStrings("grv-accordion__title", {
            "grv-accordion__title--active": active,
            [titleProps?.className]: titleProps?.className,
          })}
        >
          {title}
        </button>
        <div
          {...sectionProps}
          className={combineStrings("grv-accordion__section", {
            "grv-accordion__section--active": active,
            [sectionProps?.className]: sectionProps?.className,
          })}
        >
          <div
            {...contentProps}
            className={combineStrings("grv-accordion__content", {
              [contentProps?.className]: contentProps?.className,
            })}
            aria-hidden={String(!active)}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
);

export default Accordion;
