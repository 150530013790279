import { forwardRef } from "react";
import * as yup from "yup";
import {
  annualBusinessRevenueRangeDisplay,
  OVER_TWOMILLION,
  ONEMILLION_TO_TWOMILLION,
  FIVEHUNDREDK_TO_ONEMILLION,
  TWOHUNDREDFIFTYK_TO_FIVEHUNDREDK,
  HUNDREDK_TO_TWOHUNDREDFIFTYK,
  FIFTYK_TO_HUNDREDK,
  TWENTYK_TO_FIFTYK,
  UNDER_TWENTYK,
} from "../../../constants/annualBusinessRevenueRanges";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import Select from "../Select";
import { useDropdownId } from "../../../utils/hooks/usePageScopedId";

export const annualRevenueRangeSchema = yup
  .string()
  .required("Select an annual revenue range")
  .notOneOf([PLACEHOLDER_NONE], "Select an annual revenue range");

// Transparent wrapper around a Select, pre-populated with annual revenue values, which
// forwards all props (including ref) to the underlying Select.
// Uses enum values for the different revenue ranges.
const AnnualRevenueRangeSelect = forwardRef((props, ref) => (
  <Select id={useDropdownId(props.name)} ref={ref} {...props}>
    <option className="grv-select__placeholder" value={PLACEHOLDER_NONE} disabled>
      Select an annual revenue range
    </option>
    {[
      UNDER_TWENTYK,
      TWENTYK_TO_FIFTYK,
      FIFTYK_TO_HUNDREDK,
      HUNDREDK_TO_TWOHUNDREDFIFTYK,
      TWOHUNDREDFIFTYK_TO_FIVEHUNDREDK,
      FIVEHUNDREDK_TO_ONEMILLION,
      ONEMILLION_TO_TWOMILLION,
      OVER_TWOMILLION,
    ].map(range => (
      <option key={range} value={range}>
        {annualBusinessRevenueRangeDisplay[range]}
      </option>
    ))}
  </Select>
));

export default AnnualRevenueRangeSelect;
