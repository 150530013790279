export const getCookie = (cookies, cookieName) => {
  const startIndex = cookies.indexOf(`${cookieName}=`);
  if (startIndex < 0) {
    // malformed or missing => return null for both
    return null;
  }
  const otherCookies = cookies.slice(startIndex + `${cookieName}=`.length);
  const endIndex = otherCookies.indexOf(";");
  return endIndex < 0 ? otherCookies : otherCookies.slice(0, endIndex);
};

// TODO - ideally these should live with useLoginCookie, but right now there's a cyclic dependency
// between that, the store, and sbaoAppEndpoints. In the future I want to reorganize a lot of this code.
export const getLoginTargetCookie = cookies => JSON.parse(decodeURIComponent(getCookie(cookies, "C1_TGT")));
export const getSimulatedLoginTargetCookie = cookies =>
  JSON.parse(decodeURIComponent(getCookie(cookies, "C1_DAO_REDIRECT_TGT")));
export const setSimulatedLoginTargetCookie = content => {
  const value = encodeURIComponent(JSON.stringify(content));
  document.cookie = `C1_DAO_REDIRECT_TGT=${value};max-age=300;domain=.capitalone.com;`;
};
export const clearLoginTargetCookies = () => {
  document.cookie = "C1_TGT=; expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=.capitalone.com;";
  document.cookie = "C1_DAO_REDIRECT_TGT=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.capitalone.com;";
};
