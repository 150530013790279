import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { sendErrorToNewRelic } from "../newRelic";

const useSubmitWithErrorHandling = submitFunction => {
  const navigate = useNavigate();
  return useCallback(
    async (...args) => {
      try {
        await submitFunction(...args);
      } catch (error) {
        // If we are catching an error here, it means that we have exhausted all retries
        sendErrorToNewRelic(error); // send error to new relic for tracking
        console.log(error);
        navigate("/restart-application");
      }
    },
    [navigate, submitFunction]
  );
};

export default useSubmitWithErrorHandling;
