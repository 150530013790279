import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";
import shallow from "zustand/shallow";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import useStore from "../../../store/store";
import { AddressFields, addressSchema, useAddressValidation } from "../Address";
import { CenteredRow, Col, Grid, Row } from "../Layout";
import { NoMarginSectionHeader } from "../SectionHeader";
import { BooleanRadioListField, HorizontalRadioList } from "../RadioList";
import { SOLE_PROPRIETORSHIP } from "../../../constants/legalEntityTypes";
import { useFieldId } from "../../../utils/hooks/usePageScopedId";

export const businessAddressesSchema = {
  address: addressSchema,
  mailingAddress: yup.object().when("hasDifferentMailingAddress", {
    is: true,
    then: schema => schema.concat(addressSchema),
    otherwise: schema => schema,
  }),
  hasDifferentMailingAddress: yup.bool(),
};

export const isBusinessAddressTheHomeAddressSchema = yup.boolean().when(["businessHomeAddressEnabled"], {
  is: true,
  then: yup.boolean().nullable().required("Select a response"),
  otherwise: yup.boolean().optional().nullable(),
});

// Pulls business physical and mailing addresses from the store
// and returns an object that can be spread into the hook form's
// default values to set up the form correctly.
export const useBusinessAddressesDefaultValues = () => {
  const [
    storedAddressLine1,
    storedAddressLine2,
    storedAddressCity,
    storedAddressState,
    storedAddressZip,
    storedDifferentMailing,
    storedMailingAddressLine1,
    storedMailingAddressLine2,
    storedMailingAddressCity,
    storedMailingAddressState,
    storedMailingAddressZip,
  ] = useStore(
    state => [
      state.businessAddress1,
      state.businessAddress2,
      state.businessAddressCity,
      state.businessAddressState,
      state.businessAddressPostalCode,
      state.doesBusinessUseDifferentMailingAddress,
      state.businessMailingAddress1,
      state.businessMailingAddress2,
      state.businessMailingAddressCity,
      state.businessMailingAddressState,
      state.businessMailingAddressPostalCode,
    ],
    shallow
  );

  return {
    address: {
      addressLine1: storedAddressLine1 || "",
      addressLine2: storedAddressLine2 || "",
      city: storedAddressCity || "",
      state: storedAddressState || PLACEHOLDER_NONE,
      zip: storedAddressZip || "",
    },
    hasDifferentMailingAddress: storedDifferentMailing || false,
    mailingAddress: {
      addressLine1: storedMailingAddressLine1 || "",
      addressLine2: storedMailingAddressLine2 || "",
      city: storedMailingAddressCity || "",
      state: storedMailingAddressState || PLACEHOLDER_NONE,
      zip: storedMailingAddressZip || "",
    },
  };
};

const mailingAddressValidationSettings = {
  allowCMRA: true,
  allowPOBox: true,
  allowDPO: true,
  allowEmbassy: true,
};

// Wrapper hook that calls useAddressValidation twice - once for the business address, and
// once for the optional mailing address.
// Then, returns a pair similar to useAddressValidation - [validationState, validate].
//
// The first value, validationState, should should simply be spread directly to
// the BusinessAddressesSection component below.
//
// The latter value, validate, is an asynchronous validator for both physical and optional
// mailing addresses, the logic of which is very similar to the validator returned by
// useAddressValidation.
// This hook will pull the applicant's street address from the store. If the physical or
// mailing address are the same as the applicant's street address, validation of that address
// will be skipped.
// That is, this hook assumes the applicant address is validated *PRIOR* to business!
//
// If the hasDifferentMailingAddress field is true, validation is only done on the physical address.
//
// This should be called in form submission, and will return true if the addresses are both acceptable.
//
// This hook takes an object containing setError and getValues as its only argument, and these
// should come directly from useForm.
export const useBusinessAddressesValidation = ({ setError, getValues }) => {
  const [isSoleProp, ...personalAddress] = useStore(
    state => [
      state.businessLegalEntityType === SOLE_PROPRIETORSHIP,
      state.applicantAddress1?.toUpperCase(),
      state.applicantAddress2?.toUpperCase(),
      state.applicantAddressCity?.toUpperCase(),
      state.applicantAddressState?.toUpperCase(),
      state.applicantAddressPostalCode?.toUpperCase(),
    ],
    shallow
  );

  // For Sole Proprietorships, treat missing addresses as an error.
  // Note that if the physical address is the same as the applicant address,
  // this validation is still entirely skipped.
  const [physicalAddressValidationState, validateBusiness] = useAddressValidation({
    errorOnMissing: isSoleProp,
    setError,
    getValues,
  });
  const [mailingAddressValidationState, validateMailing] = useAddressValidation({
    setError,
    getValues,
    baseField: "mailingAddress",
    ...mailingAddressValidationSettings,
  });

  const validate = useCallback(
    async () => {
      // If the business address is the same as personal, skip validating it.
      // This ensures that the user will not see another banner pop up after they already
      // checked their "missing" personal address was correct.
      // This is also safe since business addresses are handled *after* the applicant address,
      // meaning we already know it is not a PO box or other invalid address.
      const skipBusiness = getValues([
        "address.addressLine1",
        "address.addressLine2",
        "address.city",
        "address.state",
        "address.zip",
      ])
        .map(field => field.toUpperCase())
        .every((field, i) => field === personalAddress[i]);

      // If the mailing is the same as the business address, OR the personal address,
      // skip validating it for the same reasons as above.
      // This is additionally safe because the restrictions on a mailing address are
      // weaker than those on a street address, i.e., PO boxes are allowed and we already
      // know the personal address is not one.
      const skipMailing =
        !getValues("hasDifferentMailingAddress") ||
        getValues([
          "mailingAddress.addressLine1",
          "mailingAddress.addressLine2",
          "mailingAddress.city",
          "mailingAddress.state",
          "mailingAddress.zip",
        ])
          .map(field => field.toUpperCase())
          .every((field, i) => field === personalAddress[i]);

      const [
        { isAddressValid: isPhysicalValid, isAddressAcceptable: isPhysicalAcceptable },
        { isAddressValid: isMailingValid, isAddressAcceptable: isMailingAcceptable },
      ] = await Promise.all([
        // per discussion with product - when validation is skipped, we actually don't want to
        // track separate address invalid events. If we ever did, it would probably be sufficient
        // to set isAddressValid to be isApplicantAddressValid from the store for both of these.
        skipBusiness ? { isAddressValid: true, isAddressAcceptable: true } : validateBusiness(),
        skipMailing ? { isAddressValid: true, isAddressAcceptable: true } : validateMailing(),
      ]);
      return {
        isPhysicalValid,
        isMailingValid,
        areAddressesAcceptable: isPhysicalAcceptable && isMailingAcceptable,
      };
    },
    // Ordinarily depending on personalAddress would be bad since that would recalculate on
    // every rerender (since it is not memoized) but in practice it seems to be fine, most
    // likely due to the fact that the personal address components don't change during the
    // lifetime of this hook, and the fact that recreating this callback doesn't really cost much.
    // But, if we start seeing weird rerender behavior, this is a potential place to improve
    [personalAddress, validateBusiness, validateMailing, getValues]
  );

  return [{ physicalAddressValidationState, mailingAddressValidationState }, validate];
};

const DifferentMailingRadios = ({ control, name }) => {
  // Note - cannot use BooleanRadioListField as order and labeling is different
  // Pulled out to subcomponent to minimize hook reruns

  const mailingRadioId = useFieldId(name, "Radio");
  const radios = [
    {
      id: useFieldId(name, "Radio", "SameAddress"),
      label: "Provided physical business address",
      value: "false",
    },
    {
      id: useFieldId(name, "Radio", "Different"),
      label: "Other address",
      value: "true",
    },
  ];

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, ...rest } }) => (
        <HorizontalRadioList
          id={mailingRadioId}
          legendText="Which mailing address would you like to use?"
          radios={radios}
          value={value.toString()}
          onChange={event => onChange(event.target.value === "true")}
          {...rest}
        />
      )}
    />
  );
};

// Section containing both a physical business address and an optional mailing
// address, with a toggle for the latter. The physical address will be stored
// under "address" in the containing hook form, and the mailing address will be
// stored under "mailingAddress". The result of the mailing address toggle is
// stored under "hasDifferentMailingAddress".
// This section takes props for the label and helper text (and describedby) of the physical
// address line 1 input, two validation states that should be taken from the
// useBusinessAddressesValidation hook above, as well as an optional flag for enabling the "business
// address is home address" radio. No other props are forwarded, including refs.
//
// MISSING TEST COVERAGE: Feature flag used for this value will default to false if it does not exist so will always be present
// istanbul ignore next
const BusinessAddressesSection = ({
  addressLabel,
  addressHelper,
  addressDescribedBy,
  businessHomeAddressEnabled = false,
  physicalAddressValidationState,
  mailingAddressValidationState,
}) => {
  const { watch, control } = useFormContext();
  const showMailing = watch("hasDifferentMailingAddress");

  return (
    <>
      <AddressFields
        baseField="address"
        idBase="businessAddress"
        addressLine1Label={addressLabel}
        addressLine1Helper={addressHelper}
        addressLine1DescribedBy={addressDescribedBy}
        {...physicalAddressValidationState}
      />
      <Grid>
        {businessHomeAddressEnabled && (
          <Row>
            <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
              <BooleanRadioListField
                control={control}
                name="isBusinessAddressTheHomeAddress"
                legendText="Are you operating your business from home (i.e business address is same as residential address)?"
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
            <DifferentMailingRadios control={control} name="hasDifferentMailingAddress" />
          </Col>
        </Row>
      </Grid>
      {showMailing && (
        <>
          <Grid>
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                <NoMarginSectionHeader title="Business Mailing Address" />
              </Col>
            </CenteredRow>
          </Grid>
          <AddressFields
            baseField="mailingAddress"
            idBase="businessMailingAddress"
            addressLine1Label="Mailing Address"
            addressLine1Helper="P.O. Box numbers accepted."
            {...mailingAddressValidationState}
          />
        </>
      )}
    </>
  );
};

export default BusinessAddressesSection;
