import { useCallback, useState } from "react";
import shallow from "zustand/shallow";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import { CardWithoutShadow, CenteredRow, Col, Grid } from "../../shared/Layout";
import Page from "../../shared/Page";
import useStore from "../../../store/store";
import Heading from "../../shared/Heading";
// TODO: Remove as part of NSBOAO-30318
import user from "../../../assets/blue_user.svg";
// TODO: Remove as part of NSBOAO-30318
import business from "../../../assets/blue_business.svg";
// TODO: Remove as part of NSBOAO-30318
import multiUser from "../../../assets/blue_multi_user.svg";
import mediaQuery from "../../../utils/mediaQuery";
import EligibilityNotice from "./EligibilityNotice";
import RequiredInfoBlock from "./RequiredInfoBlock";
import Button from "../../shared/Button";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import getContentReader from "../../../utils/getContentReader";
import { SBB_BRANCH, SBB_CAFE, SBB_WEB } from "../../../constants/applicationChannelTypes";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import useApplicationChannel from "../../../utils/hooks/useApplicationChannel";
import { useNextRoute } from "../../../utils/hooks/useRouting";
import { isFeatureEnabled } from "../../../utils/configSelector";
import GeolocationModal from "./GeolocationModal";
import { buildIdFromComponents } from "../../../utils/hooks/usePageScopedId";
import useLandingPageContent from "../../../utils/hooks/useLandingPageContent";

const ApplyButton = styled(Button).attrs({
  className: "grv-margin__top--medium-2",
  gravityType: "progress",
})`
  min-width: 100%;

  @media print {
    display: none;

    & * {
      display: none;
    }
  }
`;

const InfoHeader = styled.h2.attrs({
  className: "grv-text grv-text__heading--large",
  id: "gettingStarted_infoHeader_Text",
})`
  text-align: left;
  margin-bottom: 0px;
  margin-top: var(--grv-size-spacing-48);

  @media not (${mediaQuery.large}) {
    font-size: var(--grv-size-font-medium-2);
  }

  @media print {
    font-size: var(--grv-size-font-medium-2);
    margin-top: 28px;
  }
`;

const InfoSection = styled.div.attrs({
  className: "grv-card__content",
})`
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;

  @media print {
    margin-top: var(--grv-size-spacing-16);
  }
`;

const GettingStarted = () => {
  const content = useLandingPageContent();
  const [hasApplicationBeenCreated, zipcodeModalEnabled, createApp, submit] = useStore(
    state => [
      state.applicationCreated,
      isFeatureEnabled(state, "zipcodeModalEnabled"),
      state.pageSubmit.createApplication,
      state.pageSubmit.submitGettingStarted,
    ],
    shallow
  );
  usePageTitle("Getting Started");
  const headingRef = useFocusHeading();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const nextRoute = useNextRoute();
  const applicationChannel = useApplicationChannel();
  const onApply = useCallback(async () => {
    setLoading(true);
    try {
      if (!hasApplicationBeenCreated) {
        await createApp();
      }
      // do not need to await here since submit method logic consists
      // exclusively of sending a non-critical event (customerClickedApplyNow)
      submit();
    } finally {
      setLoading(false);
    }
    navigate(nextRoute);
  }, [setLoading, navigate, createApp, submit, nextRoute, hasApplicationBeenCreated]);
  const submitWithErrorHandling = useSubmitWithErrorHandling(onApply);

  // if we haven't finished initial loading/parsing of query params, applicationChannel will be null
  if (!content || !applicationChannel) {
    // display a spinner over the page, and a spacer in the underlying page so it looks less ridiculous
    return <LoadingSpinnerPage loadingSpinnerText="Loading the application..." />;
  }

  const readContent = getContentReader(content);
  // SBB_WEB and SBB_CAFE use the non-branch content
  const headerContentFieldname = applicationChannel === SBB_BRANCH ? "branchHeaderText" : "headerText";

  return (
    <Page showCIPDisclaimer hidePrintViewHeader>
      {/* only SBB_WEB channel needs the GeolocationModal */}
      {applicationChannel === SBB_WEB && zipcodeModalEnabled && <GeolocationModal />}
      <Grid ref={headingRef}>
        <Heading
          step={content?.pageName ?? ""}
          mainHeadingProps={{
            dangerouslySetInnerHTML: { __html: readContent(headerContentFieldname) },
            id: "gettingStarted_titleHeader_Text",
          }}
          subHeadingProps={
            [SBB_CAFE, SBB_WEB].includes(applicationChannel) && {
              dangerouslySetInnerHTML: { __html: readContent("subHeaderText") },
              id: "gettingStarted_subHeader_Text",
            }
          }
        />
        <EligibilityNotice />
        <CenteredRow>
          <Col lg={8}>
            <InfoHeader dangerouslySetInnerHTML={{ __html: readContent("subFooterText") }} />
          </Col>
        </CenteredRow>
        <CenteredRow>
          <Col lg={8}>
            <CardWithoutShadow>
              <InfoSection>
                <div className="grv-card__body">
                  <RequiredInfoBlock
                    listName="First"
                    icon={user}
                    title={readContent("firstListTitle")}
                    firstItem={readContent("firstListFirstItem")}
                    secondItem={readContent("firstListSecondItem")}
                    reference={readContent("firstListReference")}
                    note={readContent("firstListAuthSignerNote")}
                  />
                  <RequiredInfoBlock
                    listName="Second"
                    icon={business}
                    title={readContent("secondListTitle")}
                    firstItem={readContent("secondListFirstItem")}
                    secondItem={readContent("secondListSecondItem")}
                    thirdItem={readContent("secondListThirdItem")}
                    reference={readContent("secondListReference")}
                  />
                  <RequiredInfoBlock
                    listName="Third"
                    icon={multiUser}
                    title={readContent("thirdListTitle")}
                    firstItem={readContent("thirdListFirstItem")}
                    secondItem={readContent("thirdListSecondItem")}
                    thirdItem={readContent("thirdListThirdItem")}
                    reference={readContent("thirdListReference")}
                  />
                </div>
              </InfoSection>
            </CardWithoutShadow>
          </Col>
        </CenteredRow>
        <CenteredRow>
          <Col lg={8}>
            <p
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: readContent("multipleAppsYP") }}
              className="grv-text--small grv-margin__top--large-3 grv-margin__bottom--none"
            />
          </Col>
        </CenteredRow>
        <CenteredRow>
          <Col lg={4} md={4} sm={4}>
            <ApplyButton
              // use custom page name for ID scope
              id={buildIdFromComponents("gettingStarted", "applyOnline", "Button")}
              loading={loading}
              onClick={submitWithErrorHandling}
            >
              {readContent("applyButton")}
            </ApplyButton>
          </Col>
        </CenteredRow>
      </Grid>
    </Page>
  );
};

export default GettingStarted;
