import { useCallback } from "react";
import useStore from "../../store/store";

const usePostSubmitTreatment = (bundleName, contentId) => {
  const selector = useCallback(
    state =>
      state.content
        .flatMap(bundle => bundle?.postSubmitTreatments?.[bundleName] ?? [])
        .find(bundle => bundle.contentId === contentId),
    [bundleName, contentId]
  );
  return useStore(selector);
};

export const useMultipleDecisionPlatformTreatments = (decision, contentIds) => {
  const selector = useCallback(
    state =>
      state.content
        .flatMap(bundle => bundle?.postSubmitTreatments?.platformTreatments?.[decision] ?? [])
        .filter(bundle => contentIds.includes(bundle.contentId)),
    [decision, contentIds]
  );
  return useStore(selector);
};

export const useDecisionPlatformTreatment = (decision, contentId) => {
  const selector = useCallback(
    state =>
      state.content
        .flatMap(bundle => bundle?.postSubmitTreatments?.platformTreatments?.[decision] ?? [])
        .find(bundle => bundle.contentId === contentId),
    [decision, contentId]
  );
  return useStore(selector);
};

export default usePostSubmitTreatment;
