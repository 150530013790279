import styled from "styled-components";

const copyrightText = `\u00A9${new Date().getFullYear()} Capital One`;

const Copyright = styled.span`
  box-sizing: border-box;
  color: var(--grv-color-digital-gray-60);
  font-size: 12px;
`;

const CopyrightWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: var(--grv-size-spacing-xlarge-1);
`;

const FooterCopyright = () => (
  <CopyrightWrapper>
    <Copyright>{copyrightText}</Copyright>
  </CopyrightWrapper>
);

export default FooterCopyright;
