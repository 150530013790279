import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import TextInput from "../TextInput";
import { useInputId } from "../../../utils/hooks/usePageScopedId";

// Phone number input, using a preconfigured NumberFormat wrapping a TextInput.
// Expects id and label props for the underlying TextInput, as well as a control prop
// and field name (defaulting to "phone") for the containing hook form.
// Optionally accepts a helper prop for the helper text of the underlying TextInput.
// Optionally accepts an onChange callback which will be called *after* the hook form onChange.
// This component does *not* pass additional props down, as that could easily
// conflict with the props the Controller passes down.
// This component does *not* forward refs either, because the hook form Controller
// needs control over the ref.
// If for some reason more control via props is needed, those should be added explicitly,
// or a new component should be used.
const PhoneNumberField = ({ control, label, helper, onChange: providedOnChange, name = "phone", idBase }) => {
  const id = useInputId(idBase ?? name);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ref, ...rest }, fieldState: { error } }) => (
        <NumberFormat
          customInput={TextInput}
          id={id}
          label={label}
          helper={helper}
          getInputRef={ref}
          type="tel"
          format="(###) ###-####"
          isNumericString
          placeholder="(XXX) XXX-XXXX"
          error={error?.message}
          onValueChange={v => {
            onChange(v.value);
            providedOnChange?.(v.value);
          }}
          {...rest}
        />
      )}
    />
  );
};

export default PhoneNumberField;
