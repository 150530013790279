const defaultRestartableErrorPageContent = {
  headerTextProd: "Something went wrong",
  headerTextNonProd: "Something went wrong",
  bodyTextProd: "Looks like there was an issue opening your account online. Restart your application.",
  bodyTextNonProd: "Looks like there was an issue opening your account online. Restart your application.",
  buttonTextProd: "Restart application",
  buttonTextNonProd: "Restart application",
  buttonLinkTargetNonProd: "https://businessapply-qa.clouddqtext.capitalone.com/",
  buttonLinkTargetProd: "https://businessapply.capitalone.com/",
  contentId: "restartableWebErrors",
};

export default defaultRestartableErrorPageContent;
