import * as yup from "yup";
import { verifyEmailAddress } from "../api/sbaoAppEndpoints";

export const emailSchema = yup
  .string()
  .max(40)
  .required("Enter your email address")
  .matches(/^((?!(\d[\s.-]*){9}).)*$/, {
    excludeEmptyString: true,
    message:
      "Avoid entering sensitive information such as Business Tax ID Numbers or Social Security Numbers",
  })
  .email("Ensure your email address follows the 'name@example.com' format");

// Validates email address using the verifyEmailAddress endpoint function, and calls setError
// with the appropriate values to set the error message of the "email" field of a hook form.
// fieldId: if field is registered under a different name, this parameter can be used to ensure
// that errors are properly set on the field
export const validateEmail = async ({
  applicationReferenceId,
  email,
  setError,
  fieldId = "email",
  newVerificationServiceEnabled = false,
}) => {
  const { isEmailAddressValid, verificationResultCode } = await verifyEmailAddress({
    applicationReferenceId,
    emailAddress: email,
    newVerificationServiceEnabled,
  });
  if (!isEmailAddressValid) {
    switch (verificationResultCode) {
      case "100003":
        setError(fieldId, {
          type: "validation",
          message: "Ensure you are using a pre-existing email address",
        });
        break;
      case "100006":
        setError(fieldId, {
          type: "validation",
          message: "Ensure you are using a non-disposable email address",
        });
        break;
      default:
        setError(fieldId, {
          type: "validation",
          message: "Ensure that your email address follows the 'name@example.com' format",
        });
        break;
    }
  }
  return isEmailAddressValid;
};
