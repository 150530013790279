// Represents the MBOX that the A/B test is attached to (can only have one active test per MBOX, so could consider renaming this to be more reflective of the A/B test if we decide to run multiple concurrent tests in the future)
export const globalMboxName = "sbbdao-global-mbox";

// Each object in this array represents a valid A/B test we are conducting, along with a default value to use if a value cannot be obtained from Adobe Target + acceptable values for the test (to prevent any unexpected values from being used)
export const validReceivableTargetTests = [
  {
    targetTestName: "landingTreatment",
    defaultValue: "gettingStarted",
    acceptableValues: [
      "gettingStarted",
      "normalProductSelection",
      "productSelectionModalWithoutTiming",
      "productSelectionModalWithTiming",
    ],
  },
];

export const trackAdobeTargetEvent = mboxEvent => {
  window.adobe?.target?.trackEvent({
    mbox: globalMboxName,
    params: {
      ...mboxEvent,
    },
  });
};
