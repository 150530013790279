import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import { HorizontalRadioList } from "./RadioList";
import { useFieldId } from "../../../utils/hooks/usePageScopedId";

// A HorizontalRadioList that just has two options - Yes and No - corresponding to a boolean value.
// Generates its own field ID, using the name prop, or the idBase prop if it is provided.
// The labels can be controlled with yesLabel and noLabel, and either option can be disabled
// with yesDisabled or noDisabled.
// Otherwise, this functions as a controlled component, but the value should be a boolean. If value is
// null or not provided, neither radio will be selected.
// If onChange is provided, it will be called with a boolean value, NOT the original change event. This
// is useful for hook forms, as well as ensuring all of the boolean conversion logic is encapsulated in
// this component.
// All other props, including refs, are forwarded to the underlying radio list.
export const BooleanRadioListInput = forwardRef(
  (
    {
      name,
      idBase = name,
      yesLabel = "Yes",
      noLabel = "No",
      yesDisabled = false,
      noDisabled = false,
      value,
      onChange,
      ...rest
    },
    ref
  ) => (
    <HorizontalRadioList
      id={useFieldId(idBase, "Radio")}
      name={name}
      radios={[
        {
          id: useFieldId(idBase, "Radio", "Yes"),
          label: yesLabel,
          value: "true",
          disabled: yesDisabled,
        },
        {
          id: useFieldId(idBase, "Radio", "No"),
          label: noLabel,
          value: "false",
          disabled: noDisabled,
        },
      ]}
      value={value?.toString()}
      onChange={event => onChange?.(event.target.value === "true")}
      ref={ref}
      {...rest}
    />
  )
);

// Wraps the above BooleanRadioListInput in a Controller. Takes a form control as a prop and forwards all
// other props to the underlying BooleanRadioListInput. Notably this properly handles chaining of the
// onChange handler. Does not forward refs.
const BooleanRadioListField = ({ control, name, onChange: externalOnChange, ...radioListProps }) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange: controllerOnChange, ...rest }, fieldState: { error } }) => (
      <BooleanRadioListInput
        error={error?.message}
        onChange={value => {
          externalOnChange?.(value);
          controllerOnChange(value);
        }}
        {...radioListProps}
        {...rest}
      />
    )}
  />
);

export default BooleanRadioListField;
