import { validatePromoCode } from "../api/sbaoAppEndpoints";

export const PROMO_CODE_MAX_LENGTH = 15;

export const sanitizePromoCode = promoCode =>
  promoCode
    ?.toLocaleUpperCase() // all caps
    .replace(/[^A-Z0-9]/g, "") // only alphanumeric
    .slice(0, PROMO_CODE_MAX_LENGTH); // also handled by max length on input element

export const processPromoValidation = async (
  productId,
  productType,
  promotionCode,
  businessReferenceId = ""
) => {
  try {
    const response = await validatePromoCode(productId, promotionCode, businessReferenceId);

    if (response?.isPromotionValid) {
      const { promotionMessages } = response;
      const fundingPromotionMessage = promotionMessages?.filter(
        message => message.messagePlacement === "FUNDING" && message.messagePriority === "1"
      )?.[0]?.messageContent;
      return { isPromotionValid: true, productType, fundingPromotionMessage };
    }

    return { isPromotionValid: false, productType, errorCode: response?.id };
  } catch (error) {
    throw new Error("Failed to validate promotion code");
  }
};
