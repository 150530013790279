import React from "react";
import FatalErrorPageTemplate from "../../shared/FatalErrorPageTemplate";
import defaultFatalErrorPageContent from "../../../constants/defaultFatalErrorPageContent";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";

const BranchFailedCreateApplication = () => (
  <FatalErrorPageTemplate
    content={usePreSubmitTreatment("branchPreSubmitErrors", "branchFailedCreateApplication")}
    defaultContent={defaultFatalErrorPageContent}
    enableButtons={false}
  />
);

export default BranchFailedCreateApplication;
