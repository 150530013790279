import { useCallback } from "react";
import useStore from "../../store/store";

const useLandingPageContent = () =>
  useStore(
    useCallback(
      state =>
        state.content
          .map(bundle => bundle?.landingPageContent?.landingPageMessages?.[0] ?? null)
          .find(bundle => bundle !== null),
      []
    )
  );

export default useLandingPageContent;
