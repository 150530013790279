import { useEffect } from "react";
import shallow from "zustand/shallow";
import { validChannelsQuery } from "../../constants/applicationChannelTypes";
import { getLoginTargetCookie, getSimulatedLoginTargetCookie } from "../getCookies";
import useStore from "../../store/store";
import { KNOWN_PRODUCT_TYPES } from "../../constants/productTypes";

const isValidTargetCookie = loginTargetCookieObject => {
  // Sometimes users arrive at the site with a C1_TGT cookie from a different C1 domain.
  // We want to ensure this cookie has DAO-specific data before marking as authenticated.
  if (!loginTargetCookieObject) {
    console.log("C1_TGT is not present");
    return false;
  }
  const { applicationReferenceId, applicationChannel, PrfrdLanding, selectedProducts } =
    loginTargetCookieObject;
  if (!applicationReferenceId) {
    console.log("C1_TGT gave no applicationReferenceId");
    return false;
  }
  if (!applicationChannel) {
    console.log("C1_TGT gave no applicationChannel");
    return false;
  }
  if (!validChannelsQuery.includes(applicationChannel)) {
    console.log("C1_TGT gave an invalid applicationChannel:", applicationChannel);
    return false;
  }
  if (!PrfrdLanding?.includes("SBB_DAO")) {
    console.log("C1_TGT gave an invalid PrfrdLanding:", PrfrdLanding);
    return false;
  }
  if (!selectedProducts) {
    console.log("C1_TGT gave no selected products");
    return false;
  }
  if (!selectedProducts.split(",").every(product => KNOWN_PRODUCT_TYPES.includes(product))) {
    console.log("C1_TGT contains an invalid product type:", selectedProducts);
    return false;
  }
  return true;
};

const useLoginCookie = () => {
  const [
    addCookieParam,
    setHasProcessedLoginCookie,
    setOngoingApplication,
    setApplicationProductsSelected,
    setIsApplicantAuthenticated,
  ] = useStore(
    state => [
      state.addCookieParam,
      state.setHasProcessedLoginCookie,
      state.setOngoingApplication,
      state.setApplicationProductsSelected,
      state.setIsApplicantAuthenticated,
    ],
    shallow
  );

  useEffect(() => {
    // If a C1_TGT cookie was provided, attempt to use that.
    // If not, see if we provided our own simulated C1_TGT cookie for use across the rescoping boundary - see usePrefillHandler.
    const loginTargetCookieObject =
      getLoginTargetCookie(document.cookie) || getSimulatedLoginTargetCookie(document.cookie);
    // Note due to cookie scoping redirect this will be called multiple times. Once the pre-fill call succeeds, this cookie will be deleted
    if (isValidTargetCookie(loginTargetCookieObject)) {
      const { applicationReferenceId, applicationChannel, selectedProducts } = loginTargetCookieObject;
      console.log("C1_TGT gave applicationReferenceId:", applicationReferenceId);
      setOngoingApplication(applicationReferenceId);

      console.log("C1_TGT gave applicationChannel:", applicationChannel);
      addCookieParam("applicationChannel", applicationChannel);

      console.log("C1_TGT gave selectedProducts:", selectedProducts);
      setApplicationProductsSelected(selectedProducts.split(","));

      setIsApplicantAuthenticated();
    }

    // needed to prevent premature redirects
    setHasProcessedLoginCookie();
  }, [
    setHasProcessedLoginCookie,
    setIsApplicantAuthenticated,
    setOngoingApplication,
    addCookieParam,
    setApplicationProductsSelected,
  ]);
};

export default useLoginCookie;
