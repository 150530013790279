import styled from "styled-components";
import BlueCheckmark from "../../../../assets/blue-checkmark.svg";
import BlueComputer from "../../../../assets/blue-computer.svg";
import MoneyBag from "../../../../assets/money-bag.svg";
import Settings from "../../../../assets/settings.svg";
import mediaQuery from "../../../../utils/mediaQuery";
import {
  BUSINESS_BASIC_CHECKING,
  BUSINESS_UNLIMITED_CHECKING,
  convertProductTypeEnum,
} from "../../../../constants/productTypes";
import { SUCCESS_AND_FAILURE_MODE } from "../../../../constants/submitApplicationAndAccountStatuses";
import { CenteredRow, Col, Grid } from "../../../shared/Layout";
import Button from "../../../shared/Button";
import { useButtonId } from "../../../../utils/hooks/usePageScopedId";

const NextStepsWrapper = styled.div`
  width: 600px;

  margin-top: var(--grv-size-spacing-medium-2);
  @media (${mediaQuery.medium}) {
    margin-top: var(--grv-size-spacing-large-2);
  }
`;

const NextStepsHeading = styled.h3`
  padding: 0;
  margin-top: 0;
  font-weight: var(--grv-font-weight-semibold);

  font-size: var(--grv-size-font-medium-1);
  margin-bottom: var(--grv-size-spacing-medium-1);
  @media (${mediaQuery.medium}) {
    font-size: var(--grv-size-font-medium-3);
    margin-bottom: var(--grv-size-spacing-medium-2);
  }
`;

const NextStepsDescription = styled.p`
  padding: 0;
  margin-top: 0;

  margin-bottom: var(--grv-size-spacing-medium-1);
  font-size: var(--grv-size-font-small-1);
  font-weight: var(--grv-font-weight-normal);
  @media (${mediaQuery.medium}) {
    margin-bottom: var(--grv-size-spacing-medium-2);
    font-size: var(--grv-size-font-medium-2);
    font-weight: var(--grv-font-weight-light);
  }
`;

const NextStepsListItem = styled.li`
  list-style: none;
  background-image: url(${({ $iconUrl }) => $iconUrl});
  background-repeat: no-repeat;
  background-position: left top;

  padding-left: var(--grv-size-spacing-large-2);
  margin-bottom: var(--grv-size-spacing-medium-1);
  font-size: var(--grv-size-font-small-2);
  font-weight: var(--grv-font-weight-normal);
  /* custom line height needed to avoid icon clipping */
  line-height: 1.8;
  background-size: 24px;
  @media (${mediaQuery.medium}) {
    padding-left: var(--grv-size-spacing-large-3);
    margin-bottom: var(--grv-size-spacing-medium-2);
    font-size: var(--grv-size-font-medium-2);
    font-weight: var(--grv-font-weight-light);
    line-height: var(--grv-font-line-height-medium);
    background-size: 32px;
  }

  /* remove bottom margin from final list item */
  &:last-child {
    margin-bottom: 0;
  }

  /* style the additional info text within a list item */
  p {
    margin: 0;
    padding-top: var(--grv-size-spacing-small-2);
    font-weight: var(--grv-font-weight-normal);

    font-size: var(--grv-size-font-small-1);
    @media (${mediaQuery.medium}) {
      font-size: var(--grv-size-font-small-2);
    }
  }
`;

const ButtonRow = styled(CenteredRow)`
  flex-direction: column;
  display: flex;
  justify-content: center;
`;

const SetupButton = styled(Button).attrs({
  gravityType: "action",
})`
  padding-left: var(--grv-size-spacing-xlarge-1);
  padding-right: var(--grv-size-spacing-xlarge-1);

  margin-top: var(--grv-size-spacing-medium-2);
  margin-bottom: var(--grv-size-spacing-medium-2);
  @media (${mediaQuery.medium}) {
    margin-top: var(--grv-size-spacing-large-2);
    margin-bottom: var(--grv-size-spacing-large-2);
  }
`;

// Exported for unit testing
export const NextSteps = ({ treatment, collectiveAccountStatuses }) => {
  // array of valid checking products
  const checkingProducts = [BUSINESS_BASIC_CHECKING, BUSINESS_UNLIMITED_CHECKING];
  // see if a checking product was booked
  const checkingProductIsBooked =
    collectiveAccountStatuses.ACCOUNT_BOOKED?.some(product => checkingProducts.includes(product)) ?? false;

  return (
    <NextStepsWrapper id="nextSteps_list">
      <NextStepsHeading>Next Steps</NextStepsHeading>
      <NextStepsDescription>
        {/* we explicitly list the successfully booked product for SUCCESS_AND_FAILURE_MODE */}
        {treatment === SUCCESS_AND_FAILURE_MODE ? (
          <>
            <>
              Now that your Business {convertProductTypeEnum(collectiveAccountStatuses?.ACCOUNT_BOOKED?.[0])}®
              account has been opened, get it running in about 10 minutes. Accounts unfunded within 90 days
              will close.
            </>
          </>
        ) : (
          <>
            Now that your account(s) have been opened, get them running in about 10 minutes. Accounts unfunded
            within 90 days will close.
          </>
        )}
      </NextStepsDescription>
      {/* using a table here for semantic html reasons (makes this more understandable for screen readers) */}
      {/* eslint-disable-next-line */}
      <ul role="list" className="grv-padding__left--none grv-margin__top--none grv-margin__bottom--none">
        <NextStepsListItem $iconUrl={BlueCheckmark}>Apply for account(s)</NextStepsListItem>
        <NextStepsListItem $iconUrl={BlueComputer}>Set up or sign into your online account</NextStepsListItem>
        <NextStepsListItem $iconUrl={MoneyBag}>Add money to your account</NextStepsListItem>
        <NextStepsListItem $iconUrl={Settings}>
          Recommended onboarding activities
          {/* if no checking products are booked, we shouldn't tell them to order a debit card */}
          {checkingProductIsBooked ? (
            <p>
              Once you&apos;ve set up your account you can link an account, order a debit card, and invite an
              authorized signer
            </p>
          ) : (
            <p>
              Once you&apos;ve set up your account you can link an account and invite an authorized signer
            </p>
          )}
        </NextStepsListItem>
      </ul>
    </NextStepsWrapper>
  );
};

const NextStepsSection = ({ treatment, collectiveAccountStatuses, onSetupClick }) => (
  <Grid>
    <CenteredRow>
      <Col lg={12}>
        <CenteredRow>
          <NextSteps treatment={treatment} collectiveAccountStatuses={collectiveAccountStatuses} />
        </CenteredRow>
        <ButtonRow>
          <SetupButton id={useButtonId("approved", "setUpOnlineAccount")} onClick={onSetupClick}>
            Set up online account
          </SetupButton>
        </ButtonRow>
      </Col>
    </CenteredRow>
  </Grid>
);

export default NextStepsSection;
