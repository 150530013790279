import shallow from "zustand/shallow";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";
import * as yup from "yup";
import { Col, Row, Grid, CenteredRow, RelativePositionRow } from "../../shared/Layout";
import NavigationButtons from "../../shared/NavigationButtons";
import useStore from "../../../store/store";
import EligibilityNotice from "./EligibilityNotice";
import CommonTaxInfoFields, { useCommonTaxInfoFieldsSchema } from "./CommonTaxInfoFields";
import {
  INCORPORATED,
  INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC,
  UNINCORPORATED,
} from "../../../constants/businessTaxClassifications";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import SectionHeader from "../../shared/SectionHeader";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import useRouting from "../../../utils/hooks/useRouting";
import TaxClassificationSelect, {
  taxClassificationSchema,
  useDefaultTaxClassification,
} from "../../shared/TaxClassificationSelect";
import { LIMITED_LIABILITY_COMPANY } from "../../../constants/legalEntityTypes";
import { EIN } from "../../../constants/businessTaxIdTypes";
import NonProfitRadio, { useNonProfitRadioDefaultValues } from "../../shared/NonProfitRadio";
import UnsupportedBusinessTypesSection, {
  useUnsupportedBusinessTypesDefault,
  unsupportedBusinessTypesSchema,
} from "../../shared/UnsupportedBusinessTypesSection";

const baseLLCSchema = yup.object().shape({
  ...unsupportedBusinessTypesSchema,
  taxClassification: taxClassificationSchema,
});

const LLCTaxInfoForm = ({ areStateDocsFiled, hasMultipleBusinessOwners }) => {
  const [storedLegalName, storedTaxId, storedOwnershipType, storedBusinessTaxIdType, submitLLC] = useStore(
    state => [
      state.businessLegalName,
      state.businessTaxId,
      state.businessOwnershipType,
      state.businessTaxIdType,
      state.pageSubmit.submitLLC,
    ],
    shallow
  );

  // commonTaxInfoFields schema depends on store items, so we grab it from a hook here and use shape chaining to create the final schema for the page
  const commonTaxInfoFieldsValidation = useCommonTaxInfoFieldsSchema();
  const llcSchema = useMemo(
    () => baseLLCSchema.shape(commonTaxInfoFieldsValidation),
    [commonTaxInfoFieldsValidation]
  );

  const nonProfitRadioDefaultValues = useNonProfitRadioDefaultValues();
  const unsupportedBusinessTypesDefault = useUnsupportedBusinessTypesDefault();
  const defaultTaxClassification = useDefaultTaxClassification({
    ...nonProfitRadioDefaultValues,
    companyType: LIMITED_LIABILITY_COMPANY,
  });
  const formMethods = useForm({
    resolver: yupResolver(llcSchema),
    shouldFocusError: false,
    defaultValues: {
      taxClassification:
        // ensure that we don't use the stored tax classification when it was SMLLC
        // this isn't handled by the useDefaultTaxClassification hook
        defaultTaxClassification === INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC
          ? PLACEHOLDER_NONE
          : defaultTaxClassification,
      legalBusinessName: storedLegalName || "",
      // Check for SSN to avoid it being prefilled as an EIN
      ein: (storedBusinessTaxIdType === EIN && storedTaxId) || "",
      ownershipType: storedOwnershipType || PLACEHOLDER_NONE,
      ...nonProfitRadioDefaultValues,
      ...unsupportedBusinessTypesDefault,
    },
  });

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = formMethods;
  const navigate = useNavigate();
  const [backRoute, nextRoute] = useRouting();
  const isNonProfit = watch("isNonProfit");
  const submitHandler = useCallback(
    async ({
      taxClassification,
      legalBusinessName,
      ein,
      ownershipType,
      isUnsupportedBusinessType,
      isTrust,
    }) => {
      const { isEligibleCustomer, isEligibleBusiness } = await submitLLC({
        isUnsupportedBusinessType,
        isTrust,
        taxClassification,
        legalBusinessName,
        ein,
        ownershipType,
        isNonProfit,
        areStateDocsFiled,
        hasMultipleBusinessOwners,
      });
      if (!isEligibleCustomer || !isEligibleBusiness) {
        navigate("/cannot-proceed");
      } else {
        navigate(nextRoute);
      }
    },
    [nextRoute, submitLLC, navigate, isNonProfit, areStateDocsFiled, hasMultipleBusinessOwners]
  );
  const submitWithErrorHandling = useSubmitWithErrorHandling(submitHandler);

  // when isNonProfit switches, ensure that the tax classification is reset if needed
  useEffect(() => {
    if (!isNonProfit && [UNINCORPORATED, INCORPORATED].includes(getValues("taxClassification"))) {
      setValue("taxClassification", PLACEHOLDER_NONE);
    }
  }, [isNonProfit, getValues, setValue]);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
          <Grid>
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                {/* TODO: Change to sentence case when merging into HRBR feature branch */}
                <SectionHeader compact title="Business Type & Organization Status" />
              </Col>
            </CenteredRow>
            <Row>
              <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
                <UnsupportedBusinessTypesSection />
              </Col>
            </Row>
            <RelativePositionRow>
              <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
                <NonProfitRadio />
              </Col>
            </RelativePositionRow>
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                <SectionHeader compact title="Tax Information" />
              </Col>
            </CenteredRow>
          </Grid>
          <Grid>
            <EligibilityNotice
              id="llcEligibilityStatement"
              contentId="llcEligibilityStatement"
              bundleKey="llcEligibilityStatement"
            />
          </Grid>
          <Grid>
            <Row>
              <Col lg={5} md={5} offset={{ lg: 2 }} sm={4}>
                <TaxClassificationSelect
                  {...register("taxClassification")}
                  error={errors?.taxClassification?.message}
                  companyType={LIMITED_LIABILITY_COMPANY}
                  isNonProfit={isNonProfit}
                />
              </Col>
            </Row>
          </Grid>
          <CommonTaxInfoFields />
          <NavigationButtons backRoute={backRoute} nextLoading={isSubmitting} />
        </form>
      </FormProvider>
    </>
  );
};

export default LLCTaxInfoForm;
