import { forwardRef } from "react";
import {
  GENERAL_PARTNERSHIP,
  LIMITED_LIABILITY_PARTNERSHIP,
  LIMITED_PARTNERSHIP,
  partnershipTypesDisplay,
} from "../../../constants/partnershipTypes";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import Select from "../Select";
import { useDropdownId } from "../../../utils/hooks/usePageScopedId";

// Simple wrapper around Select that is populated with the options for partnership structure
// Forwards all props, including ref, to the underlying Select component.
// Defaults label prop to "What is your partnership structure?"
const PartnershipStructureSelect = forwardRef((props, ref) => (
  <Select id={useDropdownId(props.name)} label="What is your partnership structure?" ref={ref} {...props}>
    <option className="grv-select__placeholder" value={PLACEHOLDER_NONE} disabled>
      Select a partnership structure
    </option>
    <option value={GENERAL_PARTNERSHIP}>{partnershipTypesDisplay[GENERAL_PARTNERSHIP]}</option>
    <option value={LIMITED_LIABILITY_PARTNERSHIP}>
      {partnershipTypesDisplay[LIMITED_LIABILITY_PARTNERSHIP]}
    </option>
    <option value={LIMITED_PARTNERSHIP}>{partnershipTypesDisplay[LIMITED_PARTNERSHIP]}</option>
  </Select>
));

export default PartnershipStructureSelect;
