import { forwardRef } from "react";
import styled from "styled-components";
import mediaQuery from "../../../utils/mediaQuery";

export const Divider = styled.hr.attrs({
  "aria-hidden": "true",
})`
  margin: 7px 0 var(--grv-size-spacing-medium-2);
  border: 0;
  border-top: 1px solid var(--grv-color-digital-gray-15);
`;

// The compact field is added here because subsequent section headers on a page
// (as in, any section header after the first one) already have padding from the
// last input in the previous section, so we need to reduce how much additional
// spacing we add
const HeaderTitle = styled.h2`
  align-items: center;
  display: flex;
  font-size: var(--grv-size-font-medium-2);
  margin-top: ${({ $compact }) =>
    $compact ? `var(--grv-size-spacing-medium-2)` : `var(--grv-size-spacing-large-3)`};
  margin-bottom: 10px;
  font-weight: var(--grv-font-weight-semibold);
  color: var(--grv-color-digital-gray-120);

  @media (${mediaQuery.medium}) {
    margin-bottom: 1px;
  }
`;

const SectionHeader = forwardRef(({ title, className, compact = false }, ref) => (
  <>
    <HeaderTitle className={className} $compact={compact} ref={ref}>
      {title}
    </HeaderTitle>
    <Divider />
  </>
));

export const NoMarginSectionHeader = styled(SectionHeader)`
  margin-top: 0;
`;

export default SectionHeader;
