import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import shallow from "zustand/shallow";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import useStore from "../../../store/store";
import { CenteredRow, Col, Grid, Row } from "../../shared/Layout";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import NavigationButtons from "../../shared/NavigationButtons";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import { useBackRoute, useNextRouteProvider } from "../../../utils/hooks/useRouting";
import BusinessControllerTitleSelect, {
  businessControllerTitleSchema,
} from "../../shared/BusinessControllerTitleSelect";
import BusinessOwnershipFields, {
  hasNoBeneficialOwnersAttestationSchema,
  ownershipPercentageSchema,
} from "../../shared/BusinessOwnershipFields";

const ControllerAttestationRow = styled(CenteredRow)`
  margin-top: var(--grv-size-spacing-medium-2);
  margin-bottom: var(--grv-size-spacing-large-3);
`;

const getBCAttestation = content =>
  content
    .map(bundle => bundle?.uiDisclosures?.find(disc => disc?.disclosureId === "BCAttestation") ?? null)
    .find(bundle => bundle !== null)?.bodyText;

const getNoBOAttestation = content =>
  content
    .map(bundle => bundle?.uiDisclosures?.find(disc => disc?.disclosureId === "noBOAttestation") ?? null)
    .find(bundle => bundle !== null)?.bodyText;

const ownershipSchema = yup.object().shape({
  isNonProfit: yup.bool(),
  isApplicantBusinessOwner: yup.bool().when("isNonProfit", {
    is: false,
    then: schema =>
      schema.typeError("Select whether you are an owner").required("Select whether you are an owner"),
    otherwise: () => yup.mixed(), // allow anything, prevents null issue
  }),
  ownershipPercentage: ownershipPercentageSchema,
  businessControllerType: businessControllerTitleSchema,
  hasOtherOwners: yup.bool().when("isNonProfit", {
    is: false,
    then: schema =>
      schema
        .typeError("Select whether you have other owners that own 25% or more of the business")
        .required("Select whether you have other owners that own 25% or more of the business"),
    otherwise: () => yup.mixed(), // allow anything, prevents null issue
  }),
  hasNoBeneficialOwnersAttestation: hasNoBeneficialOwnersAttestationSchema,
});

const CommonBusinessOwnershipForm = () => {
  const navigate = useNavigate();
  const [
    storedControllerType,
    storedIsApplicantBusinessOwner,
    storedOwnershipPercentage,
    storedHasOtherOwners,
    storedHasNoBeneficialOwnersAttestation,
    isNonProfit,
    submitPage,
    bcAttestation,
    noBOAttestation,
  ] = useStore(
    state => [
      state.businessControllerType,
      state.isApplicantBusinessOwner,
      state.applicantOwnershipPercentage,
      state.hasOtherBeneficialOwners,
      state.hasNoBeneficialOwnersAttestation,
      state.isNonProfit,
      state.pageSubmit.submitBusinessOwnershipInfo,
      getBCAttestation(state.content),
      getNoBOAttestation(state.content),
    ],
    shallow
  );
  const formMethods = useForm({
    resolver: yupResolver(ownershipSchema),
    shouldFocusError: false,
    defaultValues: {
      isApplicantBusinessOwner: storedIsApplicantBusinessOwner, // explicitly allowing null
      ownershipPercentage: storedOwnershipPercentage ?? "",
      businessControllerType: storedControllerType ?? PLACEHOLDER_NONE,
      hasOtherOwners: storedHasOtherOwners, // explicitly allowing null
      hasNoBeneficialOwnersAttestation: storedHasNoBeneficialOwnersAttestation ?? false,
      isNonProfit,
    },
  });
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = formMethods;

  const backRoute = useBackRoute();
  const nextRouteProvider = useNextRouteProvider();

  const onSubmit = useCallback(
    async data => {
      await submitPage(data);
      navigate(
        nextRouteProvider({
          businessHasOtherBeneficialOwners: data.hasOtherOwners,
        })
      );
    },
    [submitPage, navigate, nextRouteProvider]
  );
  const submitWithErrorHandling = useSubmitWithErrorHandling(onSubmit);

  if (!bcAttestation || !noBOAttestation) {
    return <LoadingSpinnerPage />;
  }

  return (
    <form onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
      <Grid>
        <Row>
          <Col lg={3} md={3} offset={{ lg: 2 }} sm={4}>
            <BusinessControllerTitleSelect
              error={errors?.businessControllerType?.message}
              {...register("businessControllerType")}
            />
          </Col>
        </Row>
      </Grid>
      <FormProvider {...formMethods}>
        <BusinessOwnershipFields
          hideIsApplicantBusinessOwnerToggle={isNonProfit}
          hideHasOtherOwnersToggle={isNonProfit}
          noBOAttestation={noBOAttestation}
        />
      </FormProvider>
      <Grid>
        <ControllerAttestationRow justify="center">
          <Col lg={8} md={8} sm={4}>
            <span
              className="grv-text grv-text--small"
              id="businessOwnershipInfoSection_bcAttestation_Text"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: bcAttestation }}
            />
          </Col>
        </ControllerAttestationRow>
      </Grid>
      <NavigationButtons backRoute={backRoute} nextLoading={isSubmitting} />
    </form>
  );
};

export default CommonBusinessOwnershipForm;
