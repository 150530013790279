import styled from "styled-components";
import { Grid, Col, CenteredRow, RightRow } from "../../shared/Layout";
import Button from "../../shared/Button";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import useScrollToComponent from "../../../utils/hooks/useScrollToComponent";
import getContentReader from "../../../utils/getContentReader";
import { Divider } from "../../shared/SectionHeader";
import useApplicationChannel from "../../../utils/hooks/useApplicationChannel";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";

const HeroTitle = styled.h2`
  text-align: left;
  padding-bottom: 24px;
  margin-bottom: 0px;
`;

const SubHeaderText = styled.div.attrs({
  className: "grv-text grv-text__body--medium grv-weight--light",
})`
  text-align: left;
  margin-bottom: 32px;
`;

const FindABranchButton = styled(Button)`
  min-width: 100%;
`;

const handleFindABranchButtonClick = targetLink => {
  window.open(targetLink, "_blank");
};

const OtherBusinessFindBranchInfo = () => {
  const ref = useScrollToComponent();

  const applicationChannel = useApplicationChannel();
  const content = usePreSubmitTreatment("preSubmitFriction", "notSupportedLegalEntity");
  const buttonId = useButtonId("findABranch");

  if (!content || !applicationChannel) {
    return <LoadingSpinnerPage />;
  }

  const readContent = getContentReader(content);

  return (
    <Grid>
      <CenteredRow>
        <Col lg={8} md={8} sm={4}>
          <Divider />
        </Col>
      </CenteredRow>
      <CenteredRow>
        <Col lg={8} md={8} sm={4}>
          <HeroTitle
            ref={ref}
            className="grv-text__heading--large grv-margin__bottom--none"
            dangerouslySetInnerHTML={{ __html: readContent("headerText") }}
            aria-atomic="true"
            role="alert"
          />
        </Col>
      </CenteredRow>
      <CenteredRow>
        <Col lg={8} md={8} sm={4}>
          <SubHeaderText dangerouslySetInnerHTML={{ __html: readContent("bodyText") }} />
        </Col>
      </CenteredRow>
      <CenteredRow>
        <Col lg={8} md={8} sm={8}>
          <RightRow>
            <Col lg={3} md={3} sm={4}>
              <FindABranchButton
                id={buttonId}
                gravityType="action"
                onClick={() => handleFindABranchButtonClick(readContent("buttonLinkTarget"))}
              >
                {readContent("buttonText")}
              </FindABranchButton>
            </Col>
          </RightRow>
        </Col>
      </CenteredRow>
    </Grid>
  );
};

export default OtherBusinessFindBranchInfo;
