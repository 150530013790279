import ReactModal from "react-modal";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";
import Button from "../../shared/Button";

const GettingStartedInfoModal = ({
  idBase,
  isOpen,
  onContinue,
  showTimeToComplete,
  headingText = "Let's get started on your application",
}) => (
  <ReactModal
    ariaHideApp
    preventScroll
    shouldFocusAfterRender
    aria={{
      labelledby: `${idBase}ModalHeading`,
      describedby: `${idBase}ModalDescription`,
    }}
    bodyOpenClassName={null}
    className="grv-dialog grv-dialog--active"
    contentLabel={headingText}
    htmlOpenClassName={null}
    id={`${idBase}Modal`}
    isOpen={isOpen}
    overlayClassName="grv-dialog__overlay grv-dialog__overlay--dark"
    portalClassName="grv-portal"
    role="dialog"
  >
    <div className="grv-dialog__container">
      <h1 id={`${idBase}ModalHeading`} className="grv-dialog__heading grv-text__heading--large">
        {headingText}
      </h1>
      <div id={`${idBase}ModalDescription`} data-testid="debugElement" className="grv-dialog__content">
        <>
          <span>To be eligible to open your account online you must:</span>
          <ul>
            <li>Have an SSN and EIN (if applicable)</li>
          </ul>
          <span>
            After submission, some applications may require further review. <strong>Do not proceed</strong> if
            this business currently has a Small Business bank account application in review with Capital One.
          </span>
          {showTimeToComplete && (
            <p className="grv-margin__bottom--none">
              This usually takes around <strong>12 minutes</strong> to complete.
            </p>
          )}
        </>
      </div>
      <div className="grv-dialog__buttons grv-margin__top--normal">
        <Button id={useButtonId(`${idBase}ModalContinue`)} onClick={onContinue}>
          Continue
        </Button>
      </div>
    </div>
  </ReactModal>
);

export default GettingStartedInfoModal;
