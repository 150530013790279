import shallow from "zustand/shallow";
import useStore from "../../../store/store";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import Heading from "../../shared/Heading";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import Page from "../../shared/Page";
import SectionHeader from "../../shared/SectionHeader";
import CommonBusinessOwnershipInfo from "./CommonBusinessOwnershipForm";
import SingleMemberLLCBusinessOwnershipInfo from "./SingleMemberLLCBusinessOwnershipForm";

const BusinessOwnershipInfo = () => {
  usePageTitle("Business Ownership");
  usePreventNavigation();

  const headingRef = useFocusHeading();
  const [isSingleMemberLLC, isNonProfit] = useStore(
    state => [state.isSingleMemberLLC, state.isNonProfit],
    shallow
  );

  return (
    <Page>
      <Grid ref={headingRef}>
        <Heading
          step="SECTION 3 OF 4"
          mainHeading={isNonProfit ? "Tell us about your controller" : "Tell us about your ownership"}
          subHeading={
            // eslint-disable-next-line no-nested-ternary
            isNonProfit
              ? "We need to collect information about your company and its controller."
              : isSingleMemberLLC
              ? "We need to collect information about your company and ownership."
              : "We need to collect information about your company and its owners."
          }
        />
        <CenteredRow>
          <Col lg={8} md={8} sm={4}>
            <SectionHeader
              title={
                isNonProfit
                  ? "Business controller information"
                  : "Ownership and business controller information"
              }
            />
          </Col>
        </CenteredRow>
      </Grid>
      {isSingleMemberLLC ? <SingleMemberLLCBusinessOwnershipInfo /> : <CommonBusinessOwnershipInfo />}
    </Page>
  );
};

export default BusinessOwnershipInfo;
