import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const buildIdFromComponents = (...components) => components.join("_");

const usePageScopedId = (base, ...extraComponents) => {
  const location = useLocation();

  // Convert the first part of the current pathname to a camel-cased page name
  // ex: /business-services/0 -> businessServices
  const camelCasedRoute = useMemo(
    () =>
      location.pathname
        // get the first part of the path
        .split("/")[1]
        // match all dashes followed by letters, replacing them with the capital letter
        ?.replace(/(?:-[^-])/g, m => m.slice(1).toLocaleUpperCase()),
    [location]
  );

  return buildIdFromComponents(camelCasedRoute ?? "pageName", base, ...extraComponents);
};

export const useFieldId = (fieldName, ...extraComponents) => {
  // Take only the last dotted part of a field name
  // ex: outsideUSInfo.countryOfHeadquarters -> countryOfHeadquarters
  // This makes the IDs more readable, even if the hook form has a nested structure
  const fieldNamePart = useMemo(() => fieldName.split(".").at(-1), [fieldName]);
  return usePageScopedId(fieldNamePart, ...extraComponents);
};

export const useInputId = (...args) => useFieldId(...args, "Input");

export const useDropdownId = (...args) => useFieldId(...args, "Dropdown");

export const useCheckboxId = (...args) => useFieldId(...args, "Checkbox");

export const useButtonId = (...args) => usePageScopedId(...args, "Button");

export const useLinkId = (...args) => usePageScopedId(...args, "Link");

export default usePageScopedId;
