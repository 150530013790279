import styled, { createGlobalStyle } from "styled-components";

const ConfettiAnimationStyle = createGlobalStyle`
  @keyframes animateConfetti {
    0% {
      transform: translateY(0);
      opacity: 0;
    }
    10% {
      transform: translateY(-50%);
      opacity: 0.5;
    }
    15% {
      transform: translateY(-750%);
      opacity: 1;
    }
    25% {
      transform: translateY(-1200%);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 0;
    }
  }
`;

const ConfettiContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  overflow: hidden;
`;

// colors consist of Core Blue and Accent Light Blue variants from Gravity library
// some values are repeated to provide a heavier weight to the display of lighter colors based on design feedback
const confettiColors = [
  "#013D5B",
  "#B3CDDE",
  "8AB0C7",
  "598AAA",
  "#255F82",
  "#10253F",
  "#00132B",
  "#00C0E5",
  "#C0E7F3",
  "#9FDBEF",
  "#78D0EB",
  "#4BC7E7",
  "#00AFD4",
  "#00A3C4",
  "#007F9B",
  "#00C0E5",
  "#00C0E5",
  "#00C0E5",
  "#00C0E5",
  "#00C0E5",
  "#00C0E5",
  "#00C0E5",
];

const createConfettiPiece = key => {
  // we use these values to handle case of displaying a static confetti image instead of an animation
  const prefersReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches;
  const verticalOffset = Math.random() * 90;

  // we use these values to randomize the confetti size, placement, and color
  const leftOffset = Math.random() * 100;
  const radius = Math.random() * 5 + 2;
  const color = confettiColors[Math.floor(Math.random() * confettiColors.length)];

  // we use these values to randomize the confetti animation timing and duration
  const delay = 0.5 + Math.random() * 2;
  const animationLength = 2 + Math.random() * 2;

  // we use these objects to randomize the confetti shape
  const squareConfetti = {
    width: `${radius}px`,
    height: `${radius}px`,
    backgroundColor: `${color}`,
  };
  const circleConfetti = {
    width: `${radius}px`,
    height: `${radius}px`,
    backgroundColor: `${color}`,
    borderRadius: "50%",
  };
  const triangleConfetti = {
    width: "0",
    height: "0",
    borderLeft: `${radius / 2}px solid transparent`,
    borderRight: `${radius / 2}px solid transparent`,
    borderBottom: `${(radius / 2) * 2}px solid ${color}`,
  };

  // we randomly pick one of the three basic confetti shapes
  const shapeOptions = [squareConfetti, circleConfetti, triangleConfetti];
  const shape = shapeOptions[Math.floor(Math.random() * shapeOptions.length)];

  // we favor this approach over styled components w/ styled props since we are generating so many confetti pieces
  // and this would create too many separate styled components to the point where rendering speed is negatively impacted
  const confettiStyling = {
    ...shape, // then spread this object to include relevant values to establish the confetti shape
    left: `${leftOffset}%`,
    animation: prefersReducedMotion ? "none" : `animateConfetti ${animationLength}s linear forwards`,
    animationDelay: `${delay}s`,
    position: "absolute",
    bottom: prefersReducedMotion ? `${verticalOffset}%` : "0",
    opacity: prefersReducedMotion ? "100%" : "0",
    willChange: "transform, opacity",
  };

  return <div key={key} style={confettiStyling} />;
};

const ConfettiBanner = ({ id, numPieces = 50 }) => {
  const confettiPieces = Array.from({ length: numPieces }, (_, index) => createConfettiPiece(index));

  return (
    <>
      <ConfettiAnimationStyle />
      <ConfettiContainer id={id}>{confettiPieces}</ConfettiContainer>
    </>
  );
};

export default ConfettiBanner;
