import { forwardRef } from "react";
import combineStrings from "../../../utils/combineStrings";

const Button = forwardRef(
  ({ children, className = "", type = "button", loading = false, gravityType = null, ...rest }, ref) => {
    const gravTypeClass = `grv-button--${gravityType}`;
    const allClasses = combineStrings("grv-button", className, {
      [gravTypeClass]: gravityType,
      "grv-button--loading": loading,
      "grv-button--loading-active": loading,
    });

    return (
      // eslint-disable-next-line react/button-has-type
      <button type={type} className={allClasses} disabled={loading} ref={ref} {...rest}>
        {children}
      </button>
    );
  }
);

export default Button;
