// TINs blocklisted by Capstone
// See: https://docs.google.com/document/d/1x22c7Uhg2UQJvGZ-n-WE6ymAoAaDqkhPzfLFnSLiuaY/edit#heading=h.ux3ytkacb2bs
export const blockedTINs = [
  "000000000",
  "000000001",
  "000000008",
  "000000009",
  "000002323",
  "000002370",
  "000002402",
  "000002403",
  "000002429",
  "000002431",
  "000002434",
  "000002445",
  "000002451",
  "000002456",
  "000002483",
  "000002485",
  "000002876",
  "000008888",
  "000121475",
  "001010001",
  "004545454",
  "006316036",
  "010010001",
  "010010101",
  "010101010",
  "010101010",
  "010203040",
  "011122333",
  "012345678",
  "022281852",
  "027399000",
  "028999000",
  "040404040",
  "042103580",
  "045000000",
  "055000000",
  "062360749",
  "065000000",
  "075000000",
  "077952576",
  "078051120",
  "090909090",
  "095073645",
  "099999999",
  "100000000",
  "100101000",
  "101010101",
  "111111111",
  "111111111",
  "111119999",
  "111223333",
  "121212121",
  "121212121",
  "123121234",
  "123123123",
  "123456788",
  "123456789",
  "123456789",
  "128036045",
  "135016629",
  "135307895",
  "141186941",
  "141616926",
  "165167999",
  "165187999",
  "165207999",
  "165227999",
  "165247999",
  "189092294",
  "206107109",
  "212097694",
  "212121212",
  "219099999",
  "222222222",
  "222222222",
  "222335555",
  "234567890",
  "237002540",
  "261468765",
  "306302349",
  "308125070",
  "333333333",
  "333333333",
  "360299570",
  "362167731",
  "362835106",
  "363257440",
  "393939393",
  "404040404",
  "444444444",
  "444444444",
  "454545454",
  "468288779",
  "470144447",
  "511111111",
  "545454545",
  "548603388",
  "549241889",
  "555111111",
  "555121212",
  "555121234",
  "555555555",
  "555555555",
  "650000000",
  "666666666",
  "666666666",
  "750000000",
  "750000000",
  "777777777",
  "777777777",
  "888888888",
  "888888888",
  "930790615",
  "987654320",
  "987654321",
  "987654322",
  "987654323",
  "987654324",
  "987654325",
  "987654326",
  "987654327",
  "987654328",
  "987654329",
  "999990001",
  "999999999",
  "999999999",
];

export const ssnRejectPatterns = [
  /^666/, // starts with 666
  /^000/, // starts with 000
  /0000$/, // ends with 0000
  /^.{3}00.{4}$/, // XXX-00-XXXX
  /[^0-9]/, // non-digit or dash
];

// EIN patterns pulled from here:
// https://docs.google.com/document/d/1x22c7Uhg2UQJvGZ-n-WE6ymAoAaDqkhPzfLFnSLiuaY/edit#heading=h.ux3ytkacb2bs
export const invalidEINPatterns = [
  // cannot start with any of the following
  /^00/,
  /^07/,
  /^08/,
  /^09/,
  /^17/,
  /^18/,
  /^19/,
  /^28/,
  /^29/,
  /^49/,
  /^69/,
  /^70/,
  /^78/,
  /^79/,
  /^89/,
  /^96/,
  /^97/,
  /[^0-9]/, // non-digit
];
