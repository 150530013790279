/* eslint-disable no-underscore-dangle */
import { useEffect, useRef } from "react";
import cuid from "cuid";
import { collectDFP, deviceFingerPrint } from "@c1/risk-browser-fingerprint";
import useStore from "../../store/store";
import { sendErrorToNewRelic, sendPageActionToNewRelic } from "../newRelic";
import { getCookie } from "../getCookies";

const DFP_ENDPOINT_QA = "https://deviceinfo-it.capitalone.com/collector";
const DFP_ENDPOINT_PROD = "https://deviceinfo.capitalone.com/collector";

const generateAndStoreTidCookie = () => {
  const transactionId = `STD_${cuid()}`;

  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);
  document.cookie = `c1_ubatid=${transactionId}; expires=${date.toUTCString()}`;

  return transactionId;
};

// Adapted from this Angular Script: https://github.cloud.capitalone.com/identity-services-common/web-identity-experience/blob/b3d9c406ba5551d71db0d7b1083d0c94650bb487/libs/shared/identity-ui/common-services/src/lib/risk-assessment.service.ts#L53
// This function retrieves the 3rd-party accertify script, and appends it to our DOM in order to collect user data.
// Exported for testing
export const loadAccertifyDFP = () => {
  try {
    // The Transaction ID is a key that will later be used to store and retrieve the fingerprint data from the risk assessment backend
    // Ideally, we want a fraudster submitting multiple apps to have the same transactionId, hence why we save/retrieve a cookie here
    const transactionId = getCookie(document.cookie, "c1_ubatid") || generateAndStoreTidCookie();

    const collectorEndpoint = window.UI_ENV === "prod" ? DFP_ENDPOINT_PROD : DFP_ENDPOINT_QA;

    // Setup the 'cc' object, which is used by the accertify script
    // The variables and values here are not well-documented, but we've been approved to use them as-is
    window.cofdfp = window.cofdfp || {};
    window.cofdfp._cc = window.cofdfp._cc || []; // _cc CANNOT be renamed, used by the accertify script
    const cc = window.cofdfp._cc;
    const ci = {
      sid: "b57e9686e0bc24f0", // Some constant needed for accertify script - used across all C1 apps
      tid: transactionId,
    };
    cc.push(["ci", ci]);
    cc.push(["st", 500]); // Some constant needed for accertify script - used across all C1 apps
    cc.push(["cf", 334835]); // Some constant needed for accertify script - used across all C1 apps
    cc.push(["run", collectorEndpoint]);

    // Setup a script element that will pull the accertify script from the backend
    const accertifyScript = document.createElement("script");
    accertifyScript.type = "text/javascript";
    accertifyScript.async = true;
    accertifyScript.src = `${collectorEndpoint}/cc.js?tid=${transactionId}&namespace=cofdfp`; // GET call, retrieves the accertify script

    accertifyScript.onerror = () => {
      // onError only captures errors during the initial load/setup of the script tag
      // this does not capture errors that occur while the accertify script is run
      sendErrorToNewRelic("Error occurred while attempting to load Accertify script");
    };
    accertifyScript.onload = () => {
      sendPageActionToNewRelic("Accertify script successfully loaded");
    };

    // Append script element to the top of the <head> in the DOM
    const firstScriptInDocument = document.getElementsByTagName("script")[0];
    firstScriptInDocument.parentNode.insertBefore(accertifyScript, firstScriptInDocument);

    // Immediately return transactionId without waiting for script to load
    // The transactionId doesn't depend on the script succeeding in order to be sent downstream
    return transactionId;
  } catch (error) {
    sendErrorToNewRelic(`Error occurred while setting up the accertify script`, error);
    return null;
  }
};

// This function calls the accertify script above AND calls a separate, c1-owned library that also
// collects data about the users's device. These are then organized into the userDeviceData object that we send downstream.
export const collectUserDeviceData = () => {
  const rawDeviceFingerprintData = deviceFingerPrint(); // Example raw data returned: https://github.cloud.capitalone.com/gist/anonymous/54723e978b2f70f8e27dcb94ccdeccc2#file-sample-device-fingerprint  const c1DeviceFingerPrint = collectDFP();
  const transactionId = loadAccertifyDFP();

  const userDeviceData = {
    transactionId,
    deviceFingerPrint: collectDFP(),
    trueBrowser: rawDeviceFingerprintData?.trueBrowser,
    browserMajorVersion: rawDeviceFingerprintData?.browser?.majorVersion,
    operatingSystem: rawDeviceFingerprintData?.system?.operatingSystem,
    operatingSystemVersion: rawDeviceFingerprintData?.system?.osVersion,
    platform: rawDeviceFingerprintData?.system?.platform,
  };

  return userDeviceData;
};

// The actual hook - ensures this device collection is only performed once, and then saved to the store
export const useCollectDeviceData = () => {
  const userDeviceDataCollectedRef = useRef(false);
  const setUserDeviceData = useStore(state => state.setUserDeviceData);

  useEffect(() => {
    if (!userDeviceDataCollectedRef.current) {
      userDeviceDataCollectedRef.current = true;
      try {
        const userDeviceData = collectUserDeviceData();
        setUserDeviceData(userDeviceData);
      } catch (error) {
        sendErrorToNewRelic("Unknown error occurred while collecting user device data", error);
      }
    }
  }, [setUserDeviceData]);
};
