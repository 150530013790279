import { forwardRef } from "react";
import styled from "styled-components";
import combineStrings from "../../../utils/combineStrings";

const viewportSizes = ["xs", "sm", "md", "ml", "lg", "xl", "ds"];
const viewportFallbacks = {
  ds: "lg",
  xl: "lg",
  ml: "md",
  xs: "sm",
};
// Given a source object (with keys that are viewport sizes),
// this will pair each viewport name with its value in that object and,
// if a viewport has no value in the object, it will try to use the
// fallback associations above to pull the value for a different viewport.
// E.g., passing { sm: 1 } will end up returning
// [["xs", 1], ["sm", 1], ["md", undefined], ["ml", undefined], ...]
// This is useful for generating the correct column classes without needing
// to provide special xs, ml, and xl cases when they are the same as sm, md, and lg respectively
const getViewportValuePairs = src => viewportSizes.map(vp => [vp, src?.[vp] || src?.[viewportFallbacks[vp]]]);

export const Grid = forwardRef(({ children, className = "", ...rest }, ref) => (
  <div className={`grv-grid ${className}`} ref={ref} {...rest}>
    {children}
  </div>
));

export const Row = forwardRef(({ children, className = "", ...rest }, ref) => (
  <div className={`grv-row ${className}`} ref={ref} {...rest}>
    {children}
  </div>
));

const centeredRowClasses = viewportSizes.map(vp => `grv-row--${vp}-center`).join(" ");

export const CenteredRow = forwardRef(({ children, className = "", ...rest }, ref) => (
  <Row className={`${className} ${centeredRowClasses}`} ref={ref} {...rest}>
    {children}
  </Row>
));

const rightRowClasses = viewportSizes.map(vp => `grv-row--${vp}-right`).join(" ");

export const RightRow = forwardRef(({ children, className = "", ...rest }, ref) => (
  <Row className={`${className} ${rightRowClasses}`} ref={ref} {...rest}>
    {children}
  </Row>
));

// This is a styled Row that has its position set to relative, which is frequently used for fields with tooltips to handle the absolute positioning of that tooltip on certain screen
// sizes, since it will create a new containing block. If this is used inside a Grid it will keep those absolutely positioned elements centered horizontally in the Grid, and more
// importantly those elements will be contained within the horizontal space of the Grid (and will not need to worry about the external page margins).
// Be careful using this to contain any other absolutely positioned elements, or in situations where the Row itself needs to be positioned with inset properties.
export const RelativePositionRow = styled(Row)`
  position: relative;
`;

export const Col = forwardRef(({ children, className = "", offset = null, order = null, ...rest }, ref) => (
  <div
    className={combineStrings(
      "grv-col",
      className,
      Object.fromEntries(
        getViewportValuePairs(rest).map(([vp, value]) => [`grv-col--${vp}-${value}`, value])
      ),
      Object.fromEntries(
        getViewportValuePairs(offset).map(([vp, value]) => [`grv-col--${vp}-offset-${value}`, value])
      ),
      Object.fromEntries(
        getViewportValuePairs(order).map(([vp, value]) => [`grv-col--${vp}-order-${value}`, value])
      )
    )}
    ref={ref}
    {...Object.fromEntries(Object.entries(rest).filter(([k]) => !viewportSizes.includes(k)))}
  >
    {children}
  </div>
));

export const Wrapper = forwardRef(({ children, className = "", ...rest }, ref) => (
  <div className={`grv-wrapper ${className}`} ref={ref} {...rest}>
    {children}
  </div>
));

export const Card = forwardRef(({ children, className = "", headingText = "", ...rest }, ref) => (
  <div className={`grv-card ${className}`} ref={ref} {...rest}>
    <div className="grv-card__content">
      {headingText && <h2 className="grv-card__heading">{headingText}</h2>}
      <div className="grv-card__body">{children}</div>
    </div>
  </div>
));

export const CardWithoutShadow = styled.div.attrs({
  className: "grv-card",
})`
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
`;
