import shallow from "zustand/shallow";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import * as yup from "yup";
import { Col, Row, Grid, CenteredRow } from "../../shared/Layout";
import NavigationButtons from "../../shared/NavigationButtons";
import useStore from "../../../store/store";
import EligibilityNotice from "./EligibilityNotice";
import CommonTaxInfoFields, { useCommonTaxInfoFieldsSchema } from "./CommonTaxInfoFields";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import SectionHeader from "../../shared/SectionHeader";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import useRouting from "../../../utils/hooks/useRouting";
import useScrollToComponent from "../../../utils/hooks/useScrollToComponent";
import { SingleMemberLLCTaxClassification } from "../../shared/TaxClassificationSelect";
import { INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC } from "../../../constants/businessTaxClassifications";
import { EIN } from "../../../constants/businessTaxIdTypes";
import UnsupportedBusinessTypesSection, {
  unsupportedBusinessTypesSchema,
  useUnsupportedBusinessTypesDefault,
} from "../../shared/UnsupportedBusinessTypesSection";

const baseSingleMemberLLCSchema = yup.object().shape({
  ...unsupportedBusinessTypesSchema,
});

const SingleMemberLLCTaxInfoForm = ({ areStateDocsFiled, hasMultipleBusinessOwners }) => {
  const [storedLegalName, storedTaxId, storedOwnershipType, storedBusinessTaxIdType, submitLLC] = useStore(
    state => [
      state.businessLegalName,
      state.businessTaxId,
      state.businessOwnershipType,
      state.businessTaxIdType,
      state.pageSubmit.submitLLC,
    ],
    shallow
  );

  // commonTaxInfoFields schema depends on store items, so we grab it from a hook here and use shape chaining to create the final schema for the page
  const commonTaxInfoFieldsValidation = useCommonTaxInfoFieldsSchema();
  const singleMemberLLCSchema = useMemo(
    () => baseSingleMemberLLCSchema.shape(commonTaxInfoFieldsValidation),
    [commonTaxInfoFieldsValidation]
  );

  const unsupportedBusinessTypesDefault = useUnsupportedBusinessTypesDefault();
  const formMethods = useForm({
    resolver: yupResolver(singleMemberLLCSchema),
    shouldFocusError: false,
    defaultValues: {
      ...unsupportedBusinessTypesDefault,
      legalBusinessName: storedLegalName || "",
      // Check for SSN to avoid it being prefilled as an EIN
      ein: (storedBusinessTaxIdType === EIN && storedTaxId) || "",
      ownershipType: storedOwnershipType || PLACEHOLDER_NONE,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;
  const navigate = useNavigate();
  const [backRoute, nextRoute] = useRouting();
  const submitHandler = useCallback(
    async ({ isUnsupportedBusinessType, isTrust, legalBusinessName, ein, ownershipType }) => {
      const { isEligibleCustomer, isEligibleBusiness } = await submitLLC({
        isUnsupportedBusinessType,
        isTrust,
        taxClassification: INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC,
        legalBusinessName,
        ein,
        ownershipType,
        isNonProfit: false,
        areStateDocsFiled,
        hasMultipleBusinessOwners,
      });
      if (!isEligibleCustomer || !isEligibleBusiness) {
        navigate("/cannot-proceed");
      } else {
        navigate(nextRoute);
      }
    },
    [nextRoute, submitLLC, navigate, areStateDocsFiled, hasMultipleBusinessOwners]
  );
  const submitWithErrorHandling = useSubmitWithErrorHandling(submitHandler);
  const headerRef = useScrollToComponent();

  return (
    <>
      <Grid>
        <CenteredRow>
          <Col lg={8} md={8} sm={4}>
            {/* TODO: Change to sentence case when merging into HRBR feature branch */}
            <SectionHeader compact ref={headerRef} title="Business Type" />
          </Col>
        </CenteredRow>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
            <Row>
              <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
                <UnsupportedBusinessTypesSection />
              </Col>
            </Row>
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                <SectionHeader compact title="Tax Information" />
              </Col>
            </CenteredRow>
            <EligibilityNotice
              id="llcEligibilityStatement"
              contentId="llcEligibilityStatement"
              bundleKey="llcEligibilityStatement"
            />
            <Row>
              <Col lg={5} md={5} offset={{ lg: 2 }} sm={4}>
                <SingleMemberLLCTaxClassification />
              </Col>
            </Row>
            <CommonTaxInfoFields />
            <NavigationButtons backRoute={backRoute} nextLoading={isSubmitting} />
          </form>
        </FormProvider>
      </Grid>
    </>
  );
};

export default SingleMemberLLCTaxInfoForm;
