import * as yup from "yup";
import entitySuffixes from "../constants/entitySuffixes";

const doingBusinessNameSchema = yup
  .string()
  .notRequired()
  // have to do this instead of min(2) to allow the empty string case
  .test("length", "Enter a Doing Business As Name more than 1 letter", v => v.length === 0 || v.length >= 2)
  .max(36, "Your Doing Business As Name cannot be longer than 36 letters")
  .matches(/^((?!(\d[\s.-]*){9}).)*$/, {
    excludeEmptyString: true,
    message:
      "Avoid entering sensitive information such as Business Tax ID Numbers or Social Security Numbers",
  })
  .matches(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/, {
    message: "Enter a Doing Business As Name more than 1 letter",
    excludeEmptyString: true,
  })
  .test(
    "entitySuffixCheck",
    "We cannot accept suffixes in the DBA name. Make sure the DBA name does not include a suffix.",
    value => !entitySuffixes.some(suffix => value.toLowerCase().endsWith(suffix.toLowerCase()))
  );

export default doingBusinessNameSchema;
