import { useCallback, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import shallow from "zustand/shallow";
import { ReactComponent as Megaphone } from "@c1/gravity-icons/dist/svg/ui-filled-megaphone-1-24.svg";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import Heading from "../../shared/Heading";
import SectionHeader from "../../shared/SectionHeader";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import HiddenAlert from "../../shared/HiddenAlert";
import Page from "../../shared/Page";
import Banner from "../../shared/Banner";
import useStore from "../../../store/store";
import { BooleanRadioListInput } from "../../shared/RadioList";
import {
  SOLE_PROPRIETORSHIP,
  LIMITED_LIABILITY_COMPANY,
  PARTNERSHIP,
  CORPORATION,
  OTHER,
  convertLegalEntityEnumToDisplay,
} from "../../../constants/legalEntityTypes";
import OtherBusinessFindBranchInfo from "./OtherBusinessFindBranchInfo";
import CorporationTaxInfoForm from "./CorporationTaxInfoForm";
import PartnershipTaxInfoForm from "./PartnershipTaxInfoForm";
import SolePropTaxInfoForm from "./SolePropTaxInfoForm";
import SingleMemberLLCTaxInfoForm from "./SingleMemberLLCTaxInfoForm";
import LLCTaxInfoForm from "./LLCTaxInfoForm";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import { INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC } from "../../../constants/businessTaxClassifications";
import CompanyTypeRadios from "../../shared/CompanyTypeRadios";

const taxInfoFormLookup = {
  [SOLE_PROPRIETORSHIP]: SolePropTaxInfoForm,
  [INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC]: SingleMemberLLCTaxInfoForm,
  [LIMITED_LIABILITY_COMPANY]: LLCTaxInfoForm,
  [PARTNERSHIP]: PartnershipTaxInfoForm,
  [CORPORATION]: CorporationTaxInfoForm,
  [OTHER]: OtherBusinessFindBranchInfo,
};

const RelativePositionCenteredRow = styled(CenteredRow)`
  position: relative;
`;

const LegalEntity = () => {
  usePageTitle("Company Type");
  usePreventNavigation();
  const headingRef = useFocusHeading();

  const [
    storedCompanyType,
    storedIsSingleMemberLLC,
    isApplicantPrefilled,
    hasNoEligibleExistingBusinesses,
    storedAreStateDocsFiled,
    storedHasMultipleBusinessOwners,
  ] = useStore(
    state => [
      state.companyType,
      state.isSingleMemberLLC,
      state.isApplicantPrefilled,
      state.existingCustomerEligibleBusinesses.length === 0,
      state.areStateDocsFiled,
      state.hasMultipleBusinessOwners,
    ],
    shallow
  );

  const isInitialMount = useRef(true);
  const [areStateDocsFiled, setAreStateDocsFiled] = useState(storedAreStateDocsFiled);
  const [hasMultipleBusinessOwners, setHasMultipleBusinessOwners] = useState(storedHasMultipleBusinessOwners);
  const [companyTypeSelected, setCompanyTypeSelected] = useState(
    // for SMLLC, navigating back to this page needs to re-select SMLLC
    storedIsSingleMemberLLC ? INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC : storedCompanyType
  );

  const onLegalEntitySelectionChange = useCallback(({ target }) => {
    setCompanyTypeSelected(target.value);
  }, []);

  useEffect(() => {
    // we don't want to reset the company type selection on the initial mount, since it could be restoring from state (user went back to this page)
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // we want to reset the company type selection when the user changes their answers to either of the 2 filtering questions
      setCompanyTypeSelected("");
    }
  }, [areStateDocsFiled, hasMultipleBusinessOwners]);

  const displayCompanyTypeSection = areStateDocsFiled !== null && hasMultipleBusinessOwners !== null;
  const displayBusinessTaxInfoSection = companyTypeSelected !== "";
  const displayHiddenAlert = companyTypeSelected !== "" && companyTypeSelected !== OTHER;
  const TaxInfoSection = taxInfoFormLookup[companyTypeSelected];

  return (
    <Page>
      {displayCompanyTypeSection && (
        <HiddenAlert>
          Select your company type below. The company type options were generated based on your selections to
          the “company type identification” questions. If your company type is not included, ensure you
          answered these questions correctly.
        </HiddenAlert>
      )}
      {displayHiddenAlert && (
        <HiddenAlert>
          Fill out the associated form fields below for your{" "}
          {convertLegalEntityEnumToDisplay(companyTypeSelected)}
        </HiddenAlert>
      )}
      <Grid ref={headingRef}>
        <Heading
          step="SECTION 2 OF 4"
          mainHeading="Tell us about your company type"
          subHeading={
            isApplicantPrefilled && hasNoEligibleExistingBusinesses
              ? null
              : "Tell us about your company type to view account opening options."
          }
          margin="large"
        />
        {isApplicantPrefilled && hasNoEligibleExistingBusinesses && (
          <CenteredRow className="grv-margin__top--large-3">
            <Col lg={8} md={8} sm={4}>
              <Banner
                bannerText="There are no eligible businesses associated with your personal account. Tell us about your company type to view account opening options for a new business."
                bannerIcon={<Megaphone alt="" role="presentation" />}
              />
            </Col>
          </CenteredRow>
        )}
      </Grid>
      <Grid>
        <CenteredRow>
          <Col lg={8} md={8} sm={4}>
            <SectionHeader title="Company type identification" />
          </Col>
        </CenteredRow>
        <CenteredRow>
          <Col lg={8} md={8} sm={4}>
            <BooleanRadioListInput
              name="areStateDocsFiled"
              legendText="Did you or someone on your behalf file documents with the state to establish your business (e.g., Articles of Organization, Certificate of Formation, Articles of Incorporation, Corporate Charter)?"
              value={areStateDocsFiled}
              onChange={setAreStateDocsFiled}
            />
          </Col>
        </CenteredRow>
        <CenteredRow>
          <Col lg={8} md={8} sm={4}>
            <BooleanRadioListInput
              name="hasMultipleBusinessOwners"
              legendText="Does your business have multiple business owners?"
              value={hasMultipleBusinessOwners}
              onChange={setHasMultipleBusinessOwners}
            />
          </Col>
        </CenteredRow>
        {displayCompanyTypeSection && (
          <>
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                <SectionHeader compact title="Company type" />
              </Col>
            </CenteredRow>
            <RelativePositionCenteredRow>
              <Col lg={8} md={8} sm={4}>
                <CompanyTypeRadios
                  name="businessLegalEntityTypeSelected"
                  value={companyTypeSelected}
                  {...{ areStateDocsFiled, hasMultipleBusinessOwners }}
                  onChange={onLegalEntitySelectionChange}
                />
              </Col>
            </RelativePositionCenteredRow>
          </>
        )}
      </Grid>
      {displayBusinessTaxInfoSection && TaxInfoSection && (
        <TaxInfoSection
          areStateDocsFiled={areStateDocsFiled}
          hasMultipleBusinessOwners={hasMultipleBusinessOwners}
        />
      )}
    </Page>
  );
};

export default LegalEntity;
