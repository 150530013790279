import styled from "styled-components";
import getContentReader from "../../../utils/getContentReader";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import Page from "../../shared/Page";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import Heading from "../../shared/Heading";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import { PrefillSummaryBoxContainer } from "../../shared/PrefillBox";
import ExternalLink from "../../shared/ExternalLink";
import {
  ApplicantAddress,
  ApplicantCitizenshipStatus,
  ApplicantDateOfBirthPartial,
  ApplicantEmailAddress,
  ApplicantFirstName,
  ApplicantLastName,
  ApplicantTaxId,
  ApplicantHomePhonePartial,
  ApplicantMobilePhonePartial,
} from "./applicantPrefillSections";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";

const VisitBranchLink = styled(ExternalLink).attrs({
  href: "https://locations.capitalone.com/",
})``;

const ApplicantInformationPrefillIncorrect = () => {
  usePageTitle("Applicant Information Incorrect");
  usePreventNavigation();
  const headingRef = useFocusHeading();
  const content = usePreSubmitTreatment("branchPreSubmitFriction", "incorrectPersonalInfo");

  if (!content) {
    return <LoadingSpinnerPage />;
  }
  // NOTE: body and headerText updated needed for MVP 2 - NSBOAO-31184
  const readContent = getContentReader(content);

  return (
    <Page>
      <Grid ref={headingRef}>
        <Heading
          mainHeading={readContent("headerText")}
          step="SECTION 1 OF 4"
          subHeadingProps={{
            dangerouslySetInnerHTML: { __html: readContent("bodyText") },
            id: "applicantInformationPrefillIncorrectInfo_subHeader_Text",
          }}
          extraSpacingAfterMain
        />
        <CenteredRow>
          <Col lg={8} md={8} sm={4}>
            <p className="grv-text__block--small grv-margin__top--large-2 grv-margin__bottom--small-2">
              <span>
                If you need to update this information, call the contact center (844) 318-0851 or talk to a
                branch ambassador at <VisitBranchLink>one of our branches</VisitBranchLink>.
              </span>
            </p>
            <PrefillSummaryBoxContainer>
              <ApplicantFirstName />
              <ApplicantLastName />
              <ApplicantTaxId />
              <ApplicantCitizenshipStatus />
              <ApplicantDateOfBirthPartial />
            </PrefillSummaryBoxContainer>

            <p className="grv-text__block--small grv-margin__top--large-2 grv-margin__bottom--small-2">
              If you need to update this information, sign in to your Capital One account and save your
              changes. Then restart your application to confirm your details are correct. You may now exit
              this application.
            </p>
            <PrefillSummaryBoxContainer>
              <ApplicantMobilePhonePartial />
              <ApplicantHomePhonePartial />
              <ApplicantEmailAddress />
              <ApplicantAddress />
            </PrefillSummaryBoxContainer>
          </Col>
        </CenteredRow>
      </Grid>
    </Page>
  );
};

export default ApplicantInformationPrefillIncorrect;
