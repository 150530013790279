import styled from "styled-components";
import getContentReader from "../../../utils/getContentReader";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import Page from "../../shared/Page";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import Heading from "../../shared/Heading";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import { PrefillSummaryBoxContainer } from "../../shared/PrefillBox";
import {
  BusinessLegalName,
  BusinessLegalEntityType,
  BusinessIsNonProfit,
  BusinessTaxId,
  BusinessOwnershipType,
} from "./businessPrefillSections";
import ExternalLink from "../../shared/ExternalLink";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";

const VisitBranchLink = styled(ExternalLink).attrs({
  href: "https://locations.capitalone.com/",
})``;

const BusinessInformationPrefillIncorrect = () => {
  usePageTitle("Existing Business Information Prefilled");
  usePreventNavigation();
  const headingRef = useFocusHeading();
  const content = usePreSubmitTreatment("branchPreSubmitFriction", "incorrectBusinessInfo");

  if (!content) {
    return <LoadingSpinnerPage />;
  }

  const readContent = getContentReader(content);

  return (
    <Page>
      <Grid ref={headingRef}>
        <Heading
          step="SECTION 2 OF 4"
          mainHeading={readContent("headerText")}
          subHeadingProps={{
            dangerouslySetInnerHTML: { __html: readContent("bodyText") },
            id: "businessInformationPrefillIncorrectInfo_subHeader_Text",
          }}
        />
        <CenteredRow className="grv-margin__top--large grv-margin__bottom--normal">
          <Col lg={8} md={8} sm={4}>
            <div>
              <p className="grv-alert--warning grv-alert--active grv-alert__message grv-text__body--small">
                <span>
                  If you need to update this information, call the contact center (844) 318-0851 or talk to a
                  branch ambassador at <VisitBranchLink>one of our branches</VisitBranchLink>.
                </span>
              </p>
            </div>
          </Col>
        </CenteredRow>
        <CenteredRow className="">
          <Col lg={8} md={8} sm={4}>
            <PrefillSummaryBoxContainer>
              <BusinessLegalName />
              <BusinessOwnershipType />
              <BusinessLegalEntityType />
              <BusinessTaxId />
              <BusinessIsNonProfit />
            </PrefillSummaryBoxContainer>
          </Col>
        </CenteredRow>
      </Grid>
    </Page>
  );
};

export default BusinessInformationPrefillIncorrect;
