import { forwardRef, memo } from "react";
import * as yup from "yup";
import shallow from "zustand/shallow";
import {
  businessTaxClassificationDisplay,
  INCORPORATED,
  UNINCORPORATED,
  S_CORPORATION,
  C_CORPORATION,
  PARTNERSHIP as TAX_CLASSIFICATION_PARTNERSHIP,
  INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC,
} from "../../../constants/businessTaxClassifications";
import { CORPORATION, LIMITED_LIABILITY_COMPANY, PARTNERSHIP } from "../../../constants/legalEntityTypes";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import Select from "../Select";
import useStore from "../../../store/store";
import { useDropdownId } from "../../../utils/hooks/usePageScopedId";

export const taxClassificationSchema = yup
  .string()
  .required("Select a tax classification")
  .notOneOf([PLACEHOLDER_NONE], "Select a tax classification");

const TaxClassificationOption = memo(({ value }) => (
  <option value={value}>{businessTaxClassificationDisplay[value]}</option>
));

const DisabledTaxClassification = forwardRef(({ value, name = "taxClassification", ...rest }, ref) => (
  <Select
    id={useDropdownId(name)}
    name={name}
    className="grv-select__input--no-selection"
    labelClassName="grv-label--disabled"
    label="What is your tax classification?"
    {...rest}
    ref={ref}
    disabled // disabled is placed after prop spread to avoid enabling inadvertantly
  >
    <TaxClassificationOption value={value} />
  </Select>
));

export const SingleMemberLLCTaxClassification = forwardRef((props, ref) => (
  <DisabledTaxClassification value={INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC} {...props} ref={ref} />
));

export const PartnershipForProfitTaxClassification = forwardRef((props, ref) => (
  <DisabledTaxClassification value={TAX_CLASSIFICATION_PARTNERSHIP} {...props} ref={ref} />
));

// Utility hook for managing the default tax classification for a form.
// Forms are responsible for resetting the field when various toggles change but for setting the
// initial value of the field on a return to the page, that logic depends on what options are actually
// available in the select.
// Instead of duplicating that logic, the problem can be side-stepped - if the *stored* company type
// and non-profit status match what is passed to this function, then the stored tax classification is
// returned. Otherwise, PLACEHOLDER_NONE is returned, properly setting the field's default.
export const useDefaultTaxClassification = ({ isNonProfit, companyType }) => {
  const [storedCompanyType, storedIsNonProfit, storedTaxClassification] = useStore(
    state => [state.companyType, state.isNonProfit, state.businessTaxClassification],
    shallow
  );

  if (storedCompanyType === companyType && storedIsNonProfit === isNonProfit) {
    return storedTaxClassification || PLACEHOLDER_NONE;
  }
  return PLACEHOLDER_NONE;
};

// Simple wrapper around Select that is populated with the appropriate options depending on company type and non-profit status
// Forwards all props, including ref, to the underlying Select component, with the exception of companyType and isNonProfit, which
// it uses to determine which options are included. Also uses a default label if not provided.
const TaxClassificationSelect = forwardRef(({ companyType, isNonProfit, name, ...rest }, ref) => (
  <Select ref={ref} id={useDropdownId(name)} name={name} label="What is your tax classification?" {...rest}>
    <option className="grv-select__placeholder" value={PLACEHOLDER_NONE} disabled>
      Select a tax classification
    </option>
    {(companyType === LIMITED_LIABILITY_COMPANY || companyType === PARTNERSHIP) && (
      <TaxClassificationOption value={TAX_CLASSIFICATION_PARTNERSHIP} />
    )}
    {companyType === LIMITED_LIABILITY_COMPANY && (
      <>
        <TaxClassificationOption value={C_CORPORATION} />
        <TaxClassificationOption value={S_CORPORATION} />
      </>
    )}
    {(companyType === LIMITED_LIABILITY_COMPANY || companyType === PARTNERSHIP) && isNonProfit && (
      <>
        <TaxClassificationOption value={INCORPORATED} />
        <TaxClassificationOption value={UNINCORPORATED} />
      </>
    )}
    {companyType === CORPORATION && (
      <>
        <TaxClassificationOption value={C_CORPORATION} />
        <TaxClassificationOption value={S_CORPORATION} />
      </>
    )}
  </Select>
));

export default TaxClassificationSelect;
