import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import shallow from "zustand/shallow";
import { Col, Grid, Row } from "../Layout";
import Select from "../Select";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import {
  ADVOCACY_AND_EDUCATION,
  CHILDREN_AND_FAMILY_SERVICES,
  CIVIC_ORGANIZATIONS,
  DEVELOPMENT_AND_RELIEF_SERVICES,
  DISEASES_DISORDERS_AND_DISCIPLINES,
  EARLY_CHILDHOOD_PROGRAMS_AND_SERVICES,
  ENVIRONMENTAL_PROTECTION_AND_CONSERVATION,
  LIBRARIES_HISTORICAL_STUDIES_LANDMARK_PRESERVATION,
  NON_MEDICAL_SCIENCE_AND_TECHNOLOGY_RESEARCH,
  RELIGIOUS_ACTIVITIES,
  organizationTypeDisplayNames,
} from "../../../constants/charitableOrganizationTypes";
import { OTHER } from "../../../constants/legalEntityTypes";
import TextInput from "../TextInput";
import useStore from "../../../store/store";
import { BooleanRadioListField } from "../RadioList";
import { useInputId, useDropdownId } from "../../../utils/hooks/usePageScopedId";

// Unconditional validation of the charitableOrganization object, exported primarily for testing.
export const charitableOrgSchema = yup.object().shape({
  purposeOfOrganization: yup
    .string()
    .required("Select a purpose of organization")
    .notOneOf([PLACEHOLDER_NONE], "Select a purpose of organization"),
  purposeOfOrganizationOther: yup.string().when("purposeOfOrganization", {
    is: OTHER,
    then: purposeSchema =>
      purposeSchema
        .required("Please enter the purpose of your organization.")
        .matches(/^((?!(\d[\s.-]*){9}).)*$/, {
          excludeEmptyString: true,
          message:
            "Avoid entering sensitive information such as Business Tax ID Numbers or Social Security Numbers",
        })
        .max(60, "Your Description cannot be longer than 60 letters."),
    otherwise: purposeSchema => purposeSchema,
  }),
  hasRepeatedInternationalActivity: yup.bool(),
});

// Schema containing isCharitableOrganization as well as the charitableOrganization,
// which will be conditionally validated against the above schema when
// isCharitableOrganization is true. This should be spread into the form schema definition.
export const conditionalCharitableOrganizationSchema = {
  isCharitableOrganization: yup.bool(),
  charitableOrganization: yup.object().when("isCharitableOrganization", {
    is: true,
    then: schema => schema.concat(charitableOrgSchema),
    otherwise: schema => schema,
  }),
};

// Generate default values for the charitable organization fields by pulling content from the store.
// The resulting object can be spread into the defaultValues passed to useForm.
export const useDefaultCharitableOrganizationInfo = () => {
  const [
    storedIsCharitableOrganization,
    storedPurposeOfOrganization,
    storedPurposeOfOrganizationOther,
    storedInternationalActivity,
  ] = useStore(
    state => [
      state.isCharitableOrganization,
      state.businessPurposeOfOrganizationCategory,
      state.businessPurposeOfOrganizationOtherDescription,
      state.doesBusinessExpectRepeatedInternationalActivity,
    ],
    shallow
  );

  return {
    isCharitableOrganization: storedIsCharitableOrganization || false,
    charitableOrganization: {
      purposeOfOrganization: storedPurposeOfOrganization || PLACEHOLDER_NONE,
      purposeOfOrganizationOther: storedPurposeOfOrganizationOther || "",
      hasRepeatedInternationalActivity: storedInternationalActivity || false,
    },
  };
};

const PurposeOfOrganization = ({ register, errors }) => (
  <Select
    id={useDropdownId("purposeOfOrganization")}
    label="What is the purpose of the organization?"
    error={errors?.charitableOrganization?.purposeOfOrganization?.message}
    {...register("charitableOrganization.purposeOfOrganization")}
  >
    <option className="grv-select__placeholder" value={PLACEHOLDER_NONE} disabled>
      Select a primary purpose
    </option>
    {[
      LIBRARIES_HISTORICAL_STUDIES_LANDMARK_PRESERVATION,
      EARLY_CHILDHOOD_PROGRAMS_AND_SERVICES,
      ENVIRONMENTAL_PROTECTION_AND_CONSERVATION,
      DISEASES_DISORDERS_AND_DISCIPLINES,
      ADVOCACY_AND_EDUCATION,
      CIVIC_ORGANIZATIONS,
      CHILDREN_AND_FAMILY_SERVICES,
      DEVELOPMENT_AND_RELIEF_SERVICES,
      NON_MEDICAL_SCIENCE_AND_TECHNOLOGY_RESEARCH,
      RELIGIOUS_ACTIVITIES,
      OTHER,
    ].map(value => (
      <option key={value} value={value}>
        {organizationTypeDisplayNames[value]}
      </option>
    ))}
  </Select>
);

const PurposeOfOrganizationOther = ({ register, errors }) => (
  <TextInput
    id={useInputId("purposeOfOrganizationOther")}
    label="Describe the purpose of your organization."
    error={errors?.charitableOrganization?.purposeOfOrganizationOther?.message}
    {...register("charitableOrganization.purposeOfOrganizationOther")}
  />
);

// Section containing all charitable organization fields, which expand
// conditionally based on answers to questions within this section.
// All form data is stored under the following fields
// isCharitableOrganization - true if the business is a charitable organization, false otherwise
// charitableOrganization.purposeOfOrganization - value from the organzation purpose enum
// charitableOrganization.purposeOfOrganizationOther - string/free text provided if purposeOfOrganization is OTHER
// charitableOrganization.hasRepeatedInternationalActivity - true if the business has repeated international activity, false otherwise
const CharitableOrganizationSection = () => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const isCharitableOrganization = watch("isCharitableOrganization");
  const showOtherPurposeInput = watch("charitableOrganization.purposeOfOrganization") === OTHER;

  return (
    <Grid>
      <Row>
        <Col lg={8} md={5} offset={{ lg: 2 }} sm={4}>
          <BooleanRadioListField
            control={control}
            name="isCharitableOrganization"
            idBase="businessCharitableOrganization"
            legendText="Is your business a charitable organization?"
          />
        </Col>
      </Row>
      {isCharitableOrganization && (
        <>
          <Row>
            <Col lg={3} md={4} offset={{ lg: 2 }} sm={4}>
              <PurposeOfOrganization {...{ register, errors }} />
            </Col>
            {showOtherPurposeInput && (
              <Col lg={5} md={4} sm={4}>
                <PurposeOfOrganizationOther {...{ register, errors }} />
              </Col>
            )}
          </Row>
          <Row>
            <Col lg={4} md={4} offset={{ lg: 2 }} sm={4}>
              <BooleanRadioListField
                control={control}
                name="charitableOrganization.hasRepeatedInternationalActivity"
                idBase="internationalActivity"
                legendText="Do you expect repeatable international activity?"
              />
            </Col>
          </Row>
        </>
      )}
    </Grid>
  );
};

export default CharitableOrganizationSection;
