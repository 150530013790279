import { useCallback } from "react";
import styled from "styled-components";
import useStore from "../../../store/store";
import getContentReader from "../../../utils/getContentReader";
import { CardWithoutShadow, Col, Row } from "../../shared/Layout";

const EligibilityCard = styled(CardWithoutShadow)`
  background: var(--grv-color-background-digital-gray-5);
`;

const EligibilityRow = styled(Row)`
  margin-bottom: var(--grv-size-spacing-medium-2);
`;

const EligibilityNotice = ({ id, bundleKey, contentId }) => {
  const getEligibilityStatement = useCallback(
    state => {
      const content =
        state.content
          ?.flatMap(bundle => bundle.legalEntityDescriptionContent?.[bundleKey] ?? [])
          ?.find(bundle => bundle.contentId === contentId) ?? {};

      return getContentReader(content)("bodyText");
    },
    [bundleKey, contentId]
  );
  const statementText = useStore(getEligibilityStatement);

  return (
    <EligibilityRow>
      <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
        <EligibilityCard>
          <div className="grv-card__content">
            <p className="grv-card__body">
              <span
                className="grv-text grv-text--normal"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: statementText }}
                id={id}
              />
            </p>
          </div>
        </EligibilityCard>
      </Col>
    </EligibilityRow>
  );
};

export default EligibilityNotice;
