import styled from "styled-components";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import Button from "../../shared/Button";
import Heading from "../../shared/Heading";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import Page, { ContentContainer } from "../../shared/Page";
import ExternalLink from "../../shared/ExternalLink";
import CEMPSurvey from "../../shared/CEMPSurvey";
import { useButtonId, useLinkId } from "../../../utils/hooks/usePageScopedId";

const DecisionIcon = styled.img.attrs({
  className: "grv-margin__bottom--medium-2",
})`
  height: 128px;
  aspect-ratio: 1;
`;

const LinkSection = ({ linkId, linkTarget, linkText }) => (
  <CenteredRow className="grv-margin__top--medium-2">
    <Col offset={{ lg: 2 }}>
      <ExternalLink id={useLinkId(linkId)} href={linkTarget}>
        {linkText}
      </ExternalLink>
    </Col>
  </CenteredRow>
);

const DecisionTemplate = ({
  iconSrc,
  iconAlt = "",
  headerText,
  bodyText,
  buttonLinkId,
  onButtonLinkClicked,
  buttonType = "action",
  buttonText,
  linkTarget,
  linkId,
  linkText,
  showCEMPSurvey = false,
}) => {
  const headingRef = useFocusHeading();
  const buttonId = useButtonId(buttonLinkId);

  return (
    <Page wrapChildrenInContainer={false}>
      <Grid ref={headingRef}>
        <ContentContainer>
          <CenteredRow>
            <Col offset={{ lg: 2 }}>
              <DecisionIcon alt={iconAlt} src={iconSrc} />
            </Col>
          </CenteredRow>
          <Heading
            mainHeadingProps={{
              dangerouslySetInnerHTML: { __html: headerText },
            }}
            subHeadingProps={{
              dangerouslySetInnerHTML: { __html: bodyText },
            }}
            extraSpacingAfterMain
          />
          {onButtonLinkClicked && (
            <CenteredRow>
              <Col offset={{ lg: 2 }}>
                <Button
                  id={buttonId}
                  onClick={() => onButtonLinkClicked()}
                  gravityType={buttonType}
                  className="grv-margin__top--medium-2"
                >
                  {buttonText}
                </Button>
              </Col>
            </CenteredRow>
          )}
          {linkTarget && <LinkSection {...{ linkId, linkTarget, linkText }} />}
        </ContentContainer>
      </Grid>
      {showCEMPSurvey && <CEMPSurvey />}
    </Page>
  );
};

export default DecisionTemplate;
