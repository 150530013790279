import { forwardRef } from "react";
import createCompanyTypeRadios from "./createCompanyTypeRadios";
import { useFieldId, useLinkId } from "../../../utils/hooks/usePageScopedId";
import ExternalLink from "../ExternalLink";
import { RadioListNormalLegend } from "../RadioList";
import { BlueInfoTooltip } from "../Tooltip";

const CompanyTypeRadios = forwardRef(
  ({ name, areStateDocsFiled, hasMultipleBusinessOwners, isKnownNonProfit = false, ...rest }, ref) => {
    const id = useFieldId(name, "Radio");

    return (
      <RadioListNormalLegend
        ref={ref}
        legendText={
          <span>
            What is your company type? See{" "}
            <ExternalLink
              id={useLinkId("irsBusinessStructures")}
              href="https://www.irs.gov/businesses/small-businesses-self-employed/business-structures"
            >
              IRS Definition
            </ExternalLink>
            .{" "}
            <BlueInfoTooltip id="companyType-tooltip">
              These company type options were generated based on your selections to the “company type
              identification” questions. If you don&apos;t see your company type, ensure you answered these
              questions correctly.
            </BlueInfoTooltip>
          </span>
        }
        radios={createCompanyTypeRadios(areStateDocsFiled, hasMultipleBusinessOwners, id, isKnownNonProfit)}
        name={name}
        {...rest}
      />
    );
  }
);

export default CompanyTypeRadios;
