import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import shallow from "zustand/shallow";
import useStore from "../../../store/store";
import { BooleanRadioListField } from "../RadioList";

export const unsupportedBusinessTypesSchema = {
  isUnsupportedBusinessType: yup.boolean().nullable().required("Select a response"),
  isTrust: yup.boolean().nullable().required("Select a response"),
};

// Pulls unsupported business type and trust radio button values
// from the store and returns an object that can be spread into
// the hook form's default values to set up the form correctly.
export const useUnsupportedBusinessTypesDefault = () => {
  const [storedIsUnsupportedBusinessType, storedIsTrust] = useStore(
    state => [state.isUnsupportedBusinessType, state.isTrust],
    shallow
  );

  return {
    isUnsupportedBusinessType: storedIsUnsupportedBusinessType,
    isTrust: storedIsTrust,
  };
};

// Section containing both the radio buttons for the unsupported business
// type and trust questions.
// Since this component relies on form context, it must be wrapped inside
// of a FormProvider in order to function properly.
const UnsupportedBusinessTypesSection = () => {
  const { control } = useFormContext();

  return (
    <>
      <BooleanRadioListField
        control={control}
        name="isUnsupportedBusinessType"
        legendText="Is your business account for an association, the Boys Scouts of America, the Girl Scouts of America, a homeowners association, an electoral campaign or political action committee (PAC)?"
      />
      <BooleanRadioListField
        control={control}
        name="isTrust"
        legendText="Is your business formed as a trust?"
      />
    </>
  );
};

export default UnsupportedBusinessTypesSection;
