import usePageTitle from "../../../utils/hooks/usePageTitle";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import bankBranchImage from "../../../assets/BusinessBranchUnfilled.svg";
import getContentReader from "../../../utils/getContentReader";
import TerminalPageTemplate from "../../shared/TerminalPageTemplate";
import ExternalLink from "../../shared/ExternalLink";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";
import { useLinkId } from "../../../utils/hooks/usePageScopedId";

const OutOfFootprint = () => {
  usePageTitle("Banking products are limited in your location");
  const content = usePreSubmitTreatment("preSubmitFriction", "outOfFootPrint");
  const creditCardsLinkId = useLinkId("capitaloneCreditCards");
  const merchantServicesLinkId = useLinkId("capitaloneMerchantServices");

  if (!content) {
    return <LoadingSpinnerPage />;
  }

  const readContent = getContentReader(content);
  const bodyText = readContent("bodyText");
  const businessCardLinkStartIndex = bodyText.indexOf("{{");
  const businessCardLinkEndIndex = bodyText.indexOf("}}", businessCardLinkStartIndex);
  const merchantPaymentLinkStartIndex = bodyText.indexOf("{{", businessCardLinkEndIndex + 1);
  const merchantPaymentLinkEndIndex = bodyText.indexOf("}}", merchantPaymentLinkStartIndex + 1);
  const prebusinessCardLinkHtml = bodyText.slice(0, businessCardLinkStartIndex);
  const businessCardLinkText = bodyText.slice(businessCardLinkStartIndex + 2, businessCardLinkEndIndex);
  const inbetweenLinksHtml = bodyText.slice(businessCardLinkEndIndex + 2, merchantPaymentLinkStartIndex);
  const merchantPaymentLinkText = bodyText.slice(
    merchantPaymentLinkStartIndex + 2,
    merchantPaymentLinkEndIndex
  );
  const postMerchantPaymentLinkHtml = bodyText.slice(merchantPaymentLinkEndIndex + 2);
  return (
    <TerminalPageTemplate
      image={bankBranchImage}
      readContent={readContent}
      subHeading={
        <>
          {prebusinessCardLinkHtml}
          <ExternalLink id={creditCardsLinkId} href="https://www.capitalone.com/small-business/credit-cards/">
            {businessCardLinkText}
          </ExternalLink>
          {inbetweenLinksHtml}
          <ExternalLink
            id={merchantServicesLinkId}
            href="https://www.capitalone.com/small-business-bank/merchant-services/"
          >
            {merchantPaymentLinkText}
          </ExternalLink>
          {postMerchantPaymentLinkHtml}
        </>
      }
      enableButton
      buttonId="callToAction"
    />
  );
};

export default OutOfFootprint;
