import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Page from "../../shared/Page";
import Heading from "../../shared/Heading";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import sessionExpiredIcon from "../../../assets/session-expired.svg";
import sessionEndedIcon from "../../../assets/caution.svg";
import getContentReader from "../../../utils/getContentReader";
import Button from "../../shared/Button";
import ExternalLink from "../../shared/ExternalLink";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import useStore from "../../../store/store";
import { useButtonId, useLinkId } from "../../../utils/hooks/usePageScopedId";

const StyledImg = styled.img`
  text-align: left;
  margin-bottom: var(--grv-size-spacing-medium-2);
  height: 128px;
  aspect-ratio: 1;
`;

const Timeout = ({ icon, contentBundleId }) => {
  usePageTitle("Timeout");
  const content = usePreSubmitTreatment("preSubmitFriction", contentBundleId);
  const landingPage = useStore(state =>
    (state.getTargetTestValue("landingTreatment") || "gettingStarted") !== "gettingStarted"
      ? "/product-selection"
      : "/"
  );

  const navigate = useNavigate();
  const handleRestartApplicationButtonClick = () => {
    navigate(landingPage);
    window.location.reload();
  };
  const buttonId = useButtonId("restartApplication");
  const linkId = useLinkId("capitaloneHomepage");

  if (!content) {
    return <LoadingSpinnerPage />;
  }

  const readContent = getContentReader(content);

  return (
    <Page>
      <Grid>
        <CenteredRow>
          <Col lg={8}>
            <StyledImg alt="" src={icon} />
          </Col>
        </CenteredRow>
        <Heading
          mainHeading={readContent("headerText")}
          subHeading={readContent("bodyText")}
          extraSpacingAfterMain
        />
        <CenteredRow>
          <Col lg={8}>
            <Button
              id={buttonId}
              className="grv-button--action grv-margin__top--normal"
              onClick={handleRestartApplicationButtonClick}
            >
              {readContent("buttonText")}
            </Button>
          </Col>
        </CenteredRow>
        <CenteredRow>
          <Col lg={8} className="grv-margin__top--normal">
            <ExternalLink id={linkId} href={readContent("linkTarget")}>
              {readContent("linkText")}
            </ExternalLink>
          </Col>
        </CenteredRow>
      </Grid>
    </Page>
  );
};

export const SessionEnded = () => (
  <Timeout icon={sessionEndedIcon} contentBundleId="applicationSessionEnded" />
);

export const SessionExpired = () => (
  <Timeout icon={sessionExpiredIcon} contentBundleId="applicationSessionTimeout" />
);
