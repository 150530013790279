import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import mediaQuery from "../../../utils/mediaQuery";
import { useIsReviewPage, useReviewCancelCallback } from "../../../utils/reviewContext";
import Button from "../Button";
import { CenteredRow, Col, Grid, RightRow } from "../Layout";
import { useButtonId, buildIdFromComponents } from "../../../utils/hooks/usePageScopedId";

/*
Usage notes:
 - should be used inside a form, but after the Grid of form content, e.g.,
    <Page>
      <form>
        <Grid>
          <Heading />
          ... fields here ...
        </Grid>
        <NavigationButtons />
      </form>
    </Page>
  - no automatic navigation will occur on a review page
  - the Next and Back label defaults will change on a review page
    to be Save and Cancel respectively
  - when the Back button is clicked:
    - If onBack is provided and is a function, it is called. If onBack
      returns a promise, that promise is awaited.
    - If this is a review page, the review changes are cancelled.
    - If this is not a review page and backRoute is provided, 
      it is navigated to as a route
  - when the Next button is clicked:
    - The Next button will display a loading spinner
    - The Next button is a *submit* button which means the form
      containing it will be submitted. But, if preventSubmit is
      provided and is truthy, this event will be suppressed.
      In general this is not the right use - you should be handling
      the event in the form's onSubmit - but is provided for edge cases.
    - If onNext is provided and is a function, it is called. If onNext
      returns a promise, that promise is awaited.
    - If this is not a review page and nextRoute is provided, 
      it is navigated to as a route
    - The Next button will stop displaying a loading spinner
  - If nextLoading is set to true, the Next button will display a loading
    spinner regardless of other behavior
*/

const FullWidthButton = styled(Button)`
  width: 100%;
`;

const BackButton = styled(FullWidthButton)`
  @media not (${mediaQuery.medium}) {
    margin-top: var(--grv-size-spacing-medium-1);
  }
`;

const NavigationButtons = ({
  onBack = null,
  backRoute = null,
  hideBack = false,
  preventSubmit = false,
  onNext = null,
  nextRoute = null,
  backText = null,
  nextText = null,
  nextLoading = false,
  customCols = {},
  customBackCols = {},
  customNextCols = {},
  backProps = {},
  nextProps = {},
  pageNameOverrideForIds = null,
  className,
}) => {
  const isReviewPage = useIsReviewPage();
  const cancelReview = useReviewCancelCallback();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onNextCallback = useCallback(
    async evt => {
      setLoading(true);
      if (preventSubmit) {
        evt.preventDefault();
      }
      if (onNext && typeof onNext === "function") {
        await onNext();
      }
      if (!isReviewPage && nextRoute) {
        navigate(nextRoute);
      }
      setLoading(false);
    },
    [preventSubmit, onNext, isReviewPage, nextRoute, navigate]
  );
  const onBackCallback = useCallback(async () => {
    if (onBack && typeof onBack === "function") {
      await onBack();
    }
    if (!isReviewPage && backRoute) {
      navigate(backRoute);
    }
    if (isReviewPage && cancelReview && typeof cancelReview === "function") {
      cancelReview();
    }
  }, [onBack, isReviewPage, backRoute, cancelReview, navigate]);

  // unfortunately have to call the hooks unconditionally - thankfully this is cheap and will not trigger rerenders
  const automaticBackButtonId = useButtonId("back");
  const automaticNextButtonId = useButtonId("next");
  const backButtonId = pageNameOverrideForIds
    ? buildIdFromComponents(pageNameOverrideForIds, "back", "Button")
    : automaticBackButtonId;
  const nextButtonId = pageNameOverrideForIds
    ? buildIdFromComponents(pageNameOverrideForIds, "next", "Button")
    : automaticNextButtonId;

  return (
    <Grid className={className}>
      <CenteredRow>
        <Col lg={customCols.lg || 8} md={customCols.md || 8} sm={customCols.sm || 4}>
          <RightRow>
            <Col
              xl={customBackCols.xl || 2}
              lg={customBackCols.lg || 2}
              ml={customBackCols.ml || 2}
              md={customBackCols.md || 2}
              sm={customBackCols.sm || 4}
              order={{ xl: 1, lg: 1, ml: 1, md: 1, sm: 2 }}
            >
              {!hideBack && (
                // Button hiding is done at this level to ensure the Col space is maintained,
                // theoretically this could be done by just adding space to the Next button's
                // Col but this is probably clearer to read.
                <BackButton gravityType="text" onClick={onBackCallback} id={backButtonId} {...backProps}>
                  {backText ?? (isReviewPage ? "Cancel" : "Back")}
                </BackButton>
              )}
            </Col>
            <Col
              xl={customNextCols.xl || 2}
              lg={customNextCols.lg || 2}
              ml={customNextCols.ml || 2}
              md={customNextCols.md || 2}
              sm={customNextCols.sm || 4}
              order={{ xl: 1, lg: 1, ml: 1, md: 1, sm: 1 }}
            >
              <FullWidthButton
                type="submit"
                loading={loading || nextLoading}
                gravityType="progressive"
                onClick={onNextCallback}
                id={nextButtonId}
                {...nextProps}
              >
                {nextText ?? (isReviewPage ? "Save" : "Next")}
              </FullWidthButton>
            </Col>
          </RightRow>
        </Col>
      </CenteredRow>
    </Grid>
  );
};

export default NavigationButtons;
