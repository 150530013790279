// eligibilityTreatment
export const EXISTING_CUSTOMER_PREFILL = "EXISTING_CUSTOMER_PREFILL";
export const EXISTING_CUSTOMER_BLOCK = "EXISTING_CUSTOMER_BLOCK";
export const NEW_CUSTOMER = "NEW_CUSTOMER";
export const EXISTING_BUSINESS_PREFILL = "EXISTING_BUSINESS_PREFILL";
export const INELIGIBLE_BUSINESS_BLOCK = "INELIGIBLE_BUSINESS_BLOCK";

// reasonCodes
export const MULTIPLE_CUSTOMER_PROFILES = "MULTIPLE_CUSTOMER_PROFILES";
export const TIN_BASED_CUSTOMER = "TIN_BASED_CUSTOMER";
export const INVALID_TAX_ID_TYPE_ITIN = "INVALID_TAX_ID_TYPE_ITIN";
export const MULTIPLE_BACKEND_PROFILES = "MULTIPLE_BACKEND_PROFILES";
export const UNSUPPORTED_ACCOUNT_STATUS = "UNSUPPORTED_ACCOUNT_STATUS";
export const MISSING_KYC_FIELDS = "MISSING_KYC_FIELDS";
export const MISSING_KYC_FIELDS_EMAIL_ADDRESS = "MISSING_KYC_FIELDS_EMAIL_ADDRESS";
export const MISSING_KYC_FIELDS_PHONE_NUMBER = "MISSING_KYC_FIELDS_PHONE_NUMBER";
export const MISSING_KYC_FIELDS_HOME_PHONE_NUMBER = "MISSING_KYC_FIELDS_HOME_PHONE_NUMBER";
export const MISSING_KYC_FIELDS_MOBILE_PHONE_NUMBER = "MISSING_KYC_FIELDS_MOBILE_PHONE_NUMBER";
export const INVALID_KYC_FIELDS_EMAIL_ADDRESS = "INVALID_KYC_FIELDS_EMAIL_ADDRESS";
export const GENERAL_ERROR = "GENERAL_ERROR";
export const BACKEND_DATA_ERROR = "BACKEND_DATA_ERROR";
export const BACKEND_SYSTEM_ERROR = "BACKEND_SYSTEM_ERROR";
