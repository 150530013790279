import { format } from "date-fns";

// Puts a Date into the two formats needed for the date of birth in the application

// YYYY-MM-DD format, which is format used when updating application
// For reference, ISO strings follow the format YYYY-MM-DDTHH:mm:ss.sssZ
export const formatDateOfBirthForPatch = date => new Date(date).toISOString().substring(0, 10);

// Need date of birth in MM/DD/YYYY format for store
export const formatDateOfBirthForStore = date => format(date, "MM/dd/yyyy");
