import documentUploadImage from "../../../assets/DocumentUploadIcon.svg";
import getContentReader from "../../../utils/getContentReader";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import { useDecisionPlatformTreatment } from "../../../utils/hooks/usePostSubmitTreatment";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import TerminalPageTemplate from "../../shared/TerminalPageTemplate";

const GovIDTemplate = ({ pageTitle, decisionContentId }) => {
  usePageTitle(pageTitle);

  const govIDContent = useDecisionPlatformTreatment("inProcess", decisionContentId);

  if (!govIDContent) {
    return <LoadingSpinnerPage />;
  }

  return (
    <TerminalPageTemplate
      image={documentUploadImage}
      readContent={getContentReader(govIDContent)}
      smallIcon
    />
  );
};

export const GovIDSMS = () => <GovIDTemplate pageTitle="Gov ID SMS" decisionContentId="govIDSMS" />;

export const GovIDSelfieSMS = () => (
  <GovIDTemplate pageTitle="Gov ID Selfie SMS" decisionContentId="govIDSelfieSMS" />
);

export const GovIDEmail = () => <GovIDTemplate pageTitle="Gov ID Email" decisionContentId="govIDEmail" />;

export const GovIDSelfieEmail = () => (
  <GovIDTemplate pageTitle="Gov ID Selfie Email" decisionContentId="govIDSelfieEmail" />
);
