import styled from "styled-components";
import { CenteredRow, Col, Row } from "../../shared/Layout";

const InfoRow = styled(CenteredRow)`
  padding-top: 0;
`;

const InfoWrap = styled.div`
  display: flex;
  align-items: center;
`;

const InfoImg = styled.img`
  height: 32px;
  width: 32px;
  margin-left: var(--grv-size-spacing-16);
  border-bottom-style: none;
  border-left-style: none;
  border-right-style: none;
  border-top-style: none;
`;

const InfoTitle = styled.h3`
  margin-left: var(--grv-size-spacing-16);
  font-weight: 300;
`;

const InfoList = styled.ul.attrs({
  className: "grv-list__list grv-list__list--normal",
})`
  padding-left: 35px;
  margin-bottom: 0px;
`;

const InfoListItem = styled.li.attrs({
  className: "grv-list__list-item",
})`
  line-height: 20px;
  padding-bottom: 16px;
`;

const InfoReferenceText = styled.span.attrs({
  className: "grv-text grv-text--small grv-text--semibold",
})`
  font-style: italic;
  line-height: 21px;
  padding: 0 15px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  break-inside: avoid;
  padding-left: 59px;
  padding-bottom: ${({ $extraBottomPadding }) =>
    $extraBottomPadding ? "var(--grv-size-spacing-none)" : "var(--grv-size-spacing-medium-2)"};
`;

const InfoNoteText = styled.span.attrs({
  className: "grv-text grv-text--small grv-text--semibold",
})`
  line-height: 21px;
  padding: 0 15px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  break-inside: avoid;
  padding-top: var(--grv-size-spacing-small-2);
  padding-bottom: var(--grv-size-spacing-medium-2);
  padding-left: 59px;

  & a {
    color: var(--grv-color-icon-interaction-blue-50);
  }
`;

const RequiredInfoBlock = ({
  listName,
  icon,
  title,
  firstItem,
  secondItem,
  thirdItem = null,
  reference,
  note = null,
}) => (
  <InfoRow>
    <Col>
      <Row>
        <InfoWrap>
          <InfoImg alt="" id={`gettingStarted_${listName}ListIcon_Img`} role="presentation" src={icon} />
          <InfoTitle
            className="grv-text__heading--medium-large"
            dangerouslySetInnerHTML={{ __html: title }}
            id={`gettingStarted_${listName}ListTitle_Text`}
          />
        </InfoWrap>
      </Row>
      <Row>
        <InfoList>
          <InfoListItem
            dangerouslySetInnerHTML={{ __html: firstItem }}
            id={`gettingStarted_${listName}ListFirstItem_Text`}
          />
          <InfoListItem
            dangerouslySetInnerHTML={{ __html: secondItem }}
            id={`gettingStarted_${listName}ListSecondItem_Text`}
          />
          {thirdItem && (
            <InfoListItem
              dangerouslySetInnerHTML={{ __html: thirdItem }}
              id={`gettingStarted_${listName}ListThirdItem_Text`}
            />
          )}
        </InfoList>
        <InfoReferenceText id={`gettingStarted_${listName}ListReference_Text`} $extraBottomPadding={note}>
          Recommended for Reference:&nbsp;
          <span
            className="grv-text grv-text--small"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: reference }}
          />
        </InfoReferenceText>
        {note && (
          <InfoNoteText id={`gettingStarted_${listName}ListNote_Text`}>
            Note:&nbsp;
            <span
              className="grv-text grv-text--small"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: note }}
            />
          </InfoNoteText>
        )}
      </Row>
    </Col>
  </InfoRow>
);

export default RequiredInfoBlock;
