import React from "react";
import styled from "styled-components";
import Button from "../Button";
import { CenteredRow, Grid, Col, Row } from "../Layout";
import Page, { ContentContainer } from "../Page";
import settings from "../../../assets/settings.svg";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import getContentReader from "../../../utils/getContentReader";
import CEMPSurvey from "../CEMPSurvey";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";

const StyledHeroTitle = styled.h1`
  text-align: left;
  margin-bottom: var(--grv-size-spacing-medium-2);
  margin-top: var(--grv-size-spacing-medium-2);
`;

const ButtonContainer = styled.div`
  display: block;
`;

const StyledActionButton = styled(Button)`
  color: var(--grv-color-interaction-green-50);
  border: 3px;
  border-style: solid;
  margin-bottom: var(--grv-size-spacing-medium-2);
  border-color: var(--grv-color-interaction-green-50);
  line-height: 2em;
  margin-right: 2em;
`;

const SubHeaderRow = styled(CenteredRow)`
  margin-bottom: var(--grv-size-spacing-medium-1);
  text-align: left;
`;

const StyledImg = styled.img`
  height: 128px;
  width: 128px;
  text-align: left;
`;

const FatalErrorPageTemplate = ({
  defaultContent,
  content,
  enableButtons = false,
  pageTitle = "Fatal Error",
  showCEMPSurvey = false,
}) => {
  usePageTitle(pageTitle);
  const headingRef = useFocusHeading();
  const readContent = getContentReader(content || defaultContent);
  const findBranchButtonId = useButtonId("findBranch");
  const restartAppButtonId = useButtonId("restartApplication");

  return (
    <Page wrapChildrenInContainer={false}>
      <Grid>
        <ContentContainer>
          <CenteredRow>
            <Col lg={8}>
              <StyledImg alt="" src={settings} />
            </Col>
          </CenteredRow>
          <CenteredRow>
            <Col lg={8}>
              <StyledHeroTitle
                className="grv-text__heading--xlarge"
                dangerouslySetInnerHTML={{ __html: readContent("headerText") }}
                innerRef={headingRef}
                tabIndex="-1"
              />
            </Col>
          </CenteredRow>
          <SubHeaderRow>
            <Col lg={8}>
              <span
                className="grv-text grv-text__heading--medium-large"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: readContent("bodyText") }}
              />
            </Col>
          </SubHeaderRow>
          {enableButtons && (
            <Row className="grv-row--lg-center">
              <ButtonContainer className="grv-col--lg-8">
                <StyledActionButton
                  id={findBranchButtonId}
                  onClick={() => window.open(readContent("branchLinkTarget"), "_self")}
                  gravityType="text"
                >
                  {readContent("branchLinkText")}
                </StyledActionButton>
                <Button
                  id={restartAppButtonId}
                  onClick={() => window.open(readContent("buttonLinkTarget"), "_self")}
                  gravityType="action"
                >
                  {readContent("buttonText")}
                </Button>
              </ButtonContainer>
            </Row>
          )}
        </ContentContainer>
      </Grid>
      {showCEMPSurvey && <CEMPSurvey />}
    </Page>
  );
};

export default FatalErrorPageTemplate;
