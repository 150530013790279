// Sourced from https://stackoverflow.com/questions/62107074/how-to-hide-a-text-and-make-it-accessible-by-screen-reader
// Added due to NSBOAO-20972: Content added after selecting a legal entity type was hard to find, so screen reader users
// might not realize it's even been added to the page.
import styled from "styled-components";

const HiddenAlert = styled.p.attrs({
  "aria-atomic": "true",
  role: "alert",
})`
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  white-space: nowrap;
`;

export default HiddenAlert;
