import getContentReader from "../../../utils/getContentReader";
import TerminalPageTemplate from "../../shared/TerminalPageTemplate";
import userImage from "../../../assets/user.svg";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import usePostSubmitTreatment from "../../../utils/hooks/usePostSubmitTreatment";

const DecisionAdditionalTime = () => {
  usePageTitle("Decision/Additional Time Needed");

  const pageContent = usePostSubmitTreatment("postSubmitErrors", "5xxAutoRetryErrors");

  if (!pageContent) {
    return <LoadingSpinnerPage />;
  }

  return (
    <TerminalPageTemplate image={userImage} readContent={getContentReader(pageContent)} showCEMPSurvey />
  );
};

export default DecisionAdditionalTime;
