import cautionImage from "../../../assets/caution.svg";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import getContentReader from "../../../utils/getContentReader";
import useStore from "../../../store/store";
import TerminalPageTemplate from "../../shared/TerminalPageTemplate";
import {
  MISSING_KYC_FIELDS_HOME_PHONE_NUMBER,
  MISSING_KYC_FIELDS_MOBILE_PHONE_NUMBER,
} from "../../../constants/prefillValues";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";

const MissingPhoneNumber = ({ title = "Missing Home and Mobile Phone Number" }) => {
  usePageTitle(title);
  const content = usePreSubmitTreatment("preSubmitFriction", "missingPhoneNumber");
  const missingPhoneNumberType = useStore(state => state.missingPhoneNumberType);

  if (!content) {
    return <LoadingSpinnerPage />;
  }

  const phoneType =
    // eslint-disable-next-line no-nested-ternary
    missingPhoneNumberType === MISSING_KYC_FIELDS_HOME_PHONE_NUMBER
      ? "home"
      : missingPhoneNumberType === MISSING_KYC_FIELDS_MOBILE_PHONE_NUMBER
      ? "mobile"
      : "mobile and home";

  const readContent = getContentReader(content);
  let missingNumberBodyText = readContent("bodyText");
  missingNumberBodyText = missingNumberBodyText.replaceAll("{{PHONE_TYPE}}", phoneType);
  content[`bodyText${window.UI_ENV === "prod" ? "" : "Non"}Prod`] = missingNumberBodyText;

  return (
    <TerminalPageTemplate
      enableButton
      buttonType={null}
      buttonClassName=""
      image={cautionImage}
      mediumIcon
      readContent={getContentReader(content)}
      extraSpacingAfterMainHeading={false}
    />
  );
};

export default MissingPhoneNumber;
