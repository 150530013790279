import { forwardRef } from "react";
import combineStrings from "../../../utils/combineStrings";

const Select = forwardRef(
  (
    {
      id,
      label,
      helper,
      error,
      errorReplacesHelper = true,
      compact = false,
      labelProps = {},
      helperProps = {},
      errorProps = {},
      className = "",
      labelClassName = "",
      inputClassName = "",
      children,
      ...rest
    },
    ref
  ) => {
    const labelId = `${id}--label`;
    const helperId = `${id}--helper`;
    const errorId = `${id}--error`;
    const showHelper = helper && !(errorReplacesHelper && error);

    return (
      <div className={combineStrings("grv-form-field", className, { "grv-form-field--compact": compact })}>
        {label && (
          <label
            htmlFor={id}
            id={labelId}
            className={combineStrings("grv-label grv-label--select", labelClassName, {
              "grv-label--compact": compact,
            })}
            {...labelProps}
          >
            {label}
          </label>
        )}

        <div className={combineStrings("grv-select__wrapper", { "grv-select__wrapper": compact })}>
          <select
            ref={ref}
            id={id}
            className={combineStrings("grv-select__input", inputClassName, {
              "grv-select__input--compact": compact,
              "grv-select__input--error": error,
            })}
            aria-labelledby={label ? labelId : ""}
            aria-describedby={combineStrings({
              [helperId]: showHelper,
              [errorId]: error,
            })}
            aria-invalid={error ? "true" : "false"}
            {...rest}
          >
            {children}
          </select>
        </div>
        {showHelper && (
          <span id={helperId} className="grv-form-field__helper" {...helperProps}>
            {helper}
          </span>
        )}
        {error && (
          <span
            id={errorId}
            role="alert"
            className="grv-form-field__helper grv-form-field__helper--error"
            {...errorProps}
          >
            {error}
          </span>
        )}
      </div>
    );
  }
);

export default Select;
