import DecisionTemplate from "./DecisionTemplate";
import bankBranchImage from "../../../assets/BusinessBranchUnfilled.svg";
import cautionImage from "../../../assets/caution.svg";
import getContentReader from "../../../utils/getContentReader";
import useStore from "../../../store/store";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import { SBB_WEB } from "../../../constants/applicationChannelTypes";
import useApplicationChannel from "../../../utils/hooks/useApplicationChannel";
import usePostSubmitTreatment, {
  useMultipleDecisionPlatformTreatments,
} from "../../../utils/hooks/usePostSubmitTreatment";

const handleNewTabClick = link => () => {
  window.open(link, "_blank");
};

const DecisionDeclined = () => {
  usePageTitle("Unable to Open Account");

  // Branches and Cafes use "soft" decline messaging, which is stored under branch content
  const softDeclineContent = usePostSubmitTreatment("branchPostSubmitDeclined", "maoBranchSoftDecline");

  // Web applicants are shown more specific details explaining the decision
  // Note: the API response could return some of the fields as null instead of undefined, which will prevent default destructuring from working
  const { decisionMessages = [], adverseActionNoticeMessage = "" } = useStore(
    state => state.submissionResult?.decisionDetails ?? {}
  );
  // Current UI will only display the top priority message
  const possibleDecisionMessages = useMultipleDecisionPlatformTreatments("declined", decisionMessages);
  // Extra check in case the decision content is somehow not present, or the decisionMessages does not
  // select any valid content, this ensure we don't reduce an empty list and will instead end up with
  // a loading spinner below.
  // This really only comes up when working locally but, it doesn't hurt to be safe.
  const detailedDeclineContent =
    possibleDecisionMessages?.length &&
    possibleDecisionMessages
      .map(treatment => ({ ...treatment, priority: parseInt(treatment.priority, 10) }))
      .reduce((next, best) => (next.priority < best.priority ? next : best));

  const isChannelWeb = useApplicationChannel() === SBB_WEB;
  const content = isChannelWeb ? detailedDeclineContent : softDeclineContent;
  const decisionMessage = content?.contentId;

  let iconSrc = cautionImage;
  if (content && decisionMessage && isChannelWeb) {
    // Special cases
    if (decisionMessage === "adverseActionNotice") {
      content.bodyTextNonProd = adverseActionNoticeMessage;
      content.bodyTextProd = adverseActionNoticeMessage;
    }
    if (decisionMessage === "foreignBusiness") {
      iconSrc = bankBranchImage;
    }
  }

  if (!content) {
    return <LoadingSpinnerPage />;
  }

  const readContent = getContentReader(content);

  return (
    <DecisionTemplate
      iconSrc={iconSrc}
      headerText={readContent("headerText")}
      bodyText={readContent("bodyText")}
      buttonLinkId="decline-call-to-action"
      onButtonLinkClicked={isChannelWeb && handleNewTabClick(readContent("buttonLinkTarget"))}
      buttonType="action"
      buttonText={readContent("buttonText")}
      linkTarget={readContent("linkTarget")}
      linkId="decline-link"
      linkText={readContent("linkText")}
      showCEMPSurvey
    />
  );
};

export default DecisionDeclined;
