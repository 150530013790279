import { useEffect, useRef } from "react";

const useFocusHeading = () => {
  const headingRef = useRef();
  useEffect(() => {
    if (headingRef.current) {
      // focus the header
      headingRef.current.querySelector("h1")?.focus();
      // scroll up
      window.scrollTo({ top: 0, left: 0 });
    }
  }, []);

  return headingRef;
};

export default useFocusHeading;
