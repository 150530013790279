import styled from "styled-components";
import ExternalLink from "../ExternalLink";

const links = [
  {
    href: "https://www.capitalone.com/privacy",
    id: "privacyStyledAnchor",
    text: "Privacy",
  },
  {
    href: "https://www.capitalone.com/applications/identity-protection/commitment",
    id: "securityStyledAnchor",
    text: "Security",
  },
  {
    href: "https://www.capitalone.com/privacy/online-privacy-policy#collecting-using-sharing-information-accordion-button-5",
    id: "adChoicesStyledAnchor",
    text: "AdChoices",
  },
  {
    href: "https://www.capitalone.com/legal/terms-conditions",
    id: "termsConditionsStyledAnchor",
    text: "Terms & Conditions",
  },
];

const FooterLinksContainer = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 100%;
`;

const StyledAnchor = styled(ExternalLink).attrs({
  title: "Link opens in a new window",
})`
  color: var(--grv-color-digital-gray-60);
  display: block;
  text-decoration: none;
`;

const StyledListItem = styled.li`
  font-size: 12px;
  border-left-color: var(--grv-color-digital-gray-10);
  border-left-style: solid;
  border-left-width: 1px;
  box-sizing: border-box;
  padding-left: 8px;
  padding-right: 8px;
  width: auto;

  &:first-child {
    padding-left: 0px;
    border-left-width: 0px;
    border-left-style: none;
  }

  &:last-child {
    padding-right: 0px;
  }
`;

const FooterLinks = () => (
  <FooterLinksContainer>
    {links.map(({ href, id, text }) => (
      <StyledListItem key={id}>
        <StyledAnchor href={href} id={id}>
          {text}
        </StyledAnchor>
      </StyledListItem>
    ))}
  </FooterLinksContainer>
);

export default FooterLinks;
