import cautionImage from "../../../assets/caution.svg";
import getContentReader from "../../../utils/getContentReader";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import TerminalPageTemplate from "../../shared/TerminalPageTemplate";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";

const CannotProceed = () => {
  usePageTitle("Cannot Proceed");
  const content = usePreSubmitTreatment("preSubmitFriction", "frictionForAllCustomers");
  if (!content) {
    return <LoadingSpinnerPage />;
  }

  return (
    <TerminalPageTemplate
      image={cautionImage}
      mediumIcon
      readContent={getContentReader(content)}
      enableButton
      extraSpacingAfterMainHeading={false}
      buttonClassName=""
    />
  );
};

export default CannotProceed;
