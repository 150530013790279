export const SOLE_PROPRIETORSHIP = "SOLE_PROPRIETORSHIP";
export const LIMITED_LIABILITY_COMPANY = "LIMITED_LIABILITY_COMPANY";
export const PARTNERSHIP = "PARTNERSHIP";
export const CORPORATION = "CORPORATION";
export const GENERAL_PARTNERSHIP = "GENERAL_PARTNERSHIP";
export const C_CORPORATION = "C_CORPORATION";
export const S_CORPORATION = "S_CORPORATION";
export const LIMITED_LIABILITY_PARTNERSHIP = "LIMITED_LIABILITY_PARTNERSHIP";
export const LIMITED_PARTNERSHIP = "LIMITED_PARTNERSHIP";
export const NON_PROFIT = "NON_PROFIT";
export const OTHER = "OTHER";

export const convertLegalEntityEnumToDisplay = enumValue => {
  switch (enumValue) {
    case SOLE_PROPRIETORSHIP:
      return "Sole Proprietorship";
    case LIMITED_LIABILITY_COMPANY:
      return "Limited Liability Company";
    case PARTNERSHIP:
    case LIMITED_PARTNERSHIP:
    case GENERAL_PARTNERSHIP:
    case LIMITED_LIABILITY_PARTNERSHIP:
      return "Partnership";
    case CORPORATION:
    case C_CORPORATION:
    case S_CORPORATION:
      return "Corporation";
    case NON_PROFIT:
      return "Nonprofit";
    case OTHER:
      return "Something else";
    default:
      return enumValue;
  }
};

export const convertLegalEntityEnumToCompanyType = enumValue => {
  switch (enumValue) {
    case PARTNERSHIP:
    case LIMITED_PARTNERSHIP:
    case GENERAL_PARTNERSHIP:
    case LIMITED_LIABILITY_PARTNERSHIP:
      return PARTNERSHIP;
    case CORPORATION:
    case C_CORPORATION:
    case S_CORPORATION:
      return CORPORATION;
    default:
      return enumValue;
  }
};
