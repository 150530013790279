import styled from "styled-components";

const Link = styled.span.attrs({
  "aria-label": "", // setting the empty label is the correct accessibility behavior for links with text content
  role: "link",
  tabIndex: "0",
})`
  background: transparent;
  text-decoration: underline;
  /* May change to 40 post-gravity update, for now match progress button */
  color: var(--grv-color-icon-interaction-blue-50);
  min-width: 100%;
  cursor: pointer;
`;

export default Link;
