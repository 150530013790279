// Values should align to
// https://github-pages.cloud.capitalone.com/Gravity/gravity/foundations/grid/#specs

const mediaQuery = {
  xlarge: "min-width: 1200px",
  large: "min-width: 1024px",
  mediumLarge: "min-width: 768px",
  medium: "min-width: 600px",
  small: "min-width: 369px",
};

export default mediaQuery;
