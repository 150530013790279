import styled from "styled-components";
import shallow from "zustand/shallow";
import { CardWithoutShadow, CenteredRow, Col } from "../../shared/Layout";
import useStore from "../../../store/store";
import mediaQuery from "../../../utils/mediaQuery";
import { SBB_BRANCH } from "../../../constants/applicationChannelTypes";
import useApplicationChannel from "../../../utils/hooks/useApplicationChannel";

const selectWebEligibilityDisclosure = state =>
  state.content
    .map(
      bundle => bundle?.uiDisclosures?.find(disc => disc?.disclosureId === "eligibilityDisclosure") ?? null
    )
    .find(bundle => bundle !== null);

const selectBranchEligibilityDisclosure = state =>
  state.content
    .map(
      bundle =>
        bundle?.uiDisclosures?.find(disc => disc?.disclosureId === "branchEligibilityDisclosure") ?? null
    )
    .find(bundle => bundle !== null);

const EligibilityRow = styled(CenteredRow)`
  margin-top: var(--grv-size-spacing-48);

  @media print {
    display: none;
    margin: 0;

    & * {
      display: none;
    }
  }
`;

const EligibilitySection = styled(CardWithoutShadow)`
  background: var(--grv-color-background-digital-gray-5);
  padding-bottom: 0px;
`;

const EligibilityHeading = styled.h2.attrs({
  className: "grv-text__heading--normal grv-weight--semibold",
  id: "gettingStarted_eligibilityLabel_Text",
})`
  padding: 0;
  margin: 0px auto 14px;

  @media not (${mediaQuery.large}) {
    font-size: var(--grv-size-font-medium-1);
  }
`;

const EligibilityBody = styled.span.attrs({
  className: "grv-text grv-text--normal",
  id: "gettingStarted_eligibilityDisclosure_Text",
})`
  @media not (${mediaQuery.large}) {
    font-size: var(--grv-size-font-medium-1);
  }

  @media (${mediaQuery.large}) {
    & > p {
      margin-top: 0px;
    }
  }

  & > p:first-child {
    margin-top: 0px;
  }

  & > p {
    line-height: 1.4285em;
    margin-bottom: var(--grv-size-spacing-16);
  }
`;

const EligibilityNotice = () => {
  const [webDisclosure, branchDisclosure] = useStore(
    state => [selectWebEligibilityDisclosure(state), selectBranchEligibilityDisclosure(state)],
    shallow
  );
  const isChannelBranch = useApplicationChannel() === SBB_BRANCH;
  // SBB_WEB and SBB_CAFE both use the non-branch disclosure text
  const disclosure = isChannelBranch ? branchDisclosure : webDisclosure;

  return (
    <EligibilityRow>
      <Col lg={8}>
        <EligibilitySection>
          <div className="grv-card__content">
            <div className="grv-card__body">
              <EligibilityHeading>{disclosure?.headerText ?? "Eligibility"}</EligibilityHeading>
              <EligibilityBody dangerouslySetInnerHTML={{ __html: disclosure?.bodyText }} />
            </div>
          </div>
        </EligibilitySection>
      </Col>
    </EligibilityRow>
  );
};

export default EligibilityNotice;
