import { useCallback, useState } from "react";
import shallow from "zustand/shallow";
import { SOLE_PROPRIETORSHIP, LIMITED_LIABILITY_COMPANY } from "../../../constants/legalEntityTypes";
import useStore from "../../../store/store";
import { ReviewDialog } from "../../../utils/reviewContext";
import { CenteredRow, Col } from "../../shared/Layout";
import BusinessContactDetails from "../BusinessContactDetails";
import RiskyBusiness from "../RiskyBusiness";
import ReviewSectionHeader from "./ReviewSectionHeader";
import {
  AccountPurposes,
  AccountType,
  AnnualRevenueRange,
  ApplicantCitizenshipInfo,
  ApplicantDateOfBirth,
  ApplicantEmailAddress,
  ApplicantFullName,
  ApplicantOwnershipPercentage,
  ApplicantMobilePhoneNumber,
  ApplicantHomePhoneNumber,
  ApplicantSSN,
  ApplicantStreetAddress,
  ApplicantTitle,
  BusinessIndustry,
  BusinessMailingAddress,
  BusinessPhoneNumber,
  BusinessEmailAddress,
  BusinessStreetAddress,
  BusinessTaxId,
  BusinessWebAddress,
  CharitableBusinessInfo,
  DoingBusinessAsName,
  ForeignBusinessInfo,
  IsApplicantOwner,
  LegalBusinessName,
  LegalEntityType,
  MajorityOwnership,
  OtherBeneficialOwners,
  RiskyActivities,
  BusinessNonProfitInfo,
  TaxClassification,
  IsSingleMemberLLC,
  PrefillLegalEntityType,
  IsBusinessAddressTheHomeAddress,
  IsUnsupportedBusinessType,
  IsTrust,
  CustomerType,
  AreStateDocsFiled,
  HasMultipleBusinessOwners,
  PromoPill,
} from "./reviewSections";
import { isFeatureEnabled } from "../../../utils/configSelector";

// To enable editing for a section
// - Add the page component to the pages object below
// - Edit the page component to call useNavigateUnlessReview instead of useNavigate
// - Add an edit function prop to the ReviewSectionHeader for that section
//   that sets the page state to be the key into the pages object

// this object is used to prevent issues that arose from components being constructed prematurely
const pagesList = {
  BUSINESS_CONTACT_DETAILS: BusinessContactDetails,
  RISKY_BUSINESS: RiskyBusiness,
};

const PrefilledBusinessApplicationReviewBody = () => {
  const [
    isSoleProp,
    isLLC,
    isNonProfit,
    isSingleMemberLLC,
    promotionCode,
    displayPromotionCode,
    offerFulfillmentEnabled,
    businessHomeAddressEnabled,
    delayedExistingCustomerCheckEnabled,
  ] = useStore(
    state => [
      state.businessLegalEntityType === SOLE_PROPRIETORSHIP,
      state.businessLegalEntityType === LIMITED_LIABILITY_COMPANY,
      state.isNonProfit,
      state.isSingleMemberLLC,
      state.promotionCode,
      state.displayPromotionCode,
      isFeatureEnabled(state, "offerFulfillmentEnabled"),
      isFeatureEnabled(state, "businessHomeAddressEnabled"),
      isFeatureEnabled(state, "delayedExistingCustomerCheckEnabled"),
    ],
    shallow
  );

  const [page, setPage] = useState(null);
  const SelectedPage = page && pagesList[page];
  const closeDialog = useCallback(() => setPage(null), []);
  const showPromoPill = promotionCode && displayPromotionCode && offerFulfillmentEnabled;

  return (
    <CenteredRow>
      <Col lg={4} md={4} sm={4}>
        {showPromoPill && (
          // Note: we could have the state pulled internally in this component, but we need to know if promo code is displaying
          // in this main component anyway in order to adjust the margin on the top of the bank account type review section header.
          <PromoPill className="grv-margin__top--large-1 grv-margin__bottom--medium-1">
            Promo code: {promotionCode}
          </PromoPill>
        )}

        <ReviewSectionHeader top={!showPromoPill} id="section_bankAccountType" title="Account Type(s)" />
        <AccountType />

        {delayedExistingCustomerCheckEnabled && (
          <>
            <ReviewSectionHeader id="section_customerType" title="Existing account" />
            <CustomerType />
          </>
        )}

        <ReviewSectionHeader id="section_personalInformation" title="Personal Information" />
        <ApplicantFullName />
        <ApplicantSSN />
        <ApplicantCitizenshipInfo />
        <ApplicantDateOfBirth />
        <ApplicantMobilePhoneNumber />
        <ApplicantHomePhoneNumber />
        <ApplicantEmailAddress />
        <ApplicantStreetAddress />

        <ReviewSectionHeader top id="section_businessInformation" title="Business Information" />
        {!isSoleProp && <LegalBusinessName />}
        {!isSoleProp && <MajorityOwnership />}
        {!isNonProfit && <PrefillLegalEntityType />}
        <BusinessTaxId />
        {!isSoleProp && !isSingleMemberLLC && <BusinessNonProfitInfo />}
      </Col>
      <Col lg={4} md={4} sm={4}>
        <ReviewSectionHeader
          top
          id="section_additionalBusinessInformation"
          title="Additional Business Information"
        />
        {isNonProfit && <AreStateDocsFiled />}
        {isNonProfit && <HasMultipleBusinessOwners />}
        {/* Note that LegalEntityType is used instead of PrefillLegalEntityType purposefully here because of LLC treatment for non profit flow */}
        {isNonProfit && <LegalEntityType />}
        {!isNonProfit && isLLC && <IsSingleMemberLLC />}
        {(isNonProfit || isLLC) && <TaxClassification />}
        <IsUnsupportedBusinessType />
        <IsTrust />
        <DoingBusinessAsName />
        <AccountPurposes />
        <BusinessIndustry />
        <AnnualRevenueRange />
        <ForeignBusinessInfo />
        <CharitableBusinessInfo />
        <ReviewSectionHeader id="section_businessActivities" title="Business Activities" />
        <RiskyActivities />

        <ReviewSectionHeader
          id="section_businessContactInformation"
          title="Business Contact Information"
          edit={() => setPage("BUSINESS_CONTACT_DETAILS")}
        />
        <BusinessStreetAddress />
        {businessHomeAddressEnabled && <IsBusinessAddressTheHomeAddress />}
        <BusinessMailingAddress />
        <BusinessPhoneNumber />
        <BusinessEmailAddress />
        <BusinessWebAddress />

        {!isSoleProp && (
          <>
            <ReviewSectionHeader
              id="section_businessOwnershipInformation"
              title={isNonProfit ? "Controller Information" : "Ownership Information"}
            />
            {!isSingleMemberLLC && !isNonProfit && <IsApplicantOwner />}
            <ApplicantTitle />
            <ApplicantOwnershipPercentage />
            {!isSingleMemberLLC && !isNonProfit && <OtherBeneficialOwners />}
          </>
        )}
      </Col>
      <ReviewDialog isOpen={page !== null} onClose={closeDialog}>
        {SelectedPage && <SelectedPage />}
      </ReviewDialog>
    </CenteredRow>
  );
};

export default PrefilledBusinessApplicationReviewBody;
