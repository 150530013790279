export const isFeatureEnabled = (state, featureId) => {
  const isOverrideEnabled =
    state.content.find(bundle => bundle.configs)?.configs.find(obj => obj.label === "featureOverrideEnabled")
      ?.value || false;
  const queryParamValue = state.queryParams?.[featureId]?.toLowerCase() ?? null;
  // Default any features that aren't found to false
  const configValue =
    state.content.find(bundle => bundle.configs)?.configs?.find(obj => obj.label === featureId)?.value ||
    false;
  // Compare to string 'true' since query params are processed as strings
  return isOverrideEnabled && queryParamValue !== null
    ? queryParamValue.toLowerCase() === "true"
    : configValue;
};

export const getConfigValue = (state, configId) =>
  state.content.find(bundle => bundle.configs)?.configs?.find(obj => obj.label === configId)?.value || null;
