import SingleSignOnTemplate from "./SingleSignOnTemplate";
import getContentReader from "../../../utils/getContentReader";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";

const SingleSignOnEnroll = () => {
  usePageTitle("SSO Enroll");
  const content = usePreSubmitTreatment("branchPreSubmitFriction", "ssoEnrollment");

  if (!content) {
    return <LoadingSpinnerPage />;
  }

  const readContent = getContentReader(content);
  const enrollUrl = readContent("buttonLinkTarget");

  return (
    <SingleSignOnTemplate
      headerText={readContent("headerText")}
      bodyText={readContent("bodyText")}
      buttonText={readContent("buttonText")}
      clickHandler={() => window.open(enrollUrl, "_self")}
      buttonLinkId="ssoEnrollmentRedirect"
    />
  );
};
export default SingleSignOnEnroll;
