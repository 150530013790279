import { useCallback, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import shallow from "zustand/shallow";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import useStore from "../../../store/store";
import Heading from "../../shared/Heading";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import NavigationButtons from "../../shared/NavigationButtons";
import SectionHeader, { Divider } from "../../shared/SectionHeader";
import Page from "../../shared/Page";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import { useNavigateUnlessReview } from "../../../utils/reviewContext";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import {
  FIRST_PAGE_BUNDLE_TO_MAIN_QUESTION_MAPPING,
  CHECK_CASHING_AND_GIFT_CARDS,
  MONEY_SERVICES,
  DIGITAL_CURRENCY,
  FINANCIAL_SERVICES,
  FINANCIAL_TRANSACTIONS_PROCESSING,
  DEPOSIT_BROKER,
  lookupBusinessServiceQuestions,
} from "../../../constants/riskyBusinessQuestionsValues";
import useRouting from "../../../utils/hooks/useRouting";
import RiskyBusinessQuestions from "./RiskyBusinessQuestions";

// First HRBR page

const riskyBusinessActivitiesQuestionsContent = state =>
  state.content
    .map(bundle => bundle?.riskyProhibitedActivitiesQuestions ?? null)
    .find(bundle => bundle !== null);

export const financialActivitiesHRBRSchema = yup.object({
  // CHECK_CASHING_AND_GIFT_CARDS questions
  businessHasCheckCashingAndGiftCards: yup.boolean().nullable().required("Select a response"),
  businessHasCheckCashingAndGiftCardsThirdParty: yup
    .boolean()
    .nullable()
    .when("businessHasCheckCashingAndGiftCards", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  // MONEY_SERVICES questions
  businessHasMoneyServices: yup.boolean().nullable().required("Select a response"),
  businessHasMoneyServicesExchange: yup
    .boolean()
    .nullable()
    .when("businessHasMoneyServices", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  // DIGITAL_CURRENCY questions
  businessHasDigitalCurrency: yup.boolean().nullable().required("Select a response"),
  businessHasDigitalCurrencyExchange: yup
    .boolean()
    .nullable()
    .when("businessHasDigitalCurrency", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  // FINANCIAL_SERVICES questions
  businessHasFinancialServices: yup.boolean().nullable().required("Select a response"),
  businessHasFinancialServicesOther: yup
    .boolean()
    .nullable()
    .when("businessHasFinancialServices", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  businessHasNonStandardFinancialServices: yup
    .boolean()
    .nullable()
    .when("businessHasFinancialServices", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  businessHasInterestRateExceed: yup
    .boolean()
    .nullable()
    .when("businessHasNonStandardFinancialServices", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  businessHasNonStandardFinancialServicesOther: yup
    .boolean()
    .nullable()
    .when("businessHasFinancialServices", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  // FINANCIAL_TRANSACTIONS_PROCESSING questions
  businessHasFinancialTransactionsProcessing: yup.boolean().nullable().required("Select a response"),
  businessHasThirdPartyAchTransactions: yup
    .boolean()
    .nullable()
    .when("businessHasFinancialTransactionsProcessing", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  businessHasThirdPartyTransactionsPayments: yup
    .boolean()
    .nullable()
    .when("businessHasFinancialTransactionsProcessing", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  // DEPOSIT_BROKER questions
  businessHasDepositBroker: yup.boolean().nullable().required("Select a response"),
  businessHasDepositBrokerClients: yup
    .boolean()
    .nullable()
    .when("businessHasDepositBroker", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
  businessHasDepositBrokerDepositsPlacement: yup
    .boolean()
    .nullable()
    .when("businessHasDepositBroker", {
      is: true,
      then: () => yup.boolean().nullable().required("Select a response"),
    }),
});

const RiskyBusinessFinancialActivities = () => {
  usePageTitle("Business Services");
  usePreventNavigation();
  const headingRef = useFocusHeading();

  const [
    riskyBusinessActivitiesQuestions,
    submitRiskyProhibitedBusinesses,
    storedBusinessHasCheckCashingAndGiftCards,
    storedBusinessHasCheckCashingAndGiftCardsThirdParty,
    storedBusinessHasMoneyServices,
    storedBusinessHasMoneyServicesExchange,
    storedBusinessHasDigitalCurrency,
    storedBusinessHasDigitalCurrencyExchange,
    storedBusinessHasFinancialServices,
    storedBusinessHasFinancialServicesOther,
    storedBusinessHasNonStandardFinancialServices,
    storedBusinessHasInterestRateExceed,
    storedBusinessHasNonStandardFinancialServicesOther,
    storedBusinessHasFinancialTransactionsProcessing,
    storedBusinessHasThirdPartyTransactionsPayments,
    storedBusinessHasThirdPartyAchTransactions,
    storedBusinessHasDepositBroker,
    storedBusinessHasDepositBrokerClients,
    storedBusinessHasDepositBrokerDepositsPlacement,
  ] = useStore(
    state => [
      riskyBusinessActivitiesQuestionsContent(state),
      state.pageSubmit.submitRiskyProhibitedBusinesses,
      state.hrbrActivities.businessHasCheckCashingAndGiftCards,
      state.hrbrActivities.businessHasCheckCashingAndGiftCardsThirdParty,
      state.hrbrActivities.businessHasMoneyServices,
      state.hrbrActivities.businessHasMoneyServicesExchange,
      state.hrbrActivities.businessHasDigitalCurrency,
      state.hrbrActivities.businessHasDigitalCurrencyExchange,
      state.hrbrActivities.businessHasFinancialServices,
      state.hrbrActivities.businessHasFinancialServicesOther,
      state.hrbrActivities.businessHasNonStandardFinancialServices,
      state.hrbrActivities.businessHasInterestRateExceed,
      state.hrbrActivities.businessHasNonStandardFinancialServicesOther,
      state.hrbrActivities.businessHasFinancialTransactionsProcessing,
      state.hrbrActivities.businessHasThirdPartyTransactionsPayments,
      state.hrbrActivities.businessHasThirdPartyAchTransactions,
      state.hrbrActivities.businessHasDepositBroker,
      state.hrbrActivities.businessHasDepositBrokerClients,
      state.hrbrActivities.businessHasDepositBrokerDepositsPlacement,
    ],
    shallow
  );

  const formMethods = useForm({
    resolver: yupResolver(financialActivitiesHRBRSchema),
    shouldFocusError: false,
    defaultValues: {
      businessHasCheckCashingAndGiftCards: storedBusinessHasCheckCashingAndGiftCards,
      businessHasCheckCashingAndGiftCardsThirdParty: storedBusinessHasCheckCashingAndGiftCardsThirdParty,
      businessHasMoneyServices: storedBusinessHasMoneyServices,
      businessHasMoneyServicesExchange: storedBusinessHasMoneyServicesExchange,
      businessHasDigitalCurrency: storedBusinessHasDigitalCurrency,
      businessHasDigitalCurrencyExchange: storedBusinessHasDigitalCurrencyExchange,
      businessHasFinancialServices: storedBusinessHasFinancialServices,
      businessHasFinancialServicesOther: storedBusinessHasFinancialServicesOther,
      businessHasNonStandardFinancialServices: storedBusinessHasNonStandardFinancialServices,
      businessHasInterestRateExceed: storedBusinessHasInterestRateExceed,
      businessHasNonStandardFinancialServicesOther: storedBusinessHasNonStandardFinancialServicesOther,
      businessHasFinancialTransactionsProcessing: storedBusinessHasFinancialTransactionsProcessing,
      businessHasThirdPartyTransactionsPayments: storedBusinessHasThirdPartyTransactionsPayments,
      businessHasThirdPartyAchTransactions: storedBusinessHasThirdPartyAchTransactions,
      businessHasDepositBroker: storedBusinessHasDepositBroker,
      businessHasDepositBrokerClients: storedBusinessHasDepositBrokerClients,
      businessHasDepositBrokerDepositsPlacement: storedBusinessHasDepositBrokerDepositsPlacement,
    },
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const navigate = useNavigateUnlessReview();
  const [backRoute, nextRoute] = useRouting();

  const submitHandler = useCallback(
    async data => {
      await submitRiskyProhibitedBusinesses(data);
      navigate(nextRoute);
    },
    [submitRiskyProhibitedBusinesses, navigate, nextRoute]
  );

  const submitWithErrorHandling = useSubmitWithErrorHandling(submitHandler);

  const [mainQuestions, subQuestions] = useMemo(
    () =>
      lookupBusinessServiceQuestions({
        pageToMap: FIRST_PAGE_BUNDLE_TO_MAIN_QUESTION_MAPPING,
        content: riskyBusinessActivitiesQuestions,
      }),
    [riskyBusinessActivitiesQuestions]
  );

  if (!riskyBusinessActivitiesQuestions) {
    return <LoadingSpinnerPage />;
  }

  return (
    <Page>
      <Grid ref={headingRef}>
        <form onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
          <FormProvider {...formMethods}>
            <Heading
              step="SECTION 2 OF 4"
              mainHeading="Tell us about your business activities"
              subHeading="This is required to comply with regulatory requirements and may affect our ability to open or maintain your business accounts."
            />
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                <SectionHeader
                  id="riskyOrProhibitedBusinesses_section_header"
                  title="Financial business activities"
                />
              </Col>
            </CenteredRow>
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                <RiskyBusinessQuestions
                  category={CHECK_CASHING_AND_GIFT_CARDS}
                  label={mainQuestions[CHECK_CASHING_AND_GIFT_CARDS]}
                  subQuestions={subQuestions[CHECK_CASHING_AND_GIFT_CARDS]}
                />
                <Divider />
                <RiskyBusinessQuestions
                  category={MONEY_SERVICES}
                  label={mainQuestions[MONEY_SERVICES]}
                  subQuestions={subQuestions[MONEY_SERVICES]}
                />
                <Divider />
                <RiskyBusinessQuestions
                  category={DIGITAL_CURRENCY}
                  label={mainQuestions[DIGITAL_CURRENCY]}
                  subQuestions={subQuestions[DIGITAL_CURRENCY]}
                />
                <Divider />
                <RiskyBusinessQuestions
                  category={FINANCIAL_SERVICES}
                  label={mainQuestions[FINANCIAL_SERVICES]}
                  subQuestions={subQuestions[FINANCIAL_SERVICES]}
                />
                <Divider />
                <RiskyBusinessQuestions
                  category={FINANCIAL_TRANSACTIONS_PROCESSING}
                  label={mainQuestions[FINANCIAL_TRANSACTIONS_PROCESSING]}
                  subQuestions={subQuestions[FINANCIAL_TRANSACTIONS_PROCESSING]}
                />
                <Divider />
                <RiskyBusinessQuestions
                  category={DEPOSIT_BROKER}
                  label={mainQuestions[DEPOSIT_BROKER]}
                  subQuestions={subQuestions[DEPOSIT_BROKER]}
                />
              </Col>
            </CenteredRow>
            <NavigationButtons
              backRoute={backRoute}
              nextLoading={isSubmitting}
              pageNameOverrideForIds="riskyBusinessFinancialActivities"
            />
          </FormProvider>
        </form>
      </Grid>
    </Page>
  );
};

export default RiskyBusinessFinancialActivities;
