import styled from "styled-components";
import shallow from "zustand/shallow";
import Logo from "../../../assets/logo.png";
import LogoLightMode from "../../../assets/logo-light-mode.png";
import { Col, Grid, Row, Wrapper } from "../Layout";
import useStore from "../../../store/store";
import { isFeatureEnabled } from "../../../utils/configSelector";
import mediaQuery from "../../../utils/mediaQuery";

const Background = styled.div`
  /* Header follows scrolling */
  position: sticky;
  /* Containing element is a flexbox - make this header span the whole width */
  align-self: stretch;
  inset: 0 0 auto;
  background-color: ${({ $lightMode }) =>
    $lightMode ? `var(--grv-color-white)` : `var(--grv-color-background-core-blue-60)`};
  z-index: var(--grv-z-index-sticky);

  @media print {
    position: relative;
    -webkit-print-color-adjust: exact !important;
    display: ${({ $hidePrintViewHeader }) => ($hidePrintViewHeader ? "none" : "block")};
  }

  margin-bottom: var(--grv-size-spacing-medium-1);
  @media (${mediaQuery.large}) {
    ${({ $smallMarginOnLarge }) => ($smallMarginOnLarge ? "margin-bottom: 0;" : "")}
  }
`;

const FullHeightWrapper = styled(Wrapper)`
  height: var(--grv-size-spacing-xlarge-2);
`;

const FullHeightGrid = styled(Grid)`
  height: 100%;
`;

const FullHeightRow = styled(Row)`
  height: 100%;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
`;

const LogoImage = styled.img`
  flex-shrink: 0;
  height: 39px;
  width: 112px;
  /* margin to put space between this and the dividing line */
  margin-right: 18px;

  @media print {
    position: relative;
  }
`;

const LogoText = styled.div.attrs({
  className: "grv-text grv-text--normal",
})`
  color: ${({ $lightMode }) => ($lightMode ? `var(--grv-color-core-blue-60)` : `var(--grv-color-white)`)};
  /* put a 41px tall dividing line between this and the logo */
  border-left: 1px solid
    ${({ $lightMode }) =>
      $lightMode ? `var(--grv-color-digital-gray-15)` : `var(--grv-color-digital-gray-15)`};
  padding-left: 18px;
  height: 41px;
  /* vertically center the text in the div */
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const GreenBanner = styled.div.attrs({
  className:
    "grv-text--medium-1 grv-padding--small-2 grv-background--accent-green-10 grv-color--digital-gray-100",
})`
  text-align: center;
`;

const Header = ({ hidePrintViewHeader, hidePromoBanner, lightMode }) => {
  const [promotionCode, displayPromotionCode, reachedTerminalPage, offerFulfillmentEnabled] = useStore(
    state => [
      state.promotionCode,
      state.displayPromotionCode,
      state.reachedTerminalPage,
      isFeatureEnabled(state, "offerFulfillmentEnabled"),
    ],
    shallow
  );

  const showPromoBanner =
    promotionCode &&
    displayPromotionCode &&
    !reachedTerminalPage &&
    offerFulfillmentEnabled &&
    !hidePromoBanner;

  return (
    <Background
      $hidePrintViewHeader={hidePrintViewHeader}
      $lightMode={lightMode}
      $smallMarginOnLarge={showPromoBanner} // reduce margin on large screens only when the promo banner is shown
      className={lightMode || showPromoBanner ? "" : "grv-elevation--level-3"} // only add drop shadow conditionally
    >
      <FullHeightWrapper>
        <FullHeightGrid>
          <FullHeightRow>
            <Col>
              <LogoContainer>
                <LogoImage alt="Capital One" src={lightMode ? LogoLightMode : Logo} />
                <LogoText $lightMode={lightMode}>Small Business</LogoText>
              </LogoContainer>
            </Col>
          </FullHeightRow>
        </FullHeightGrid>
      </FullHeightWrapper>
      {showPromoBanner && (
        <GreenBanner>
          <span>
            The promo code <span className="grv-weight--semibold">{promotionCode}</span> was added to your
            application.
          </span>
        </GreenBanner>
      )}
    </Background>
  );
};

export default Header;
