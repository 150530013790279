import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import {
  citizenshipTypeDisplay,
  NONRESIDENT_ALIEN,
  RESIDENT_ALIEN,
  US_CITIZEN,
} from "../../../constants/citizenshipTypes";
import { countryTypes } from "../../../constants/countryTypes";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import CountrySelect from "../CountrySelect";
import { Col, Grid, Row } from "../Layout";
import Select from "../Select";
import { useDropdownId } from "../../../utils/hooks/usePageScopedId";

export const citizenshipSchema = {
  citizenshipStatus: yup
    .string()
    .required("Select a citizenship status")
    .notOneOf([PLACEHOLDER_NONE], "Select a citizenship status"),
  primaryCitizenship: yup.string().when("citizenshipStatus", {
    is: val => val !== US_CITIZEN && val !== PLACEHOLDER_NONE,
    then: primaryCitizenshipSchema =>
      primaryCitizenshipSchema
        .required("Select a country of primary citizenship")
        .test(
          "validateCitizenshipCountryValidity",
          "Select a country of primary citizenship",
          selectedCountry =>
            selectedCountry !== PLACEHOLDER_NONE
              ? countryTypes
                  // Need to filter "US" out since this field is not shown when citizenshipStatus = US_CITIZEN
                  .filter(country => country.value !== "US")
                  .find(({ value }) => value === selectedCountry)
              : true
        )
        .notOneOf([PLACEHOLDER_NONE], "Select a country of primary citizenship"),
  }),
  secondaryCitizenship: yup.string().when("citizenshipStatus", {
    is: val => val !== US_CITIZEN && val !== PLACEHOLDER_NONE,
    then: secondaryCitizenshipSchema =>
      secondaryCitizenshipSchema
        .test(
          "validateCitizenshipCountryValidity",
          "Select a country of secondary citizenship",
          selectedCountry =>
            selectedCountry !== PLACEHOLDER_NONE
              ? countryTypes
                  // Need to filter "US" out since this field is not shown when citizenshipStatus = US_CITIZEN
                  .filter(country => country.value !== "US")
                  .find(({ value }) => value === selectedCountry)
              : true
        )
        .test(
          "validateCitizenshipCountry",
          "You cannot select the same country",
          function combined(secondaryCitizenship) {
            // must be a function and not a closure in order to access parent
            const { primaryCitizenship } = this.parent;
            return secondaryCitizenship !== PLACEHOLDER_NONE
              ? primaryCitizenship !== secondaryCitizenship
              : true;
          }
        ),
  }),
};

export const CitizenshipFields = ({
  citizenshipStatusLabel = "Citizenship Status",
  primaryCitizenshipLabel = "Country of Primary Citizenship",
  secondaryCitizenshipLabel = "Country of Secondary Citizenship (If Applicable)",
}) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const citizenshipStatus = watch("citizenshipStatus");
  const showCitizenshipAdditionalInfo =
    citizenshipStatus !== US_CITIZEN && citizenshipStatus !== PLACEHOLDER_NONE;

  return (
    <Grid>
      <Row>
        <Col lg={3} md={3} offset={{ lg: 2 }} sm={4}>
          <Select
            id={useDropdownId("citizenshipStatus")}
            label={citizenshipStatusLabel}
            {...register("citizenshipStatus")}
            error={errors?.citizenshipStatus?.message}
          >
            <option className="grv-select__placeholder" value={PLACEHOLDER_NONE} disabled>
              Select a citizenship status
            </option>
            <option value={US_CITIZEN}>{citizenshipTypeDisplay[US_CITIZEN]}</option>
            <option value={RESIDENT_ALIEN}>{citizenshipTypeDisplay[RESIDENT_ALIEN]}</option>
            <option value={NONRESIDENT_ALIEN}>{citizenshipTypeDisplay[NONRESIDENT_ALIEN]}</option>
          </Select>
        </Col>
      </Row>
      {showCitizenshipAdditionalInfo && (
        <>
          <Row>
            <Col lg={6} md={6} offset={{ lg: 2 }} sm={4}>
              <CountrySelect
                label={primaryCitizenshipLabel}
                {...register("primaryCitizenship")}
                error={errors?.primaryCitizenship?.message}
                placeholder="Select a country of primary citizenship"
                excludeUS
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} offset={{ lg: 2 }} sm={4}>
              <CountrySelect
                label={secondaryCitizenshipLabel}
                {...register("secondaryCitizenship")}
                error={errors?.secondaryCitizenship?.message}
                placeholder="Select a country of secondary citizenship (if applicable)"
                disablePlaceholder={false} // allow deselection since field is optional
                excludeUS
              />
            </Col>
          </Row>
        </>
      )}
    </Grid>
  );
};
