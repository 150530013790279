import { useState, useCallback, forwardRef } from "react";
import TextInput from "../TextInput";

// This is a TextInput that (partially) hides its contents on blur
// Primarily this is useful for SSNs/EINs
// Props are as follows:
// - secretMask: the string to use to partially mask the input (e.g., "•••-••-" for SSNs)
// - hideIf: an optional additional predicate, which will be passed the value and must return true to hide the input
//   (i.e., this can be used to prevent hiding when the input is blurred before it is fully filled out)
// - ...rest: all other props will be passed on to TextInput, with the value, onFocus, and onBlur being
//   tweaked by this component, but in a way that should be transparent both to TextInput and to
//   wherever SecretTextInput is being used
const SecretTextInput = forwardRef(({ secretMask, hideIf, onFocus, onBlur, value = "", ...rest }, ref) => {
  const [blurred, setBlurred] = useState(true);
  return (
    <TextInput
      onFocus={useCallback(
        (...args) => {
          setBlurred(false);
          onFocus?.(...args);
        },
        [onFocus]
      )}
      onBlur={useCallback(
        (...args) => {
          setBlurred(true);
          onBlur?.(...args);
        },
        [onBlur]
      )}
      value={blurred && (hideIf?.(value) ?? true) ? `${secretMask}${value.slice(secretMask.length)}` : value}
      ref={ref}
      {...rest}
    />
  );
});

export default SecretTextInput;
