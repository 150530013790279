import ExplanationModal, { ModalText } from "../../shared/ExplanationModal";

const FactaReportingModal = ({ active, onOk }) => (
  <ExplanationModal
    {...{ active, onOk }}
    idBase="facta-reporting"
    headingText="FATCA Reporting"
    className="grv-dialog--large"
    shouldCloseOnEsc={false}
  >
    <ModalText>
      FATCA stands for Foreign Account Tax Compliance Act. This act requires certain U.S. persons to report
      information about foreign financial assets and accounts. This certification does not apply to this
      particular account with us.
    </ModalText>
  </ExplanationModal>
);

export default FactaReportingModal;
