import styled from "styled-components";
import { ReactComponent as EditFilled } from "@c1/gravity-icons/dist/svg/ui-filled-edit-1-24.svg";
import { Divider } from "../../shared/SectionHeader";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";

const HeaderContainer = styled.div`
  margin-top: ${({ $top }) => ($top ? "var(--grv-size-spacing-large-1)" : "0")};
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
`;

const Title = styled.h2.attrs({
  className: "grv-text__heading--medium-large grv-weight--semibold",
})`
  font-size: var(--grv-size-font-medium-2);
  margin-bottom: 0;
`;

const EditButton = styled.button`
  border: none;
  background: transparent;
  user-select: none;
  cursor: pointer;
`;

const EditIcon = styled(EditFilled)`
  fill: var(--grv-color-icon-interaction-blue-50);
  width: 24px;
  height: 24px;
`;

const ThinnerDivider = styled(Divider)`
  margin-bottom: var(--grv-size-spacing-medium-1);
`;

const ReviewSectionHeader = ({ title, id, top = false, edit = false }) => {
  const buttonId = useButtonId(`${id}Edit`);
  return (
    <>
      <HeaderContainer $top={top}>
        <Title id={id}>{title}</Title>
        {edit && (
          <EditButton id={buttonId} aria-label={`edit ${title.toLowerCase()}`} onClick={edit}>
            <EditIcon type="EditFilled" />
          </EditButton>
        )}
      </HeaderContainer>
      <ThinnerDivider />
    </>
  );
};

export default ReviewSectionHeader;
