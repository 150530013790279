import styled from "styled-components";
import { NON_PROFIT, convertLegalEntityEnumToDisplay } from "../../../constants/legalEntityTypes";
import { Card } from "../../shared/Layout";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";

const CardWrapper = styled(Card)`
  border: ${({ $card }) =>
    $card ? "1px solid var(--grv-color-interaction-blue-50)" : "1px solid var(--grv-color-digital-gray-100)"};
  background: ${({ $card }) => ($card ? "var(--grv-color-interaction-blue-10)" : "var(--grv-color-white)")};
  cursor: pointer;
  height: auto;
  & .grv-card__content {
    margin-top: var(--grv-size-spacing-medium-1);
    margin-bottom: var(--grv-size-spacing-medium-1);
    height: 100%;
  }
  & .grv-card__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: var(--grv-size-spacing-medium-1);
  text-align: center;
`;

const BusinessIcon = styled.svg`
  & path {
    fill: ${({ $card }) =>
      $card ? "var(--grv-color-interaction-blue-50)" : "var(--grv-color-digital-gray-100)"};
  }
`;

const BusinessRow = styled.div`
  text-align: center;
  overflow: auto;
  margin-bottom: var(--grv-size-spacing-medium-1);
`;

const BusinessRowBottom = styled(BusinessRow)`
  margin-top: auto;
`;

const BusinessCard = ({ content, handleClick, isSelected, icon, isNonProfit = false }) => (
  <CardWrapper
    role="button"
    tabIndex="0"
    onClick={() => handleClick(content)}
    id={useButtonId(content.businessSorId ? `sorId_${content.businessSorId}` : "newBusiness")}
    onKeyPress={event => event.key === "Enter" && handleClick(content)}
    $card={isSelected}
    aria-pressed={isSelected}
  >
    <ImageContainer justify="center">
      <BusinessIcon $card={isSelected} fill="none" height="75" viewBox="0 0 64 64" width="75">
        {icon}
      </BusinessIcon>
    </ImageContainer>
    <BusinessRow justify="center">
      <span className="grv-text grv-text--normal grv-text--semibold">{content.legalBusinessName}</span>
    </BusinessRow>
    <BusinessRowBottom justify="center">
      <span className="grv-text">
        {isNonProfit
          ? convertLegalEntityEnumToDisplay(NON_PROFIT)
          : convertLegalEntityEnumToDisplay(content.legalEntityType)}
      </span>
    </BusinessRowBottom>
  </CardWrapper>
);

export default BusinessCard;
