import ReactModal from "react-modal";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";
import Button from "../Button";

const PromoCodeIssueModal = ({
  idBase,
  isOpen,
  headingText,
  children,
  onClose,
  onContinue,
  showBackButton = true,
}) => {
  const modalCloseId = useButtonId(`${idBase}ModalClose`);
  const modalBackId = useButtonId(`${idBase}ModalBack`);
  const modalContinueId = useButtonId(`${idBase}ModalContinue`);

  return (
    <ReactModal
      ariaHideApp
      preventScroll
      shouldFocusAfterRender
      aria={{
        labelledby: `${idBase}ModalHeading`,
        describedby: `${idBase}ModalDescription`,
      }}
      bodyOpenClassName={null}
      className="grv-dialog grv-dialog--active"
      contentLabel={headingText}
      htmlOpenClassName={null}
      id={`${idBase}Modal`}
      isOpen={isOpen}
      overlayClassName="grv-dialog__overlay grv-dialog__overlay--dark"
      portalClassName="grv-portal"
      role="dialog"
    >
      <div className="grv-dialog__container">
        <h1 id={`${idBase}ModalHeading`} className="grv-dialog__heading grv-text__heading--large">
          {headingText}
        </h1>
        <button
          aria-label="close promotional code problem modal"
          id={modalCloseId}
          className="grv-dialog__close"
          type="button"
          onClick={onClose}
        />
        <div id={`${idBase}ModalDescription`} className="grv-dialog__content">
          {children}
        </div>
        <div className="grv-dialog__buttons">
          {showBackButton && (
            <Button gravityType="text" id={modalBackId} onClick={onClose}>
              Back
            </Button>
          )}
          <Button id={modalContinueId} onClick={onContinue}>
            Continue
          </Button>
        </div>
      </div>
    </ReactModal>
  );
};

export default PromoCodeIssueModal;
