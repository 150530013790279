import styled from "styled-components";
import { convertProductTypeEnum } from "../../../../constants/productTypes";
import { APPROVED_MODE, NEXT_DAY_MODE } from "../../../../constants/submitApplicationAndAccountStatuses";
import mediaQuery from "../../../../utils/mediaQuery";
import useStore from "../../../../store/store";

const ApplicationStatusText = styled.div`
  margin: 0;
  text-align: center;

  color: var(--grv-color-digital-gray-100);
  font-size: var(--grv-size-font-medium-1);
  font-weight: var(--grv-font-weight-normal);
  @media (${mediaQuery.medium}) {
    color: var(--grv-color-white);
    font-size: var(--grv-size-font-medium-3);
    font-weight: var(--grv-font-weight-light);
  }

  p {
    margin-top: var(--grv-size-spacing-medium-1);
    margin-bottom: var(--grv-size-spacing-medium-1);
  }
`;

const ApplicationStatusContent = ({
  numberOfAccounts,
  treatment,
  collectiveAccountStatuses,
  productList,
}) => {
  const legalBusinessName = useStore(state => state.businessLegalName);

  // possible cases are as follows:
  //
  // single account APPROVED
  // single account PENDING
  // NOTE: single account FAILURE or ERROR will show ISSUE_OPENING_MODE instead,
  //       which does not include the TitleElement
  // NOTE: single account CONFLICT will redirect to /restart-application
  // multiple account both APPROVED
  // multiple account both PENDING
  // multiple account one APPROVED one FAILURE/ERROR
  // NOTE: multiple account combos not from the above list are not valid and will show
  //       ISSUE_OPENING_MODE, which does not include the TitleElement, or will redirect
  //       to /restart-application if any of the account statuses were CONFLICT
  if (numberOfAccounts === 1) {
    // single account APPROVED:
    if (treatment === APPROVED_MODE) {
      return (
        <p>
          You&apos;ve been <strong>approved</strong>! Your{" "}
          <strong>Business {convertProductTypeEnum(productList[0])}®</strong> account for {legalBusinessName}{" "}
          has been opened.
        </p>
      );
    }
    // single account PENDING:
    return (
      <>
        <p>
          Your <strong>Business {convertProductTypeEnum(productList[0])}®</strong> account for{" "}
          {legalBusinessName} has been <strong>approved</strong>.
        </p>
        <p>
          Our systems are working on opening your account, but we&apos;ve sent you an initial email! You can
          look for <strong>setup and account details</strong> in your personal email address within the{" "}
          <strong>next business day</strong>.
        </p>
      </>
    );
  }
  if (treatment === APPROVED_MODE) {
    // multiple account both APPROVED:
    return (
      <p>
        You&apos;ve been <strong>approved</strong>! Your{" "}
        <strong>Business {convertProductTypeEnum(productList[0])}®</strong> and{" "}
        <strong>Business {convertProductTypeEnum(productList[1])}®</strong> accounts for {legalBusinessName}{" "}
        have been opened.
      </p>
    );
  }
  if (treatment === NEXT_DAY_MODE) {
    // multiple account both PENDING:
    return (
      <>
        <p>
          Your <strong>Business {convertProductTypeEnum(productList[0])}®</strong> and{" "}
          <strong>Business {convertProductTypeEnum(productList[1])}®</strong> accounts for {legalBusinessName}{" "}
          have been <strong>approved</strong>.
        </p>
        <p>
          Our systems are working on opening your account(s), but we&apos;ve sent you an initial email! You
          can look for <strong>setup and account details</strong> in your personal email address within the{" "}
          <strong>next business day</strong>.
        </p>
      </>
    );
  }
  // multiple account one APPROVED one FAILURE/ERROR:
  return (
    <>
      <p>
        You&apos;ve been <strong>approved</strong>!
      </p>
      <p>
        Your <strong>Business {convertProductTypeEnum(collectiveAccountStatuses?.ACCOUNT_BOOKED[0])}®</strong>{" "}
        account for <strong>{legalBusinessName}</strong> has been opened.
      </p>
      <p>
        However, though your{" "}
        <strong>
          Business{" "}
          {convertProductTypeEnum(
            collectiveAccountStatuses?.FAILURE?.[0] ?? collectiveAccountStatuses?.ERROR?.[0]
          )}
          ®
        </strong>{" "}
        application has been approved, there was an error on our end and your Business{" "}
        {convertProductTypeEnum(
          collectiveAccountStatuses?.FAILURE?.[0] ?? collectiveAccountStatuses?.ERROR?.[0]
        )}
        ® account was not opened. Our team is working on it and will reach out within{" "}
        <strong>3 business days</strong>.
      </p>
    </>
  );
};

const ApplicationStatusSection = props => (
  <ApplicationStatusText>
    <ApplicationStatusContent {...props} />
  </ApplicationStatusText>
);

export default ApplicationStatusSection;
