import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Fuse from "fuse.js";
import styled from "styled-components";
import TextInput from "../TextInput";
import combineRefs from "../../../utils/combineRefs";

// The Item component is used for the actual suggestions displayed in the Combobox list.
// For accessibility reasons, an id should be supplied as a prop.
// Items are mostly just a passthrough to an underlying li, but have one special prop
//    selectionValue - If this Item is selected in a Combobox, the value given for selectionValue will
//                     be passed to the onSelected callback given to the Combobox. This value can be
//                     anything, and is not passed to the underlying DOM element.
export const Item = styled.li.attrs({
  role: "option",
})`
  cursor: pointer;
  padding: var(--grv-size-spacing-small-2) var(--grv-size-spacing-medium-1);

  &[aria-selected="true"] {
    background-color: var(--grv-color-digital-gray-5);
  }
`;

const SuggestionList = styled.ul.attrs({
  role: "listbox",
  className: "grv-elevation--level-2 grv-background--white",
})`
  position: absolute;
  box-sizing: border-box;

  &[hidden] {
    display: none;
  }

  /* Rounded box */
  border-radius: var(--grv-size-border-radius-4);

  /* Hide bullets and spacing */
  list-style-type: none;
  padding-inline: 0;

  /* Fix margins */
  margin-block: 0;

  /* Elevation level 2, take 1 off z-index bc of header */
  z-index: calc(var(--grv-z-index-sticky) - 1);
  border: 1px solid var(--grv-color-digital-gray-100);

  /* Fix dimensions per design */
  width: 100%;
  min-height: 3em;
  max-height: 25em;
  overflow: auto;

  /* Terrible hardcoding to move below the actual input */
  top: 4.8em;
`;

const ComboboxWrapper = styled.div`
  position: relative;
`;

// The Combobox component provides an input field (using TextInput) for searching a list of provided options.
// The options should be provided as the children of the Combobox, and each option should be an Item component (above).
// Note this component provides no searching/filtering logic over the Items - see SearchCombobox and QueryCombobox (below).
// The props for Combobox are
//    idBase - The id of the input will be `${idBase}--input` and the id of the listbox will be `${idBase}--listbox`.
//             For accessibility, this prop should always be provided.
//    listboxLabel - The aria-label of the listbox. For accessibility, this prop should always be provided.
//    onSelected - Function that will be called when an option is selected.
//                 Args are the selectionValue prop of the selected Item, and that Item's index.
//                 Should return the text to display in the input field. If this returns null (or undefined),
//                 the raw value of the input is left in place.
//    inputProps - An object containing any props to pass to the TextInput. Defaults to an empty object.
//    children - All children should be <Item /> components
export const Combobox = ({
  idBase,
  listboxLabel,
  onSelected,
  children,
  inputProps: {
    onKeyDown: passedOnKeyDown,
    onChange: passedOnChange,
    onFocus: passedOnFocus,
    onBlur: passedOnBlur,
    ref: passedRef,
    ...restInputProps
  } = {},
}) => {
  const inputRef = useRef();
  const listRef = useRef();

  const childCount = useMemo(() => React.Children.count(children), [children]);
  const itemIds = useMemo(() => React.Children.map(children, c => c.props.id), [children]);

  const [showList, setShowList] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const closeList = useCallback(() => {
    setShowList(false);
    setActiveIndex(-1);
  }, []);

  // Make sure the active item is visible
  useEffect(() => {
    if (activeIndex >= 0) {
      listRef.current?.children
        ?.item(activeIndex)
        ?.scrollIntoView?.({ behavior: "smooth", block: "nearest" });
    }
  }, [activeIndex]);

  // Moves focus to the next item in the list, wrapping around
  const moveNext = useCallback(() => {
    setActiveIndex(currentIndex => (currentIndex + 1) % childCount);
  }, [childCount]);

  // Moves focus to the previous item in the list, wrapping around
  const movePrev = useCallback(() => {
    setActiveIndex(currentIndex => (currentIndex <= 0 ? childCount : currentIndex) - 1);
  }, [childCount]);

  // Select the active item, if that index is valid
  const selectActiveItem = useCallback(async () => {
    if (activeIndex >= 0 && activeIndex < React.Children.count(children)) {
      const selected = React.Children.toArray(children)?.[activeIndex]?.props?.selectionValue;
      inputRef.current.value = (await onSelected(selected, activeIndex)) ?? inputRef.current.value;
    }
  }, [activeIndex, children, onSelected]);

  const onKeyDown = useCallback(
    event => {
      // call the provided inputProps.onKeyDown if present
      passedOnKeyDown?.(event);

      const { altKey, ctrlKey, shiftKey, key } = event;

      if (ctrlKey || shiftKey) {
        return;
      }

      // Behavior is intended to match the ARIA standards laid out here:
      // https://www.w3.org/TR/wai-aria-practices-1.2/examples/combobox/combobox-autocomplete-list.html
      // MISSING TEST COVERAGE: Unable to test "Down", "Up", and "Esc" cases since they are synonymous with
      // "ArrowDown", "ArrowUp", and "Escape" respectively
      // istanbul ignore next
      switch (key) {
        case "Enter":
          // If an item is focused (i.e., currentIndex >= 0), select that item
          // Close the listbox
          selectActiveItem();
          closeList();
          break;
        case "Tab":
          // If an item is focused (i.e., currentIndex >= 0), select that item
          // Close the listbox
          // Then advance focus to next element (this is done by returning from here, which propagates the event)
          selectActiveItem();
          closeList();
          return;
        case "Down":
        case "ArrowDown":
          // Show the listbox
          // If not alt-down, focus the next item in the listbox
          setShowList(true);
          if (!altKey) {
            moveNext();
          }
          break;
        case "Up":
        case "ArrowUp":
          // Show the listbox
          // Focus the previous item in the list box
          setShowList(true);
          movePrev();
          break;
        case "Esc":
        case "Escape":
          // If the listbox is visible, hide it
          // If the listbox is hidden, clear the input
          if (!showList) {
            inputRef.current.value = "";
            // can skip the onChange logic added by this component
            passedOnChange?.({ target: inputRef.current });
          }
          closeList();
          break;
        case "Home":
          // Move selection to beginning of input
          inputRef.current.setSelectionRange(0, 0);
          break;
        case "End":
          // Move selection to end of input
          inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
          break;
        default:
          return; // returns out of the function, so event propagates
      }

      event.stopPropagation();
      event.preventDefault();
    },
    [passedOnKeyDown, passedOnChange, selectActiveItem, moveNext, movePrev, showList, closeList]
  );

  // Show the list and reset focus when input changes
  // Also scroll back to the top of the list
  // Also call the provided inputProps.onChange if present
  const onChange = useCallback(
    event => {
      passedOnChange?.(event);
      listRef.current?.scrollTo?.({ top: 0, behavior: "smooth" });
      setActiveIndex(-1);
      setShowList(true);
    },
    [passedOnChange]
  );

  // Show the list when input gets DOM focus
  // Also call the provided inputProps.onFocus if present
  const onFocus = useCallback(
    event => {
      passedOnFocus?.(event);
      setShowList(true);
    },
    [passedOnFocus]
  );

  // Hide the list and clear list focus when input loses DOM focus
  // Also call the provided inputProps.onBlur if present
  const onBlur = useCallback(
    event => {
      passedOnBlur?.(event);
      closeList();
    },
    [passedOnBlur, closeList]
  );

  return (
    <ComboboxWrapper>
      <TextInput
        ref={combineRefs(inputRef, passedRef)}
        id={`${idBase}--input`}
        type="text"
        role="combobox"
        aria-autocomplete="list"
        aria-expanded={activeIndex >= 0 ? "true" : "false"}
        aria-controls={`${idBase}--listbox`}
        aria-activedescendant={itemIds[activeIndex] ?? ""}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        {...restInputProps}
      />
      <SuggestionList
        ref={listRef}
        id={`${idBase}--listbox`}
        aria-label={listboxLabel}
        hidden={childCount === 0 || !showList}
      >
        {React.Children.map(children, (child, i) =>
          React.cloneElement(child, {
            "aria-selected": i === activeIndex,
            onMouseEnter: () => {
              setActiveIndex(i);
            },
            onMouseDown: () => {
              // setting the active index and closing the list isn't *strictly* necessary here
              // since the onMouseEnter covers the former and the onBlur covers the latter,
              // but this ensures there's no way to hit a weird corner case on those events.
              setActiveIndex(i);
              selectActiveItem();
              closeList();
            },
          })
        )}
      </SuggestionList>
    </ComboboxWrapper>
  );
};

// The SearchCombobox component is a wrapper around a Combobox for when all options are known, and can be searched
// using Fuse (https://fusejs.io/examples.html#search-object-array).
// When no text is present in the input, no items are considered to match the search.
// This inherits all props from Combobox, as well as requiring the following
//    source - An array of objects to search
//    keys - A list of keys to use to search the source objects (see Fuse docs for details)
//    maxResults - Maximum number of results, defaults to 10
//    renderItem - Function that will be called to render each object in the search results.
//                 Called with the object from the source array, and the current text of the TextInput.
//                 Should return an <Item /> component. Each <Item /> should ideally have a unique key as well.
export const SearchCombobox = ({
  source,
  keys,
  maxResults = 10,
  renderItem,
  idBase,
  listboxLabel,
  onSelected,
  inputProps: { onChange: passedOnChange, ...restInputProps } = {},
}) => {
  const lookup = useMemo(
    // threshold of 0.3 chosen by experiment, balancing accuracy and speed
    // the default value of 0.6 was causing significant slowdown with longer search strings
    // see Fuse docs for more details: https://fusejs.io/concepts/scoring-theory.html
    () => new Fuse(source, { keys, includeScore: false, threshold: 0.3 }, Fuse.createIndex(keys, source)),
    [source, keys]
  );
  const [search, setSearch] = useState("");
  const onChange = useCallback(
    event => {
      passedOnChange?.(event);
      setSearch(event.target.value);
    },
    [passedOnChange]
  );

  return (
    <Combobox
      {...{
        idBase,
        listboxLabel,
        onSelected,
      }}
      inputProps={{
        onChange,
        ...restInputProps,
      }}
    >
      {search.length > 0
        ? lookup.search(search, { limit: maxResults }).map(({ item }) => renderItem(item, search))
        : []}
    </Combobox>
  );
};

// The QueryCombobox component is a wrapper around a Combobox for when options must be looked up from
// a potentially asynchronous source.
// When no text is present in the input, no results are shown, and getResults is not called.
// This inherits all props from Combobox, as well as requiring the following
//    getResults - Function that will be called each time the input changes.
//                 Called with the current text of the TextInput (i.e., the current query) as the first argument
//                 and the previous list of results as the second argument.
//                 Should return a list of results. This can be asynchronous, as the return value will be awaited.
//    renderItem - Function that will be called to render each result.
//                 Called with the result, and the current text of the TextInput.
//                 Should return an <Item /> component. Each <Item /> should ideally have a unique key as well.
export const QueryCombobox = ({
  getResults,
  renderItem,
  idBase,
  listboxLabel,
  onSelected,
  inputProps: { onChange: passedOnChange, ...restInputProps } = {},
}) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  // when the input changes, update the query
  const onChange = useCallback(
    event => {
      passedOnChange?.(event);
      setQuery(event.target.value);
    },
    [passedOnChange]
  );

  // when the results update, point this ref at the latest
  // this is used to avoid dependency loop in the getResults call below
  const resultsRef = useRef(results);
  useEffect(() => {
    resultsRef.current = results;
  }, [results]);

  // when the query changes, update the current list of results by calling getResults
  useEffect(() => {
    if (query === "") {
      setResults([]);
      return;
    }

    const updateResults = async () => {
      setResults((await getResults(query, resultsRef.current)) ?? []);
    };
    updateResults();
    // TODO might be nice to debounce these requests, and make them cancellable
  }, [query, getResults]);

  return (
    <Combobox
      {...{
        idBase,
        listboxLabel,
        onSelected,
      }}
      inputProps={{
        onChange,
        ...restInputProps,
      }}
    >
      {results.map(item => renderItem(item, query))}
    </Combobox>
  );
};
