import { ReactComponent as Megaphone } from "@c1/gravity-icons/dist/svg/ui-filled-megaphone-1-24.svg";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import NavigationButtons from "../../shared/NavigationButtons";
import Page from "../../shared/Page";
import { CenteredRow, Col, Grid } from "../../shared/Layout";
import Heading from "../../shared/Heading";
import SectionHeader from "../../shared/SectionHeader";
import Banner from "../../shared/Banner";
import useRouting from "../../../utils/hooks/useRouting";
import { PrefillSummaryBoxContainer } from "../../shared/PrefillBox";
import {
  BusinessLegalName,
  BusinessLegalEntityType,
  BusinessIsNonProfit,
  BusinessTaxId,
  BusinessOwnershipType,
} from "./businessPrefillSections";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";

const BusinessInformationPrefill = () => {
  usePageTitle("Business Information Prefilled");
  usePreventNavigation();
  const headingRef = useFocusHeading();
  const [backRoute, nextRoute] = useRouting();

  return (
    <Page>
      <Grid ref={headingRef}>
        <Heading
          step="SECTION 2 OF 4"
          mainHeading="Confirm your business details"
          subHeading={
            <span>
              Review the information and confirm the details are correct.{" "}
              <strong>Incorrect information may result in issues opening your new account.</strong>
            </span>
          }
        />
        <CenteredRow className="grv-margin__top--large-3">
          <Col lg={8} md={8} sm={4}>
            <Banner
              bannerText="We've filled in some of your business details"
              bannerIcon={<Megaphone alt="" role="presentation" />}
            />
          </Col>
        </CenteredRow>
        <CenteredRow className="grv-margin__top--medium-2">
          <Col lg={8} md={8} sm={4}>
            <SectionHeader title="Business information" compact />
          </Col>
        </CenteredRow>
        <CenteredRow>
          <Col lg={8} md={8} sm={4}>
            <PrefillSummaryBoxContainer>
              <BusinessLegalName />
              <BusinessOwnershipType />
              <BusinessLegalEntityType />
              <BusinessTaxId />
              <BusinessIsNonProfit />
            </PrefillSummaryBoxContainer>
          </Col>
        </CenteredRow>
        <CenteredRow className="grv-margin__top--medium-2 grv-margin__bottom--large-3">
          <Col lg={8} md={8} sm={4}>
            <p className="grv-text grv-text--small-1 grv-margin__bottom--none grv-margin__top--none">
              By selecting <strong>Confirm</strong>, I certify that my business details are accurate and
              up-to-date, and that incorrect information may result in a delay in opening my account.
            </p>
          </Col>
        </CenteredRow>
        <NavigationButtons
          backRoute={backRoute}
          nextRoute={nextRoute}
          nextProps={{ id: useButtonId("correct") }}
          backProps={{ id: useButtonId("incorrect") }}
          nextText="Confirm"
          backText="My information is wrong"
          customBackCols={{ xl: 5, lg: 5, ml: 3, md: 4, sm: 4 }}
          customNextCols={{ xl: 3, lg: 3 }}
        />
      </Grid>
    </Page>
  );
};

export default BusinessInformationPrefill;
