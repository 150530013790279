import ReactModal from "react-modal";
import styled from "styled-components";
import combineStrings from "../../../utils/combineStrings";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";

const ModalContainer = styled.div.attrs({
  className: "grv-dialog__container",
})`
  max-width: 740px;
  flex: 0 1 740px;
  #business-activities-dialog-content br {
    display: none;
  }
  #business-activities-dialog-content p:not(.modalNumberAnswer) {
    display: inline;
  }
`;

const ModalHeading = styled.h1.attrs({
  className: "grv-dialog__heading grv-text__heading--large",
})`
  margin-top: var(--grv-size-spacing-16);
  margin-bottom: var(--grv-size-spacing-16);
`;

const ModalContent = styled.div.attrs({
  className: "grv-dialog__content",
})`
  overflow-y: scroll;
`;

export const ModalSubheading = styled.h2.attrs({
  className: "grv-text__heading--medium",
})`
  margin-top: var(--grv-size-spacing-8);
  margin-bottom: var(--grv-size-spacing-32);
`;

export const ModalText = styled.p.attrs({
  className: "grv-text__heading--normal grv-weight--normal",
})`
  margin-bottom: var(--grv-size-spacing-16);
`;

const ExplanationModal = ({
  active,
  idBase,
  headingText,
  subHeadingText,
  onOk,
  className = "",
  buttonClassName = "",
  children,
  showButton = true,
  ...rest
}) => {
  const headingId = `${idBase}-dialog-heading`;
  const subHeadingId = `${idBase}-dialog-subheading`;
  const descriptionId = `${idBase}-dialog-content`;
  const acknowledgeButtonId = useButtonId(`${idBase}ModalAcknowledge`);
  const closeButtonId = useButtonId(`${idBase}ModalClose`);

  return (
    <ReactModal
      ariaHideApp
      preventScroll
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
      aria={{
        labelledby: headingId,
        describedby: descriptionId,
      }}
      bodyOpenClassName={null}
      className={combineStrings("grv-dialog grv-dialog--active", className)}
      contentLabel={headingText}
      htmlOpenClassName={null}
      id={`${idBase}-explanation-modal`}
      isOpen={active}
      overlayClassName="grv-dialog__overlay grv-dialog__overlay--dark"
      portalClassName="grv-portal"
      role="dialog"
      {...rest}
    >
      <ModalContainer>
        <ModalHeading id={headingId}>{headingText}</ModalHeading>
        <button
          aria-label={`close ${headingText} modal`}
          id={closeButtonId}
          className="grv-dialog__close"
          type="button"
          onClick={onOk}
        />
        {subHeadingText && <ModalSubheading id={subHeadingId}>{subHeadingText}</ModalSubheading>}
        <ModalContent tabIndex="0" id={descriptionId}>
          {children}
        </ModalContent>
        {showButton && (
          <div className="grv-dialog__buttons">
            <button
              id={acknowledgeButtonId}
              className={combineStrings("grv-button", buttonClassName)}
              type="button"
              onClick={onOk}
            >
              Got it
            </button>
          </div>
        )}
      </ModalContainer>
    </ReactModal>
  );
};

export default ExplanationModal;
