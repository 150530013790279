import { useCallback, useEffect, useRef } from "react";
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import NumberFormat from "react-number-format";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as TrashFilled } from "@c1/gravity-icons/dist/svg/ui-filled-trash-1-24.svg";
import shallow from "zustand/shallow";
import useStore from "../../../store/store";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import DisabledField from "../../shared/DisabledField";
import Heading from "../../shared/Heading";
import { CenteredRow, Col, Grid, Row } from "../../shared/Layout";
import Link from "../../shared/Link";
import Page from "../../shared/Page";
import SectionHeader from "../../shared/SectionHeader";
import TextInput from "../../shared/TextInput";
import NavigationButtons from "../../shared/NavigationButtons";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import useRouting from "../../../utils/hooks/useRouting";
import getNameSchema from "../../../utils/nameValidation";
import { useInputId } from "../../../utils/hooks/usePageScopedId";

const ApplicantOwnershipEntry = () => {
  const [businessName, firstName, lastName, percentage] = useStore(
    state => [
      state.businessLegalName,
      state.applicantFirstName,
      state.applicantLastName,
      state.applicantOwnershipPercentage,
    ],
    shallow
  );

  return (
    <Grid>
      <Row>
        <Col lg={4} md={4} offset={{ lg: 2 }} sm={4}>
          <h3 className="grv-text__heading grv-text__heading--normal">Owner 1 (You)</h3>
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} offset={{ lg: 2 }} sm={4}>
          <DisabledField label="First Name" value={firstName} />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} offset={{ lg: 2 }} sm={4}>
          <DisabledField label="Last Name" value={lastName} />
        </Col>
      </Row>
      <Row>
        <Col lg={2} md={2} offset={{ lg: 2 }} sm={2}>
          <DisabledField label={`Percentage Ownership of ${businessName}`} value={`${percentage}%`} />
        </Col>
      </Row>
    </Grid>
  );
};

const AddMoreOwnersLink = styled(Link)`
  display: inline-block;
  margin-top: var(--grv-size-spacing-small-2);
  margin-bottom: far(--grv-size-spacing-large-3);
  user-select: none;
  text-decoration: none;
`;

const DeleteLink = styled(Link).attrs({
  "aria-label": "remove business owner",
})`
  display: inline-flex;
  justify-content: flex-end;
`;

const DeleteIcon = styled(TrashFilled)`
  fill: var(--grv-color-icon-interaction-blue-50);
  width: 18px;
  height: 20px;
`;

const CanAddMoreRow = styled(CenteredRow)`
  margin-bottom: var(--grv-size-spacing-medium-2);
`;

const InsufficientOwnershipErrorRow = styled(CenteredRow)`
  margin-bottom: var(--grv-size-spacing-large-3);
`;

const InsufficientOwnershipError = styled.span.attrs({
  role: "alert",
  "aria-atomic": "true",
})`
  color: var(--grv-color-interaction-red-50);
`;

// exported for testing
export const ownerFirstNameSchema = getNameSchema({
  required: "Enter their first name",
  minLengthInvalid: "Their first name cannot be less than 2 letters",
  maxLengthInvalid: "Their first name cannot be longer than 25 letters",
  nameInvalid: "Enter their first name",
  combinedLengthInvalid: "Make sure their first and last name are less than 39 characters combined",
  otherNameKey: "lastName",
});

// exported for testing
export const ownerLastNameSchema = getNameSchema({
  required: "Enter their last name",
  minLengthInvalid: "Their last name cannot be less than 2 letters",
  maxLengthInvalid: "Their last name cannot be longer than 25 letters",
  nameInvalid: "Enter their last name",
  combinedLengthInvalid: "Make sure their first and last name are less than 39 characters combined",
  otherNameKey: "firstName",
});

// exported for testing
export const ownershipPercentageSchema = yup
  .number()
  .typeError("Enter their ownership percentage")
  .required("Enter their ownership percentage")
  .min(25, "Only provide information about owners who own 25% or more of the business")
  .max(100, "Ownership percentage cannot be more than 100%");

const ownersSchema = yup.object().shape({
  owners: yup.array().of(
    yup.object().shape({
      firstName: ownerFirstNameSchema,
      lastName: ownerLastNameSchema,
      ownershipPercentage: ownershipPercentageSchema,
    })
  ),
});

const BusinessOwnerHeading = ({ index, shouldFocus }) => {
  const ref = useRef();

  // automatically draws focus to itself on mount for accessibility
  useEffect(() => {
    if (shouldFocus && ref.current) {
      ref.current.focus();
    }
  }, [shouldFocus]);

  return (
    <h3 ref={ref} className="grv-text__heading grv-text__heading--normal" tabIndex="-1">
      Owner {index}
    </h3>
  );
};

const BusinessOwnerFieldGroup = ({ index, indexOffset, shouldFocus, onRemove }) => {
  const businessName = useStore(state => state.businessLegalName);
  const {
    register,
    control,
    trigger,
    formState: { isSubmitted, errors },
  } = useFormContext();
  const ownershipPercentageId = useInputId(`owner${index}OwnershipPercentage`);

  return (
    <Grid>
      <Row>
        <Col lg={7} md={7} offset={{ lg: 2 }} sm={3}>
          <BusinessOwnerHeading index={index + 1 + indexOffset} shouldFocus={shouldFocus} />
        </Col>
        <Col lg={1} md={1} sm={1}>
          <DeleteLink onClick={onRemove}>
            <DeleteIcon />
          </DeleteLink>
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} offset={{ lg: 2 }} sm={4}>
          <TextInput
            id={useInputId(`owner${index}FirstName`)}
            label="First Name"
            helper="This is the legal first name found on their government-issued ID"
            error={errors?.owners?.[index]?.firstName?.message}
            {...register(`owners.${index}.firstName`, {
              onChange: () => {
                // manually re-trigger validation on last name due to shared name length validation
                if (isSubmitted) {
                  trigger(`owners.${index}.lastName`);
                }
              },
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} offset={{ lg: 2 }} sm={4}>
          <TextInput
            id={useInputId(`owner${index}LastName`)}
            label="Last Name"
            helper="This is the legal last name found on their government-issued ID"
            error={errors?.owners?.[index]?.lastName?.message}
            {...register(`owners.${index}.lastName`, {
              onChange: () => {
                // manually re-trigger validation on first name due to shared name length validation
                if (isSubmitted) {
                  trigger(`owners.${index}.firstName`);
                }
              },
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={2} md={2} offset={{ lg: 2 }} sm={2}>
          <Controller
            control={control}
            name={`owners.${index}.ownershipPercentage`}
            render={({ field: { onChange, ref, ...rest } }) => (
              <NumberFormat
                customInput={TextInput}
                id={ownershipPercentageId}
                getInputRef={ref}
                label={`Percentage Ownership of ${businessName}`}
                helper="Beneficial owners are considered to have at least 25% or more ownership of the business"
                type="tel"
                decimalSeparator={false}
                isNumericString={false}
                suffix="%"
                allowNegative={false}
                error={
                  errors?.owners?.[index]?.ownershipPercentage?.message ??
                  errors?.totalOwnershipInvalid?.message
                }
                onValueChange={v => onChange(v.value)}
                {...rest}
              />
            )}
          />
        </Col>
      </Row>
    </Grid>
  );
};

const BusinessOwnersTopLevel = () => {
  usePageTitle("Business Owners");
  usePreventNavigation();
  const navigate = useNavigate();
  const [backRoute, nextRoute] = useRouting();
  const headingRef = useFocusHeading();
  const [
    businessName,
    isApplicantBusinessOwner,
    applicantOwnershipPercentage,
    storedOwners,
    isSingleMemberLLC,
    submitPage,
  ] = useStore(
    state => [
      state.businessLegalName,
      state.isApplicantBusinessOwner,
      state.applicantOwnershipPercentage || 0,
      state.businessOwners,
      state.isSingleMemberLLC,
      state.pageSubmit.submitBusinessOwnersTopLevel,
    ],
    shallow
  );
  const indexOffset = isApplicantBusinessOwner ? 1 : 0;
  const formMethods = useForm({
    resolver: yupResolver(ownersSchema, { context: { applicantOwnershipPercentage } }),
    shouldFocusError: false,
    defaultValues: {
      owners: storedOwners ?? [],
    },
  });
  const {
    control,
    watch,
    handleSubmit,
    clearErrors,
    setError,
    formState: { isSubmitting, isSubmitted, errors },
  } = formMethods;
  const { fields, append, remove } = useFieldArray({ control, name: "owners" });

  const canAddMore = !isSingleMemberLLC && indexOffset + fields.length < 4;
  // if on this page, then user must have indicated that they have additional BOs, thus they must add at least 1
  const hasAddedEnough = isSingleMemberLLC || fields.length > 0;

  // validate that the total ownership percentage is valid
  // this is done by setting/clearing an error that is unassociated with any inputs,
  // but is manuallyed used by all the percetange inputs
  // this was cleaner than alternative options with yup, since top level array validations cleared other errors
  // additionally, all of this is broken out so as to avoid setting the error before submit
  const setTotalOwnershipError = useCallback(
    () =>
      setError("totalOwnershipInvalid", {
        type: "validation",
        message:
          "Adjust your percentage to ensure that the total ownership for your company does not exceed 100%",
      }),
    [setError]
  );

  const setInsufficientOwnershipError = useCallback(
    () =>
      setError("insufficientOwnershipError", {
        type: "validation",
        message:
          "On the previous page, you indicated there are additional owners with 25% or more ownership. Add their information to continue.",
      }),
    [setError]
  );

  // first, validate the total ownership (by watching the relevant fields)
  const totalOwnershipPercentage = watch(fields.map((_, index) => `owners.${index}.ownershipPercentage`))
    .map(p => parseInt(p, 10) || 0)
    .reduce((x, y) => x + y, 0);
  const totalOwnershipInvalid = totalOwnershipPercentage + applicantOwnershipPercentage > 100;

  // then, after submit, manage the error from this effect
  useEffect(() => {
    if (isSubmitted) {
      if (totalOwnershipInvalid) {
        setTotalOwnershipError();
      } else if (!hasAddedEnough) {
        setInsufficientOwnershipError();
      } else {
        clearErrors(["totalOwnershipInvalid", "insufficientOwnershipError"]);
      }
    }
  }, [
    isSubmitted,
    totalOwnershipInvalid,
    hasAddedEnough,
    setTotalOwnershipError,
    setInsufficientOwnershipError,
    clearErrors,
  ]);

  // and on submit, make sure to set the total ownership error if relevant
  const onSubmit = useCallback(
    async ({ owners }) => {
      if (totalOwnershipInvalid) {
        setTotalOwnershipError();
        return;
      }
      if (!hasAddedEnough) {
        setInsufficientOwnershipError();
        return;
      }
      await submitPage(owners);
      navigate(nextRoute);
    },
    [
      totalOwnershipInvalid,
      hasAddedEnough,
      setTotalOwnershipError,
      setInsufficientOwnershipError,
      submitPage,
      navigate,
      nextRoute,
    ]
  );
  const submitWithErrorHandling = useSubmitWithErrorHandling(onSubmit);

  // assume that owners should not be focused - this is set to true when a new owner is added
  // this prevents owner headings grabbing focus when navigating back to this page,
  // but allows them to grab focus when they are first created
  // this is done as a ref since it is not necessary to re-render when changing this
  const shouldFocusOwners = useRef(false);

  return (
    <Page>
      <Grid ref={headingRef}>
        <Heading
          step="SECTION 3 OF 4"
          mainHeading="Tell us about your owners"
          subHeading={`By law, we need to collect information about all individuals who own 25% or more of ${businessName}.`}
        />
        <CenteredRow>
          <Col lg={8} md={8} sm={4}>
            <SectionHeader title={`${businessName} owners`} />
          </Col>
        </CenteredRow>
      </Grid>
      {isApplicantBusinessOwner && <ApplicantOwnershipEntry />}
      <form onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
        <FormProvider {...formMethods}>
          {fields.map((item, index) => (
            <BusinessOwnerFieldGroup
              key={item.id}
              {...{ index, indexOffset }}
              shouldFocus={shouldFocusOwners.current}
              onRemove={() => remove(index)}
            />
          ))}
        </FormProvider>
        {canAddMore && (
          <Grid>
            <CanAddMoreRow>
              <Col lg={8}>
                <AddMoreOwnersLink
                  onClick={() => {
                    // let owner sections automatically focus their headers
                    shouldFocusOwners.current = true;
                    // add a new owner, matching the structure expected by the API since that is what will be stored
                    append(
                      {
                        firstName: "",
                        lastName: "",
                        ownershipPercentage: "",
                      },
                      // do not focus the first field in the owner section
                      { shouldFocus: false }
                    );
                  }}
                >
                  + Add {businessName} owner (with 25% or more ownership)
                </AddMoreOwnersLink>
              </Col>
            </CanAddMoreRow>
          </Grid>
        )}
        {errors?.insufficientOwnershipError && (
          <Grid>
            <InsufficientOwnershipErrorRow>
              <Col lg={8}>
                <InsufficientOwnershipError className="grv-text">
                  {errors?.insufficientOwnershipError?.message}
                </InsufficientOwnershipError>
              </Col>
            </InsufficientOwnershipErrorRow>
          </Grid>
        )}
        <NavigationButtons backRoute={backRoute} nextLoading={isSubmitting} />
      </form>
    </Page>
  );
};

export default BusinessOwnersTopLevel;
