import SingleSignOnTemplate from "./SingleSignOnTemplate";
import getContentReader from "../../../utils/getContentReader";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";
import usePrefillHandler from "../../../utils/hooks/usePrefillHandler";

const SingleSignOnSignIn = () => {
  usePageTitle("SSO Sign In");
  const { handlePrefill } = usePrefillHandler({
    performSSORescopingRedirect: false, // cookie scoping redirects become real sign-ins to avoid confusing the user
  });
  const content = usePreSubmitTreatment("branchPreSubmitFriction", "ssoSignIn");

  if (!content || !handlePrefill) {
    return <LoadingSpinnerPage />;
  }

  const readContent = getContentReader(content);

  const attemptPrefillBeforeRedirect = async event => {
    event.preventDefault(); // stop redirect so we can check prefill endpoint first
    await handlePrefill();
  };

  return (
    <SingleSignOnTemplate
      headerText={readContent("headerText")}
      bodyText={readContent("bodyText")}
      buttonText={readContent("buttonText")}
      clickHandler={attemptPrefillBeforeRedirect}
      buttonLinkId="ssoSignInRedirect"
    />
  );
};
export default SingleSignOnSignIn;
