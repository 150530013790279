import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";
import useStore from "../../store/store";
import getContentReader from "../getContentReader";
import usePreSubmitTreatment from "./usePreSubmitTreatment";
import { sendErrorToNewRelic } from "../newRelic";
import useApplicationChannel from "./useApplicationChannel";
import { setSimulatedLoginTargetCookie } from "../getCookies";

const usePrefillHandler = ({
  performSSOLoginRedirect = true, // whether or not to perform an SSO login redirect - if false, will treat unauthorized users as prefill failures
  performSSORescopingRedirect = true, //  whether or not to perform an SSO cookie scoping redirect - if false, will never follow redirectLocation from Web Gateway and will instead force users to sign in again
} = {}) => {
  const navigate = useNavigate();
  const [environment, applicationReferenceId, selectedProducts, handleApplicantPrefill] = useStore(
    state => [
      state.content
        .find(bundle => bundle.configs)
        ?.configs?.find(({ label }) => label === "ssoEnvironmentToken")?.value,
      state.applicationReferenceId,
      String(state.applicationProductsSelected?.map(({ productType }) => productType) ?? []),
      state.handleApplicantPrefill,
    ],
    shallow
  );
  const applicationChannel = useApplicationChannel();

  // PrfrdLanding is used by SSO to determine which environment to route back to.
  const preferredLanding = window.UI_ENV === "dev" ? "SBB_DAO_DEV" : environment;

  // The applicationReferenceId + applicationChannel + selectedProducts params are all returned
  // inside the C1_TGT cookie that is used to resume and prefill the application.
  const encodedAppRefId = encodeURIComponent(applicationReferenceId);
  const encodedProducts = encodeURIComponent(selectedProducts);
  const ssoSignInContent = usePreSubmitTreatment("branchPreSubmitFriction", "ssoSignIn");
  const baseUrl = getContentReader(ssoSignInContent)("buttonLinkTarget");
  const ssoUrl = `${baseUrl}?PrfrdLanding=${preferredLanding}&applicationChannel=${applicationChannel}&selectedProducts=${encodedProducts}&applicationReferenceId=${encodedAppRefId}`;

  const handlePrefill = useCallback(async () => {
    try {
      const nextPage = await handleApplicantPrefill();
      navigate(nextPage);
    } catch (error) {
      if (error?.code === 401 && error.redirectLocation && performSSORescopingRedirect) {
        // Unauthorized error, with a redirect location for cookie re-scoping
        // For a cookie rescoping redirect - user will not even see an SSO screen, and will
        // instead be returned to the application with a "rescoped" cookie.
        // In those situations, we actually need to set up our own C1_TGT equivalent cookie, as SSO
        // will not provide this information to us.
        // Theoretically this could also be achieved by modifying the redirectLocation given to us
        // in the Web Gateway response, but that could be brittle if these APIs change even slightly.
        setSimulatedLoginTargetCookie({
          PrfrdLanding: preferredLanding, // expected by useLoginCookie logic
          applicationChannel, // expected by useLoginCookie logic and ensures the correct content is displayed
          selectedProducts, // required to resume the application
          applicationReferenceId, // required to resume the application
        });
        window.open(error.redirectLocation, "_self");
      } else if (error?.code === 401 && performSSOLoginRedirect) {
        // Other unauthorized error - send the applicant to SSO to login, unless there is an explicit reason not to do so.
        window.open(ssoUrl, "_self");
      } else {
        // Other error - just treat this as a prefill failure
        sendErrorToNewRelic(error);
        navigate("/existing-data-failure");
      }
    }
  }, [
    applicationChannel,
    applicationReferenceId,
    handleApplicantPrefill,
    navigate,
    performSSOLoginRedirect,
    performSSORescopingRedirect,
    preferredLanding,
    selectedProducts,
    ssoUrl,
  ]);

  if (!applicationReferenceId || !applicationChannel || !ssoSignInContent) {
    // should never happen but let callsite handle this and display a loading spinner
    return { ssoUrl: null, handlePrefill: null };
  }

  return { ssoUrl, handlePrefill };
};

export default usePrefillHandler;
