import { useCallback } from "react";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import shallow from "zustand/shallow";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import Heading from "../../shared/Heading";
import { CenteredRow, Col, Grid, Row } from "../../shared/Layout";
import NavigationButtons from "../../shared/NavigationButtons";
import Page from "../../shared/Page";
import SectionHeader from "../../shared/SectionHeader";
import useStore from "../../../store/store";
import TextInput from "../../shared/TextInput";
import { getPhoneNumberSchema } from "../../../utils/phoneNumberValidation";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import { useNavigateUnlessReview } from "../../../utils/reviewContext";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import useRouting from "../../../utils/hooks/useRouting";
import PhoneNumberField from "../../shared/PhoneNumberField";
import BusinessAddressesSection, {
  businessAddressesSchema,
  isBusinessAddressTheHomeAddressSchema,
  useBusinessAddressesDefaultValues,
  useBusinessAddressesValidation,
} from "../../shared/BusinessAddressesSection";
import webAddressSchema from "../../../utils/webAddressSchema";
import { emailSchema, validateEmail } from "../../../utils/emailValidation";
import { isFeatureEnabled } from "../../../utils/configSelector";
import { AddressInformationTooltip } from "../../shared/Address";
import { useInputId } from "../../../utils/hooks/usePageScopedId";

const contactInfoSchema = yup.object().shape({
  ...businessAddressesSchema,
  phone: getPhoneNumberSchema({
    required: "Enter your business phone number",
    invalid: "Enter a valid 10-digit business phone number",
  }),
  businessEmail: emailSchema,
  webAddress: webAddressSchema,
  isBusinessAddressTheHomeAddress: isBusinessAddressTheHomeAddressSchema,
});

const EmailAddress = ({ register, errors }) => (
  <TextInput
    id={useInputId("businessEmail")}
    type="email"
    label="Business Email Address"
    helper={
      <>
        Email communication will be sent to your provided <em>personal</em> email address
      </>
    }
    error={errors?.businessEmail?.message}
    {...register("businessEmail")}
  />
);

const WebAddress = ({ register, errors }) => (
  <TextInput
    id={useInputId("webAddress")}
    label="Business Web Address (Optional)"
    error={errors?.webAddress?.message}
    {...register("webAddress")}
  />
);

const BusinessContactDetails = () => {
  usePageTitle("Business Contact Details");
  usePreventNavigation();
  const headingRef = useFocusHeading();
  const [
    applicationReferenceId,
    storedBusinessPhone,
    storedBusinessEmail,
    storedWebAddress,
    storedIsBusinessAddressTheHomeAddress,
    businessHomeAddressEnabled,
    newVerificationServiceEnabled,
    submitBusinessContactInfo,
  ] = useStore(
    state => [
      state.applicationReferenceId,
      state.businessPrimaryPhone,
      state.businessPrimaryEmail,
      state.businessWebAddress,
      state.isBusinessAddressTheHomeAddress,
      isFeatureEnabled(state, "businessHomeAddressEnabled"),
      isFeatureEnabled(state, "newVerificationServiceEnabled"),
      state.pageSubmit.submitBusinessContactInfo,
    ],
    shallow
  );
  const addressDefaultValues = useBusinessAddressesDefaultValues();

  const formMethods = useForm({
    resolver: yupResolver(contactInfoSchema),
    shouldFocusError: false,
    defaultValues: {
      ...addressDefaultValues,
      phone: storedBusinessPhone || "",
      businessEmail: storedBusinessEmail || "",
      webAddress: storedWebAddress || "",
      businessHomeAddressEnabled,
      isBusinessAddressTheHomeAddress: storedIsBusinessAddressTheHomeAddress,
    },
  });
  const {
    register,
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { isSubmitting, errors },
  } = formMethods;
  const navigate = useNavigateUnlessReview();

  const [backRoute, nextRoute] = useRouting();

  const [addressValidationState, validateBusinessAddresses] = useBusinessAddressesValidation({
    setError,
    getValues,
  });
  const onSubmit = useCallback(
    async data => {
      const [{ isPhysicalValid, isMailingValid, areAddressesAcceptable }, isEmailAddressValid] =
        await Promise.all([
          validateBusinessAddresses(),
          validateEmail({
            applicationReferenceId,
            email: data.businessEmail,
            setError,
            fieldId: "businessEmail",
            newVerificationServiceEnabled,
          }),
        ]);
      if (areAddressesAcceptable && isEmailAddressValid) {
        await submitBusinessContactInfo({
          isPhysicalValid,
          isMailingValid,
          ...data,
        });
        navigate(nextRoute);
      }
    },
    [
      validateBusinessAddresses,
      setError,
      submitBusinessContactInfo,
      nextRoute,
      navigate,
      applicationReferenceId,
      newVerificationServiceEnabled,
    ]
  );

  const submitWithErrorHandling = useSubmitWithErrorHandling(onSubmit);

  return (
    <Page>
      <Grid ref={headingRef}>
        <Heading
          step="SECTION 2 OF 4"
          mainHeading="Where can we find your business?"
          subHeading="We need this to verify your business and know how to get in touch."
        />
        <CenteredRow>
          <Col lg={8} md={8} sm={4}>
            <SectionHeader title="Business address" />
          </Col>
        </CenteredRow>
      </Grid>
      <FormProvider {...formMethods}>
        <form autoComplete="off" onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
          <BusinessAddressesSection
            addressLabel={
              <span>
                Business Street Address <AddressInformationTooltip id="business-address-tooltip" />
              </span>
            }
            addressHelper={`
              Use the address for the physical location where you operate your business. Submitting an 
              unsupported address will result in delays in the application process and/or a decline decision.
            `}
            addressDescribedBy="business-address-tooltip"
            businessHomeAddressEnabled={businessHomeAddressEnabled}
            {...addressValidationState}
          />
          <Grid>
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                <SectionHeader title="Business contact information" compact />
              </Col>
            </CenteredRow>
          </Grid>
          <Grid>
            <Row>
              <Col lg={2} md={2} offset={{ lg: 2 }} sm={2}>
                <PhoneNumberField label="Business Phone Number" control={control} />
              </Col>
            </Row>
            <Row>
              <Col lg={3} md={3} offset={{ lg: 2 }} sm={4}>
                <EmailAddress {...{ register, errors }} />
              </Col>
            </Row>
            <Row>
              <Col lg={3} md={3} offset={{ lg: 2 }} sm={4}>
                <WebAddress {...{ register, errors }} />
              </Col>
            </Row>
          </Grid>
          <NavigationButtons backRoute={backRoute} nextLoading={isSubmitting} />
        </form>
      </FormProvider>
    </Page>
  );
};

export default BusinessContactDetails;
